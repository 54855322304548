import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, Spinner, Table } from 'react-bootstrap';
import { selectOrdersLoadComplete, selectOrders } from './ordersSlice';
import OrderObj from './orderObj';
import './recent-order.scss';

export default function RecentOrder() {
  const orders = useSelector(selectOrders);
  const ordersLoaded = useSelector(selectOrdersLoadComplete);
  const order = OrderObj.findRecentOrder(orders);

  return (
    <Card className="recent-order border border-black border-2">
      <Card.Title className='fw-bold text-nowrap'>Recent Order</Card.Title>
      <Card.Body>
        {!ordersLoaded &&
          <div className='text-center'>
            <Spinner className="text-center text-dark" variant="primary" animation="grow" />
          </div>}
        {(ordersLoaded && order) &&
          <>
            <Table className='table-borderless'>
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Delivery Date</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Button
                      variant="link"
                      as={Link as any}
                      to={'/my-account/orders/' + order.data.id}
                      className='text-dark p-0'
                    >
                      {order.data.id}
                    </Button>
                  </td>
                  <td>{order.getDate()}</td>
                  <td>{order.data.status}</td>
                  <td>{order.getDeliveryDate()}</td>
                  <td>${order.data.total}</td>
                </tr>
              </tbody>
            </Table>
            <div className='text-center'>
              <Button
                variant="dark"
                size="lg"
                as={Link as any}
                to="/my-account/orders"
                className='bg-black'
              >
                View All Orders
              </Button>
            </div>
          </>
        }
      </Card.Body>
    </Card>
  );
}