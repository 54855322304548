import { useSelector } from "react-redux";
import AuthorizeNetCardDetails from "./AuthorizeNetCardDetails";
import StripeCardDetails from "./StripeCardDetails";
import { selectGeneralOptions } from "../../features/mobile/mobileSlice";

export default function CreditCardDetails() {
  const getGeneralOptions = useSelector(selectGeneralOptions);
  const paymentOption = getGeneralOptions?.payment_option;
  const creditCardProcessor = paymentOption ? paymentOption : process.env.REACT_APP_CREDIT_CARD_PROCESSOR;
  if (creditCardProcessor === 'stripe') {
    return (<StripeCardDetails />)
  }

  if (creditCardProcessor === 'authorize.net') {
    return (<AuthorizeNetCardDetails />)
  }
  return (<></>);
}