import { Badge } from 'react-bootstrap';
import ProductObj from './productObj';

interface Props {
  product: ProductObj;
}
export default function ProductBadges({ product }: Props) {
  const gf_meta = product.data.meta_data.find((meta_item: { key: string }) => {
    return meta_item.key === "gluten_free";
  });
  const gluten_free = gf_meta ? gf_meta.value === "1" : false;
  const veg_meta = product.data.meta_data.find((meta_item: { key: string }) => {
    return meta_item.key === "vegetarian";
  });
  const vegetarian = veg_meta ? veg_meta.value === "1" : false;

  return (
    <div className='product-badges d-flex'>
      {gluten_free && 
        <h4 className='me-1'><Badge bg="black">Gluten Free</Badge></h4>}
      {product.isUnder500Cal() && 
      <h4 className='me-1'><Badge bg="black">&lt;500 Cal</Badge></h4>}
      {product.isOver500Cal() && 
      <h4 className='me-1'><Badge bg="black">&gt;500 Cal</Badge></h4>}
      {product.isLowCarb() &&
      <h4 className='me-1'><Badge bg="black">Low Carb &lt;35</Badge></h4>}
      {product.isLowSodium() &&
      <h4 className='me-1'><Badge bg="black">Low Sodium</Badge></h4>}
      {product.isBreakfastItem() &&
      <h4 className='me-1'><Badge bg="black">Breakfast</Badge></h4>}
      {product.isEmp180Approved() &&
      <h4 className='me-1'><Badge bg="black">EMP180 Approved</Badge></h4>}
      {vegetarian &&
      <h4 className='me-1'><Badge bg="black">Vegetarian</Badge></h4>}
    </div>
  );
}
