import { UpdateCustomerAccount,
         UpdateCustomerBilling, 
         UpdateCustomerShipping } from "../features/customer/interfaces";

export default class CustomerAPI {
  static async getCustomer(token: string) {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/customers?token=' + token,
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }
  
  static async referFriends(token: string, emails: string[]) {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/friend_referrals', 
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({ 
          token: token,
          emails: emails
        })
      }
    ));
    if (!response.ok) throw new Error(`HTTP error. status: ${response.status}`);
    
    return await response.json();
  }

  static async referFriendSMS(email: string) {
    let response = await(fetch(
      process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/my_referral_code?email=' + email, 
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async updateCustomerAccount(data: UpdateCustomerAccount) {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/customers/' + data.customer_id + '/account', 
      {
        method: 'PUT',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify(data)
      }
    ));
    if (!response.ok) throw new Error(`HTTP error. status: ${response.status}`);
    
    return await response.json();
  }
  static async updateCustomerBilling(data: UpdateCustomerBilling) {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/customers/' + data.customer_id + '/billing', 
      {
        method: 'PUT',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify(data)
      }
    ));
    if (!response.ok) throw new Error(`HTTP error. status: ${response.status}`);
    
    return await response.json();
  }
  
   static async updateCustomerShipping(data: UpdateCustomerShipping) {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/customers/' + data.customer_id + '/shipping', 
      {
        method: 'PUT',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify(data)
      }
    ));
    if (!response.ok) throw new Error(`HTTP error. status: ${response.status}`);
    
    return await response.json();
  }
}
