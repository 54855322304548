import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from '../../../app/store';
import MightyFitAPI from '../../../API/mightyFitAPI';

interface DetailsState {
  details: Array<Record<string, any>>;
  loaded: boolean;
  loading: boolean;
  loadError: boolean;
}

const initialState: DetailsState = {
  details: [],
  loaded: false,
  loading: false,
  loadError: false,
}

export const loadDetails = createAsyncThunk(
  'details/loadDetails',
  async (id: number) => {
    const response = await MightyFitAPI.getworkoutsDetails(id);
    return response;
  }
);

export const detailsSlice = createSlice({
  name: "details",
  initialState: initialState,
  reducers: {
    addDetails: (state, action: PayloadAction<any>) => {
      state.details.push(action.payload);
    },
    removeDetails: (state, action: PayloadAction<any>) => {
      state.details = state.details.filter((item) => {
        return item !== action.payload;
      });
    },
    clearDetails: (state) => {
      state.details = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(loadDetails.pending, (state) => {
      return {
        ...state,
        loading: true
      }
    });
    builder.addCase(loadDetails.fulfilled, (state, action) => {
      state.details = [action.payload.data];
      state.loaded = true;
      state.loading = false;
      state.loadError = false;
    });
    builder.addCase(loadDetails.rejected, (state) => {
      return {
        ...state,
        loading: false,
        loadError: true
      }
    });
  }
});

export const { addDetails, clearDetails, removeDetails } = detailsSlice.actions;
export const selectDetails = (state: RootState) => state.details.details;
export const selectDetailsLoaded = (state: RootState) => state.details.loaded;
export const selectDetailsLoadError = (state: RootState) => state.details.loadError;
export const selectDetailsLoading = (state: RootState) => state.details.loading;
export default detailsSlice.reducer;