import {Container, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import MyAccountNav from '../components/MyAccountNav';
import MyMemberships from '../features/customer/MyMemberships';
import { selectToken } from '../features/user/userSlice';
import MyAccountPage from './MyAccountPage';

export default function MembershipsPage() {
  const token = useSelector(selectToken);
  const setLoginInfo = localStorage.getItem('setAuthInfo');
  const logInfo = setLoginInfo && JSON.parse(setLoginInfo);
  const userToken = logInfo ? logInfo.token : token;

  if (!userToken) {
    return (
      <MyAccountPage />
    )
  }
  
  return (
    <Container fluid="lg" className='address-page'>
      <Row>
        <Col sm={12} md={3}>
          <MyAccountNav />
        </Col>
        <Col sm={12} md={9} className='d-flex flex-column'>
          <MyMemberships />
        </Col>
      </Row>
    </Container>
  );

}