import { useDispatch, useSelector } from 'react-redux';
import { CloseButton, Modal } from "react-bootstrap";
import { selectShowCart, setShowCart } from '../mobile/mobileSlice';
import Cart from './Cart';
import Window from '../../utils/Window';

export default function CartModal() {
  const dispatch = useDispatch();
  const show = useSelector(selectShowCart);

  const handleHide = () => {
    dispatch(setShowCart(false));
  }
  return (
    <>
      {!Window.isMobile() &&
        <Modal
          fullscreen={true}
          show={show}
          onHide={handleHide}
        >
          <Modal.Body>
            <div className='d-flex justify-content-end'>
              <CloseButton onClick={handleHide} />
            </div>
            <Cart onCheckout={handleHide} />
          </Modal.Body>
        </Modal>
      }
    </>
  )
}