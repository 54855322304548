// import { Form } from 'formik/dist/Form';
import React, { useEffect, useState } from 'react'
import { Form as BootstrapForm, Form } from 'react-bootstrap';

function GooglePlace({ formik, streetRef, townRef, name, city, state, postcode }: any) {
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        if (!scriptLoaded) {
            const script = document.createElement('script');
            const GoogleApiKey = process.env.REACT_APP_WEB_URL === 'https://shop.emmstaging.com/' ? 'AIzaSyB9UC5V2PvY2kJnzilocA3GD3Fc5hUo9Oo' : 'AIzaSyAyl3jI8_4oiGf_t4mk3rRiOnFt0-T-SJc';
            script.src = `https://maps.googleapis.com/maps/api/js?key=${GoogleApiKey}&libraries=places`;
            script.async = true;
            script.onload = () => setScriptLoaded(true);
            document.body.appendChild(script);
        }

        return () => {
            if (scriptLoaded) {
                document.body.removeChild(WScript as any);
            }
        };
    }, [scriptLoaded]);

    useEffect(() => {
        if (scriptLoaded) {
            initAutocomplete();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scriptLoaded]);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const initAutocomplete = () => {
        const streetOptions = {
            types: ['geocode'],
            componentRestrictions: { country: 'us' },
        };

        const townOptions = {
            types: ['(cities)'],
            componentRestrictions: { country: 'us' },
        };

        if (!google.maps?.places) {
            return console.error("Google maps places API must be loaded.");
        }

        const autocompleteStreet = new (window as any).google.maps.places.Autocomplete(
            streetRef.current!,
            streetOptions
        );

        const autocompleteTown = new (window as any).google.maps.places.Autocomplete(
            townRef.current!,
            townOptions
        );

        autocompleteStreet.addListener('place_changed', handlePlaceChanged.bind(autocompleteStreet));
        autocompleteTown.addListener('place_changed', handleTownPlaceChanged.bind(autocompleteTown));
    };

    const handlePlaceChanged = function (this: google.maps.places.Autocomplete) {
        const place = this.getPlace();
        if (!place.geometry) {
            console.error("No details available for input: '" + place.name + "'");
            return;
        }

        let streetNumber = '';
        let streetName = '';
        let extractedTown = '';
        let extractedPostalCode = '';
        let extractedState = '';

        const addressComponents = place.address_components as any;
        for (let i = 0; i < addressComponents?.length; i++) {
            const component = addressComponents[i];
            if (component.types.includes('street_number')) {
                streetNumber = component.long_name;
            } else if (component.types.includes('route')) {
                streetName = component.long_name;
            } else if (component.types.includes('locality')) {
                extractedTown = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
                extractedState = component.short_name;
            } else if (component.types.includes('postal_code')) {
                extractedPostalCode = component.long_name;
            }
        }

        const streetAddress = `${streetNumber} ${streetName}`.trim();
        formik.setFieldValue(name, streetAddress);

        const cityStatePostal = [extractedTown].filter(Boolean).join(', ');
        formik.setFieldValue(city, cityStatePostal);
        formik.setFieldValue(state, extractedState);
        formik.setFieldValue(postcode, extractedPostalCode);
    }

    const handleTownPlaceChanged = function (this: google.maps.places.Autocomplete) {
        const place = this.getPlace();
        if (!place.geometry) {
            console.error("No details available for input: '" + place.name + "'");
            return;
        }

        let extractedTown = '';
        let extractedPostalCode = '';
        let extractedState = '';

        const addressComponents = place.address_components as any;
        for (let i = 0; i < addressComponents?.length; i++) {
            const component = addressComponents[i];
            if (component.types.includes('locality')) {
                extractedTown = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
                extractedState = component.short_name;
            } else if (component.types.includes('postal_code')) {
                extractedPostalCode = component.long_name;
            }
        }

        const cityStatePostal = [extractedTown].filter(Boolean).join(', ');

        formik.setFieldValue(city, cityStatePostal);
        formik.setFieldValue(state, extractedState);
        formik.setFieldValue(postcode, extractedPostalCode);
    }

    return (
        <Form>
            <BootstrapForm.Group className="mb-3 form-group required">
                <BootstrapForm.Control
                    ref={streetRef}
                    type="text"
                    name={name}
                    placeholder="Enter Street Address"
                    value={formik.values[name]}
                    onChange={formik.handleChange}
                    onKeyDown={handleKeyPress}
                />
            </BootstrapForm.Group>

        </Form>
    )
}

export default GooglePlace