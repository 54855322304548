import { useEffect, useState } from 'react';
import { Badge, Dropdown, NavItem, NavLink } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectCartIsLoaded, selectCartItems } from './cartSlice';
import "./cart.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBagShopping } from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import CartObj from './cartObj';
import Cart from './Cart';
import { useLocation } from 'react-router-dom';

export default function CartDropdown() {
  const [open, setOpen] = useState(false);
  const cartIsLoaded = useSelector(selectCartIsLoaded);
  const cart = new CartObj(useSelector(selectCartItems));
  const location = useLocation();

  const handleDropdownToggle = () => {
    setOpen(!open);
  }

  useEffect(() => {
    if (open) {
      const cartEl = document.querySelector('.cart-dropdown-menu');
      const navRect = document.querySelector('nav.navbar')?.getBoundingClientRect();

      if (!cartEl || !navRect) return;

      (cartEl as HTMLElement).style.maxHeight = window.innerHeight - navRect.bottom + 'px';
    }
  }, [open]);
  return (
    <Dropdown
      as={NavItem}
      className="cart-dropdown mt-2"
      align="end"
      onToggle={handleDropdownToggle}
      show={open}
    >
      {((location.pathname === '/order' && location.search === '') || (location.pathname === '/order' && location.search === '?product_type=gift-card')) ?
        (<div className='cart-icon position-relative d-inline-block p-1 mt-1 nav-link'>
          <FontAwesomeIcon icon={faBagShopping} size={'2x' as SizeProp} />
          <Badge
            pill
            bg="secondary"
            className='position-absolute top-0 start-75 translate-middle ms-1'
          >
            {cartIsLoaded ? cart.getItemCount() : 0}
          </Badge>
        </div>) :
        (<Dropdown.Toggle as={NavLink}>
          <div className='cart-icon position-relative d-inline-block p-1'>
            <FontAwesomeIcon icon={faBagShopping} size={'2x' as SizeProp} />
            <Badge
              pill
              bg="secondary"
              className='position-absolute top-0 start-75 translate-middle ms-1'
            >
              {cartIsLoaded ? cart.getItemCount() : 0}
            </Badge>
          </div>
        </Dropdown.Toggle>)}
      <Dropdown.Menu className='px-2 cart-dropdown-menu'>
        <Cart onCheckout={handleDropdownToggle} />
      </Dropdown.Menu>
    </Dropdown>
  );
}