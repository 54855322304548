import { ChangeEvent } from 'react';
import classnames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
import './multiRangeSlider.scss';

interface Props {
  min: number;
  max: number; 
  minVal: number;
  maxVal: number;
  onChange: (min: number, max: number) => void;
  className?: string;
};

export const MultiRangeSlider = ({ min, max, minVal, maxVal, onChange, className }: Props) => {
  const minValRef = useRef<HTMLInputElement>((null));
  const maxValRef = useRef<HTMLInputElement>(null);
  const range = useRef<HTMLDivElement>(null);

  const getPercent = useCallback((value: number): number => {
    return Math.round(((value - min) / (max - min)) * 100);
  }, [min, max]);

  const handleMinChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Math.min(parseInt(e.target.value), maxVal - 1);
    onChange(value, maxVal);
    e.target.value = value.toString();
  }

  const handleMaxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(parseInt(e.target.value), minVal + 1);
    onChange(minVal, value);
    e.target.value = value.toString();
  }

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); 
  
      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);
    
  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  return (
    <div className={"multi-range-slider d-flex justify-content-center ps-3 pe-4 " + className}>
      <div className="slider-left-value">{minVal}</div>
      <div className="slider-right-value">{maxVal}</div>
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        ref={minValRef}
        onChange={handleMinChange}
        className={classnames("thumb thumb-left", {
          "zindex-5": minVal > max - 100
        })}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        ref={maxValRef}
        onChange={handleMaxChange}
        className="thumb thumb-right"
      />
      <div className="slider">
        <div className="slider-track" />
        <div ref={range} className="slider-range" />
      </div>
    </div>
  )
}