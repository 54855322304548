import {
    BasicInformationCustomerDetails,
    DietaryNeedsGoalsCustomerDetails,
    FitNessGoalsCustomerDetails,
    AdditionalInformationCustomerDetails,
    UserProfileImage,
    UpdatePassword
} from "../features/customer/interfaces";

export default class CustomerDetailsAPI {
    static async getCustomerDetails(email: string) {
        let response = await (fetch(
            process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/user_detail?email=' + email,
            {
                method: 'GET',
                headers: { 'content-type': 'application/json;charset=UTF-8' },
            }
        ));
        if (!response.ok) {
            throw new Error(`HTTP error. status: ${response.status}`);
        }
        return await response.json();
    }

    static async updateBasicInformationCustomerDetails(data: BasicInformationCustomerDetails) {
        let response = await (fetch(
            process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/update_user',
            {
                method: 'POST',
                headers: { 'content-type': 'application/json;charset=UTF-8' },
                body: JSON.stringify(data)
            }
        ));
        if (!response.ok) {
            throw new Error(`HTTP error. status: ${response.status}`);
        }
        return await response.json();
    }
    static async updateDietaryNeedsGoalsCustomerDetails(data: DietaryNeedsGoalsCustomerDetails) {
        let response = await (fetch(
            process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/update_user',
            {
                method: 'POST',
                headers: { 'content-type': 'application/json;charset=UTF-8' },
                body: JSON.stringify(data)
            }
        ));
        if (!response.ok) {
            throw new Error(`HTTP error. status: ${response.status}`);
        }
        return await response.json();
    }
    static async updateFitNessGoalsCustomerDetails(data: FitNessGoalsCustomerDetails) {
        let response = await (fetch(
            process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/update_user',
            {
                method: 'POST',
                headers: { 'content-type': 'application/json;charset=UTF-8' },
                body: JSON.stringify(data)
            }
        ));
        if (!response.ok) {
            throw new Error(`HTTP error. status: ${response.status}`);
        }
        return await response.json();
    }
    static async updateAdditionalInformationCustomerDetails(data: AdditionalInformationCustomerDetails) {
        let response = await (fetch(
            process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/update_user',
            {
                method: 'POST',
                headers: { 'content-type': 'application/json;charset=UTF-8' },
                body: JSON.stringify(data)
            }
        ));
        if (!response.ok) {
            throw new Error(`HTTP error. status: ${response.status}`);
        }
        return await response.json();
    }

    static async updateUserProfileInfo(data: UserProfileImage) {
        let response = await (fetch(
            process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/update_user_image',
            {
                method: 'POST',
                headers: { 'content-type': 'application/json;charset=UTF-8' },
                body: JSON.stringify(data)
            }
        ));
        if (!response.ok) {
            throw new Error(`HTTP error. status: ${response.status}`);
        }
        return await response.json();
    }

    static async updateUserPassword(data: UpdatePassword) {
        let response = await (fetch(
            process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/update_password',
            {
                method: 'POST',
                headers: { 'content-type': 'application/json;charset=UTF-8' },
                body: JSON.stringify(data)
            }
        ));
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(JSON.stringify(errorData));
        }
        return await response.json();
    }
}
