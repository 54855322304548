import { useDispatch, useSelector } from "react-redux";
import { selectProductTypeFilter, setProductTypeFilter } from "../productsSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from "react-router-dom";
import { selectIsMobileRoute } from "../../mobile/mobileSlice";

export default function ProductTypeFilterTag() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const typeFilter = useSelector(selectProductTypeFilter);
  const AppURL = useSelector(selectIsMobileRoute);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productType = queryParams.get('product_type');

  const handleClick = () => {
    if(typeFilter === 'gift-card'){
      navigate(`/order${AppURL}`);
    }
    dispatch(setProductTypeFilter('all'));
  }

  const getFilterText = () => {
    switch (typeFilter) {
      case 'standard-menu':
        return 'Meals';
      case 'bulk': 
        return 'À la Carte';
      case 'breakfast':
        return 'Breakfast';
      case 'snacks':
        return 'Snacks';
      case 'desserts':
          return 'Desserts';
      case 'gift-card':
        return 'Gift Card';
      case 'giftcard-donation':
        return 'Donate';
      case 'supplements':
        return 'Supplements';
      case productType:
        return productType;
    }
  }
  if (typeFilter === 'all') {
    return (
      <></>
    );
  }

  return (
    <div className='product-type-filter-tag bg-secondary bg-opacity-25 p-2 fw-bold text-nowrap'>
      {getFilterText()} 
      <FontAwesomeIcon 
        role="button"
        className='ps-3 pe-auto' 
        icon={faXmark} 
        size={"xl" as SizeProp}
        onClick={handleClick} 
      />
    </div>
  )
}