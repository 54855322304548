import { useFormikContext } from 'formik';
import { CardCvcElement, 
         CardExpiryElement, 
         CardNumberElement } from '@stripe/react-stripe-js';
import { FormValues } from './interfaces';
import { Card, Col, Form, Row } from 'react-bootstrap';


const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: "black",
      color: "black",
      "::placeholder": {
        color: "black",
      },
    },
    invalid: {
      iconColor: "#fa004f",
      color: "#fa004f",
    },
  },
};

export default function StripeCardDetails() {
  const formik = useFormikContext<FormValues>();
  const handleChange = (event: Record<string, any>) => {
    if (event.elementType === 'cardNumber' ||
        event.elementType === 'cardExpiry' ||
        event.elementType === 'cardCvc') {
      if (event.empty) {
        formik.setFieldValue(event.elementType, 'empty');
      } else if (event.complete) {
        formik.setFieldValue(event.elementType, 'complete');
      } else if (event.error !== undefined ) {
        formik.setFieldValue(event.elementType, 'incomplete');
        formik.setFieldError(event.elementType, event.error.message);
      } else {
        formik.setFieldValue(event.elementType, 'incomplete');
        formik.setFieldError(event.elementType, undefined);
      }
    }
  }
  
  return (
    <Card body>
      <div className='my-4 fs-3'>Credit Card</div>
      <div className='fs-5 my-3'>Pay securely using your credit card.</div>
      <Form.Group className="mb-3 form-group required">
        <Form.Label>Card Number</Form.Label>
        <Form.Control 
          as={CardNumberElement} 
          options={CARD_ELEMENT_OPTIONS} 
          id='card_number'
          type='text'
          isInvalid={Boolean(formik.errors.cardNumber)}
          value={formik.values.cardNumber}
          onChange={handleChange}
        />
        {formik.errors.cardNumber ? 
          <Form.Control.Feedback type="invalid">
            {formik.errors.cardNumber}
          </Form.Control.Feedback> : null}
      </Form.Group>
      <Row>
        <Col>
          <Form.Group className="mb-3 form-group required">
            <Form.Label>Expiration (MM/YY)</Form.Label>
            <Form.Control 
              as={CardExpiryElement} 
              options={CARD_ELEMENT_OPTIONS} 
              id='card_expiry'
              isInvalid={Boolean(formik.errors.cardExpiry)}
              value={formik.values.cardExpiry}
              onChange={handleChange}
            />
            {formik.errors.cardExpiry ? 
              <Form.Control.Feedback type="invalid">
                {formik.errors.cardExpiry}
              </Form.Control.Feedback> : null}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3 form-group required">
            <Form.Label>Card Security Code</Form.Label>
            <Form.Control 
              as={CardCvcElement} 
              id='card_cvc'
              options={CARD_ELEMENT_OPTIONS} 
              isInvalid={Boolean(formik.errors.cardCvc)}
              value={formik.values.cardCvc}
              onChange={handleChange}
            />
            {formik.errors.cardCvc ? 
              <Form.Control.Feedback type="invalid">
                {formik.errors.cardCvc}
              </Form.Control.Feedback> : null}
          </Form.Group>
        </Col>
      </Row>
    </Card>
  );
}

