import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface GuestState {
  email: string;
  zipcode: string;
}

const initialState: GuestState = {
  email: '',
  zipcode: '',
}

export const guestSlice = createSlice({
  name: "guest",
  initialState: initialState,
  reducers: {
    setEmail: (state, action) => {
      return {
        ...state,
        email: action.payload
      }
    }
  }
});

export const { setEmail } = guestSlice.actions;
export const selectGuestEmail = (state: RootState ) => state.guest.email;
export default guestSlice.reducer;