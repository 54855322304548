import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { selectCustomer, selectCustomerIsLoaded } from './customerSlice';
import CustomerObj from './customerObj';

export default function MightyPointsSummary() {
  const custLoaded = useSelector(selectCustomerIsLoaded);
  const customer = new CustomerObj(useSelector(selectCustomer));

  return (
    <div className='mightypoints-summary bg-primary-300 rounded-3 h-100 d-flex justify-content-center align-items-center flex-column p-3'>
      {!custLoaded && <Spinner className="text-black" animation="grow" />}
      {(custLoaded && customer.hasMembership()) ?
        <>
          <div className={`fw-400 text-white text-center text-uppercase`}>
            You are a
          </div>
          <div className='text-white fw-600 h3 text-center text-uppercase'>
            Membership Customer
          </div>
          <div className={`fw-400 text-white text-center text-uppercase`}>
            Assigned to the
          </div>
          <div className={`fs-3 fw-500 text-success text-center font-barlow`}>
            {customer?.data?.memberships[0]?.plan_name}
          </div>
          <div className='text-white fw-600 h3 text-center text-uppercase'>
            Plan
          </div>
        </> :
        (custLoaded && !customer.hasMembership()) &&
        <>
          <div className={`fw-400 text-white text-center text-uppercase`}>
            You have earned
          </div>
          <div className={`text-xlarge fw-500 text-success text-center font-barlow`}>
            {customer.getMightyPoints()}
          </div>
          <div className='text-white fw-600 h3 text-center text-uppercase'>
            Mighty Points
          </div>
          <div className='text-center my-3'>
            <Button
              variant="success"
              size="sm"
              as={Link as any}
              to="/my-account/mightypoints"
            >
              View Point Activity
            </Button>
          </div>
        </>}
    </div>
  );
}