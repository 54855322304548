import { useLocation } from "react-router-dom";
import ElementorPage from "./ElementorPage";
import { useEffect } from "react";

export default function ConciergePage() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/concierge') {
      const cssUrl = `${process.env.REACT_APP_BLOG_URL}/wp-content/uploads/elementor/css/post-646495.css`;
      const cssUrlWithCacheBust = `${cssUrl}?v=${new Date().getTime()}`;

      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = cssUrlWithCacheBust;

      document.head.appendChild(link);
      return () => {
        document.head.removeChild(link);
      };
    }
    else {
      const link = document.querySelector('link[href*="post-646495.css"]');
      if (link) {
        document.head.removeChild(link);
      }
    }
  }, [location.pathname, location.search]);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    const script = document.createElement('script');
    script.src = 'https://eatmightymeals.freshworks.com/crm/sales/web_forms/9976c7fa0d6b2f412beb3c5b4a54bbd4dfe49661eb10b9c67d823284f2bae0b8/form.js';
    script.async = true;

    document.body.style.overflow = 'hidden';
    document.body.appendChild(script);

    setTimeout(() => {
      document.body.style.overflow = 'auto';
      let parents = document.querySelectorAll('.elementor-widget-container');
      let bgParentStyle = document.getElementsByClassName('elementor-widget-wrap')[6] as HTMLElement;
      let bgParentBorder = document.getElementsByClassName('elementor-widget-wrap')[6] as HTMLElement;
      let getForm = document.getElementsByClassName('fserv-container');

      if (getForm[0]) {
        let childEle = getForm[0] as HTMLElement;
        bgParentStyle.style.background = '#00B156';
        bgParentBorder.style.borderStyle = 'solid';
        bgParentBorder.style.borderWidth = '0px 0px 15px 0px';
        bgParentBorder.style.borderColor = 'black';
        bgParentBorder.style.transition = 'background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s';
        bgParentBorder.style.padding = '20px 20px 20px 20px';
        childEle.style.background = 'white';
        parents[6]?.appendChild(childEle);
      }
    }, 4000);
  }, []);

  return (
    <ElementorPage pageNo={646495} />
  )
}