import { Card, Col, Row } from 'react-bootstrap';
import { IMembership } from './interfaces';

interface Props {
  membership: IMembership;
}
export default function Membership({ membership }: Props) {
  return (
    <Card className='membership border-0' body>
      <Row>
        <Col xs={4} className='fs-5 fw-bold'>
          Plan Name
        </Col>
        <Col className='fs-5'>{membership.plan_name}</Col>
      </Row>
      <Row>
        <Col xs={4} className='fs-5 fw-bold'>
          Status
        </Col>
        <Col className='fs-5'>{membership.status}</Col>
      </Row>
      <Row>
        <Col xs={4} className='fs-5 fw-bold'>
          Start Date
        </Col>
        <Col className='fs-5'>{membership.start_date}</Col>
      </Row>
      <Row>
        <Col xs={4} className='fs-5 fw-bold'>
          Expires
        </Col>
        <Col className='fs-5'>{membership.end_date}</Col>
      </Row>
    </Card>
  );
}