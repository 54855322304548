import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AddEditPaymentMethod from './AddEditPaymentMethod'
import { Elements } from '@stripe/react-stripe-js'
import { useSelector } from 'react-redux';
import { selectGeneralOptions } from '../mobile/mobileSlice';
import { loadStripe } from '@stripe/stripe-js';

export default function PaymentMethodsPage() {
    const getGeneralOptions = useSelector(selectGeneralOptions);
    const paymentMode = getGeneralOptions?.payment_mode;
    const publishKey = paymentMode === 'live' ?
        process.env.REACT_APP_PROD_STRIPE_PUBLISHABLE_KEY :
        process.env.REACT_APP_STAGE_STRIPE_PUBLISHABLE_KEY;
    const stripePromise = publishKey ? loadStripe(publishKey) : null;

    return (
        <Elements stripe={stripePromise}>
            <Routes>
                <Route path="/payment-method" element={<AddEditPaymentMethod />} />
            </Routes>
        </Elements>
    )
}
