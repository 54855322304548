import ProductObj from "./productObj";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import dairyIconSrc from '../../assets/images/icons/key-dairy.webp';
import eggsIconSrc from '../../assets/images/icons/key-eggs.webp';
import fishIconSrc from '../../assets/images/icons/key-fish.png';
import glutenFreeIconSrc from '../../assets/images/icons/key-gluten_free.webp';
import nutsIconSrc from '../../assets/images/icons/key-nuts.webp';
import paleoFriendlyIconSrc from '../../assets/images/icons/key-paleofriendly.webp';
import proteinPackedIconSrc from '../../assets/images/icons/key-proteinpacked.webp';
import shellfishIconSrc from '../../assets/images/icons/key-shellfish.webp';
import soyIconSrc from '../../assets/images/icons/key-soy.webp';
import spicyIconSrc from '../../assets/images/icons/key-spicy.webp';
import vegetarianIconSrc from '../../assets/images/icons/key-vegetarian.webp';
import wheatIconSrc from '../../assets/images/icons/key-wheat.webp';
import sesameIconSrc from '../../assets/images/icons/key-sesame.png';

interface Props {
  product: ProductObj;
  className?: string;
}

export default function DietaryIcons({product, className=''}: Props) {
  return (
    <div className={`dietary-icons mt-auto ${className}`}>
      {!product.hasTag('dairy-free') &&
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Contains Dairy</Tooltip>}
        >
          <img 
            alt='contains-dairy'
            className='d-inline-block mx-1'
            src={dairyIconSrc} 
            height="25" width="25" 
          />
        </OverlayTrigger>
      }
      {product.containsEggs() &&
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Contains Eggs</Tooltip>}
        >
          <img 
            alt='contains-eggs'
            className='d-inline-block mx-1'
            src={eggsIconSrc} 
            height="25" width="25" 
          />
        </OverlayTrigger>
      }
      {product.containsFish() &&
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Contains Fish</Tooltip>}
        >
          <img 
            alt='contains-fish'
            className='d-inline-block mx-1'
            src={fishIconSrc} 
            height="25" width="25" 
          />
        </OverlayTrigger>
      }
      {product.hasTag('gluten-free') &&
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Gluten Free</Tooltip>}
        >
          <img 
            alt='gluten-free'
            className='d-inline-block mx-1' 
            src={glutenFreeIconSrc} 
            height="25" width="25" 
          />
        </OverlayTrigger>
      }
      {!product.hasTag('nut-free') &&
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Contains Nuts</Tooltip>}
        >
          <img 
            alt='contains-nuts'
            className='d-inline-block mx-1' 
            src={nutsIconSrc} 
            height="25" width="25" 
          />
        </OverlayTrigger>
      }
      {product.isPaleoFriendly() &&
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Paleo Friendly</Tooltip>}
        >
          <img 
            alt='paleo-friendly'
            className='d-inline-block mx-1'
            src={paleoFriendlyIconSrc} 
            height="25" width="25" 
          />
        </OverlayTrigger>
      }
      {product.isProteinBoosted() &&
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Protein Boosted</Tooltip>}
        >
          <img 
            alt='protein-boosted'
            className='d-inline-block mx-1'
            src={proteinPackedIconSrc} 
            height="25" width="25" 
          />
        </OverlayTrigger>
      }
      {product.containsShellfish() &&
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Contains Shellfish</Tooltip>}
        >
          <img 
            alt='contains-shellfish'
            className='d-inline-block mx-1' 
            src={shellfishIconSrc} 
            height="25" width="25" 
          />
        </OverlayTrigger>
      }
      {!product.hasTag('soy-free') &&
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Contains Soy</Tooltip>}
        >
          <img 
            alt='contains-soy'
            className='d-inline-block mx-1' 
            src={soyIconSrc} 
            height="25" width="25" 
          />
        </OverlayTrigger>
      }
      {product.isSpicy() &&
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Spicy</Tooltip>}
        >
          <img 
            alt='spicy'
            className='d-inline-block mx-1' 
            src={spicyIconSrc} 
            height="25" width="25" 
          />
        </OverlayTrigger>
      }
      {product.isVegetarian() &&
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Vegetarian</Tooltip>}
        >
          <img 
            alt='vegetarian'
            className='d-inline-block mx-1' 
            src={vegetarianIconSrc} 
            height="25" width="25" 
          />
        </OverlayTrigger>
      }
      {!product.hasTag('wheat-free') &&
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Contains Wheat</Tooltip>}
        >
          <img 
            alt='contains-wheat'
            className='d-inline-block mx-1' 
            src={wheatIconSrc} 
            height="25" width="25" 
          />
        </OverlayTrigger>
      }
      {product.containsSesame() &&
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Contains Sesame</Tooltip>}
        >
          <img 
            alt='contains-sesame'
            className='d-inline-block mx-1' 
            src={sesameIconSrc} 
            height="25" width="25" 
          />
        </OverlayTrigger>
      }
    </div>
  );
}