export default class Helper {
  static formattedCurrency = (amount: number) =>  {
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });
    return formatter.format(amount);  
  }

  static formatPhoneNo = ( phoneNo: string) => {
    if (!phoneNo) {
      return '';
    }
    phoneNo = phoneNo.replace(/\D/g, '');
    let newNo = '';
    if (phoneNo.length > 0) {
      if (phoneNo[0] === "1") {
        phoneNo = phoneNo.substring(1);
      }
      if (phoneNo.length >= 1) {
        newNo = '(' + phoneNo.substring(0, 3);
      } 

      if (phoneNo.length >= 4) {
        newNo +=  ') ' + phoneNo.substring(3, 6);
      }
      if (phoneNo.length > 6) {
        newNo += '-' + phoneNo.substring(6);
      }
    }
    return newNo;
  }
}