import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { selectProductTypeFilter, setProductTypeFilter } from '../../productsSlice';
import { useLocation } from 'react-router-dom';

export default function ProductTypeFilterForm() {
  const dispatch = useDispatch();
  const location = useLocation();
  const filterValue = useSelector(selectProductTypeFilter);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProductTypeFilter(e.target.id));
  }

  return (
    <Form className='fs-6 mx-2'>
      <Form.Check
        type="radio"
        name="product-type"
        label="All"
        id="all"
        checked={filterValue === "all"}
        onChange={handleChange}
      />
      <Form.Check
        type="radio"
        name="product-type"
        label="Breakfast"
        id="breakfast"
        checked={filterValue === "breakfast"}
        onChange={handleChange}
      />
      <Form.Check
        type="radio"
        name="product-type"
        label="Meals"
        id="standard-menu"
        checked={filterValue === "standard-menu"}
        onChange={handleChange}
      />
      <Form.Check
        type="radio"
        name="product-type"
        label="À la Carte"
        id="bulk"
        checked={filterValue === 'bulk'}
        onChange={handleChange}
      />
      <Form.Check
        type="radio"
        name="product-type"
        label="Snacks"
        id="snacks"
        checked={filterValue === 'snacks'}
        onChange={handleChange}
      />
      <Form.Check
        type="radio"
        name="product-type"
        label="Desserts"
        id="desserts"
        checked={filterValue === 'desserts'}
        onChange={handleChange}
      />
      {!location.pathname.startsWith('/autoship') &&
        <Form.Check
          type="radio"
          name="product-type"
          label="Gift Card"
          id="gift-card"
          checked={filterValue === 'gift-card'}
          onChange={handleChange}
        />
      }
      {!location.pathname.startsWith('/autoship') &&
        <Form.Check
          type="radio"
          name="product-type"
          label="Donate"
          id="giftcard-donation"
          checked={filterValue === 'giftcard-donation'}
          onChange={handleChange}
        />
      }
      {/* <Form.Check 
        type="radio" 
        name="product-type" 
        label="Supplements" 
        id="supplements" 
        checked={filterValue === 'supplements'}
        onChange={handleChange} 
      /> */}
    </Form>
  );
}