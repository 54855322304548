export default class DeliveryAPI {
  static async getDeliveryInfo() {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/delivery_info',
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset-UTF-8' }  
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async updateOrderDeliveryDate(
    token: string, orderId: number, customerId: number, deliveryDate: string
  ) {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/orders/' + orderId + '/delivery_date', 
      {
        method: 'PUT',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({ 
          token: token,
          order_id: orderId,
          customer_id: customerId,
          delivery_date: deliveryDate
        })
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  
  }
}