import React from 'react'
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ProductContainer from '../features/products/ProductContainer';
import ProductObj from '../features/products/productObj';
import { useParams } from 'react-router-dom';
import '../components/checkout-form/UpCellsProducts.scss'
import { useSelector } from 'react-redux';
import { selectProducts } from '../features/products/productsSlice';

SwiperCore.use([Navigation]);

interface Props {
    swiperProducts: any;
}

export default function SwiperProductsPage({ swiperProducts }: Props) {
    const products = useSelector(selectProducts);
    const productSlug = useParams().productSlug;
    // const navigationPrevRef = React.useRef(null);
    // const navigationNextRef = React.useRef(null);

    return (
        <div className='w-full position-relative upsellsCarousel' >
            <div className="slider-part">
                <Swiper
                    slidesPerView={2}
                    spaceBetween={0}
                    navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    }}
                    scrollbar={{ draggable: true }}
                    breakpoints={{
                        640: {
                            slidesPerView: 3,
                        },
                        1600: {
                            slidesPerView: 4,
                        },
                    }}>
                    {swiperProducts.map((itemsID: number, index: any) => {
                        const product = ProductObj.getById(products, itemsID);
                        return (
                            <SwiperSlide key={index}>
                                {product && <div className="card-part">
                                    <React.Fragment key={product?.data.id}>
                                        <div className="card-col">
                                            {product &&
                                                <ProductContainer
                                                    product={product}
                                                    showModal={productSlug === product.data.slug}
                                                />}
                                        </div>
                                    </React.Fragment>
                                </div>}
                            </SwiperSlide>)
                    })}
                </Swiper>
                {swiperProducts.length > 0 &&
                    <div className="arrow-wrap">
                        <div className="arrow-left arrow-both swiper-button-prev">
                        </div>
                        <div className="arrow-right arrow-both swiper-button-next">
                        </div>
                    </div>}
            </div>
        </div>
    )
}
