import { useFormikContext } from 'formik';
import React, { ChangeEvent } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { FormValues } from './interfaces';

export default function AuthrizedCardForm() {
    const formik = useFormikContext<FormValues>();

    const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
        var value = e.target.value.replace(/\D/g, '');
        let newValue = value.substring(0, 4);

        if (value.length > 4) {
            newValue += ' ' + value.substring(4, 8);
        }
        if (value.length > 8) {
            newValue += ' ' + value.substring(8, 12);
        }
        if (value.length > 12) {
            newValue += ' ' + value.substring(12, 16);
        }
        formik.setFieldValue("cardNumber", newValue);
    }

    const handleExpiryChange = (e: ChangeEvent<HTMLInputElement>) => {
        var value = e.target.value.replace(/\D/g, '');
        let newValue = value.substring(0, 2);

        if (value.length === 1 && !['0', '1'].includes(value)) {
            newValue = '0' + value;
        }
        if (value.length > 2) {
            newValue += ' / ' + value.substring(2)
        }
        formik.setFieldValue("cardExpiry", newValue);
    }

    const handleCvcChange = (e: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("cardCvc", e.target.value.replace(/\D/g, ''));
    }
    return (
        <>
            <Form.Group className="mb-3 form-group required">
                <Form.Label>Card Number</Form.Label>
                <Form.Control
                    id='cardNumber'
                    type='text'
                    placeholder="1234 1234 1234 1234"
                    maxLength={19}
                    isInvalid={Boolean(formik.errors.cardNumber)}
                    value={formik.values.cardNumber}
                    onChange={handleNumberChange}
                />
                {formik.errors.cardNumber ?
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.cardNumber}
                    </Form.Control.Feedback> : null}
            </Form.Group>
            <Row>
                <Col>
                    <Form.Group className="mb-3 form-group required">
                        <Form.Label>Expiration (MM/YY)</Form.Label>
                        <Form.Control
                            id='cardExpiry'
                            placeholder="MM / YY"
                            maxLength={7}
                            isInvalid={Boolean(formik.errors.cardExpiry)}
                            value={formik.values.cardExpiry}
                            onChange={handleExpiryChange}
                        />
                        {formik.errors.cardExpiry ?
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.cardExpiry}
                            </Form.Control.Feedback> : null}
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3 form-group required">
                        <Form.Label>Card Security Code</Form.Label>
                        <Form.Control
                            id='cardCvc'
                            placeholder="CVC"
                            maxLength={4}
                            isInvalid={Boolean(formik.errors.cardCvc)}
                            value={formik.values.cardCvc}
                            onChange={handleCvcChange}
                        />
                        {formik.errors.cardCvc ?
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.cardCvc}
                            </Form.Control.Feedback> : null}
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}
