import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { MultiRangeSlider } from '../../../../components/MultiRangeSlider';
import { selectProductCalorieFilter, 
         selectProductCarbFilter,
         selectProductFatFilter,
         selectProductProteinFilter,
         setProductCalorieFilter,
         setProductCarbFilter,
         setProductFatFilter,
         setProductProteinFilter } from '../../productsSlice';
export default function MacroFilterForm() {
  const dispatch = useDispatch();
  const calFilter = useSelector(selectProductCalorieFilter);
  const carbFilter = useSelector(selectProductCarbFilter);
  const fatFilter = useSelector(selectProductFatFilter);
  const proteinFilter = useSelector(selectProductProteinFilter);

  const handleCalChange = (min: number, max: number) => {
    dispatch(setProductCalorieFilter({min: min, max: max}));
  }
  const handleCarbChange = (min: number, max: number) => {
    dispatch(setProductCarbFilter({min: min, max: max}));
  }
  const handleFatChange = (min: number, max: number) => {
    dispatch(setProductFatFilter({min: min, max: max}));
  }
  const handleProteinChange = (min: number, max: number) => {
    dispatch(setProductProteinFilter({min: min, max: max}));
  }

  const handleResetFilter = () => {
    dispatch(setProductCalorieFilter({ min: 0, max: 1200 }))
    dispatch(setProductCarbFilter({ min: 0, max: 150 }))
    dispatch(setProductFatFilter({ min: 0, max: 100 }))
    dispatch(setProductProteinFilter({ min: 0, max: 150 }))
  }

  return (
    <>
      <div className='fs-6 mx-2'>
        <div className='d-flex align-items-center mb-1'>
          <span className='macro-label'>Calories</span>
          <MultiRangeSlider 
            min={0} 
            max={1200} 
            minVal={calFilter.min}
            maxVal={calFilter.max}
            onChange={handleCalChange} 
            className='ms-auto'
          />
        </div>
        <div className='d-flex align-items-end mb-1'>
          <span className='macro-label'>Protein</span>
          <MultiRangeSlider 
            min={0} 
            max={150}
            minVal={proteinFilter.min}
            maxVal={proteinFilter.max}
            onChange={handleProteinChange} 
            className='ms-auto'
          />
        </div>
        <div className='d-flex align-items-end mb-1'>
          <span className='macro-label'>Carbs</span>
          <MultiRangeSlider 
            min={0} 
            max={150} 
            minVal={carbFilter.min}
            maxVal={carbFilter.max}
            onChange={handleCarbChange}
            className='ms-auto'
          />
        </div>
        <div className='d-flex align-items-end'>
          <span className='macro-label'>Fat</span>
          <MultiRangeSlider 
            min={0} 
            max={100} 
            minVal={fatFilter.min}
            maxVal={fatFilter.max}
            onChange={handleFatChange}
            className='ms-auto'
          />
        </div>
      </div>
      <div className='d-flex justify-content-center'>
        <Button 
          variant="link" 
          className='text-dark' 
          onClick={handleResetFilter}
        >
          Reset Macros
        </Button>
      </div>
    </>
  )
}