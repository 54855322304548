import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from '../../../app/store';
import MightyFitAPI from '../../../API/mightyFitAPI';

interface MightyFitState {
  mightyFit: Array<Record<string, any>>;
  loaded: boolean;
  loading: boolean;
  loadError: Boolean;
  mightyFitFilter: Array<string>;
}

const initialState: MightyFitState = {
  mightyFit: [],
  loaded: false,
  loading: false,
  loadError: false,
  mightyFitFilter: [],
}

export const loadMightyFit = createAsyncThunk(
  'mightyFit/loadMightyFit',
  async () => {
    return await MightyFitAPI.getFilters();
  }
);

export const filterSlice = createSlice({
  name: "mightyFit",
  initialState: initialState,
  reducers: {
    addMightyFitFilter: (state, action) => {
      state.mightyFitFilter.push(action.payload);
    },
    removeMightyFitFilter: (state, action) => {
      state.mightyFitFilter = state.mightyFitFilter.filter((item) => {
        return item !== action.payload;
      });
    },
    clearMightyFitFilter: (state) => {
      state.mightyFitFilter = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(loadMightyFit.pending, (state) => {
      return {
        ...state,
        loading: true
      }
    });
    builder.addCase(loadMightyFit.fulfilled, (state, action) => {
      const filterData = action.payload;
      return {
        ...state,
        mightyFit: [filterData],
        loading: false,
        loaded: true
      }
    });
    builder.addCase(loadMightyFit.rejected, (state) => {
      return {
        ...state,
        loading: false,
        loadError: true
      }
    });
  }
});

export const { addMightyFitFilter, clearMightyFitFilter, removeMightyFitFilter, } = filterSlice.actions;
export const selectMightyFit = (state: RootState) => state.mightyFit.mightyFit;
export const selectMightyFitFilter = (state: RootState) => state.mightyFit.mightyFitFilter;
export const selectMightyFitLoaded = (state: RootState) => state.mightyFit.loaded;
export const selectMightyFitLoadError = (state: RootState) => state.mightyFit.loadError;
export const selectMightyFitLoading = (state: RootState) => state.mightyFit.loading;
export default filterSlice.reducer;