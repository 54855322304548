import ProductTypeFilterTag from "./ProductTypeFilterTag"
import ProteinFilterTag from "./ProteinFilterTag";
import DietFilterTag from './DietFilterTag';
import DietaryRestrictionsFilterTags from './DietaryRestrictionsFilterTags';

export default function ProductFilterTags() {
  return (
    <div className='product-filter-tags hstack flex-wrap gap-3 mt-2 me-2'>
      <ProductTypeFilterTag />
      <ProteinFilterTag />
      <DietFilterTag />
      <DietaryRestrictionsFilterTags />
    </div>
  )
}