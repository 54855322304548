import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Image, Row, Spinner } from 'react-bootstrap';
import {
  selectProductCalorieFilter,
  selectProductCarbFilter,
  selectProductDietFilter,
  selectProductDietRestrictionsFilter,
  selectProductFatFilter,
  selectProductProteinFilter,
  selectProductProteinTypeFilter,
  selectProducts,
  selectProductSearchFilter,
  selectProductSortFilter,
  selectProductTypeFilter
} from './productsSlice';
import ProductContainer from './ProductContainer';
import ProductObj from './productObj';
import Window from '../../utils/Window';
import {
  filterProductsByCalories,
  filterProductsByCarbs,
  filterProductsByCategory,
  filterProductsByDiet,
  filterProductsByDietRestrictions,
  filterProductsByFat,
  filterProductsByProtein,
  filterProductsByProteinType,
  filterProductsBySLug,
  filterProductsBySearch,
  filterProductsByType,
  sortProducts
} from "./productUtils";
import { Link, useLocation, useParams } from 'react-router-dom';
import ProductsAPI from '../../API/productsAPI';
import { selectMealPlans, selectMealPlansLoaded } from '../meal_plans/mealPlansSlice';

export default function ProductList() {
  const allProducts: Array<Record<string, any>> = useSelector(selectProducts);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [products, setProducts] = useState<Record<string, any>[]>(allProducts);
  const calFilter = useSelector(selectProductCalorieFilter);
  const carbFilter = useSelector(selectProductCarbFilter);
  const dietFilter = useSelector(selectProductDietFilter);
  const dietRestrictionsFilter = useSelector(selectProductDietRestrictionsFilter);
  const fatFilter = useSelector(selectProductFatFilter);
  const typeFilter = useSelector(selectProductTypeFilter);
  const proteinFilter = useSelector(selectProductProteinFilter);
  const proteinTypeFilter = useSelector(selectProductProteinTypeFilter);
  const sortFilter = useSelector(selectProductSortFilter);
  const searchFilter = useSelector(selectProductSearchFilter);
  const mealPlans = useSelector(selectMealPlans);
  const mealPlansLoaded = useSelector(selectMealPlansLoaded);
  const productSlug = useParams().productSlug;
  const productCategory = useParams().categorySlug;
  const [displayedProducts, setDisplayedProducts] = useState<Record<string, any>[]>([]);
  const [visibleCount, setVisibleCount] = useState(12);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [previousProductSlug, setPreviousProductSlug] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productType = queryParams.get('product_type');

  useEffect(() => {
    const fetchData = async () => {
      let pList = [...allProducts];

      if (productCategory) pList = filterProductsByCategory(pList, productCategory);

      if (productType && productType !== previousProductSlug) {
        setPreviousProductSlug(productType);
        const foundProduct = await handleLoadProductsByCategory(productType);
        if (foundProduct) {
          pList = foundProduct;
        }
      }

      pList = filterProductsByCalories(pList, calFilter);
      pList = filterProductsByCarbs(pList, carbFilter);
      pList = filterProductsByDiet(pList, dietFilter);
      pList = filterProductsByDietRestrictions(pList, dietRestrictionsFilter);
      pList = filterProductsByFat(pList, fatFilter);
      pList = filterProductsByType(pList, typeFilter);
      pList = filterProductsByProtein(pList, proteinFilter);
      pList = filterProductsByProteinType(pList, proteinTypeFilter);
      if (sortFilter === 'favorite_meals') {
        const favoriteProductIds = new Set();
        mealPlans.forEach((meal: any) => {
          meal.items.forEach((item: any) => {
            favoriteProductIds.add(item.productId);
          });
        });

        pList.sort((a, b) => {
          const isAFavoriteMeal = favoriteProductIds.has(a.id);
          const isBFavoriteMeal = favoriteProductIds.has(b.id);

          return isBFavoriteMeal ? 1 : isAFavoriteMeal ? -1 : 0;
        });
      } else if (sortFilter !== 'favorite_meals') {
        pList = sortProducts(pList, sortFilter);
      }
      pList = filterProductsBySearch(pList, searchFilter);

      if (productSlug) {
        const foundProduct = filterProductsBySLug(pList, productSlug);
        if (foundProduct) {
          pList = [foundProduct];
        }
      }

      if (location.pathname.startsWith('/autoship')) {
        pList = pList.filter((product) =>
          !product.slug.startsWith("gift-card") &&
          !product.slug.startsWith("donate-gift-card") &&
          !product.slug.startsWith("mighty-bucks-gift-card-for")
        );
      }

      // Create a Set to store unique product IDs
      const uniqueProductIds = new Set();

      // Filter products and only add those with unique IDs to filteredProducts
      const filteredProducts = pList.filter((product) => {
        if (!product.slug.startsWith("mighty-bucks-gift-card-for-")) {
          if (!uniqueProductIds.has(product.id)) {
            uniqueProductIds.add(product.id);
            return true;
          }
        }
        return false;
      });

      setProducts(filteredProducts);
      setDisplayedProducts(filteredProducts.slice(0, visibleCount));
      setShowLoadMore(filteredProducts.length > visibleCount);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(productType ? [] : [allProducts]), calFilter, carbFilter, dietFilter, dietRestrictionsFilter, fatFilter, productCategory, productSlug, proteinFilter, proteinTypeFilter, searchFilter, sortFilter, typeFilter, productType, visibleCount, mealPlans, mealPlansLoaded]);

  useEffect(() => {
    const filteredProducts = products.filter((product) => !product.slug.startsWith("mighty-bucks-gift-card-for-"));
    setDisplayedProducts(filteredProducts.slice(0, visibleCount));
    setShowLoadMore(visibleCount < filteredProducts.length);
  }, [products, visibleCount]);

  const handleScroll = () => {
    const loadMoreDiv = document.getElementById('load-more');
    if (loadMoreDiv) {
      const loadMoreRect = loadMoreDiv.getBoundingClientRect();
      const scrolledToLoadMore = loadMoreRect.top <= window.innerHeight;

      if (scrolledToLoadMore) {
        setVisibleCount(prevCount => prevCount + 12);
      }
    }
  };

  const handleLoadProductsByCategory = async (product_category: string) => {
    let currentPage: number = 1;
    let allProducts: any[] = [];

    while (true) {
      const response = await ProductsAPI.loadProductsByCategory(product_category, currentPage);
      const { products, total_product_pages } = response;

      // Add the products from the current page to the accumulated list
      allProducts = [...allProducts, ...products];

      // If there are more pages, increment the page number; otherwise, break the loop
      if (currentPage < parseInt(total_product_pages)) {
        currentPage++;
      } else {
        break;
      }
    }

    return allProducts;
  };


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Row className='product-list gx-3 gy-3 px-2 pb-4'>
      {displayedProducts.map((p, index) => {
        const product = new ProductObj(p);
        return (
          <React.Fragment key={index}>
            {((Window.isXLorGreater() && index === 8) ||
              ((Window.isLG() || Window.isTablet()) && index === 6) ||
              (Window.isMobile() && index === 4)) &&
              <Col xs={12}>
                <Link to={'/autoship/orders'} >
                  {Window.isMobile() ?
                    <Image
                      alt='autoship'
                      src='https://eatmightymeals.com/wp-content/uploads/2024/07/order-planner-banner-mobile.jpg'
                      className='w-100'
                    /> :
                    <Image
                      alt='autoship'
                      src='https://eatmightymeals.com/wp-content/uploads/2024/07/order-planner-banner-desktop.jpg'
                      className='w-100'
                    />}
                </Link>
              </Col>
            }
            {((Window.isXLorGreater() && index === 16) ||
              ((Window.isLG() || Window.isTablet()) && index === 12) ||
              (Window.isMobile() && index === 8)) &&
              <Col xs={12}>
                <Link to={`/nutrition?utm_source=order-page`}>
                  {Window.isMobile() ?
                    <Image
                      alt='nutrition'
                      src='https://eatmightymeals.com/wp-content/uploads/2023/12/big-Nutritional-Team-Mobile.png'
                      className='w-100'
                    /> :
                    <Image
                      alt='nutrition'
                      src='https://eatmightymeals.com/wp-content/uploads/2023/12/Nutritional-Team-Desktop.png'
                      className='w-100'
                    />}
                </Link>
              </Col>
            }
            {((Window.isXLorGreater() && index === 24) ||
              ((Window.isLG() || Window.isTablet()) && index === 18) ||
              (Window.isMobile() && index === 12)) &&
              <Col xs={12}>
                <Link to={`/sustainability?utm_source=order-page`}>
                  {Window.isMobile() ?
                    <Image
                      alt='sustainability'
                      src='https://eatmightymeals.com/wp-content/uploads/2023/11/Sustainability-Banner-Mobile.png'
                      className='w-100'
                    /> :
                    <Image
                      alt='sustainability'
                      src='https://eatmightymeals.com/wp-content/uploads/2023/11/Sustainability-Banner-Desktop.png'
                      className='w-100'
                    />}
                </Link>
              </Col>
            }
            {((Window.isXLorGreater() && index === 32) ||
              ((Window.isLG() || Window.isTablet()) && index === 24) ||
              (Window.isMobile() && index === 16)) &&
              <Col xs={12}>
                <Link to={`/mightycheesecake?utm_source=order-page`}>
                  {Window.isMobile() ?
                    <Image
                      alt='mightycheesecake'
                      src='https://eatmightymeals.com/wp-content/uploads/2023/12/Cheesecake-Mobile.png'
                      className='w-100'
                    /> :
                    <Image
                      alt='mightycheesecake'
                      src='https://eatmightymeals.com/wp-content/uploads/2023/12/Cheesecake-desktop.png'
                      className='w-100'
                    />}
                </Link>
              </Col>
            }
            {((Window.isXLorGreater() && index === 40) ||
              ((Window.isLG() || Window.isTablet()) && index === 30) ||
              (Window.isMobile() && index === 20)) &&
              <Col xs={12}>
                <Link to={`/mightyfit?utm_source=order-page`}>
                  {Window.isMobile() ?
                    <Image
                      alt='mightyfit'
                      src='https://eatmightymeals.com/wp-content/uploads/2023/10/big-mightyfit-banner-mobile.png'
                      className='w-100'
                    /> :
                    <Image
                      alt='mightyfit'
                      src='https://eatmightymeals.com/wp-content/uploads/2023/10/MightyFit-banner.png'
                      className='w-100'
                    />}
                </Link>
              </Col>
            }
            {((Window.isXLorGreater() && index === 48) ||
              ((Window.isLG() || Window.isTablet()) && index === 36) ||
              (Window.isMobile() && index === 24)) &&
              <Col xs={12}>
                <Link to={`/our-mission?utm_source=order-page`}>
                  {Window.isMobile() ?
                    <Image
                      alt='official-meal-prep-co-of'
                      src='https://eatmightymeals.com/wp-content/uploads/2024/05/Official-Meal-Prep-Mobile-5-24.png'
                      className='w-100'
                    /> :
                    <Image
                      alt='official-meal-prep-co-of'
                      src='https://eatmightymeals.com/wp-content/uploads/2024/05/Official-Meal-Prep-Desktop-5-24.png'
                      className='w-100'
                    />}
                </Link>
              </Col>
            }
            {((Window.isXLorGreater() && index === 56) ||
              ((Window.isLG() || Window.isTablet()) && index === 42) ||
              (Window.isMobile() && index === 28)) &&
              <Col xs={12}>
                <Link to={`/catering?utm_source=order-page`}>
                  {Window.isMobile() ?
                    <Image
                      alt='catering'
                      src='https://eatmightymeals.com/wp-content/uploads/2023/11/Catering-Banner-Mobile.png'
                      className='w-100'
                    /> :
                    <Image
                      alt='catering'
                      src='https://eatmightymeals.com/wp-content/uploads/2023/11/Catering-Banner-Desktop.png'
                      className='w-100'
                    />}
                </Link>
              </Col>
            }
            <Col xl={3} lg={4} xs={6}>
              <ProductContainer
                product={product}
                showModal={String(productSlug) === String(product.data.slug)}
              />
            </Col>
          </React.Fragment>
        )
      })}
      {showLoadMore &&
        <div id="load-more" className='text-center my-5'>
          <b>Products loading...  &nbsp;</b> <Spinner animation="border" as="span" size="sm" />
        </div>}
      {!showLoadMore && (displayedProducts.length === 0 || allProducts.length === 0 || products.length === 0) &&
        <div className='text-center my-5'>
          No products found...
        </div>
      }
    </Row>
  );
}