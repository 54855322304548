import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleChevronUp } from "@fortawesome/free-solid-svg-icons";
import './scrollToTopButton.css';

export default function ScrollToTopButton() {
  const handleWindowScroll = () => {
    const bannerRect = document.querySelector('.main-banner')?.getBoundingClientRect();
    const scrollEl = document.querySelector('#scroll-to-top-btn');
    const productsRect = document.querySelector('.product-list')?.getBoundingClientRect();

    if (!bannerRect || !scrollEl || !productsRect) return;

    if (window.scrollY > bannerRect.height) {
      (scrollEl as HTMLElement).style.display = 'block';
      (scrollEl as HTMLElement).style.right = 
        window.innerWidth - productsRect.right + 20 + 'px';
    } else {
      (scrollEl as HTMLElement).style.display = 'none';
    }

  }

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);

    return () => window.removeEventListener('scroll', handleWindowScroll);
    // eslint-disable-next-line
  }, []);

  return (
    <FontAwesomeIcon
      id='scroll-to-top-btn'
      role="button"
      icon={faCircleChevronUp}
      size={'3x' as SizeProp}
      onClick={() => window.scrollTo(0,0)}
      className='fa-bg-white'
    />
  )
}