import axios from 'axios';
import React, { useEffect, useState } from 'react'
import SwiperProductsPage from '../SwiperProductsPage';

export default function UpCellsProducts() {
    const [relatedProducts, setRelatedProducts] = useState([]);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        return axios.get(`${process.env.REACT_APP_API_URL}wp-json/mmr/v1/upsell_products`).then((response) => {
            setRelatedProducts(response.data.upsell_products)
        });
    }

    return (
        <div className="container0">
            <div className="detail-wrapper">
                <div className="releted-work pb-4">
                    {relatedProducts.length > 0 &&
                        <SwiperProductsPage swiperProducts={relatedProducts} />}
                </div>
            </div>
        </div>
    )
}
