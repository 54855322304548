import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from '../../../app/store';
import MightyFitAPI from '../../../API/mightyFitAPI';

interface WorkoutState {
  workouts: Array<Record<string, any>>;
  pages_loaded: number;
  loaded: boolean;
  loading: boolean;
  loadError: boolean;
}

const initialState: WorkoutState = {
  workouts: [],
  pages_loaded: 0,
  loaded: false,
  loading: false,
  loadError: false,
}

export const loadWorkouts = createAsyncThunk(
  'workouts/loadWorkouts',
  async (slug: string, thunkAPI) => {
    let result: { total_pages: number | null, json: Array<Record<string, any>> | null } = { 
      total_pages: null,
      json: null };
    try {
      let response = await MightyFitAPI.getWorkoutBySlug(slug);
      result = response.data;
    } catch (e)  {
      if (e instanceof Error) {
        return { error: "Error retrieving workouts: " +  e.message};
      }
      return { error: "Unknown error while retrieving workouts." };
    }

    return result
  }
);

export const workoutSlice = createSlice({
  name: "workouts",
  initialState: initialState,
  reducers: {
    addWorkout: (state, action: PayloadAction<any>) => {
      state.workouts.push(action.payload);
    },
    removeWorkout: (state, action: PayloadAction<any>) => {
      state.workouts = state.workouts.filter((item) => {
        return item !== action.payload;
      });
    },
    clearWorkout: (state) => {
      state.workouts = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(loadWorkouts.pending, (state) => {
      state.loading = true;
      state.loaded = false;
      state.loadError = false;
    });
    builder.addCase(loadWorkouts.fulfilled, (state, action) => {
      if (action.payload && Array.isArray(action.payload)) {
        state.workouts = action.payload;
        state.pages_loaded += 1;
        state.loaded = true;
      }
      state.loading = false;
    });
    builder.addCase(loadWorkouts.rejected, (state) => {
      state.loading = false;
      state.loadError = true;
    });
  }
});

export const { addWorkout, clearWorkout, removeWorkout } = workoutSlice.actions;
export const selectWorkout = (state: RootState) => state.workouts.workouts;
export const selectWorkoutLoaded = (state: RootState) => state.workouts.loaded;
export const selectWorkoutLoadError = (state: RootState) => state.workouts.loadError;
export const selectWorkoutLoading = (state: RootState) => state.workouts.loading;
export default workoutSlice.reducer;