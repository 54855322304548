import { ISetCartCouponData, ISetCartData, ISetCartItemInput } from '../features/cart/interfaces';


export default class CartAPI {
  static async deleteCart(token: string) {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/cart?token=' + token,
      {
        method: 'DELETE',
        headers: { 'content-type': 'application/json;charset=UTF-8' }  
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async removeCartCoupon(token: string) {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/cart/coupons?token=' + token,
      {
        method: 'DELETE',
        headers: { 'content-type': 'application/json;charset=UTF-8' }  
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async getCart(token: string) {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/cart?token=' + token,
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset-UTF-8' }
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async setCart(data: ISetCartData) {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/cart',
      {
        method: 'PUT',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify(data)
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();

  }

  static async updateCartCoupon(data: ISetCartCouponData) {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/cart/coupons',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({
          token: data.token,
          coupon: JSON.stringify(data.coupon)
        })
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }


  static async updateCartItem(data: ISetCartItemInput) {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/cart_items',
      {
        method: 'PUT',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify(data)
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }
}
