import { useDispatch, useSelector } from "react-redux";
import { selectProductDietFilter, setProductDietFilter } from "../productsSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function DietFilterTag() {
  const dispatch = useDispatch();
  const dietFilter = useSelector(selectProductDietFilter);

  const getFilterText = () => {
    switch (dietFilter) {
      case 'breakfast':
        return 'Breakfast';
      case 'emp180-approved':
        return 'EMP180 Approved';
      case 'low-carb':
        return "Low Carb < 35";
      case 'low-sodium':
        return 'Low Sodium';
      case 'over-500-cal':
        return '> 500 Cal';
      case 'under-500-cal':
        return '< 500 Cal';
      case 'vegan':
        return 'Vegan';
      case 'vegetarian':
        return 'Vegetarian';
    }
  }

  const handleClick = () => {
    dispatch(setProductDietFilter('all'));
  }

  if (dietFilter === 'all') {
    return (<></>);
  }

  return (
    <div className='protein-filter-tag bg-secondary bg-opacity-25 p-2 fw-bold text-nowrap'>
      {getFilterText()}
      <FontAwesomeIcon
        role="button"
        className='ps-3 pe-auto'
        icon={faXmark}
        size={"xl" as SizeProp}
        onClick={handleClick}
      />
    </div>
  );
}