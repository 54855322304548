import React, { useState, useEffect, useRef } from 'react';
import SearchFilter from './SearchFilter';
import TrainersFilter from './TrainersFilter';
import FitFilter from './FitFilter';

export default function FilterPage() {
    const [isFitFilterOpen, setIsFitFilterOpen] = useState(false);
    const [isTrainerFilterOpen, setIsTrainerFilterOpen] = useState(false);
    const filterRef = useRef<HTMLDivElement | null>(null);

    const handleIsFilterOpen = () => {
        if (isTrainerFilterOpen) {
            setIsTrainerFilterOpen(false);
        }
        setIsFitFilterOpen(!isFitFilterOpen);
    };
    
    const handleIsTrainerOpen = () => {
        if (isFitFilterOpen) {
            setIsFitFilterOpen(false);
        }
        setIsTrainerFilterOpen(!isTrainerFilterOpen);
    };

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (filterRef.current && !filterRef.current.contains(event.target as Node)) {
                setIsFitFilterOpen(false);
                setIsTrainerFilterOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    

    return (
        <div className="filter-part" ref={filterRef}>
            <div className="container">
                <div className="fp-wrapper">
                    <div className="bottom-filter">
                        <div className="both-filter">
                            <button className={`${isTrainerFilterOpen ? 'isOpen ' : ''}trainers`} onClick={handleIsTrainerOpen}>
                                <div className="bf-title">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className='user'>
                                        <path d="M12.75 15.75V14.25C12.75 13.4544 12.4339 12.6913 11.8713 12.1287C11.3087 11.5661 10.5456 11.25 9.75 11.25H3.75C2.95435 11.25 2.19129 11.5661 1.62868 12.1287C1.06607 12.6913 0.75 13.4544 0.75 14.25V15.75" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6.75 8.25C8.40685 8.25 9.75 6.90685 9.75 5.25C9.75 3.59315 8.40685 2.25 6.75 2.25C5.09315 2.25 3.75 3.59315 3.75 5.25C3.75 6.90685 5.09315 8.25 6.75 8.25Z" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17.25 15.75V14.25C17.2495 13.5853 17.0283 12.9396 16.621 12.4142C16.2138 11.8889 15.6436 11.5137 15 11.3475" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 2.3475C12.6453 2.51273 13.2173 2.88803 13.6257 3.41424C14.0342 3.94044 14.2559 4.58763 14.2559 5.25375C14.2559 5.91988 14.0342 6.56706 13.6257 7.09327C13.2173 7.61948 12.6453 7.99478 12 8.16" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className="bf-item text-dark">Trainers</span>
                                    <svg width="10" className='btnArrow' height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L5 5L9 1" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </button>
                            <button className={`${isFitFilterOpen ? 'isOpen ' : ''}trainers`} onClick={handleIsFilterOpen}>
                                <div className="bf-title">
                                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" className='user'>
                                        <path d="M16 2.5H1L7 9.595V14.5L10 16V9.595L16 2.5Z" stroke="#2B2B2B" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className="bf-item text-dark">Filter</span>
                                    <svg width="10" className='btnArrow' height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L5 5L9 1" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </button>
                        </div>
                        <SearchFilter />
                    </div>
                    {isTrainerFilterOpen && <TrainersFilter />}
                    {isFitFilterOpen && <FitFilter />}
                </div>
            </div>
        </div>
    )
}

// import React, { useState } from 'react'
// import SearchFilter from './SearchFilter';
// import TrainersFilter from './TrainersFilter';
// import FitFilter from './FitFilter';

// export default function FilterPage() {
//     const [isFitFilterOpen, setIsFitFilterOpen] = useState(false);
//     const [isTrainerFilterOpen, setIsTrainerFilterOpen] = useState(false);

//     const handleIsFilterOpen = () => {
//         if (isTrainerFilterOpen) {
//             setIsTrainerFilterOpen(false)
//         }
//         setIsFitFilterOpen(!isFitFilterOpen)
//     }
//     const handleIsTrainerOpen = () => {
//         if (isFitFilterOpen) {
//             setIsFitFilterOpen(false)
//         }
//         setIsTrainerFilterOpen(!isTrainerFilterOpen)
//     }

//     return (
//         <div className="filter-part">
//             <div className="container">
//                 <div className="fp-wrapper">
//                     <div className="bottom-filter">
//                         <div className="both-filter">
//                             <button className={`${isTrainerFilterOpen ? 'isOpen ' : ''}trainers`} onClick={handleIsTrainerOpen}>
//                                 <div className="bf-title">
//                                     <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className='user'>
//                                         <path d="M12.75 15.75V14.25C12.75 13.4544 12.4339 12.6913 11.8713 12.1287C11.3087 11.5661 10.5456 11.25 9.75 11.25H3.75C2.95435 11.25 2.19129 11.5661 1.62868 12.1287C1.06607 12.6913 0.75 13.4544 0.75 14.25V15.75" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" />
//                                         <path d="M6.75 8.25C8.40685 8.25 9.75 6.90685 9.75 5.25C9.75 3.59315 8.40685 2.25 6.75 2.25C5.09315 2.25 3.75 3.59315 3.75 5.25C3.75 6.90685 5.09315 8.25 6.75 8.25Z" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" />
//                                         <path d="M17.25 15.75V14.25C17.2495 13.5853 17.0283 12.9396 16.621 12.4142C16.2138 11.8889 15.6436 11.5137 15 11.3475" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" />
//                                         <path d="M12 2.3475C12.6453 2.51273 13.2173 2.88803 13.6257 3.41424C14.0342 3.94044 14.2559 4.58763 14.2559 5.25375C14.2559 5.91988 14.0342 6.56706 13.6257 7.09327C13.2173 7.61948 12.6453 7.99478 12 8.16" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" />
//                                     </svg>
//                                     <span className="bf-item text-dark">Trainers</span>
//                                     <svg width="10" className='btnArrow' height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                         <path d="M1 1L5 5L9 1" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" />
//                                     </svg>
//                                 </div>
//                             </button>
//                             <button className={`${isFitFilterOpen ? 'isOpen ' : ''}trainers`} onClick={handleIsFilterOpen}>
//                                 <div className="bf-title">
//                                     <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" className='user'>
//                                         <path d="M16 2.5H1L7 9.595V14.5L10 16V9.595L16 2.5Z" stroke="#2B2B2B" strokeLinecap="round" strokeLinejoin="round" />
//                                     </svg>
//                                     <span className="bf-item text-dark">Filter</span>
//                                     <svg width="10" className='btnArrow' height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                         <path d="M1 1L5 5L9 1" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" />
//                                     </svg>
//                                 </div>
//                             </button>
//                         </div>
//                         <SearchFilter />
//                     </div>
//                     {isTrainerFilterOpen && <TrainersFilter />}
//                     {isFitFilterOpen && <FitFilter />}
//                 </div>
//             </div>
//         </div>
//     )
// }