import { IReferralPost, IVisitPost } from "../features/referrals/interfaces";

export default class AffilitateAPI {
  static async getAffiliate(
    slug: string
  ) {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/affiliates/' + slug,
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
      }
    ));
    
    if (!response.ok) {
      console.error('HTTP error while getting affiliate:', response);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async postReferral(data: IReferralPost) {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/affiliates/' + data.affiliateId + '/referrals',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({
          ...('visitId' in data && { visit_id: data.visitId }),
          description: data.description,
          reference: data.reference,
          amount: data.affiliateRate,
          currency: 'USD',
          status: 'unpaid',
          customer_id: data.customerId,
        })  
      }
    ));

    if (!response.ok) {
      console.error('HTTP error while posting affiliate referral:', response);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    const responseData = await response.json();
    return responseData;
  }

  static async UpdateRefferal(refferealID: number, cutomerID: number, visitorID: number) {
    let response = await(fetch(
      process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/update_referral',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({
          referral_id: refferealID,
          customer_id: cutomerID,
          visit_id: visitorID,
        })  
      }
    ));

    if (!response.ok) {
      console.error('HTTP error while recording affiliate visit:', response);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async postVisit(data: IVisitPost) {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/affiliates/' + data.affiliateId + '/visits',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({
          url: data.url,
          ip: data.ip,
        })  
      }
    ));

    if (!response.ok) {
      console.error('HTTP error while recording affiliate visit:', response);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async postLifetimeAffiliate(affiliateId: number, customerID: number) {
    let response = await(fetch(
      process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/create_lifetime_customer',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({
          affiliate_id: affiliateId,
          user_id: customerID,
        })  
      }
    ));

    if (!response.ok) {
      console.error('HTTP error while recording affiliate visit:', response);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async getAffiliateByUser(
    userId: number
  ) {
    let response = await(fetch(
      process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/get_user_affiliate?user_id=' + userId,
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
      }
    ));
    
    if (!response.ok) {
      console.error('HTTP error while getting affiliate:', response);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }
}