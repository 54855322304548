import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { loadDeliveryInfo, selectDeliveryLoading } from '../features/delivery/deliverySlice';
import MyAccountNav from '../components/MyAccountNav';
import MyOrders from '../features/orders/MyOrders';
import MyOrdersMobile from '../features/orders/MyOrdersMobile';
import Window from '../utils/Window';
import { selectToken } from '../features/user/userSlice';
import MyAccountPage from './MyAccountPage';



export default function MyOrdersPage() {
  const dispatch = useDispatch();
  const [deliveryReloaded, setDeliveryReloaded] = useState(false);
  const deliveryLoading = useSelector(selectDeliveryLoading);
  const token = useSelector(selectToken);
  const setLoginInfo = localStorage.getItem('setAuthInfo');
  const logInfo = setLoginInfo && JSON.parse(setLoginInfo);
  const userToken = logInfo ? logInfo.token : token;

  useEffect(() => {
    if (!deliveryLoading && !deliveryReloaded) {
      dispatch(loadDeliveryInfo());
      setDeliveryReloaded(true);
    }
  }, [deliveryLoading, deliveryReloaded, dispatch]);

  if (!userToken) {
    return (
      <MyAccountPage />
    )
  }

  return (
    <Container fluid="lg" className='address-page'>
      <Row>
        <Col sm={12} md={3}>
          <MyAccountNav />
        </Col>
        <Col sm={12} md={9} className='d-flex flex-column'>
          {Window.isMobile() ? <MyOrdersMobile /> : <MyOrders />}
        </Col>
      </Row>
    </Container>
  );
}
