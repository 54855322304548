import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  CloseButton,
  Col,
  Image,
  Modal,
  Row
} from 'react-bootstrap';
import PlusMinusInput from '../../components/PlusMinusInput';
import {
  selectCartUpdateInProgress,
  selectOrderInProgress
} from '../cart/cartSlice';
import ProductBadges from './ProductBadges';
import ProductFAQs from './ProductFAQs';
import ProductIngredients from './ProductIngredients';
import ProductObj from './productObj';
import DietaryIcons from './DietaryIcons';
import CustomerObj from '../customer/customerObj';
import { selectCustomer } from '../customer/customerSlice';
import classnames from 'classnames';
import Window from '../../utils/Window';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import TiktokPixel from 'tiktok-pixel';
import NutritionPerServing from './NutritionPerServing';
import ProductsImagesSlider from './ProductsImagesSlider';

declare const _learnq: any;

interface Props {
  product: ProductObj;
  show: boolean;
  onHide: () => void;
  onQtyChange: (qty: number) => void;
  quantity: number;
}

export default function ProductDetailModal({
  product,
  show,
  onHide,
  onQtyChange,
  quantity
}: Props) {
  const customer = new CustomerObj(useSelector(selectCustomer));
  const cartUpdateInProgress = useSelector(selectCartUpdateInProgress);
  const orderInProgress = useSelector(selectOrderInProgress);
  const description = new DOMParser().parseFromString(product.data.description, "text/html")
    .documentElement.textContent;
  const hasNutritionalInfo = (product.getCalories().length > 0 && product.getCalories() !== '0') ||
    (product.getNutritionFat().length > 0 && product.getCalories() !== '0') ||
    product.getNutritionSaturatedFat().length > 0 ||
    product.getNutritionCarbohydrate().length > 0 ||
    product.getNutritionSugar().length > 0 ||
    product.getNutritionDietaryFiber().length > 0 ||
    (product.getNutritionProtein().length > 0 && product.getNutritionProtein() !== '0') ||
    product.getNutritionCholesterol().length > 0 ||
    product.getNutritionSodium().length > 0;

  useEffect(() => {
    if (show) {
      // Track event with React Pixel
      ReactPixel.track('ViewContent', {
        content_ids: [product.data.id],
        content_name: product.data.name,
        content_type: 'product',
        currency: 'USD',
        value: customer.getProductPrice(product),
      });

      // Track event with React GA
      ReactGA.event('view_item', {
        currency: 'USD',
        value: customer.getProductPrice(product),
        items: [{
          item_id: product.data.id,
          item_name: product.data.name,
        }],
      });

      // Track event with Tiktok
      TiktokPixel.track('ViewContent', {
        contents: [{
          content_id: product.data.id,
          content_name: product.data.name,
          price: product?.data?.price,
        }],
        content_type: 'product',
        currency: 'USD',
        value: customer.getProductPrice(product),
      });

      // Reddit Pixel tracking
      const email = customer.data.email ? customer.data.email : "";
      (window as any).rdt('track', 'ViewContent', {
        email: email,
        products: [
          {
            id: product.data.id,
            name: product?.data.name,
            category: product?.data.categories,
          }
        ]
      });

      const item = {
        ProductName: product.data.name,
        ProductID: product.data.id,
        SKU: product.data.sku,
        Categories: product.data.categories,
        ImageURL: product.data.images[0].src,
        URL: `${process.env.REACT_APP_WEB_URL}product/${product.data.slug}`,
        Price: product.data.price,
        product_id: product.data.id,
        product_name: product.data.name,
      };

      if (typeof _learnq !== 'undefined') {
        _learnq.push(["track", "Viewed Product", item]);
      }

    }
    // eslint-disable-next-line
  }, [show]);

  return (
    <Modal size="xl" show={show} onHide={onHide} className='product-detail-modal'>
      <Modal.Body className={classnames(null, {
        'p-5': !Window.isMobile(),
        'p-15px': Window.isMobile()
      })}>
        <div className='d-flex justify-content-end'>
          <CloseButton onClick={onHide} />
        </div>
        <Row>
          <Col className="product-title fs-2">{product.data.name}</Col>
        </Row>
        <Row>
          <Col md={12} lg={6}>
            <ProductBadges product={product} />
          </Col>
          <Col md={12} lg={6}>
            <div className="d-flex justify-content-between mb-3">
              <PlusMinusInput
                onChange={onQtyChange}
                value={quantity}
                disabled={orderInProgress || cartUpdateInProgress}
                className='py-2'
              />
              <div className='pt-3'>
                {customer.hasMembership() &&
                  <div className='d-flex fs-5'>
                    <span><del>${product.data.price}</del></span>
                  </div>
                }
                <div className='d-flex fs-5 fw-bold'>
                  {customer.getProductPrice(product, true)}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={6}>
            {product.data.slider_data && Object.values(product.data.slider_data).length > 0 ?
              <ProductsImagesSlider product={product} /> :
              <Image
                alt="product-img"
                src={product?.data?.images[0]?.src}
                className='mb-4 mb-sm-0'
              />}
          </Col>
          <Col md={12} lg={6}>
            <Card className='product-description fs-5 h-100' body>
              <div className='d-flex flex-column h-100'>
                {product.data.slug.startsWith("mighty") ?
                  <div className='mb-sm-auto font-barlow fs-20px mb-4' dangerouslySetInnerHTML={{ __html: product.data.description }}></div>
                  : <div className='mb-sm-auto font-barlow fs-20px mb-4'>{description}</div>}
                <Row>
                  <Col xs={6} className='fs-6 h-100'>
                    {!customer.hasMembership() &&
                      <div className='fw-bold font-barlow fs-18px'>
                        Earn {product.getPoints()} Mighty Points
                      </div>
                    }
                  </Col>
                  <Col xs={6}>
                    <DietaryIcons product={product} className='float-end' />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          {hasNutritionalInfo && <Col md={12} lg={6}>
            <NutritionPerServing product={product} />
          </Col>}
          <Col md={12} lg={hasNutritionalInfo ? 6 : 12}>
            <ProductIngredients product={product} />
          </Col>
        </Row>
        <ProductFAQs />
      </Modal.Body>
    </Modal>
  )
}
