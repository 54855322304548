import React, { useEffect, useState } from 'react'
import { Alert, Col, Container, Row, Spinner } from 'react-bootstrap'
import 'swiper/css';
import 'swiper/css/pagination';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDetails } from '../../core/detailsSlice';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createWorkPlan, deleteWorkPlan, selectWorkPlans } from '../../core/workoutPlansSlice';
import { selectToken } from '../../../../features/user/userSlice';
import WorkoutPlansAPI from '../../../../API/myWorkoutsPlanAPI';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faHeart as faRegularHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faSolidHeart } from "@fortawesome/free-solid-svg-icons";

SwiperCore.use([Navigation]);

export default function RelatedWorkOutPage() {
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const workoutDetails = useSelector(selectDetails)[0];
    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);
    const [selectedProductId, setSelectedProductId] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [workouts, setWorkouts] = useState(workoutDetails);
    const favoriteWorkouts = useSelector(selectWorkPlans);
    const [showModalId, setShowModalId] = useState<number[] | null>(null);
    const [isAdding, setIsAdding] = useState(false);

    useEffect(() => {
        setWorkouts(workoutDetails);
    }, [workoutDetails])

    useEffect(() => {
        if (errorMessage) {
            setTimeout(() => {
                setErrorMessage('')
                setSelectedProductId([]);
            }, 3000)
        }
    }, [errorMessage])

    useEffect(() => {
        const matchedWorkouts = workouts.related_workouts.filter((workout: any) =>
            favoriteWorkouts.some((myworkouts: any) =>
                myworkouts.items.some((myWorkoutFavorite: any) =>
                    myWorkoutFavorite.workoutId === workout.id
                )
            )
        );

        const matchingIds = matchedWorkouts.map((workout: any) => workout.id);
        setShowModalId(matchingIds);
    }, [favoriteWorkouts, workouts, isAdding]);

    const handleFavoriteSet = (items: any) => {
        if (!token) {
            return setErrorMessage('Please Log-in/Register To Add To Your Favorite.')
        } else {
            if (showModalId?.includes(items.id)) {
                setIsAdding(true);
                const matchedWorkouts = favoriteWorkouts.filter((myworkouts: any) =>
                    myworkouts.items.some((myWorkoutFavorite: any) =>
                        myWorkoutFavorite.workoutId === items.id
                    )
                );
                WorkoutPlansAPI.deleteWorkoutsPlan(token, matchedWorkouts[0].id).then(async () => {
                    await dispatch(deleteWorkPlan(matchedWorkouts[0].id));
                }).catch((e) => {
                    console.error(e);
                }).finally(() => {
                    setIsAdding(false);
                    setSelectedProductId([]);
                });
            } else {
                setIsAdding(true);
                WorkoutPlansAPI.createWorkoutsPlan(
                    token, {
                    name: items.trainer_name,
                    items: [{
                        workoutId: items.id,
                        workoutName: items.title
                    }]
                }).then(async (workPlan) => {
                    await dispatch(createWorkPlan(workPlan));
                }).catch((e) => {
                    console.error(e);
                }).finally(() => {
                    setIsAdding(false);
                    setSelectedProductId([]);
                });
            }
        }
    }

    if (workoutDetails?.related_workouts.length === 0) {
        return (
            <></>
        )
    }

    return (
        <div className="releted-work">
            <div className="rw-heading">
                <h2 className='h2'>Related Workouts</h2>
            </div>

            <div className="slider-part">
                <Swiper
                    spaceBetween={30}
                    slidesPerView={3}
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    scrollbar={{ draggable: true }}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        992: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    {workoutDetails?.related_workouts?.map((items: any, index: number) => (
                        <SwiperSlide key={index}>
                            <div className="card-part">
                                <Container fluid="lg" className='address-page'>
                                    <Row className="card-row">
                                        <Col lg={4} md={6} className="card-col">
                                            <div className="cp-wrapper">
                                                <div className="cp-imagewrap">
                                                    <Link to={`/mightyfit/${items.id}`} className="card-link">
                                                        <div className='imagewrap'>
                                                            <img src={items.image} alt="" />
                                                        </div>
                                                    </Link>
                                                    <div className='icon-wrap'>
                                                        {selectedProductId === items.id && errorMessage && (
                                                            <Alert variant="danger" className='my-3 text-center error-message'>{errorMessage}</Alert>
                                                        )}
                                                        {(selectedProductId === items.id && !errorMessage) && (
                                                            <Spinner animation="border" as="span" size="sm" />
                                                        )} {(selectedProductId !== items.id &&
                                                            <FontAwesomeIcon
                                                                role="button"
                                                                icon={showModalId?.includes(items.id) ? faSolidHeart : faRegularHeart}
                                                                color={`${showModalId?.includes(items.id) ? 'red' : 'green'}`}
                                                                size={'xl' as SizeProp}
                                                                onClick={() => {
                                                                    setSelectedProductId(items.id);
                                                                    handleFavoriteSet(items);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="cp-deswrap">
                                                    <div className="cp-show">
                                                        <h5 className='h5'>{items.title}</h5>
                                                        <p className="pera">{items.small_description}</p>
                                                        <div className="iconpart">
                                                            <div className='first-part'>
                                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="#00B156" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M9 4.5V9L12 10.5" stroke="#00B156" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                <span>{items.duration_in_minutes}</span>
                                                            </div>
                                                            <div className='first-part'>
                                                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0_213_937)">
                                                                        <path d="M3 0L3.927 1.878L6 2.181L4.5 3.642L4.854 5.706L3 4.731L1.146 5.706L1.5 3.642L0 2.181L2.073 1.878L3 0Z" fill="#00B156" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_213_937">
                                                                            <rect width="6" height="6" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>

                                                                <span>{items.body_focus}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bg-hover">
                                                        <div className="cp-hover">
                                                            <Row className="hoverrow">
                                                                <Col className="hover-col">
                                                                    <p>Difficulty</p>
                                                                </Col>
                                                                <Col className="hover-col right">
                                                                    <span className='text-white'>{items.difficulty_level}</span>
                                                                </Col>
                                                            </Row>
                                                            <Row className="hoverrow">
                                                                <Col className="hover-col">
                                                                    <p>Training Type</p>
                                                                </Col>
                                                                <Col className="hover-col right">
                                                                    <p>{items.training_type.join(", ")}</p>
                                                                </Col>
                                                            </Row>
                                                            <Row className="hoverrow">
                                                                <Col className="hover-col">
                                                                    <p>Equipment</p>
                                                                </Col>
                                                                <Col className="hover-col right">
                                                                    <p>{items.equipments.join(", ")}</p>
                                                                </Col>
                                                            </Row>
                                                            <Row className="hoverrow">
                                                                <Col className="hover-col">
                                                                    <p>Burn Estimate</p>
                                                                </Col>
                                                                <Col className="hover-col right">
                                                                    <p>{items.minimum_duration_of_calorie_burn} - {items.maximum_duration_of_calorie_burn} Calories</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="arrow-wrap">
                    <div ref={navigationPrevRef} className="arrow-left">
                    </div>
                    <div ref={navigationNextRef} className="arrow-right">
                    </div>
                </div>
            </div>
        </div>
    )
}
