import type { RootState } from '../../app/store';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import OrdersAPI from '../../API/ordersAPI';
import AutoShipAPI from '../../pages/AutoshipPages/core/autoshipAPI';

interface OrdersState {
  orders: Array<Record<string, any>>;
  ordersList: Array<Record<string, any>>;
  pages_loaded: number;
  total_pages: number;
  load_complete: boolean;
  load_error: boolean;
  updatingOrder: boolean;
}

interface OrderDeliveryDatePayload {
  order_id: number;
  delivery_date: string;
}

interface MetaItem {
  id: number;
  key: string;
  value: any;
}

export const loadOrders = createAsyncThunk(
  'orders/loadOrders',
  async (params: { token: string, isAutoship?: boolean, customerId?: any }, thunkAPI) => {
    const { token, isAutoship = false, customerId } = params;
    const state = (thunkAPI.getState() as RootState).orders;
    let result;
    if (isAutoship) {
      result = await AutoShipAPI.getAutoshipOrders(customerId);
    } else {
      result = await OrdersAPI.getOrders(token, state.pages_loaded + 1, 5);
    }
    
    const orders = [...result.json];

    return {
      orders: orders,
      total_pages: result.total_pages
    };
  }
);

const initialState: OrdersState = {
  orders: [],
  ordersList: [],
  pages_loaded: 0,
  total_pages: 0,
  load_complete: false,
  load_error: false,
  updatingOrder: false
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState: initialState,
  reducers: {
    addOrder: (state, action: PayloadAction<Record<string, any>>) => {
      return {
        ...state,
        orders: [...state.orders, action.payload]
      }
    },
    resetOrders: (state, action: PayloadAction<undefined>) => {
      return initialState;
    },
    setUpdatingOrder: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        updatingOrder: action.payload
      }
    },
    updateOrder: (state, action: PayloadAction<Record<string, any>>) => {
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order.id === action.payload.id) {
            return action.payload;
          } else {
            return order;
          }
        })
      }
    },
    updateOrderDeliveryDate: (state, action: PayloadAction<OrderDeliveryDatePayload>) => {
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order.id === action.payload.order_id) {
            return {
              ...order,
              meta_data: order.meta_data.map((meta_item: MetaItem) => {
                if (meta_item.key === '_delivery_date') {
                  return { ...meta_item, value: action.payload.delivery_date }
                }
                return meta_item;
              })
            }
          }
          return order;
        })
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(loadOrders.pending, (state, action) => {
      state.load_complete = false;
      state.load_error = false;
    });
    builder.addCase(loadOrders.fulfilled, (state, action) => {
      let orders;
      if (action.payload.orders !== undefined && action.payload.total_pages !== undefined) {
        if (state.pages_loaded === 0) {
          orders = [...action.payload.orders]
        } else {
          orders = [...state.orders, ...action.payload.orders]
        }
        return {
          ...state,
          total_pages: action.payload.total_pages,
          pages_loaded: state.pages_loaded + 1,
          load_complete: (state.pages_loaded + 1 >= action.payload.total_pages),
          orders: orders
        }
      }
    });
    builder.addCase(loadOrders.rejected, (state, action) => {
      state.load_error = true;
    });
  }
});

export const { addOrder,
  resetOrders,
  setUpdatingOrder,
  updateOrder,
  updateOrderDeliveryDate } = ordersSlice.actions;
export const selectOrders = (state: RootState) => state.orders.orders;
// export const selectOrdersList = (state: RootState) => state.orders.ordersList;
export const selectOrdersLoadComplete = (state: RootState) => state.orders.load_complete;
export const selectOrdersTotalPages = (state: RootState) => state.orders.total_pages;
export const selectOrdersPagesLoaded = (state: RootState) => state.orders.pages_loaded;
export const selectOrderLoadError = (state: RootState) => state.orders.load_error;
export const selectUpdatingOrder = (state: RootState) => state.orders.updatingOrder;
export default ordersSlice.reducer;