import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import CartObj from './cartObj';
import { selectCartZipcode, setCartZipcode } from './cartSlice';
import { selectShippingLoaded, 
         selectShippingMethods } from '../shipping/shippingSlice';
import ShippingMethodObj from '../shipping/shippingMethodObj'
import { selectCustomer } from '../customer/customerSlice';
import CustomerObj from '../customer/customerObj';
import Window from '../../utils/Window';

interface Props {
  cart: CartObj;
  handleZipChange?: (isValid: boolean) => void;
  getShippingMethodDates?: (zip: any) => void;
}

export default function DeliveryZip({cart, handleZipChange, getShippingMethodDates }: Props) {
  const dispatch = useDispatch();
  const custData = useSelector(selectCustomer);
  const customer = useMemo(() => {
    return new CustomerObj(custData);
  }, [custData]);
  const cartZip = useSelector(selectCartZipcode);
  const shipping_info = Object.keys(customer.data).length > 0 ? customer.data.shipping : {};
  const billing_info = Object.keys(customer.data).length > 0 ? customer.data.billing : {};
  const shippingLoaded = useSelector(selectShippingLoaded);
  const shippingMethods = useSelector(selectShippingMethods);
  const zipCode = shipping_info && 'postcode' in shipping_info ? shipping_info.postcode : billing_info && 'postcode' in billing_info ? billing_info.postcode : cartZip
  const [errorMsg, setErrorMsg] = useState('');
  const [val, setVal] = useState(zipCode || '');

  useEffect(() => {
    handleZipCodeEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode]);
  
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let zip = e.target.value;
    handleZipCodeChange(zip);
  }
  
  const handleZipCodeEffect = () => {
    if (zipCode) {
      handleZipCodeChange(zipCode);
    }
  }
  
  const handleZipCodeChange = (zip: string) => {
    setVal(zip);
    setErrorMsg('');
  
    if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip)) {
      handleInvalidZipCode();
      return;
    }
  
    const matchingShippingMethod = shippingMethods.find((smData: Record<string, any>) => {
      const sm = new ShippingMethodObj(smData);
      return sm.isZipCodeMatch(zip);
    });
  
    if (matchingShippingMethod) {
      handleValidZipCode(zip);
      return;
    }
  
    handleUnsupportedZipCode();
  }
  
  const handleInvalidZipCode = () => {
    setErrorMsg("Invalid");
    dispatch(setCartZipcode(''));
    handleZipChange?.(false);
  }
  
  const handleValidZipCode = (zip: string) => {
    dispatch(setCartZipcode(zip));
    handleZipChange?.(true);
    getShippingMethodDates?.(zip);
  }
  
  const handleUnsupportedZipCode = () => {
    setErrorMsg("Unfortunately, we don't deliver to that zip code");
  }

  return (
    <div className='delivery-zip me-1'>
      {!Window.isMobile() && <div className='delivering-to-label'>Enter Zip Code:</div>}
      <Form.Control 
        id="delivery_postcode"
        type="text"
        maxLength={10}
        isInvalid={Boolean(cartZip) && Boolean(errorMsg)}
        isValid={Boolean(cartZip)}
        onChange={handleChange}
        autoComplete="foo"
        value={val}
        disabled={!shippingLoaded}
        placeholder='Enter Zip Code'
      />
      {errorMsg &&
        <Form.Control.Feedback type="invalid">
          {errorMsg === "Unfortunately, we don't deliver to that zip code" ? 
          <div>
            Unfortunately, at this time we do not deliver to you. Please join our &nbsp;
            <a 
              href="https://manage.kmail-lists.com/subscriptions/subscribe?a=XNn54T&g=UjPZic" 
              rel="noreferrer" 
              target="_blank"
              className='text-red'>waitlist</a> and be the first to know when we do!
          </div> : errorMsg}
        </Form.Control.Feedback>
      }
    </div>
  )
}