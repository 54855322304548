export default class AffiliateObj {
  data: Record<string, any>;

  constructor(data: Record<string, any>) {
    this.data = data
  }

  getBrandColor = () => {
    if ('input_mm-brand-color' in this.data.meta) {
      return this.data.meta['input_mm-brand-color'][0];
    }
    return null;
  }

  getCustomSlug = () => {
    if (this.data && this.data.meta && 'custom_slug' in this.data.meta) {
      return this.data.meta['custom_slug'][0];
    }
    return null;
  }

  getLogoId = () => {
    if ('mm_brand_logo_id' in this.data.meta) return this.data.meta.mm_brand_logo_id[0];

    return null;
  }
}