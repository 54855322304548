import { useState } from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import ProductDetailModal from './ProductDetailModal';
import ProductObj from './productObj';
import PlusMinusInput from '../../components/PlusMinusInput';
import DietaryIcons from './DietaryIcons';

interface Props {
  product: ProductObj;
  quantity: number;
  onQtyChange: (qty: number) => void;
  cartPrice: number;
  cartUpdateInProgress: boolean;
  orderInProgress: boolean;
}

export default function PopularProduct({ 
  product,
  quantity,
  onQtyChange,
  cartPrice,
  cartUpdateInProgress,
  orderInProgress
}: Props) {
  const [showDetail, setShowDetail] = useState(false);

  return (
    <div className='popular-product m-1 p-1'>
      <Card className='border-0 p-1'>
        <ProductDetailModal 
          product={product} 
          show={showDetail} 
          onHide={() => setShowDetail(false)} 
          onQtyChange={onQtyChange}
          quantity={quantity}
        />
        <Row>
          <Col xs={4}>
            <Image
              src={product.data.images[0].src} 
              thumbnail={true}
              onClick={() => setShowDetail(true)}
              className='mb-4 border-0'
            />
          </Col>
          <Col xs={8} >
            <div className='product-title'>
              {product.data.name}
            </div>
            <div className='d-flex'>
              <div className='pe-1'>
                <div>{product.getCalories()}</div>
                <div>Cal</div>
              </div>
              <div className='px-1'>
                <div>{product.getCarbs()}g</div>
                <div>Carbs</div>
              </div>
              <div className='ps-1'>
                <div>{product.getProtein()}g</div>
                <div>Protein</div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className='mt-1'>
          <Col xs={8}>
            <DietaryIcons product={product} />
          </Col>
          <Col xs={4}>
            <PlusMinusInput 
              onChange={onQtyChange} 
              value={quantity} 
              disabled={orderInProgress || cartUpdateInProgress}
            />          
          </Col>
        </Row>
      </Card>
    </div>
  );
}