import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface CheckoutState {
  pendingOrder: Record<string, any> | null;
}

const initialState: CheckoutState = {
  pendingOrder: {}
}

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: initialState,
  reducers: {
    resetCheckout: (state) => {
      return initialState;
    },
    setPendingOrder: (state, action) => {
      return {
        ...state, 
        pendingOrder: {...action.payload}
      }
    }
  }
});

export const { resetCheckout, setPendingOrder } = checkoutSlice.actions;
export const selectPendingOrder = (state: RootState) => state.checkout.pendingOrder;
export default checkoutSlice.reducer;