import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, Container, Image, Row, Spinner } from 'react-bootstrap'
import pickmyself from '../../../assets/images/person-icon.svg';
import pickforme from '../../../assets/images/chefs-choice.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Window from '../../../utils/Window';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedDate, selectSelectedDates, setEditCouponLines, setExistingCartItems, setIsOrderChanged, setSelectedDates } from '../core/autoShipSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { selectOrders, selectUpdatingOrder, setUpdatingOrder, updateOrder } from '../../../features/orders/ordersSlice';
import { selectToken } from '../../../features/user/userSlice';
import { selectProducts } from '../../../features/products/productsSlice';
import { selectCustomer } from '../../../features/customer/customerSlice';
import { setCart } from '../../../features/cart/cartSlice';
import CustomerObj from '../../../features/customer/customerObj';
import OrderObj from '../../../features/orders/orderObj';
import { format } from 'date-fns';
import OrdersAPI from '../../../API/ordersAPI';
import { selectCustomerDetails } from '../../../features/customer/customerDetailSlice';
import CouponForm from '../../../features/coupons/CouponForm';

interface Props {
  orderId?: string;
  autoshipSelectPage: boolean;
  setAutoshipSelectPage: (autoshipSelectPage: boolean) => void;
  setMultiDates: (Dates: Date[]) => void;
}

const AutoshipSelectPage = ({ orderId, autoshipSelectPage, setAutoshipSelectPage, setMultiDates }: Props) => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const orders = useSelector(selectOrders);
  const products = useSelector(selectProducts);
  const customerData = useSelector(selectCustomer);
  const customerDetails = useSelector(selectCustomerDetails);
  const selectedDate = useSelector(selectSelectedDate);
  const selectedDates = useSelector(selectSelectedDates);
  const updatingOrder = useSelector(selectUpdatingOrder);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSelected, setIsSelected] = useState('');
  const customerUserData = customerDetails?.user_data;
  const customerImage = customerUserData?.imageUrl;
  const customer = useMemo(() => {
    return new CustomerObj(customerData);
  }, [customerData]);
  const processingOrders = orders.filter((order: any) => {
    const orderObj = new OrderObj(order);
    return orderObj.data.status === 'autoship';
  });
  const ordersDateString = format(new Date(selectedDate), 'EEE, MMM d');
  const processing = processingOrders.filter((order: any) => {
    const orderObj = new OrderObj(order);
    return orderObj.getDeliveryDate() === ordersDateString;
  });
  const pickForMyselfOrder = processing.some((order: any) => {
    const metaData = order.meta_data.find((meta: any) => meta.key === '_autoship_order_type' && meta.value === 'pick_for_myself');
    return !!metaData;
  });

  const pickForMeOrder = processing.some((order: any) => {
    const metaData = order.meta_data.find((meta: any) => meta.key === '_autoship_order_type' && meta.value === 'pick_for_me');
    return !!metaData;
  });
  const latestOrder = processing.length > 0 ? new OrderObj(processing[0]) : new OrderObj(orders.at(-1));
  const imageUrl = customerImage && customerImage.includes('userdefault.png') ? pickmyself : customerImage;
  const isNotAutoshipSelectPage = location.pathname !== '/autoship/select';
  const currentOrderId = orderId ? orderId : params.orderId;

  useEffect(() => {
    if (selectedDate === null || selectedDate === undefined) {
      navigate('/autoship');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  const formatDate = (date: any) => {
    if (!date) return '';
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const handleEditOrder = async (isNeedToChange: boolean) => {
    setIsUpdating(true);
    dispatch(setIsOrderChanged(!isNeedToChange));

    if (processing.length > 0) {
      try {
        const cartItems = latestOrder.existingItemsToCartItems(products, customer);
        await Promise.all([
          dispatch(setCart({ token, cart_items: cartItems })),
          dispatch(setExistingCartItems(cartItems)),
          dispatch(setEditCouponLines(latestOrder.data.coupon_lines))
        ]);
      } catch (e) {
        console.error("Error while updating cart:", e);
      } finally {
        navigate(`/autoship/menu/${latestOrder.data.id}`, {
          state: {
            isEditOrder: true,
            shippingId: latestOrder.data.shipping_lines[0].id,
            pickedForMyself: false,
            date: latestOrder?.getDeliveryDateTime(),
            changeToSelect: isNeedToChange
          }
        });
      }
    } else {
      navigate('/autoship/menu', {
        state: {
          pickedForMyself: true,
          date: selectedDate
        }
      });
    }

    setIsUpdating(false);
    setAutoshipSelectPage(false);
  };

  const handleCancelOrder = async () => {
    setIsUpdating(true);
    const selectedDateString = format(new Date(selectedDate), 'yyyy-MM-dd');
    const updatedDates = await selectedDates.filter((date: Date) => format(date, 'yyyy-MM-dd') !== selectedDateString);
    setMultiDates(updatedDates);
    await dispatch(setSelectedDates(updatedDates));
    if (currentOrderId) {
      dispatch(setUpdatingOrder(true));
      try {
        const response = await OrdersAPI.updateOrder(token, Number(currentOrderId), { status: 'mmcancelled' });
        OrdersAPI.createOrderNote(token, Number(currentOrderId), "Customer cancelled order.");

        if ('id' in response) {
          dispatch(updateOrder(response));
        }

      } catch (error: any) {
        console.log(error.message);
      } finally {
        dispatch(setUpdatingOrder(false));
      }
    }
    navigate('/autoship');
    setIsUpdating(false);
    setAutoshipSelectPage(false);
  }

  const handlePickForMeOption = async () => {
    setIsSelected('Forme');
    if (pickForMyselfOrder) {
      setIsUpdating(true);
      try {
        const cartItems = latestOrder.existingItemsToCartItems(products, customer);
        await dispatch(setCart({ token: token, cart_items: cartItems }));
        await dispatch(setExistingCartItems(cartItems));
        await dispatch(setEditCouponLines(latestOrder.data.coupon_lines));
      } catch (e: any) {
        console.log("Error while Updating cart: ", e);
      } finally {
        navigate(`/autoship/settings/${latestOrder.data.id}`, {
          state: {
            isSetting: true,
            isEditOrder: true,
            shippingId: latestOrder.data.shipping_lines[0].id,
            pickedForMyself: false,
            date: latestOrder?.getDeliveryDateTime(),
            changeToSelect: true
          }
        });
      }
      setIsUpdating(false);
    } else {
      navigate('/autoship/settings', { state: { pickedForMe: true, date: selectedDate, isSetting: true } });
    }
    setAutoshipSelectPage(false);
  }

  return (
    <Container className='autoshipContainer'>
      <div className='autoshipselect'>
        <div className='d-flex justify-content-center align-items-center autoshipPage mb-2'>
          {!isNotAutoshipSelectPage &&
            <div className='selectcrossicon'>
              <FontAwesomeIcon
                color='black'
                className='cursor-pointer'
                onClick={() => navigate('/autoship')}
                icon={faClose}
              ></FontAwesomeIcon>
            </div>}
          <span className='editingtext'>Editing&nbsp;
            <span className='editedDate'>
              {formatDate(new Date(selectedDate))}
            </span>
          </span>
        </div>
        <Row className='d-flex justify-content-center w-auto autoshipselect-row'>
          <Col md={isNotAutoshipSelectPage ? 12 : 6}>
            <div className='autoshipselect-col'>
              <div className='d-flex justify-content-center'>
                <Image
                  src={imageUrl}
                  alt="pickmyself"
                  className={`${customerImage && !customerImage.includes('userdefault.png') ? 'customerImageIcon' : ''}`}
                />
              </div>
              <div className='d-flex justify-content-center'>
                <span className='headerText'>Pick for Myself</span>
              </div>
              <div className={`${customerImage && customerImage.includes('userdefault.png') ?
                'for-me-text' :
                'for-myself-text'} d-flex justify-content-center`}>
                <span>Select meals of your choosing for this delivery date.</span>
              </div>
              <div className='d-flex justify-content-center'>
                <Button
                  size="sm"
                  variant={pickForMyselfOrder ? 'success' : 'dark'}
                  className='mx-3 w-100'
                  disabled={isUpdating || pickForMyselfOrder}
                  onClick={() => {
                    setIsSelected('Myself');
                    handleEditOrder(true);
                  }}
                >
                  {(isUpdating && isSelected === 'Myself') ?
                    <>
                      Please wait ...&nbsp;
                      <Spinner
                        animation="border"
                        as="span"
                        size="sm"
                      />
                    </> :
                    pickForMyselfOrder ?
                      'Selected' :
                      pickForMeOrder ?
                        'Change to Pick For Myself' :
                        'Select'}
                </Button>
              </div>
            </div>
          </Col>
          <Col md={isNotAutoshipSelectPage ? 12 : 6} className='pt-3'>
            <div className='autoshipselect-col'>
              <div className='d-flex justify-content-center'>
                <Image
                  src={pickforme}
                  alt="pickforme"
                />
              </div>
              <div className='d-flex justify-content-center'>
                <span className='headerText'>Chef's Choice</span>
              </div>
              <div className='for-me-text d-flex justify-content-center'>
                <span>Select this option if you would like us to pick your meals for you for this delivery date.</span>
              </div>
              <div className='d-flex justify-content-center'>
                <Button
                  size="sm"
                  variant={pickForMeOrder ? 'success' : 'dark'}
                  className='mx-3 w-100'
                  disabled={isUpdating || pickForMeOrder}
                  onClick={handlePickForMeOption}
                >
                  {(isUpdating && isSelected === 'Forme') ?
                    <>
                      Please wait ...&nbsp;
                      <Spinner
                        animation="border"
                        as="span"
                        size="sm"
                      />
                    </> :
                    pickForMeOrder ?
                      'Selected' :
                      pickForMyselfOrder ?
                        `Change to Chef's Choice` :
                        'Select'}
                </Button>
              </div>
              {orderId &&
                <div>
                  {(processing[0]?.coupon_lines && processing[0]?.coupon_lines?.length > 0) ?
                    <span>Coupon "{processing[0]?.coupon_lines[0]?.code}" applied to order.</span> :
                    <>
                      <Row className="py-1">
                        <Col className='fw-bold'>Have a discount?</Col>
                      </Row>
                      <CouponForm isAutoshipOrder={true} latestOrder={processing[0]} orderId={orderId} />
                    </>
                  }
                </div>
              }
            </div>
          </Col>
          <Col md={12} className='both-part'>
            <Col className="btn-both-part">
              <div className={`d-flex ${Window.isMobile() ? 'justify-content-center' : 'justify-content-end'}`}>
                <Button
                  size="sm"
                  variant="danger"
                  className='mx-3 text-white my-2'
                  disabled={updatingOrder || isUpdating}
                  onClick={handleCancelOrder}
                >
                  {updatingOrder ?
                    <>
                      Please wait ...&nbsp;
                      <Spinner
                        animation="border"
                        as="span"
                        size="sm"
                      />
                    </> : 'CANCEL THIS ORDER'}
                </Button>
              </div>
            </Col>
            <Col className={`btn-both-part ${(pickForMyselfOrder || pickForMeOrder) ? 'center' : 'right'}`}>
              <div className={`d-flex ${Window.isMobile() ? 'justify-content-center ps-2' : 'justify-content-start'}`}>
                <Button
                  size="sm"
                  variant="dark"
                  className='mx-3 my-2'
                  disabled={isUpdating}
                  onClick={() => {
                    navigate('/autoship', { state: { changeDate: true, date: selectedDate } });
                    setAutoshipSelectPage(false);
                  }}
                >
                  CHANGE DATE
                </Button>
              </div>
            </Col>
            {(pickForMyselfOrder || pickForMeOrder) &&
              <Col className="btn-both-part right">
                <div className={`d-flex ${Window.isMobile() ? 'justify-content-center ps-2' : 'justify-content-start'}`}>
                  <Button
                    size="sm"
                    variant="success"
                    className='mx-3 my-2 text-white'
                    disabled={isUpdating}
                    onClick={() => handleEditOrder(false)}
                  >
                    {(isUpdating && isSelected === '' && !updatingOrder) ?
                      <>
                        Please wait ...&nbsp;
                        <Spinner
                          animation="border"
                          as="span"
                          size="sm"
                        />
                      </> :
                      'VIEW CART'
                    }
                  </Button>
                </div>
              </Col>}
          </Col>
        </Row>
      </div>
    </Container >
  )
}

export default AutoshipSelectPage;