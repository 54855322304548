import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Spinner } from 'react-bootstrap';
import MealPlanObj from "./mealPlanObj";
import ToastError from "../../components/ToastError";
import MealPlansAPI from "../../API/mealPlansAPI";
import { selectToken } from "../user/userSlice";
import { deleteMealPlan } from "./mealPlansSlice";

interface Props {
  mealPlan: MealPlanObj;
}

export default function DeleteMealPlan({ mealPlan }: Props) {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [errorMsg, setErrorMsg] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDelete = () => {
    setShowModal(false);
    setErrorMsg('');
    setDeleting(true);
    MealPlansAPI.deleteMealPlan(token, mealPlan.data.id).then(() => {
      setDeleting(false);
      dispatch(deleteMealPlan(mealPlan.data.id));
    }).catch((e) => {
      setErrorMsg('An unexpected error has occurred. Please try again later.');
      setDeleting(false);
    });
  }
  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)} className='pb-5' centered>
        <Modal.Body className="text-center">
          <p>Are you sure want to delete meal plan?</p>
          <div className='d-flex justify-content-center gap-2'>
            <Button variant="outline-dark" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="dark" onClick={handleDelete}>
              Delete meal plan
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {errorMsg &&
        <ToastError 
          msg={errorMsg} 
          onClose={() => setErrorMsg('')} 
          position="top-center"
        />
      }

      {deleting ?
        <Button 
          variant='dark'
          size="sm"
          disabled
        >
          <Spinner animation="border" as="span" size="sm" />
          &nbsp;&nbsp;Deleting List ...
        </Button> :
        <Button
          variant='dark'
          size="sm"
          onClick={() => setShowModal(true)}
        >
          <span className='pe-1'>Delete</span> <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="#e2e3e5"></path>
            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
            </svg>
        </Button>
      }
    </>
  );
}