import { Dropdown } from 'react-bootstrap';
import Window from '../../../utils/Window';
import DietFilterForm from './forms/DietFilterForm';

export default function DietFilter() {
  if (Window.isMobile()) {
    return (
      <div className='diet-filter'>
        <div className='fs-6 mb-1'>DIET</div>
        <DietFilterForm />
      </div>
    )
  }
  return (
    <Dropdown className="diet-filter">
      <Dropdown.Toggle 
      className='rounded-2 w-100'
      variant='outline-dark'
      >Diet</Dropdown.Toggle>
      <Dropdown.Menu className='px-2'>
        <DietFilterForm />
      </Dropdown.Menu>
    </Dropdown>
  );
}