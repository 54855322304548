import { ChangeEvent, useState } from 'react';
import { useFormikContext } from 'formik';
import { Card, Form } from 'react-bootstrap';
import { FormValues } from './interfaces';

export default function GiftCardInput() {
  const [show, setShow] = useState(false);
  const formik = useFormikContext<FormValues>();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === 'send-to-me') {
      formik.setFieldValue('send_gift_card', '');
      setShow(false);
    } else {
      formik.setFieldValue('send_gift_card', '1');
      setShow(true);
    }
  }

  return (
    <Card className='gift-card-input my-3' body>
      <div className='fs-3 mb-3'>Send gift card to ...</div>
      <Form.Check 
        type="radio" 
        name="send-to-me" 
        label="Send to me" 
        id="send-to-me" 
        checked={show === false}
        onChange={handleChange} 
      />
      <Form.Check 
        type="radio" 
        name="send-as-gift" 
        label="Gift to someone else" 
        id="send-as-gift" 
        checked={show === true}
        onChange={handleChange} 
      />
      {show &&
        <div className='mt-4'>
          <Form.Group className="mb-3 form-group required">
            <Form.Label>Recipient Email</Form.Label>
            <Form.Control 
              id="gift_card_email"
              type="text"
              isInvalid={Boolean(formik.errors.gift_card_email)}
              value={formik.values.gift_card_email}
              onChange={formik.handleChange}
            />
            {formik.errors.gift_card_email &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.gift_card_email}
              </Form.Control.Feedback>}
          </Form.Group>
          <Form.Group className="mb-3 form-group">
            <Form.Control 
              as="textarea"
              id="gift_card_msg"
              rows={4}
              placeholder="Write a message ..."
              isInvalid={Boolean(formik.errors.gift_card_msg)}
              value={formik.values.gift_card_msg}
              onChange={formik.handleChange}
            />
            {formik.errors.gift_card_msg &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.gift_card_msg}
              </Form.Control.Feedback>}
          </Form.Group>
        </div>
      }
    </Card>
  );
}