import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicket } from '@fortawesome/free-solid-svg-icons';
import CouponObj from "../../features/coupons/couponObj";
import { selectCartItems,
         selectCartUpdateInProgress, 
         setCartCoupon } from '../cart/cartSlice';
import CartObj from '../cart/cartObj';
import ToastError from '../../components/ToastError';
import { useState } from "react";
import { selectProducts } from "../products/productsSlice";

interface Props {
  coupon: CouponObj
}

export default function Coupon({ coupon }: Props) {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const [showModal, setShowModal] = useState(false);
  const products = useSelector(selectProducts);
  const updateInProgress = useSelector(selectCartUpdateInProgress);
  const cart = new CartObj(useSelector(selectCartItems));

  const handleClick = () => {
    if (!updateInProgress) {
      let validationResult = coupon.validateAgainstCart(cart, products);
      if (validationResult.error) {
        setErrorMsg(validationResult.message);
        return;
      }
      dispatch(setCartCoupon({ token: '', coupon: coupon.data }))
      setShowModal(true);
    }
  }

  return (
    <Col className="coupon my-1" sm={6} md={4} xl={3}>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>MightyMeals Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>The discount has been added to your cart.</p>
          <div className='d-flex justify-content-end gap-2'>
            <Button variant="dark" onClick={() => setShowModal(false)}>
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {errorMsg &&
        <ToastError 
          msg={errorMsg} 
          onClose={() => {
            setErrorMsg('')
          }} 
          position="top-center"
        />
      }
      <Card body role="button" onClick={handleClick} className='h-100'>
        <Row>
          <Col xs={2} className='d-flex me-1'>
            <FontAwesomeIcon 
              icon={faTicket} 
              className="align-self-center fa-xl" 
            />
          </Col>
          <Col>
            <div className="fw-bold">{coupon.getTitle()}</div>
            <div>{coupon.data.code}</div>
            <div>{coupon.getExpiryDate()}</div>
          </Col>
        </Row>
      </Card>
    </Col>
  )
}