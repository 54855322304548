import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from '../../app/store';
import { IDietaryPlan } from './interfaces';
import DietaryPlansAPI from '../../API/dietaryAPI';

interface DietaryPlansState {
  dietaryPlans: Array<IDietaryPlan>;
  loaded: boolean;
  loading: boolean;
  loadError: Boolean;
}

const initialState: DietaryPlansState = {
  dietaryPlans: [],
  loaded: false,
  loading: false,
  loadError: false
}

export const loadDietaryPlans = createAsyncThunk(
  'dietaryPlans/loadDietaryPlans',
  async (token: string) => {
    return await DietaryPlansAPI.getDietaryPlans(token);
  }
);

export const dietaryPlansSlice = createSlice({
  name: "dietaryPlans",
  initialState: initialState,
  reducers: {
    createDietaryPlan: (state, action: PayloadAction<IDietaryPlan>) => {
      return {
        ...state,
        dietaryPlans: [...state.dietaryPlans, action.payload]
      }
    },
    deleteDietaryPlan: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        dietaryPlans: state.dietaryPlans.filter((mp) => mp.id !== action.payload)
      }
    },
    updateDietaryPlan: (state, action: PayloadAction<IDietaryPlan>) => {
      return {
        ...state,
        dietaryPlans: state.dietaryPlans.map((mp) => {
          if (mp.id === action.payload.id) return action.payload;
          return mp;
        })
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(loadDietaryPlans.pending, (state) => {
      return {
        ...state,
        loading: true
      }
    });
    builder.addCase(loadDietaryPlans.fulfilled, (state, action) => {
      return {
        ...state,
        dietaryPlans: [...action.payload],
        loading: false,
        loaded: true
      }
    });
    builder.addCase(loadDietaryPlans.rejected, (state) => {
      return {
        ...state,
        loading: false,
        loadError: true
      }
    });
  }
});

export const { createDietaryPlan, 
               deleteDietaryPlan, 
               updateDietaryPlan } = dietaryPlansSlice.actions;
export const selectDietaryPlans = (state: RootState) => state.dietaryPlans.dietaryPlans;
export const selectDietaryPlansLoaded = (state: RootState) => state.dietaryPlans.loaded;
export const selectDietaryPlansLoadError = (state: RootState) => state.dietaryPlans.loadError;
export const selectDietaryPlansLoading = (state: RootState) => state.dietaryPlans.loading;
export default dietaryPlansSlice.reducer;