import { FormikErrors, useFormik } from 'formik';
import { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import MealPlansAPI from '../../API/mealPlansAPI';
import ProductObj from "../products/productObj";
import { selectToken } from '../user/userSlice';
import { IMealPlan } from './interfaces';
import MealPlanObj from './mealPlanObj';
import { createMealPlan, selectMealPlans, updateMealPlan } from './mealPlansSlice';

interface Props {
    product: ProductObj;
    onCancel: () => void;
    onProductAdded: () => void;
}

interface FormValues {
  planId: string;
  planName: string;
}

export default function MealPlanForm({ product, onCancel, onProductAdded }: Props) {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const [busy, setBusy] = useState(false);
  const token = useSelector(selectToken);
  const mealPlans = useSelector(selectMealPlans);

  const validate = (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {};

    if (!values.planId) {
      if (!values.planName) {
        errors.planName = "Required";
      } else if (values.planName.trim().length < 3) {
        errors.planName = "3 characters mimimum";
      }
    }
    return errors;
  }

  const formik = useFormik({
    initialValues: {
      planId: mealPlans.length ? mealPlans[0].id : '',
      planName: ''
    },
    validate,
    onSubmit: values => {
      setErrorMsg('');
      if (!values.planId) {
        setBusy(true);
        MealPlansAPI.createMealPlan(
          token, { 
            name: values.planName, 
            items: [{
              productId: product.data.id,
              productName: product.data.name,
              quantity: 1 
            }]
        }).then((mealPlan) => {
          dispatch(createMealPlan(mealPlan));
          onProductAdded();
        }).catch((e) => {
          console.error(e);
          setErrorMsg("An unexpected error occurred. Please try again later.");
        }).finally(() => {
          setBusy(false);
        });
        return;
      }

      let mealPlan = MealPlanObj.getById(mealPlans, parseInt(values.planId));
      if (!mealPlan) {
        setErrorMsg('An unexpected error has occurred. Please try again later.');
        return;
      }

      mealPlan = new MealPlanObj(JSON.parse(JSON.stringify(mealPlan.data)));
      mealPlan.addProduct(product);
      setBusy(true);
      MealPlansAPI.updateMealPlan(token, mealPlan.data).then((mealPlan) => {
        dispatch(updateMealPlan(mealPlan));
        onProductAdded();
      }).catch((e) => {
        console.error(e);
        setErrorMsg("An unexpected error occurred. Please try again later.");
      }).finally(() => {
        setBusy(false);
      });
    }
  })
  return (
    <Form className='new-meal-plan-form' onSubmit={formik.handleSubmit}>
      {errorMsg &&
        <p className='text-danger'>{errorMsg}</p>}
      {mealPlans.length > 0 && 
        <Form.Group className="mb-3 required">
          <Form.Label>Favorite Meal Lists</Form.Label>
          <Form.Select 
            id="planId"
            onChange={formik.handleChange}
          >
            {mealPlans.map((mp: IMealPlan) => {
              return (
                <option key={mp.id} value={mp.id}>{mp.name}</option>
              )
            })}
            <option value="">-- Add New Favorites List --</option>
          </Form.Select>
        </Form.Group>
      }
      {!formik.values.planId &&
        <Form.Group className="form-group required">
          <Form.Label>Name Your Meal List</Form.Label>
          <Form.Control 
            id="planName"
            type="text"
            size='sm'
            isValid={Boolean(formik.values.planName) && 
              !Boolean(formik.errors.planName)}
            isInvalid={Boolean(formik.errors.planName)}
            value={formik.values.planName}
            onChange={formik.handleChange}
          />
          {formik.errors.planName &&
            <Form.Control.Feedback type="invalid">
              {formik.errors.planName}
            </Form.Control.Feedback>}
        </Form.Group>
      }
      <div className='d-flex gap-2 mt-4'>
        <Button
          variant='outline-dark'
          disabled={busy}
          onClick={onCancel}
        >
          Cancel
        </Button>
        {busy ? 
          <Button 
            variant='dark'
            className='bg-black'
            disabled
          >
            <Spinner animation="border" as="span" size="sm" />
            Adding to List ...
          </Button>
          :
          <Button
            variant='dark'
            className='bg-black'
            type="submit"
          >
            Add to List
          </Button>
        }
      </div>
    </Form>
  )
}