import { useState } from 'react';
import { Badge, Card, Col, Image, Row } from 'react-bootstrap';
import ProductDetailModal from './ProductDetailModal';
import ProductObj from './productObj';
import CustomerObj from '../customer/customerObj';
import DietaryIcons from './DietaryIcons';

interface Props {
  product: ProductObj;
  customer: CustomerObj;
  cartPrice: number;
  quantity: number;
  onQtyChange: (qty: number) => void;
}

export default function NewProduct({ 
  product,
  customer,
  quantity,
  onQtyChange,
  cartPrice
}: Props) {
  const [showDetail, setShowDetail] = useState(false);

  return (
    <div className='new-product mx-1 p1'>
      <Card className='border-0 p-1'>
        <ProductDetailModal 
          product={product} 
          show={showDetail} 
          onHide={() => setShowDetail(false)} 
          onQtyChange={onQtyChange}
          quantity={quantity}
        />
        <div className='position-relative'>
          <Image
            src={product.data.images[0].src} 
            thumbnail={true}
            onClick={() => setShowDetail(true)}
            className='p-0 border-0'
          />
          <div 
            className='position-absolute bottom-0 w-100 bg-white text-black opacity-75 p-2'
          >
            <div className='new-badge'>
              <Badge className='bg-black'>New</Badge>
            </div>
            <div className='fs-5 ms-1'>
              {product.data.name}
            </div>
            <div className='macro-info d-flex ms-1'>
              <div className='pe-1'>
                <div>{product.getCalories()}</div>
                <div>Cal</div>
              </div>
              <div className='px-1'>
                <div>{product.getCarbs()}g</div>
                <div>Carbs</div>
              </div>
              <div className='ps-1'>
                <div>{product.getProtein()}g</div>
                <div>Protein</div>
              </div>
            </div>
            <Row>
              <Col xs={4} className='ps-3 fw-bold'>
                {customer.hasMembership() &&
                  <Row>
                    <Col><del>${product.data.price}</del></Col>
                  </Row>
                }
                <Row>
                  <Col>{customer.getProductPrice(product, true)}</Col>
                </Row>
              </Col>
              <Col xs={8}>
                <DietaryIcons product={product} className='float-end' />
              </Col>
            </Row>
          </div>
        </div>
      </Card>
    </div>
  )
}
