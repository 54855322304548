import { IMealPlan, IMealPlanPost } from "../features/meal_plans/interfaces";

export default class MealPlansAPI {
  static getMealPlans = async (token: string) => {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/meal_plans?token=' + token,
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset=UTF-8' }
      }
    ));

    if (!response.ok) {
      console.error('HTTP error while retrieving meal plans. Status:', response.status);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static createMealPlan = async(token: string, mealPlan: IMealPlanPost) => {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/meal_plans',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({ token: token, meal_plan: JSON.stringify(mealPlan)})
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while creating meal plan. Status:', response.status);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static updateMealPlan = async(token: string, mealPlan: IMealPlan) => {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/meal_plans/' + mealPlan.id,
      {
        method: 'PUT',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({ token: token, meal_plan: JSON.stringify(mealPlan)})
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while updating meal plan. Status:', response.status);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static deleteMealPlan = async(token: string, mealPlanId: number) => {
    let response: any = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/meal_plans/' + mealPlanId + 
        '?token=' + token,
      {
        method: 'DELETE',
        headers: { 'content-type': 'application/json;charset=UTF-8' }
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while deleting meal plan. Status:', response.status);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
   
  }
}