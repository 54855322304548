import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Table, Image, Button, Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ElementorPage from '../../pages/ElementorPage';
import ProductObj from '../products/productObj';
import { selectProducts } from '../products/productsSlice';
import axios from "axios";
import { setCartItem } from '../cart/cartSlice';
import { selectToken } from '../user/userSlice';
import { selectIsMobileRoute } from '../mobile/mobileSlice';

export default function SharedMeals() {
    const navigate = useNavigate();
    const products = useSelector(selectProducts);
    let { mealPlanId } = useParams();
    let { sharedmealsSlug } = useParams();
    const [userMeal, setUserMeal] = useState([]);
    const [userItems, setUserItems] = useState([]);
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const AppURL = useSelector(selectIsMobileRoute);
    const myMealPlan = userMeal.filter((item: any) => item.id === Number(mealPlanId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchData = async () => {
        return axios.get(`${process.env.REACT_APP_SERVER_URL}/sharedmeals?email=${sharedmealsSlug}`)
            // eslint-disable-next-line array-callback-return
            .then((response) => response.data.filter((item: any) => {
                if (item.id === Number(mealPlanId)) {
                    setUserMeal(response.data);
                    setUserItems(item.items);
                }
            }));
    }
    
    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleCancle = () => {
        navigate(`/${AppURL}`);
    }

    const handleAddToCart = () => {
        if (Object.entries(userItems).length > 0) {
            // eslint-disable-next-line array-callback-return
            Object.entries(userItems).map((mealItems: any, i) => {
                const product = ProductObj.getById(products, mealItems[1]?.productId);
                dispatch(setCartItem({ 
                    token: token,
                    cart_item: {
                      product_id: mealItems[1]?.productId, 
                      product_qty: mealItems[1]?.quantity, 
                      product_price: product?.data?.price  
                    }
                  }));
            });
        }
        navigate(`/order${AppURL}`);
    }

    return (
        <>
            <Container fluid="lg" className='my-meal-plans-page w-50 border border-dark'>
                <Modal
                    backdrop="static"
                    show={true}
                >
                    <Modal.Body className='font-barlow'>
                        <Row>
                            <Col className='d-flex flex-column p-2'>
                                <label className='table-borderless font-barlow fs-6 fw-bold'>Shared by : {sharedmealsSlug}</label>
                                <label className='table-borderless font-barlow pb-2 pt-1 fs-6 fw-bold'>Plan Name : {myMealPlan[0]?.['name']}</label>
                                <Table
                                    striped
                                    responsive
                                    className='table-borderless font-barlow border border-dark fs-6'
                                >
                                    <thead>
                                        <tr>
                                            <th># Items</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userItems?.map((mealPlanData: any, index: any) => {
                                            const product = ProductObj.getById(products, mealPlanData.productId);
                                            return (
                                                <>
                                                    <tr key={`mymeals-plans-${mealPlanData.productId}${index}`}>
                                                        <td>
                                                            {product ?
                                                                <div className='d-flex'>
                                                                    <Image
                                                                        src={product?.data.images[0].src}
                                                                        width="50"
                                                                        height="50"
                                                                        className='d-none d-sm-block'
                                                                    />
                                                                    <span className='ms-2'>{product?.data.name}
                                                                    </span>
                                                                </div>
                                                                :
                                                                <div className='mt-2'>
                                                                    <Spinner animation="border" as="span" size="sm" title='Product Image Loading'/> 
                                                                    &nbsp;&nbsp;&nbsp;{mealPlanData?.productName}</div>
                                                            }</td>
                                                        <td>
                                                            <Button className="bg-green border-green">
                                                                <Link
                                                                    to={'/product/' + product?.data.slug}
                                                                    className='text-black'
                                                                >View</Link>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Button variant='outline-dark' onClick={() => handleCancle()}>
                            Cancel
                        </Button>
                        <Button variant='outline-dark' onClick={() => handleAddToCart()} style={{float: 'right'}}>
                            Add meals to cart
                        </Button>
                    </Modal.Body>
                </Modal>
            </Container>
            <ElementorPage pageNo={43} />
        </>
    )
}