import type { RootState } from '../../app/store';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DeliveryAPI from '../../API/deliveryAPI';

export const loadDeliveryInfo = createAsyncThunk(
  'delivery/loadDeliveryInfo',
  async () => {
    return await DeliveryAPI.getDeliveryInfo();
  }
);

interface DeliveryState {
  data: Record<string, any>;
  loaded: boolean;
  loading: boolean;
  loadError: boolean;
}

const initialState: DeliveryState = {
  data: {},
  loaded: false,
  loading: false,
  loadError: false
}

export const deliverySlice = createSlice({
  name: 'delivery',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadDeliveryInfo.pending, (state, action) => {
      state.loadError = false;
      state.loading = true;
      state.loaded = false;
    });
    builder.addCase(loadDeliveryInfo.fulfilled, (state, action) => {
      return {
        ...state,
        data: { ...action.payload },
        loading: false,
        loaded: true
      }
    });
    builder.addCase(loadDeliveryInfo.rejected, (state, action) => {
      state.loadError = true;
      state.loading = false;
    });
  }
});

export const selectDeliveryData = (state: RootState) => state.delivery.data;
export const selectDeliveryLoaded = (state: RootState) => state.delivery.loaded;
export const selectDeliveryLoading = (state: RootState) => state.delivery.loading;
export const selectDeliveryLoadError = (state: RootState) => state.delivery.loadError;

export default deliverySlice.reducer;