import { Accordion, Alert } from 'react-bootstrap';
import { useState } from 'react';
import '../customer/EditAccountForm.scss';
import BasicInformationForm from './BasicInformationForm';
import DietaryNeedsGolsForm from './DietaryNeedsGolsForm';
import FitnessGoalsForm from './FitnessGoalsForm';
import AdditionInformationForm from './AdditionInformationForm';
import Window from '../../utils/Window';

export default function EditAccountForm() {
  const [errorMsg, setErrorMsg] = useState('');
  const [eventKey, setEventKey] = useState('0');
  const isMobile = Window.isMobile();

  const handleAccordionClick = (eventKey: string) => {
    setEventKey(eventKey);
    if(isMobile){
      window.scrollTo(500, 500);
    }
    else{
      window.scrollTo(0, 0);
    }
  };

  const handleFirstAccordian = () => {
    const nextEventKey = parseInt(eventKey) + 1
    if(isMobile){
      window.scrollTo(500, 500);
    }
    else{
      window.scrollTo(0, 0);
    }
    setEventKey(nextEventKey.toString());
  }
  const handleSecondAccordian = () => {
    const nextEventKey = parseInt(eventKey) + 1
    if(isMobile){
      window.scrollTo(500, 500);
    }
    else{
      window.scrollTo(0, 0);
    }
    setEventKey(nextEventKey.toString());
  }
  const handleThirdAccordian = () => {
    const nextEventKey = parseInt(eventKey) + 1
    if(isMobile){
      window.scrollTo(500, 500);
    }
    else{
      window.scrollTo(0, 0);
    }
    setEventKey(nextEventKey.toString());
  }
  const handleFourthAccordian = () => {
    const nextEventKey = 0;
    if(isMobile){
      window.scrollTo(500, 500);
    }
    else{
      window.scrollTo(0, 0);
    }
    setEventKey(nextEventKey.toString());
  }

  return (
    <>
        {errorMsg &&
          <Alert className='mb-4' variant='danger'>{errorMsg}</Alert>
        }
        <Accordion defaultActiveKey={'0'} activeKey={eventKey} onSelect={(e: any) => handleAccordionClick(e)} flush>
          <Accordion.Item eventKey="0" className='border mb-3 bg-light text-dark'>
            <Accordion.Header>BASIC INFORMATION</Accordion.Header>
            <Accordion.Body className='p-4 mt-md-3'>
              <BasicInformationForm handleFirstAccordian={handleFirstAccordian} setErrorMsg={setErrorMsg}/>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className='border my-3 bg-light text-dark'>
            <Accordion.Header>
              DIETARY NEEDS & GOALS
            </Accordion.Header>
            <Accordion.Body className='p-4 mt-md-3'>
              <DietaryNeedsGolsForm handleSecondAccordian={handleSecondAccordian} setErrorMsg={setErrorMsg}/>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className='border my-3 bg-light text-dark'>
            <Accordion.Header>FITNESS GOALS</Accordion.Header>
            <Accordion.Body className='p-4 mt-md-3'>
              <FitnessGoalsForm handleThirdAccordian={handleThirdAccordian} setErrorMsg={setErrorMsg}/>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className='border my-3 bg-light text-dark'>
            <Accordion.Header>
              ADDITIONAL INFORMATION
            </Accordion.Header>
            <Accordion.Body className='p-4 mt-md-3'>
              <AdditionInformationForm handleFourthAccordian={handleFourthAccordian} setErrorMsg={setErrorMsg}/>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
    </>
  )
}