import { Card } from 'react-bootstrap';
import AuthrizedCardForm from './AuthrizedCardForm';

export default function AuthorizeNetCardDetails() {
  return (
    <Card body>
      <div className='my-4 fs-3'>Credit Card</div>
      <div className='fs-5 my-3'>Pay securely using your credit card.</div>
      <AuthrizedCardForm />
    </Card>
  )
}