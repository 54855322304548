import React, { useEffect, useState } from 'react'
import DefaultImg from '../../../assets/images/userdefault.png';
import '../core/filters.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addMightyFitFilter, clearMightyFitFilter, removeMightyFitFilter, selectMightyFit, selectMightyFitFilter } from '../core/filterSlice';
import { Button, Form, Image } from 'react-bootstrap';
import { loadWorkouts } from '../core/workoutSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function TrainersFilter() {
    const dispatch = useDispatch();
    const mightyFit = useSelector(selectMightyFit);
    const mightyFilter = useSelector(selectMightyFitFilter);
    const filters = mightyFit[0].filter_data;
    const trainers = [...filters.trainers].sort((a, b) => b.count - a.count);

    const [selectedCheckboxes, setSelectedCheckboxes] = useState<any>(mightyFilter);
    const [selectedIds, setSelectedIds] = useState<any>([]);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if(mounted){
            handleFilterAPIResponse()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted, selectedCheckboxes])

    const handleCheckboxChange = (event: { target: { value: any; checked: any; id: any; }; }) => {
        const checkboxValue = event.target.value;
        const isChecked = event.target.checked;
        const Ids = event.target.id;
        if (isChecked) {
            setSelectedCheckboxes([...selectedCheckboxes, checkboxValue]);
            setSelectedIds([...selectedIds, Ids])
            dispatch(addMightyFitFilter(checkboxValue))
        } else {
            setSelectedCheckboxes(selectedCheckboxes.filter((value: any) => value !== checkboxValue));
            setSelectedIds(selectedIds.filter((value: any) => value !== Ids));
            dispatch(removeMightyFitFilter(checkboxValue));
        }
        setMounted(true);
    };

    const handleFilterAPIResponse = async () => {
        const filteredData = {
          trainers: filters.trainers.filter((item: any) => selectedIds.includes(item.id.toString()))
        };
        let url = 'mighty_fits?page=1&';
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [key, value] of Object.entries(filteredData)) {
          if (value.length > 0) {
            url += `trainer_id=${value.map((item: any) => item.id).join(',')}&`;
          }
        }
        url = url.slice(0, -1);
        await dispatch(loadWorkouts(url));
        setMounted(false);
    }

    const handleRemovetags = (tagValue: string) => {
        setSelectedCheckboxes(selectedCheckboxes.filter((value: string) => value !== tagValue));
        dispatch(removeMightyFitFilter(selectedCheckboxes.filter((value: string) => value !== tagValue)));
        setMounted(true);
    };

    const handleClearAllTags = () => {
        setSelectedCheckboxes([]);
        dispatch(clearMightyFitFilter());
        setMounted(true);
    }

    return (
        <>
            <div className="scrollbar-new" id="style-3">
                <div className="wraptrainers">
                    {trainers.length === 0 ? (
                        <p>No trainers found</p>
                    ) : (
                        trainers?.map((trainee: any, index: number) => (
                            <div className="wt-box" key={index}>
                                <div className="wt-left">
                                {trainee.trainer_image !== ""
                                    ? <Image src={`${trainee.trainer_image}`} alt={trainee.trainer_image} className="trainersImg" />
                                    : <Image src={DefaultImg} alt="default Image" className="defaultImg" />}
                                    <span>{trainee.trainer_name}</span>
                                </div>
                                <div className="wt-right">
                                    <Form.Check
                                        type="checkbox"
                                        className="tag-sbmt"
                                        name="tag[]"
                                        value={trainee.trainer_name}
                                        label={trainee.count}
                                        id={trainee.id}
                                        onChange={handleCheckboxChange}
                                        checked={mightyFilter.includes(trainee.trainer_name)}
                                    />
                                </div>
                            </div>
                        )))}
                </div>
            </div>
            <div className="display-checkbox">
                {selectedCheckboxes.length > 0 && (
                    <>
                        <div className="wrapcheck">
                            {selectedCheckboxes.map((value: any, index: any) => (
                                <div className="checkmark" key={index}>
                                    <div className="selected-checkboxes">
                                        <p className='check-text'>{value}</p>
                                    </div>
                                    <FontAwesomeIcon
                                        id={value}
                                        role="button"
                                        className='pe-auto'
                                        icon={faXmark}
                                        size={"xl" as SizeProp}
                                        onClick={() => handleRemovetags(value)}
                                    />
                                </div>))}
                        </div>
                        <Button variant="success" className='btn-clear' onClick={handleClearAllTags}>Clear All</Button>
                    </>)}
            </div>
        </>
    )
}