import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../user/userSlice';
import {
  selectCartItems,
  selectCartUpdateInProgress,
  selectOrderInProgress,
  setCartItem
} from "../cart/cartSlice";
import NewProduct from './NewProduct';
import NYPProduct from './NYPProduct';
import PopularProduct from './PopularProduct';
import Product from './Product';
import ProductObj from './productObj';
import CartObj from '../../features/cart/cartObj';
import { selectCustomer } from '../customer/customerSlice';
import CustomerObj from '../customer/customerObj';
import './product.scss';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { setNotifyItem } from '../mobile/mobileSlice';
import GiftCardProduct from './GiftCardProduct';
import TiktokPixel from 'tiktok-pixel';
import { setIsOrderChanged } from '../../pages/AutoshipPages/core/autoShipSlice';

declare const _learnq: any;
interface Props {
  product: ProductObj;
  displayType?: string;
  showModal?: boolean
}

export default function ProductContainer({ product, displayType, showModal }: Props) {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const cartItems = useSelector(selectCartItems);
  const customer = new CustomerObj(useSelector(selectCustomer));
  const cartUpdateInProgress = useSelector(selectCartUpdateInProgress);
  const orderInProgress = useSelector(selectOrderInProgress);
  const quantity = (product.data.id in cartItems) ?
    cartItems[product.data.id].product_qty : 0;
  const cartPrice = (product.data.id in cartItems) ?
    cartItems[product.data.id].product_price : 0;
  const cart = useMemo(() => {
    return new CartObj(cartItems)
  }, [cartItems]);

  const trackAddToCartEvent = (num_items: number) => {
    ReactPixel.track(
      'AddToCart',
      {
        content_ids: [product.data.id],
        content_name: product?.data.name,
        content_type: 'product',
        contents: cart.getContents(),
        currency: 'USD',
        num_items: num_items - quantity,
        value: customer.getProductPrice(product) as number * (num_items - quantity)
      }
    );
    ReactGA.event(
      'add_to_cart',
      {
        currency: 'USD',
        items: [{
          item_id: product.data.id,
          item_name: product?.data.name,
          quantity: num_items - quantity
        }],
        value: customer.getProductPrice(product) as number * (num_items - quantity)
      }
    );

    TiktokPixel.track(
      'AddToCart',
      {
        contents: [
          {
            content_id: product.data.id,
            content_name: product?.data.name,
            quantity: num_items - quantity,
            price: customer.getProductPrice(product) as number
          }
        ],
        content_type: 'product',
        value: customer.getProductPrice(product) as number * (num_items - quantity),
        currency: 'USD'
      }
    );

    // Reddit Pixel tracking
    const email = customer.data.email ? customer.data.email : "";
    (window as any).rdt('track', 'AddToCart', {
      email: email,
      currency: 'USD',
      itemCount: num_items,
      value: customer.getProductPrice(product) as number * (num_items - quantity),
      products: [
        {
          id: product.data.id,
          name: product?.data.name,
          category: product?.data.categories,
        }
      ]
    });

    const item = {
      CheckoutURL: `${process.env.REACT_APP_WEB_URL}checkout`,
      items: [{
        ProductName: product?.data.name,
        ProductID: product?.data.id,
        SKU: product?.data.sku,
        Categories: product?.data.categories,
        ImageURL: product?.data.images[0].src,
        URL: `${process.env.REACT_APP_WEB_URL}product/${product?.data.slug}`,
        Price: product?.data.price,
        product_id: product?.data.id,
        product_name: product?.data.name,
      }],
      $value: customer.getProductPrice(product) as number * (num_items - quantity)
    };

    if (typeof _learnq !== 'undefined') {
      _learnq.push(["track", "Added to Cart", item]);
    }

  }

  const handleQtyChange = (qty: number) => {
    if (qty - quantity > 0) {
      trackAddToCartEvent(qty);
    }
    dispatch(setCartItem({
      token: token,
      cart_item: {
        product_id: product.data.id,
        product_qty: qty,
        product_price: customer.getProductPrice(product) as number
      }
    }));
    const newCartItem = {
      productName: product?.data?.name,
      productPrice: customer?.getProductPrice(product) as number,
      productIMG: product?.data?.images[0]?.src,
    };
    dispatch(setNotifyItem(newCartItem));
    dispatch(setIsOrderChanged(false));
  }

  const handlePriceChange = (price: number) => {
    if (!cart.isEmpty() && product.hasCategory('gift-card')) {
      return 'Gift cards must be purchased separate from other ' +
        'items. Clear your cart to purchase this item.';
    }
    dispatch(setCartItem({
      token: token,
      cart_item: {
        product_id: product.data.id,
        product_qty: 1,
        product_price: price
      }
    }));
    return '';
  }

  if (product.isNameYourPrice() && product.data.name === "Gift Card") {
    return (
      <GiftCardProduct
        product={product}
        price={cartPrice}
        onPriceChange={handlePriceChange}
        cartUpdateInProgress={cartUpdateInProgress}
        orderInProgress={orderInProgress}
        showModal={Boolean(showModal)}
      />
    );
  }
  else if (displayType === 'popular-product' && product.data.name !== "Gift Card") {
    return (
      <PopularProduct
        product={product}
        quantity={quantity}
        onQtyChange={handleQtyChange}
        cartPrice={cartPrice}
        cartUpdateInProgress={cartUpdateInProgress}
        orderInProgress={orderInProgress}
      />
    )
  } else if (displayType === 'new-product' && product.data.name !== "Gift Card") {
    return (
      <NewProduct
        product={product}
        customer={customer}
        quantity={quantity}
        onQtyChange={handleQtyChange}
        cartPrice={cartPrice}
      />
    );
  } else if (product.isNameYourPrice() && product.data.name !== "Gift Card") {
    return (
      <NYPProduct
        product={product}
        price={cartPrice}
        onPriceChange={handlePriceChange}
        cartUpdateInProgress={cartUpdateInProgress}
        orderInProgress={orderInProgress}
        showModal={Boolean(showModal)}
      />
    );
  }

  return (
    <Product
      product={product}
      customer={customer}
      quantity={quantity}
      onQtyChange={handleQtyChange}
      onPriceChange={handlePriceChange}
      cartUpdateInProgress={cartUpdateInProgress}
      orderInProgress={orderInProgress}
      showModal={Boolean(showModal)}
    />
  )
}