import { Dropdown } from 'react-bootstrap';
import Window from '../../../utils/Window';
import MacroFilterForm from './forms/MacroFilterForm';

export default function MacroFilter() {
  if (Window.isMobile()) {
    return (
      <div className='macro-filter'>
        <div className='fs-5 mb-2 text-center'>MACROS</div>
        <MacroFilterForm />
      </div>
    )
  }
  return (
    <Dropdown className="macro-filter">
      <Dropdown.Toggle 
      className='rounded-2 w-100'
      variant='outline-dark'
      >
        Macros
      </Dropdown.Toggle>
      <Dropdown.Menu className='ps-2 pe-3 pt-3'>
        <MacroFilterForm />
      </Dropdown.Menu>
    </Dropdown>
  )
}