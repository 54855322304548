import React from 'react'
import VideoPage from './DetailsPages/VideoPage'
import DescriptionPage from './DetailsPages/DescriptionPage'
import RelatedWorkOutPage from './DetailsPages/RelatedWorkOutPage'
import RelatedMeals from './DetailsPages/RelatedMeals'

export default function DetailsWrapper() {
    return (
        <div className="container">
            <div className="detail-wrapper">
                <VideoPage />
                <DescriptionPage />
                <RelatedWorkOutPage />
                <RelatedMeals />
            </div>
        </div>
    )
}