import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { selectProductProteinTypeFilter, 
         setProductProteinTypeFilter } from '../../productsSlice';

export default function ProteinFilterForm() {
  const dispatch = useDispatch();
  const filterValue = useSelector(selectProductProteinTypeFilter);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProductProteinTypeFilter(e.target.id));
  }

  return (
    <Form className='fs-6 mx-2'>
      <Form.Check 
        type="radio" 
        name="product-protein" 
        label="All" 
        id="all" 
        checked={filterValue === "all"}
        onChange={handleChange} 
      />
      <Form.Check 
        type="radio" 
        name="product-protein" 
        label="Beef" 
        id="beef" 
        checked={filterValue === "beef"}
        onChange={handleChange} 
      />
      <Form.Check 
        type="radio" 
        name="product-protein" 
        label="Chicken" 
        id="chicken" 
        checked={filterValue === "chicken"}
        onChange={handleChange} 
      />
      <Form.Check 
        type="radio" 
        name="product-protein" 
        label="Fish/Seafood" 
        id="fish-seafood" 
        checked={filterValue === "fish-seafood"}
        onChange={handleChange} 
      />
      <Form.Check 
        type="radio" 
        name="product-protein" 
        label="Pork" 
        id="pork" 
        checked={filterValue === "pork"}
        onChange={handleChange} 
      />
      <Form.Check 
        type="radio" 
        name="product-protein" 
        label="Turkey" 
        id="turkey" 
        checked={filterValue === "turkey"}
        onChange={handleChange} 
      />
    </Form>
  );
}