import { useEffect } from "react";
import ElementorPage from "./ElementorPage";
import { useLocation } from "react-router-dom";

export default function HowItWorksPage() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/reviews') {
      const cssUrl = `${process.env.REACT_APP_BLOG_URL}/wp-content/uploads/elementor/css/post-81369.css`;
      const cssUrl2 = `${process.env.REACT_APP_BLOG_URL}/wp-content/plugins/elementor/assets/lib/eicons/css/elementor-icons.min.css`;
      const cssUrlWithCacheBust = `${cssUrl}?v=${new Date().getTime()}`;
      const cssUrlWithCacheBust2 = `${cssUrl2}?v=${new Date().getTime()}`;

      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = cssUrlWithCacheBust;

      const link2 = document.createElement("link");
      link2.rel = "stylesheet";
      link2.type = "text/css";
      link2.href = cssUrlWithCacheBust2;

      document.head.appendChild(link);
      document.head.appendChild(link2);
      return () => {
        document.head.removeChild(link);
        document.head.removeChild(link2);
      };
    }
    else {
      const link = document.querySelector('link[href*="post-81369.css"]');
      if (link) {
        document.head.removeChild(link);
      }
    }
  }, [location.pathname, location.search]);
  
  return (
    <ElementorPage pageNo={81369} />
  )
}