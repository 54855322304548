import { useEffect } from 'react';
import { Card, Container, Col, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../features/user/userSlice";
import { loadMealPlans, 
         selectMealPlans, 
         selectMealPlansLoaded, 
         selectMealPlansLoadError, 
         selectMealPlansLoading } from '../features/meal_plans/mealPlansSlice';
import MyAccountNav from '../components/MyAccountNav';
import MyMealPlans from '../features/meal_plans/MyMealPlans';
import MyAccountPage from './MyAccountPage';

export default function MyMealPlansPage() {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const mealPlans = useSelector(selectMealPlans);
  const mealPlansLoaded = useSelector(selectMealPlansLoaded);
  const mealPlansLoadError = useSelector(selectMealPlansLoadError);
  const mealPlansLoading = useSelector(selectMealPlansLoading);
  const setLoginInfo = localStorage.getItem('setAuthInfo');
  const logInfo = setLoginInfo && JSON.parse(setLoginInfo);
  const userToken = logInfo ? logInfo.token : token;

  useEffect(() => {
    if (token && !mealPlansLoadError && !mealPlansLoading && !mealPlansLoaded) {
      dispatch(loadMealPlans(token));
    }
  }, [token, mealPlansLoadError, mealPlansLoading, mealPlansLoaded, dispatch]);

  if (!userToken) {
    return (
      <MyAccountPage />
    )
  }

  return (
    <Container fluid="lg" className='my-meal-plans-page'>
      <Row>
        <Col sm={12} md={3}>
          <MyAccountNav />
        </Col>
        <Col sm={12} md={9} className='d-flex flex-column'>
          <Card className=" my-5 font-barlow flex-fill">
            <div className='fs-2 mt-2 mb-4 ms-3'>My Favorite Meals</div>
            {mealPlansLoading &&
              <div className='d-flex justify-content-center my-2'>
                <Spinner className='text-black my-2' animation="grow" />
              </div>
            }
            {mealPlansLoadError &&
              <div className='fs-4 text-danger mb-5 ms-3'>
                We're sorry. An unexpected error has occurred. Please try again later.
              </div>
            }
            {mealPlansLoaded && mealPlans.length <= 0 &&
              <div className='fs-4 mb-5 ms-3'>
                You haven't created any meal plans yet.
              </div>
            }
            {mealPlansLoaded && mealPlans.length > 0 && (
              <>
                <span className='px-3 d-block mb-3'>Click on a meal list below to quickly add it to your cart</span>
                <MyMealPlans />
              </>)
            }
          </Card>
        </Col>
      </Row>
    </Container>      
  ); 
}