import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Alert, Button, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import CartObj from '../features/cart/cartObj';
import {
  selectCartCoupon,
  selectCartItems,
  selectCartZipcode,
  selectOrderInProgress,
} from '../features/cart/cartSlice';
import { selectProducts } from '../features/products/productsSlice';
import {
  loadDeliveryInfo,
  selectDeliveryLoading
} from '../features/delivery/deliverySlice';
import CheckoutForm from '../components/checkout-form/CheckoutForm';
import ReturningCustomer from '../components/ReturningCustomer';
import { selectToken, selectUser } from '../features/user/userSlice';
import CustomerObj from '../features/customer/customerObj';
import {
  selectCustomer,
  selectCustomerIsLoading
} from '../features/customer/customerSlice';
import {
  selectCoupons,
} from '../features/coupons/couponsSlice';
import ApplyMightyPoints from '../features/coupons/ApplyMightyPoints';
import CouponList from '../features/coupons/CouponList';
import CouponUtils from '../features/coupons/couponUtils';
import CouponEntry from '../features/coupons/CouponEntry';
import './checkoutPage.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import UpCellsProducts from '../components/checkout-form/UpCellsProducts';
import CheckoutReviews from '../components/checkout-form/CheckoutReviews';
import { selectGeneralOptions, selectIsMobileRoute, setGeneralOptions, setLifeTimeAffiliate } from '../features/mobile/mobileSlice';
import TiktokPixel from 'tiktok-pixel';
import { selectShippingMethods } from '../features/shipping/shippingSlice';
import ShippingMethodObj from '../features/shipping/shippingMethodObj';
import AffilitateAPI from '../API/affiliateAPI';
import ProfileOptionsAPI from '../API/profileOptionsAPI';
import CouponObj from '../features/coupons/couponObj';
import { Image } from 'react-bootstrap';
import logo from '../assets/images/cooking.svg';

export default function CheckoutPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deliveryReloaded, setDeliveryReloaded] = useState(false);
  const token = useSelector(selectToken);
  const cartItems = useSelector(selectCartItems);
  const cart = useMemo(() => {
    return new CartObj(cartItems);
  }, [cartItems]);
  const cartCoupon = useSelector(selectCartCoupon);
  const cartCouponObj = CouponObj.createCoupon(useSelector(selectCartCoupon));
  const products = useSelector(selectProducts);
  const user = useSelector(selectUser);
  const custData = useSelector(selectCustomer);
  const customer = useMemo(() => {
    return new CustomerObj(custData);
  }, [custData]);
  const customerIsLoading = useSelector(selectCustomerIsLoading);
  const coupons = useSelector(selectCoupons);
  const displayableCoupons = useMemo(() => {
    return CouponUtils.getDisplayableCoupons(coupons, customer);
  }, [coupons, customer]);
  const deliveryLoading = useSelector(selectDeliveryLoading);
  const orderInProgress = useSelector(selectOrderInProgress);
  const getGeneralOptions = useSelector(selectGeneralOptions);
  const pointsBalance = customer.getMightyPoints();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productCodeQuery = queryParams.get("product_id");
  const AppURL = useSelector(selectIsMobileRoute);
  const [regionalShipLimit, seteRgionalShipLimit] = useState(0);
  const shippingMethods = useSelector(selectShippingMethods);
  const cartZipcode = useSelector(selectCartZipcode);
  const paymentMode = getGeneralOptions?.payment_mode;
  const publishKey = paymentMode === 'live' ?
    process.env.REACT_APP_PROD_STRIPE_PUBLISHABLE_KEY :
    process.env.REACT_APP_STAGE_STRIPE_PUBLISHABLE_KEY;
  const stripePromise = publishKey ?
    loadStripe(publishKey) : null;


  useEffect(() => {
    async function fetchData() {
      try {
        const getGeneralOption = await ProfileOptionsAPI.getGeneralOptions();
        if (getGeneralOption) {
          dispatch(setGeneralOptions(getGeneralOption));
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchData() {
      try {
        const shippingMethodInputs = document.getElementsByName('shipping-method');
        if (shippingMethodInputs.length > 0) {
          const firstShippingMethodValue = (shippingMethodInputs[0] as HTMLInputElement)?.value;
          const getShippingMethod = ShippingMethodObj.getById(shippingMethods, Number(firstShippingMethodValue));
          if (getShippingMethod?.data.ups_delivery_method === true) {
            if (getGeneralOptions) {
              seteRgionalShipLimit(Number(getGeneralOptions.regional_shipping_meal_limit));
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [shippingMethods, cartZipcode, getGeneralOptions]);

  // We reload delivery data each time this page is visited so we
  // have current blocked date info.
  useEffect(() => {
    if (!deliveryLoading && !deliveryReloaded) {
      dispatch(loadDeliveryInfo());
      setDeliveryReloaded(true);
    }
  }, [deliveryLoading, deliveryReloaded, dispatch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const couponCode = searchParams.get('coupon-code');
    const hasProductCode = productCodeQuery && productCodeQuery !== '' && productCodeQuery !== null;

    if (!hasProductCode && couponCode) {
      if (couponCode && cart.isEmpty()) {
        navigate(`/order/?coupon-code=${couponCode}${AppURL}`);
      } else {
        navigate(`/checkout/?coupon-code=${couponCode}${AppURL}`);
      }
    } else if (!hasProductCode && !couponCode && !orderInProgress && cart.isEmpty()) {
      navigate(`/order${AppURL}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderInProgress, cart, navigate, productCodeQuery, location.search]);

  // Facebook Pixel initiate checkout
  useEffect(() => {
    ReactPixel.track(
      'InitiateCheckout',
      {
        currency: 'USD',
        num_items: cart.getItemCount(),
        content_type: 'product',
        content_ids: cart.getProductIDs(),
        content_name: cart.getProductNames(products),
        value: cart.getSubtotal(null, null, null)
      }
    );

    ReactGA.event(
      'begin_checkout',
      {
        currency: 'USD',
        value: cart.getSubtotal(null, null, null),
        coupon: cartCoupon ? cartCoupon.code : '',
        items: cart.getItemsForGA(products)

      }
    );

    TiktokPixel.track(
      'InitiateCheckout',
      {
        currency: 'USD',
        num_items: cart.getItemCount(),
        content_type: 'product',
        content_ids: cart.getProductIDs(),
        content_name: cart.getProductNames(products),
        value: cart.getSubtotal(null, null, null)
      }
    );

    // eslint-disable-next-line    
  }, []);

  useEffect(() => {
    if (customer.data.id) {
      handleAffiliateReferrals(Number(customer.data.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.data.id])

  const handleAffiliateReferrals = async (userId: number) => {
    await AffilitateAPI.getAffiliateByUser(
      userId,
    ).then((response) => {
      if (response?.status === 200) {
        dispatch(setLifeTimeAffiliate(response.data));
      }
      if ('errors' in response) console.log(response.errors);
    }).catch((e) => {
      console.log([e.message]);
    })
  }

  const getCoockingTIme = (meals: number) => {
    const totalTimeInHours = meals * 0.5;
    const totalTimeInMinutes = meals * 30;
    return meals <= 2 ? totalTimeInMinutes : totalTimeInHours;
  }

  return (
    <Container className="checkout-page pt-3">
      {(regionalShipLimit > 0 && regionalShipLimit > cart.getItemCount() && !orderInProgress) &&
        <Modal show={true}>
          <Modal.Body className='font-barlow mb-4'>
            <div className='text-center fs-4'>
              <p className='mt-2'>Shipping requires a minimum of {regionalShipLimit} meals.
                Please add {regionalShipLimit} meals to your cart to continue to checkout</p>
            </div>
            <div className='d-flex justify-content-center p-3'>
              <Button variant="dark" onClick={() => { navigate(`/order${AppURL}`) }}>Add Items To Cart</Button>
            </div>
          </Modal.Body>
        </Modal>}
      <h1 className='py-4 text-center fw-bold'>COMPLETE CHECKOUT</h1>
      {!customer.hasMembership() &&
        <Alert variant="dark" className='earn-pts-alert font-barlow fs-20px bg-black text-white'>
          {(cartCoupon && cartCouponObj?.getEarnedPoints() > 0) &&
            `${(cartCouponObj?.getEarnedPoints() / 100)}X POINTS ACTIVATED! `}
          Complete your order and earn <span className='fw-bold'>
            {(cartCoupon && cartCouponObj && cart.getEarnedPoints(products, cartCouponObj) > 0) ?
              cart.getEarnedPoints(products, cartCouponObj) :
              cart.getPoints(products)}</span> Mighty
          Points for a discount on a future purchase period
        </Alert>
      }
      <Row className='my-3'>
        <Col>
          {!user.token &&
            <ReturningCustomer />
          }
        </Col>
      </Row>
      {token && !cartCoupon && pointsBalance > 0 && !customer.hasMembership() &&
        <ApplyMightyPoints />
      }
      {!cartCoupon &&
        <CouponEntry />
      }
      {token && !cartCoupon && displayableCoupons.length > 0 &&
        <div>
          <div className=' fs-2 my-2 pt-2 ps-1'>Available Coupons &amp; Gift Cards</div>
          <CouponList />
        </div>
      }
      <div className='cooking-wrap mt-4'>
        <Row className='cooking-top cookingCallout p-4'>
          <Col xl={2} sm={4} xs={3} className='d-flex justify-content-center align-items-center'>
            <Image
              src={logo}
              className='d-block  mx-auto'
              width="159"
              height="169"
            />
          </Col>
          <Col xl={10} sm={8} xs={9} className='d-flex justify-content-center align-items-center'>
            <Row className='align-items-center w-100'>
              <Col xl={4} className='text-end px-2'>
                <span className='text-title py-4 fs-26px'>THIS ORDER SAVES YOU</span>
              </Col>
              <Col xl={4} className='middel-part px-2'>
                <span className='py-4 fs-52px px-2 fw-bold '>
                  {cart.getItemCount() <= 2 ?
                    `${getCoockingTIme(cart.getItemCount())} MINUTES` :
                    `${getCoockingTIme(cart.getItemCount())} HOURS`}</span>
              </Col>
              <Col xl={4} className='text-start px-2 p-last'>
                <span className='text-title py-4 fs-26px'>OF COOKING & CLEANING!</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='cooking-bottom'>
          <Col md={12} className='d-flex justify-content-center align-items-center mt-4'>
            <div className="rw-heading d-flex justify-content-center bg-black p-1 px-3">
              <h2 className='h2 fw-bold text-white'>ADD POPULAR MEALS</h2>
            </div>
          </Col>
          <Col md={12} className='d-flex justify-content-center align-items-center'>
            <span className='fs-20px'>to save even more!</span>
          </Col>
        </Row>
      </div>
      {(!(cart.hasProductWithCategory('gift-card', products) || cart.hasProductWithCategory('mighty-bucks-gift-card', products))) &&
        <UpCellsProducts />}
      {(token && (customerIsLoading) && !orderInProgress) ?
        <div className='text-center my-5'>
          <Spinner
            variant="dark"
            animation="border"
            as="span"
            className='spinner-lg'
          />
        </div>
        :
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      }
      <div className='pt-4'>
        <CheckoutReviews />
      </div>
    </Container>
  );
}