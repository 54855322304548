import { useDispatch, useSelector } from "react-redux";
import { selectProductProteinTypeFilter, setProductProteinTypeFilter } from "../productsSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function ProteinFilterTag() {
  const dispatch = useDispatch();
  const proteinFilter = useSelector(selectProductProteinTypeFilter);

  const getFilterText = () => {
    switch (proteinFilter) {
      case 'beef':
        return 'Beef';
      case 'chicken': 
        return 'Chicken';
      case 'fish-seafood':
        return 'Fish/Seafood';
      case 'pork':
        return 'Pork';
      case 'turkey':
        return 'Turkey';
      case 'vegetarian':
        return 'Vegetarian';
    }
  }

  const handleClick = () => {
    dispatch(setProductProteinTypeFilter('all'));
  }

  if (proteinFilter === 'all') {
    return (<></>);
  }

  return (
    <div className='protein-filter-tag bg-secondary bg-opacity-25 p-2 fw-bold text-nowrap'>
      {getFilterText()}
      <FontAwesomeIcon 
        role="button"
        className='ps-3 pe-auto' 
        icon={faXmark} 
        size={"xl" as SizeProp}
        onClick={handleClick} 
      />
    </div>
  )
}