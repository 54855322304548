import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import MyAccountNav from '../components/MyAccountNav';
import BillingAddress from '../features/customer/BillingAddress';
import ShippingAddress from '../features/customer/ShippingAddress';
import { selectToken } from '../features/user/userSlice';
import MyAccountPage from './MyAccountPage';

export default function AddressPage() {
  const token = useSelector(selectToken);
  const setLoginInfo = localStorage.getItem('setAuthInfo');
  const logInfo = setLoginInfo && JSON.parse(setLoginInfo);
  const userToken = logInfo ? logInfo.token : token;

  if (!userToken) {
    return (
      <MyAccountPage />
    )
  }

  return (
    <Container fluid="lg" className='address-page'>
      <Row>
        <Col sm={12} md={3}>
          <MyAccountNav />
        </Col>
        <Col sm={12} md={9} className="my-5">
          <BillingAddress />
          <ShippingAddress />
        </Col>
      </Row>
    </Container>
  );
}
