import { useFormikContext } from 'formik';
import React, { ChangeEvent, useState } from 'react'
import { Form } from 'react-bootstrap'
import { FormValues } from './interfaces';
import { useSelector } from 'react-redux';
import { selectOrderInProgress } from '../../features/cart/cartSlice';

export default function SavePaymentData() {
    const formik = useFormikContext<FormValues>();
    const [save, setSave] = useState(Boolean(formik.values.save_payment));
    const orderInProgress = useSelector(selectOrderInProgress);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        formik.setFieldValue('save_payment', isChecked);
        setSave(isChecked);
    }

    return (
        <div className='d-flex mt-4 save-payment'>
            <Form.Check
                type="switch"
                className='d-flex align-items-center'
                defaultChecked={save}
                onChange={handleChange}
                disabled={orderInProgress}

            />
            <span className='text-start'>Save payment details for future purchases.</span>
        </div>
    )
}
