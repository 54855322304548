import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card } from 'react-bootstrap';
import { selectCustomer } from './customerSlice';
import ShippingAddressForm from './ShippingAddressForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

export default function ShippingAddress() {
  const customer = useSelector(selectCustomer);
  const [editing, setEditing] = useState(false);
  const handleClick = () => setEditing(true);
  const handleClose = () => setEditing(false);
  const shipping_info = customer && customer.shipping ? customer.shipping : {};
  const billing = {
    first_name: !!shipping_info.first_name && shipping_info.first_name.trim(),
    last_name: !!shipping_info.last_name && shipping_info.last_name.trim(),
    address_1: !!shipping_info.address_1 && shipping_info.address_1.trim(),
    city: !!shipping_info.city && shipping_info.city.trim(),
    state: !!shipping_info.state && shipping_info.state.trim(),
    postcode: !!shipping_info.postcode && shipping_info.postcode.trim(),
    country: 'US',
  };
  const anyNullOrEmpty = Object.values(billing).some(value => value === false);

  if (editing) {
    return (
      <ShippingAddressForm onClose={handleClose} />
    );
  }
  return (
    <Card className="shipping-address my-3">
      <Card.Header>
        <div className="d-flex justify-content-between">
          <span className="fs-4">Shipping Address</span>
          <Button variant="light" onClick={handleClick}>
            <FontAwesomeIcon icon={faPenToSquare} size={"xl" as SizeProp} />
          </Button>
        </div>
      </Card.Header>
      <Card.Body>
        {anyNullOrEmpty && <span>No records found.</span>}
        {customer?.shipping?.first_name} {customer?.shipping?.last_name}<br />
        {customer?.shipping?.address_1}<br />
        {customer?.shipping?.address_2.length > 0 &&
          <div>{customer?.shipping?.address_2}<br /></div>}
        {customer?.shipping?.city.length > 0 &&
          <span>{customer?.shipping?.city}, </span>}
        {customer?.shipping?.state} {customer?.shipping?.postcode}
      </Card.Body>
    </Card>
  );
}
