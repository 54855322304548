import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  CloseButton,
  Col,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Row
} from 'react-bootstrap';
import {
  selectCartUpdateInProgress,
  selectOrderInProgress
} from '../cart/cartSlice';
import ProductObj from './productObj';
import ToastError from '../../components/ToastError';

interface Props {
  product: ProductObj;
  show: boolean;
  onHide: () => void;
  onPriceChange: (price: number) => string;
  price: number;
}
export default function GiftCardDetailModel({
  product,
  show,
  onHide,
  onPriceChange,
  price
}: Props) {
  const [errorMsg, setErrorMsg] = useState('');
  const cartUpdateInProgress = useSelector(selectCartUpdateInProgress);
  const orderInProgress = useSelector(selectOrderInProgress);
  const [giftPrice, setGiftPrice] = useState(price);
  const description = new DOMParser().parseFromString(product.data.description, "text/html")
    .documentElement.textContent;

  const handlePurchase = (e: any) => {
    e.preventDefault();
    const error = onPriceChange(price);
    if (error) {
      setErrorMsg('Gift cards must be purchased separate from other items. Clear your cart to purchase this item.')
      setTimeout(() => {
        setErrorMsg('')
      }, 5000)
    }
    else {
      if (Number(giftPrice) === price) {
        setErrorMsg('Please add a gift card for at least $1.')
        setTimeout(() => {
          setErrorMsg('')
        }, 3000)
      }
      else {
        if (onPriceChange(giftPrice)) {
          e.target.value = "0";
        }
      }
    }
  }

  const handleOnChange = (e: any) => {
    setGiftPrice(e.target.value);
  }

  return (
    <Modal size="xl" show={show} onHide={onHide} className='nyp-product-detail-modal giftCardBox'>
      <Modal.Body className='p-0'>
        <div className='giftCardBox-close'>
          <CloseButton className="float-end" onClick={onHide} />
        </div>
        <Row className='mx-0'>
          <Col md={12} lg={6} className='px-0'>
            <div className='card-image'>
              <div className='giftCardBox-icon'>
                <svg width="31" height="27" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M28.4167 6.5641H25.2914C25.6261 5.94564 25.8333 5.25538 25.8333 4.51282C25.8333 2.02462 23.7085 0 21.0972 0C18.8583 0 17.4101 1.09436 15.5517 3.5041C13.6938 1.09385 12.2455 0 10.0066 0C7.39533 0 5.27054 2.02462 5.27054 4.51282C5.27054 5.25538 5.47774 5.94564 5.8125 6.5641H2.58333C1.15658 6.5641 0 7.66615 0 9.02564V23.7949C0 25.1544 1.15658 26.2564 2.58333 26.2564H28.4167C29.8434 26.2564 31 25.1544 31 23.7949V9.02564C31 7.66615 29.8434 6.5641 28.4167 6.5641ZM21.0972 2.46154C22.2845 2.46154 23.25 3.38154 23.25 4.51282C23.25 5.64359 22.2845 6.5641 21.0972 6.5641H16.465C19.2291 2.64256 20.002 2.46154 21.0972 2.46154ZM7.85387 4.51282C7.85387 3.38154 8.81939 2.46154 10.0066 2.46154C11.0798 2.46154 11.8677 2.62923 14.6389 6.5641H10.0066C8.81939 6.5641 7.85387 5.64359 7.85387 4.51282ZM11.9511 9.02564L9.1676 11.6779C8.83123 11.9985 8.83123 12.5179 9.1676 12.8385L9.7763 13.4185C10.1127 13.739 10.6579 13.739 10.9942 13.4185L15.5 9.12513L20.0058 13.4185C20.3421 13.739 20.8873 13.739 21.2237 13.4185L21.8324 12.8385C22.1688 12.5179 22.1688 11.9985 21.8324 11.6779L19.0489 9.02564H28.4167V16.4103H2.58333V9.02564H11.9511ZM2.58333 23.7949V19.6923H28.4167V23.7949H2.58333Z" fill="#00B156" />
                </svg>
              </div>
              <span className='card-image-star'>
                <svg viewBox="0 0 238 228" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M127.742 0C127.758 0.503591 127.896 1.04533 127.766 1.5184C125.748 8.63735 126.716 14.7644 134.935 19.022C126.017 19.5256 120.02 22.7837 118.197 31.6194C115.479 23.4704 110.141 19.4417 100.678 20.0979C108.01 15.1077 109.946 9.04939 106.814 1.09111C114.251 5.78367 120.947 6.53906 126.928 0C127.196 0 127.473 0 127.742 0Z" fill="#1B1C1E" />
                  <path d="M33.89 130.66C26.5912 135.093 22.3845 140.129 26.2739 148.377C25.8589 148.468 25.4358 148.568 25.0209 148.659C22.9704 147.469 20.7571 146.462 18.9019 145.042C16.5015 143.204 14.3209 142.944 11.5544 144.363C9.50387 145.416 7.13603 145.912 3.51513 147.126C8.28333 139.251 7.18485 132.994 0 127.379C9.12956 128.516 14.9799 125.433 18.2835 117.406C20.0004 125.692 24.6384 130.278 33.8981 130.667L33.89 130.66Z" fill="#1B1C1E" />
                  <path d="M136.138 208.517C128.067 212.828 126.146 218.864 129.857 227.257C125.145 225.098 121.94 222.488 118.709 222.473C115.479 222.458 112.24 225.037 108.213 226.822C108.449 224.434 108.188 222.473 108.896 220.901C110.523 217.315 109.23 214.858 106.309 212.645C104.738 211.455 103.282 210.135 101.597 208.166C110.019 207.663 116.756 205.358 117.659 195.927C122.208 201.07 130.133 206.488 136.13 208.517H136.138Z" fill="#1B1C1E" />
                  <path d="M237.328 127.515C230.387 133.131 228.849 139.288 233.121 146.98C225.204 142.211 218.458 143.233 211.786 149.131C214.406 140.563 211.493 134.947 202.412 131.216C207.262 129.796 211.542 129.59 214.04 127.507C216.562 125.401 217.319 121.426 219.451 117.046C222.462 125.417 228.263 128.583 237.328 127.515Z" fill="#1B1C1E" />
                  <path d="M18.7634 48.0932C27.5756 46.9639 32.1729 42.424 32.3357 33.573C36.7947 40.7301 42.58 44.1179 51.7014 41.5236C46.388 47.849 44.004 53.9532 50.7576 60.6067C50.3588 60.7898 49.952 60.9729 49.5533 61.1561C47.3075 60.744 44.8502 60.7364 42.8729 59.8132C39.7565 58.3634 37.5921 59.1112 35.3707 61.2018C33.7759 62.705 31.9533 63.9945 29.439 66.0165C29.1298 63.1704 28.6334 61.0035 28.7311 58.8594C28.8694 55.6852 27.779 53.6327 24.4917 52.3966C22.5714 51.6718 20.9522 50.2449 19.1946 49.1385C19.0482 48.7876 18.9017 48.4442 18.7634 48.0932Z" fill="#1B1C1E" />
                  <path d="M26.5356 76.7288C26.5356 80.7499 25.4697 84.687 26.8448 87.6246C28.1549 90.4097 32.0199 92.1341 35.2665 94.7283C31.133 95.9034 26.7635 96.0407 24.3468 98.139C21.8569 100.306 21.2629 104.396 19.4566 108.516C15.9821 101.038 11.0756 96.1399 1.50664 99.0165L1.44968 97.872C3.14214 95.8652 4.67191 93.7059 6.57594 91.8899C8.4881 90.0663 8.53689 88.2274 7.71507 86.0299C6.91766 83.9011 6.29113 81.7113 5.33098 78.7355C7.82899 79.4833 9.77372 79.7808 11.4011 80.6202C14.8186 82.3827 17.7804 82.81 20.8724 79.9029C22.2963 78.56 24.4282 77.8733 26.5356 76.7364V76.7288Z" fill="#1B1C1E" />
                  <path d="M50.3338 164.804C48.7959 167.864 46.0538 170.916 46.0538 173.976C46.0538 177.005 48.8122 180.034 51.001 184.315C42.1888 180.996 35.72 182.422 31.0901 190.648C30.3252 188.099 29.5848 186.741 29.5767 185.383C29.5523 180.919 28.1446 177.554 23.059 176.25C21.5863 175.868 20.3088 174.838 17.5911 173.381C26.9566 171.397 30.9518 166.3 30.6507 157.754C35.8909 164.461 42.0179 167.154 50.4152 163.355C50.3826 163.843 50.3582 164.324 50.3257 164.812L50.3338 164.804Z" fill="#1B1C1E" />
                  <path d="M206.62 190.67C201.526 183.147 195.456 180.339 186.644 184.07C192.234 177.119 192.429 170.877 186.204 164.689C186.505 164.506 186.798 164.323 187.1 164.148C188.865 164.323 190.712 164.262 192.38 164.735C196.262 165.841 199.533 165.574 201.892 162.08C202.975 160.477 204.146 158.929 206.213 157.624C206.538 166.109 210.151 171.465 219.818 173.365C215.351 175.837 211.274 176.959 209.321 179.499C207.401 182.01 207.629 185.977 206.62 190.678V190.67Z" fill="#1B1C1E" />
                  <path d="M206.546 68.7102C203.25 65.9709 200.793 62.5068 197.53 61.6141C194.332 60.7366 190.321 62.4763 185.244 63.3004C191.59 56.2882 192.136 49.8789 184.91 43.027C190.215 43.5382 194.284 44.9574 197.457 43.9426C200.622 42.9278 202.884 39.4561 205.846 36.7855C206.847 40.7074 206.798 44.7285 208.832 47.2999C210.753 49.7263 214.87 50.619 218.507 52.435C214.772 54.6096 210.663 55.8228 208.735 58.4476C206.863 60.9961 207.229 64.9866 206.546 68.7178V68.7102Z" fill="#1B1C1E" />
                  <path d="M77.0305 220.687C73.0841 212.645 67.4372 209.318 58.6901 212.073C58.625 211.737 58.5517 211.409 58.4866 211.073C59.341 209.624 59.9431 207.953 61.1067 206.77C63.9464 203.87 64.3696 200.933 62.3028 197.514C61.3996 196.019 60.8789 194.325 59.5607 191.296C68.0881 195.088 74.9394 194.913 80.4073 187.511C79.8459 195.683 82.2219 201.795 91.3433 204.145C91.148 204.443 90.9608 204.733 90.7656 205.03C87.2993 206.465 82.9053 207.136 80.6433 209.563C78.4301 211.936 78.3894 216.102 77.0305 220.687Z" fill="#1B1C1E" />
                  <path d="M155.838 187.634C159.199 190.053 161.892 193.25 165.212 194.013C168.336 194.73 172.128 192.868 176.449 191.968C175.456 194.57 174.919 196.638 173.902 198.469C172.519 200.956 172.145 203.001 174.439 205.313C176.026 206.908 176.978 209.06 178.971 212.135C169.549 209.556 163.373 212.341 159.199 219.971C158.824 211.349 154.512 206.549 145.448 205.596C145.57 205.252 145.692 204.909 145.814 204.558C148.808 202.589 152.885 201.185 154.504 198.484C156.172 195.707 155.456 191.671 155.83 187.634H155.838Z" fill="#1B1C1E" />
                  <path d="M210.622 76.722C217.375 82.8643 224.072 83.4137 231.403 78.271C231.517 78.6372 231.631 79.0035 231.745 79.3621C230.834 82.727 228.669 86.3361 229.353 89.3881C230.044 92.5012 233.535 95.065 236.098 98.2468C231.973 98.5825 227.668 97.8805 224.772 99.46C221.81 101.078 220.231 104.931 218.051 107.792C217.766 107.685 217.424 107.647 217.4 107.54C215.666 100.162 211.704 95.1031 202.078 95.1565C209.092 90.7005 214.202 85.8019 210.613 76.7373L210.622 76.722Z" fill="#1B1C1E" />
                  <path d="M56.4213 15.2222C65.5346 17.2595 71.5396 14.4135 75.5918 6.63831C76.5031 15.1307 80.5634 20.1285 89.9697 21.0059C82.5488 25.0575 77.3819 29.6128 80.4658 38.2959C80.0834 38.2959 79.6928 38.3035 79.3103 38.3111C77.3331 36.9301 75.1606 35.7398 73.4356 34.1222C71.3037 32.1231 69.2369 31.8255 66.5355 32.9471C64.4769 33.8017 62.2148 34.2214 58.4067 35.3049C63.297 27.6823 63.4923 21.2348 56.4295 15.2222H56.4213Z" fill="#1B1C1E" />
                  <path d="M177.482 37.647C169.59 33.2673 163.08 34.1753 156.741 40.2489C158.637 31.8634 155.879 25.9881 147.742 22.051C156.896 21.4329 161.835 17.3508 163.072 8.76685C167.14 15.8019 172.063 20.4944 181.575 18.1672C175.611 23.9051 172.82 29.8185 177.482 37.647Z" fill="#1B1C1E" />
                </svg>
              </span>
              <img
                alt="product-img"
                src={product.data.images[0].src}
              />
            </div>
          </Col>
          <Col md={12} lg={6} className='d-flex align-items-center px-0'>
              <div className='product-description giftCardBox-desc fs-5 p-3 px-md-5'>
                <Col className="product-title fs-2">{product.data.name}</Col>
                {description &&
                  <p className='mb-5'>{description}</p>}
                <Form className='py-3'>
                  <InputGroup className='nyp-input mb-4 d-flex justify-content-center my-2'>
                    <Row>
                      <Col sm={1} xs={1} className='px-1 d-flex justify-content-end align-items-center font-glegoo h3 pt-1 fw-bold'>
                        $
                      </Col>
                      <Col sm={5} xs={6} className="px-1">
                        <FormControl
                          type="text"
                          maxLength={4}
                          size="sm"
                          className="text-center rounded-1"
                          defaultValue={giftPrice}
                          disabled={orderInProgress || cartUpdateInProgress}
                          onChange={(e: any) => handleOnChange(e)}
                        />
                      </Col>
                      <Col sm={5} xs={5} className="px-1">
                        <Button
                          type='submit'
                          variant="success"
                          className='fw-500 w-100 rounded-1'
                          onClick={(e: any) => handlePurchase(e)}
                        >
                          PURCHASE
                        </Button>
                      </Col>
                    </Row>
                  </InputGroup>
                </Form>
                {errorMsg &&
                  <ToastError
                    msg={errorMsg}
                    onClose={() => setErrorMsg('')}
                    position="middle-end"
                  />
                }
              </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}