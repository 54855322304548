import type { RootState } from '../../app/store';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import CartAPI from '../../API/cartAPI';
import { ICleanseCartData,
         ISetCartCouponData, 
         ISetCartData,
         ICartItems, 
         ISetCartItemInput } from './interfaces';
import CartObj from './cartObj';


interface CartState {
  cart_items: ICartItems;
  coupon: Record<string, any> | null;
  cart_loaded: boolean;
  cart_loading: boolean;
  order_in_progress: boolean; 
  update_in_progress: boolean;
  loadError: boolean;
  zipcode: string;
  deliveryDate: Date | undefined;
  setCartComplete: boolean;
  cartCleanseComplete: boolean;
  cartCleanseError: boolean;
}

const initialState: CartState = {
  cart_items: {},
  coupon: null,
  cart_loaded: false,
  cart_loading: false,
  order_in_progress: false,
  update_in_progress: false,
  loadError: false,
  zipcode: '',
  deliveryDate: undefined,
  setCartComplete: false,
  cartCleanseComplete: false,
  cartCleanseError: false
};

export const deleteCartCoupon = createAsyncThunk(
  'cart/deleteCartCoupon',
  async (token: string) => {
    if (token) {
      await CartAPI.removeCartCoupon(token);
    }
    return null;
  }  
);

export const setCartCoupon = createAsyncThunk(
  'cart/setCartCoupon',
  async ( data: ISetCartCouponData ) => {
    let result;
    if (data.token) {
      result = await CartAPI.updateCartCoupon(data);
    } else {
      result = data.coupon;
    }
    return result;
  }
);

export const cleanseCart = createAsyncThunk(
  'cart/cleanseCart',
  async (data: ICleanseCartData, api) => {
    const state = (api.getState() as RootState).cart;
    const cart = new CartObj(JSON.parse(JSON.stringify(state.cart_items)));
    cart.cleanse(data.products);
    let result = await CartAPI.setCart({
      token: data.token, cart_items: cart.items 
    });
    return result
  }
);

export const clearCart = createAsyncThunk(
  'cart/clearCart',
  async (token: string) => {
    let result = await CartAPI.deleteCart(token);
    return result
  }
);

export const loadCart = createAsyncThunk(
  'cart/loadCart',
  async (token: string)  => {
    let result = {};
    result = await CartAPI.getCart(token);
    return result;
  }
);

export const setCart = createAsyncThunk(
  'cart/setCart',
  async (data: ISetCartData) => {
    let result = {};
    result = await CartAPI.setCart(data);
    return result;
  }
);

export const setCartItem = createAsyncThunk(
  'cart/setCartItem',
  async (data: ISetCartItemInput) => {
    let result: any[] = [];
    result = await CartAPI.updateCartItem(data);
    if (result.length) {
      return result[0];
    }
    return data.cart_item;
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    resetCart: () => {
      return initialState;
    },
    setCartDeliveryDate: (state, action: PayloadAction<Date>) => {
      state.deliveryDate = action.payload;
    },
    setCartUpdateInProgress: (state, action: PayloadAction<boolean>) => {
      state.update_in_progress = action.payload;
    },
    setOrderInProgress: (state, action: PayloadAction<boolean>) => {
      state.order_in_progress = action.payload;
    },
    setCartZipcode: (state, action) => {
      return {
        ...state,
        zipcode: action.payload
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(cleanseCart.pending, (state, action) => {
      state.cartCleanseComplete = false;
      state.update_in_progress = true;
    });
    builder.addCase(cleanseCart.fulfilled, (state, action) => {
      return {
        ...state, 
        ...action.payload,
        cartCleanseComplete: true,
        update_in_progress: false
      }
    });
    builder.addCase(cleanseCart.rejected, (state, action) => {
      state.cartCleanseError = true;
      state.update_in_progress = false;
    });
    builder.addCase(deleteCartCoupon.pending, (state, action) => {
      state.update_in_progress = true;
    });
    builder.addCase(deleteCartCoupon.fulfilled, (state, action) => {
      state.update_in_progress = false;
      state.coupon = null;
    });
    builder.addCase(deleteCartCoupon.rejected, (state, action) => {
      state.update_in_progress = false;
    });
    builder.addCase(setCart.pending, (state, action) => {
      state.setCartComplete = false;
      state.update_in_progress = true;
    });
    builder.addCase(setCart.fulfilled, (state, action) => {
      return {
        ...state, 
        ...action.payload,
        setCartComplete: true,
        update_in_progress: false
      }
    });
    builder.addCase(setCart.rejected, (state, action) => {
      state.update_in_progress = false;
      state.setCartComplete = false;
    });
    builder.addCase(setCartCoupon.pending, (state, action) => {
      state.update_in_progress = true;
    });
    builder.addCase(setCartCoupon.fulfilled, (state, action) => {
      return {
        ...state,
        update_in_progress: false,
        coupon: action.payload
      }
    });
    builder.addCase(setCartCoupon.rejected, (state, action) => {
      state.update_in_progress = false;
    });
    builder.addCase(clearCart.pending, (state, action) => {
      state.update_in_progress = true;
    });
    builder.addCase(clearCart.fulfilled, (state, action) => {
      return {
        ...state,
        update_in_progress: false,
        cart_items: {},
        coupon: null
      }
    });
    builder.addCase(clearCart.rejected, (state, action) => {
      state.update_in_progress = false;
    });
    builder.addCase(loadCart.pending, (state, action) => {
      state.cart_loading = true;
      state.update_in_progress = true;
    });
    builder.addCase(loadCart.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
        cart_loaded: true,
        cart_loading: false,
        update_in_progress: false
      }
    });
    builder.addCase(loadCart.rejected, (state, action) => {
      state.cart_loading = false;
      state.update_in_progress = false;
      state.loadError = true;
    });
    builder.addCase(setCartItem.pending, (state, action) => {
      state.update_in_progress = true;
    });
    builder.addCase(setCartItem.fulfilled, (state, action) => {
      const { product_id, product_qty, product_price } = action.payload;
      if (product_qty > 0 && product_price) {
        return {
          ...state,
          update_in_progress: false,
          cart_items: { ...state.cart_items, [product_id]: {...action.payload }}
        }
      }

      // Immutably remove product from cart
      const { [product_id]: remove, ...cartItems } = state.cart_items;
      return { ...state, update_in_progress: false, cart_items: cartItems };
    });
    builder.addCase(setCartItem.rejected, (state, action) => {
      state.update_in_progress = false;
    });
  }
});

export const { resetCart,
               setCartDeliveryDate,
               setCartUpdateInProgress, 
               setCartZipcode, 
               setOrderInProgress } = cartSlice.actions;
export const selectCartCoupon = (state: RootState) => state.cart.coupon;
export const selectCartDeliveryDate = (state: RootState) => state.cart.deliveryDate;
export const selectCartItems = (state: RootState) => state.cart.cart_items;
export const selectCartCleanseComplete = (state: RootState) => state.cart.cartCleanseComplete;
export const selectCartCleanseError = (state: RootState) => state.cart.cartCleanseError;
export const selectCartIsLoaded = (state: RootState) => state.cart.cart_loaded;
export const selectCartIsLoading = (state: RootState) => state.cart.cart_loading;
export const selectCartLoadError = (state: RootState) => state.cart.loadError;
export const selectCartUpdateInProgress = (state: RootState) => state.cart.update_in_progress;
export const selectCartZipcode = (state: RootState ) => state.cart.zipcode;
export const selectOrderInProgress = (state: RootState) => state.cart.order_in_progress;
export const selectSetCartComplete = (state: RootState) => state.cart.setCartComplete;
export default cartSlice.reducer;