import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import Window from "../../../utils/Window";
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotifications, selectSelectedDate, setNotifications } from '../core/autoShipSlice';
import { selectCustomer } from '../../../features/customer/customerSlice';
import AutoShipAPI from '../core/autoshipAPI';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
    handleSecondAccordian: () => void;
}

export default function NotificationsPage({ handleSecondAccordian }: Props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const selectedDate = useSelector(selectSelectedDate);
    const [busy, setBusy] = useState(false);
    const [smsNotify, setSMSNotify] = useState(false);
    const [emailNotify, setEmailNotify] = useState(false);
    const custData = useSelector(selectCustomer);
    const notifications = useSelector(selectNotifications);
    const isSelectedPickForMe = location.state && location.state.isSetting;
    const isNotAutoshipSettingPage = location.pathname !== '/autoship/settings';

    const formik = useFormik({
        initialValues: {
            userId: custData.id,
            autoshipEmailNotifications: notifications._autoship_email_notifications,
            autoshipTextMsgNotifications: notifications._autoship_text_msg_notifications
        },
        onSubmit: values => {
            setBusy(true);
            AutoShipAPI.postUserNotifications({
                userId: custData.id,
                autoshipAutoAddWeeks: notifications._auto_add_weeks,
                autoshipEmailNotifications: values.autoshipEmailNotifications,
                autoshipTextMsgNotifications: values.autoshipTextMsgNotifications,
            }).then((response) => {
                if (response.status === "success") {
                    dispatch(setNotifications(response.data));
                }
            }).catch((e) => {
                console.log(e.message);
            }).finally(() => {
                setBusy(false);
                handleSecondAccordian();
            });
        }
    });

    useEffect(() => {
        if (notifications) {
            const smsNotifyValue = notifications._autoship_text_msg_notifications === "1";
            const emailNotifyValue = notifications._autoship_email_notifications === "1";

            setSMSNotify(smsNotifyValue);
            setEmailNotify(emailNotifyValue);

            formik.setValues({
                ...formik.values,
                autoshipEmailNotifications: notifications._autoship_email_notifications,
                autoshipTextMsgNotifications: notifications._autoship_text_msg_notifications
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications]);

    const handleSMSChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            formik.setFieldValue('autoshipTextMsgNotifications', '1');
        } else {
            formik.setFieldValue('autoshipTextMsgNotifications', '');
        }
        setSMSNotify(e.target.checked);
    }

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            formik.setFieldValue('autoshipEmailNotifications', '1');
        } else {
            formik.setFieldValue('autoshipEmailNotifications', '');
        }
        setEmailNotify(e.target.checked);
    }

    return (
        <Form onSubmit={formik.handleSubmit}>
            <div className='d-flex justify-content-center align-items-center'>
                <Row className={`${isNotAutoshipSettingPage ? 'w-80' : Window.isMobile() ? 'w-100' : 'w-50'}`}>
                    <Col className='d-flex justify-content-center align-items-center btn-col'>
                        <span>SMS Notifications : </span>
                        <Form.Check
                            type="switch"
                            checked={smsNotify}
                            value={formik.values.autoshipTextMsgNotifications}
                            onClick={(e: any) => handleSMSChange(e)}
                            className='mx-2 d-flex justify-content-center align-items-center' />
                    </Col>
                    <Col className='d-flex justify-content-center align-items-center btn-col'>
                        <span>Email Notifications : </span>
                        <Form.Check
                            type="switch"
                            checked={emailNotify}
                            value={formik.values.autoshipEmailNotifications}
                            onClick={(e: any) => handleEmailChange(e)}
                            className='mx-2 d-flex justify-content-center align-items-center' />
                    </Col>
                </Row>
            </div>
            <div className='mt-4 d-flex justify-content-end flex-wrap btn-group btn-order-group'>
                <div className='col-md-auto' >
                    <Button
                        className='text-white bg-danger'
                        disabled={busy}
                        onClick={() => navigate('/autoship')}
                    >
                        {isSelectedPickForMe ? 'CANCEL THIS ORDER' : 'CANCEL'}
                    </Button>
                </div>
                {isSelectedPickForMe &&
                    <div className='col-md-auto'>
                        <Button
                            disabled={busy}
                            onClick={() => navigate('/autoship', {
                                state: {
                                    changeDate: true,
                                    date: selectedDate
                                }
                            })}
                        >
                            CHANGE DATE
                        </Button>
                    </div>}
                <div className='col-md-auto' >
                    <Button
                        variant="success"
                        className='text-white bg-success'
                        type="submit"
                        disabled={busy}
                    >
                        {busy ?
                            <>
                                UPDATING... &nbsp;&nbsp;
                                <Spinner
                                    animation="border"
                                    as="span"
                                    size="sm"
                                />
                            </> : 'UPDATE & CONTINUE'}
                    </Button>
                </div>
            </div>
        </Form>
    )
}
