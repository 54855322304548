import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Form } from 'react-bootstrap';
import { selectProductSortFilter, setProductSortFilter } from '../productsSlice';
import { selectMealPlans, selectMealPlansLoaded } from '../../meal_plans/mealPlansSlice';

export default function SortFilter() {
  const dispatch = useDispatch();
  const filterValue = useSelector(selectProductSortFilter);
  const mealPlansLoaded = useSelector(selectMealPlansLoaded);
  const mealPlans = useSelector(selectMealPlans);
  const [favoriteMeals, setFavoriteMeals] = useState(false);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProductSortFilter(e.target.id));
  }

  useEffect(() => {
    setFavoriteMeals((mealPlansLoaded && mealPlans.length > 0) ? true : false);
  }, [mealPlans, mealPlansLoaded])

  return (
    <Dropdown className="sort-filter">
      <Dropdown.Toggle
      className='rounded-2 w-100'
      variant='outline-dark'
      >
        Sort By
      </Dropdown.Toggle>
      <Dropdown.Menu className='px-2'>
        <Form className='fs-6 mx-2'>
          <Form.Check 
            type="radio" 
            name="product-sort" 
            label="None" 
            id="none" 
            checked={filterValue === "none"}
            onChange={handleChange} 
          />
          {favoriteMeals && <Form.Check 
            type="radio" 
            name="product-sort" 
            label="Favorite Meals" 
            id="favorite_meals" 
            checked={filterValue === "favorite_meals"}
            onChange={handleChange} 
          />}
          <Form.Check 
            type="radio" 
            name="product-sort" 
            label="Most Popular" 
            id="most_popular" 
            checked={filterValue === "most_popular"}
            onChange={handleChange} 
          />
          <Form.Check 
            type="radio" 
            name="product-sort" 
            label="Low Calories" 
            id="calories_asc" 
            checked={filterValue === "calories_asc"}
            onChange={handleChange} 
          />
          <Form.Check 
            type="radio" 
            name="product-sort" 
            label="High Calories" 
            id="calories_desc" 
            checked={filterValue === "calories_desc"}
            onChange={handleChange} 
          />
          <Form.Check 
            type="radio" 
            name="product-sort" 
            label="Low Carbs" 
            id="carbs_asc" 
            checked={filterValue === "carbs_asc"}
            onChange={handleChange} 
          />
          <Form.Check 
            type="radio" 
            name="product-sort" 
            label="High Carbs" 
            id="carbs_desc" 
            checked={filterValue === "carbs_desc"}
            onChange={handleChange} 
          />
          <Form.Check 
            type="radio" 
            name="product-sort" 
            label="Low Fat" 
            id="fat_asc" 
            checked={filterValue === "fat_asc"}
            onChange={handleChange} 
          />
          <Form.Check 
            type="radio" 
            name="product-sort" 
            label="High Fat" 
            id="fat_desc" 
            checked={filterValue === "fat_desc"}
            onChange={handleChange} 
          />
          <Form.Check 
            type="radio" 
            name="product-sort" 
            label="Low Protein" 
            id="protein_asc" 
            checked={filterValue === "protein_asc"}
            onChange={handleChange} 
          />
          <Form.Check 
            type="radio" 
            name="product-sort" 
            label="High Protein" 
            id="protein_desc" 
            checked={filterValue === "protein_desc"}
            onChange={handleChange} 
          />
       </Form>
      </Dropdown.Menu>
    </Dropdown>
  );
}