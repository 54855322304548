import { IWorkoutPlan, IWorkoutPlanPost } from "../pages/MightyFit/core/interfaces";

export default class WorkoutPlansAPI {
  static getWorkoutsPlans = async (token: string) => {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/workouts_plans?token=' + token,
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset=UTF-8' }
      }
    ));

    if (!response.ok) {
      console.error('HTTP error while retrieving meal plans. Status:', response.status);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static createWorkoutsPlan = async(token: string, workoutPlan: IWorkoutPlanPost) => {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/workouts_plans',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({ token: token, workouts_plan: JSON.stringify(workoutPlan)})
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while creating meal plan. Status:', response.status);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static updateWorkoutsPlan = async(token: string, workoutPlan: IWorkoutPlan) => {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/workouts_plans/' + workoutPlan.id,
      {
        method: 'PUT',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({ token: token, meal_plan: JSON.stringify(workoutPlan)})
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while updating meal plan. Status:', response.status);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static deleteWorkoutsPlan = async(token: string, workoutPlanId: number) => {
    let response: any = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/workouts_plans/' + workoutPlanId + 
        '?token=' + token,
      {
        method: 'DELETE',
        headers: { 'content-type': 'application/json;charset=UTF-8' }
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while deleting meal plan. Status:', response.status);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
   
  }
}