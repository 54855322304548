import React, { useEffect, useState } from 'react'
import '../core/filters.scss';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { addMightyFitFilter, clearMightyFitFilter, removeMightyFitFilter, selectMightyFit, selectMightyFitFilter } from '../core/filterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { loadWorkouts } from '../core/workoutSlice';

export default function FitFilter() {
    const dispatch = useDispatch();
    const mightyFit = useSelector(selectMightyFit);
    const mightyFilter = useSelector(selectMightyFitFilter);
    const filters = mightyFit[0].filter_data;
    const difficulty = filters.difficulty_level;
    //const bodyFocus = filters.body_focus;
    const trainingTypes = filters.training_types;
    const equipments = filters.equipments;
    //const memberShip = filters.membership_type;

    const [selectedCheckboxes, setSelectedCheckboxes] = useState<any>(mightyFilter);
    const [mounted, setMounted] = useState(false);

    // useEffect(() => {
    //     if(mightyFilter.length){
    //         setMounted(true);
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])
    
    useEffect(() => {
        if (mounted) {
            handleFilterAPIResponse()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted, selectedCheckboxes])

    const handleCheckboxChange = (event: { target: { value: any; checked: any; }; }) => {
        const checkboxValue = event.target.value;
        const isChecked = event.target.checked;
        if (isChecked) {
            setSelectedCheckboxes([...selectedCheckboxes, checkboxValue]);
            dispatch(addMightyFitFilter(checkboxValue))
        } else {
            setSelectedCheckboxes(selectedCheckboxes.filter((value: any) => value !== checkboxValue));
            dispatch(removeMightyFitFilter(checkboxValue))
        }
        setMounted(true);
    };

    const handleRemovetags = (tagValue: string) => {
        setSelectedCheckboxes(selectedCheckboxes.filter((value: string) => value !== tagValue));
        dispatch(removeMightyFitFilter(selectedCheckboxes.filter((value: string) => value !== tagValue)));
        setMounted(true);
    };

    const handleClearAllTags = () => {
        setSelectedCheckboxes([]);
        dispatch(clearMightyFitFilter());
        setMounted(true);
    }

    const handleFilterAPIResponse = async () => {
        const filteredData = {
            //body_focus: filters.body_focus.filter((item: any) => selectedCheckboxes.includes(item.body_focus_key)),
            equipments: filters.equipments.filter((item: any) => selectedCheckboxes.includes(item.slug)),
            difficulty_level: filters.difficulty_level.filter((item: any) => selectedCheckboxes.includes(item.level.toString())),
            training_types: filters.training_types.filter((item: any) => selectedCheckboxes.includes(item.slug)),
            //membership_type: filters.membership_type.filter((item: any) => selectedCheckboxes.includes(item.membership_type_key)),
            trainers: filters.trainers.filter((item: any) => selectedCheckboxes.includes(item.trainer_name.toLowerCase()))
        };

        let url = 'mighty_fits?page=1&';
        for (const [key, value] of Object.entries(filteredData)) {
            if (value.length > 0) {
                url += `${key}=${value.map((item: any) => item.slug || item.body_focus_key || item.level || item.trainer_name.toLowerCase()).join(',')}&`;
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        url = url.slice(0, -1);
        await dispatch(loadWorkouts(url))
        setMounted(false);
    }

    return (
        <div className="wrapfilter">
            <div className="first-filter">

               

                <Row className="filter-row right-filter">
                    <Col sm={3} className="filter-col">
                            <div className="open-filter">
                                <div className="title-box">
                                    <span className="filter-item" >Difficulty</span>
                                </div>
                                <div className="check-div">
                                    <div className="scrollbar" id="style-1">
                                        <ul className="filter-ul force-overflow">
                                            {difficulty.map((item: any, index: number) => (
                                                <li className="checkbox" key={index}>
                                                    <Form.Check
                                                        type="checkbox"
                                                        className="tag-sbmt"
                                                        name="tag[]"
                                                        value={item.level}
                                                        label={item.level + ` (${item.count})`}
                                                        id={item.level}
                                                        onChange={handleCheckboxChange}
                                                        checked={selectedCheckboxes.includes(item.level.toString())}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    <Col sm={5} className="filter-col">
                        <div className="open-filter">
                            <div className="title-box">
                                <span className="filter-item">Training Type</span>
                            </div>
                            <div className="check-div right-ul">
                                <div className={`${trainingTypes.length > 7 ? 'scrollbar' : ''}`} id="style-1">
                                    <ul className="filter-ul force-overflow">
                                        {trainingTypes.map((item: any, index: number) => (
                                            <li className="checkbox" key={index}>
                                                <Form.Check
                                                    type="checkbox"
                                                    className="tag-sbmt"
                                                    name="tag[]"
                                                    value={item.slug}
                                                    label={item.name + ` (${item.count})`}
                                                    id={item.slug}
                                                    onChange={handleCheckboxChange}
                                                    checked={selectedCheckboxes.includes(item.slug)}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4} className="filter-col">
                        <div className="open-filter">
                            <div className="title-box">
                                <span className="filter-item">Equipment</span>
                            </div>
                            <div className="check-div right-ul">
                                <div className={`${equipments.length > 7 ? 'scrollbar' : ''}`} id="style-1">
                                    <ul className="filter-ul force-overflow">
                                        {equipments.map((item: any, index: number) => (
                                            <li className="checkbox" key={index}>
                                                <Form.Check
                                                    type="checkbox"
                                                    className="tag-sbmt"
                                                    name="tag[]"
                                                    value={item.slug}
                                                    label={item.name + ` (${item.count})`}
                                                    id={item.slug}
                                                    onChange={handleCheckboxChange}
                                                    checked={selectedCheckboxes.includes(item.slug)}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="display-checkbox">
                {selectedCheckboxes.length > 0 && (
                    <>
                        <div className="wrapcheck">
                            {selectedCheckboxes.map((value: any, index: any) => (
                                <div className="checkmark" key={index}>
                                    <div className="selected-checkboxes">
                                        <p className='check-text'>{value}</p>
                                    </div>
                                    <FontAwesomeIcon
                                        id={value}
                                        role="button"
                                        className='pe-auto'
                                        icon={faXmark}
                                        size={"xl" as SizeProp}
                                        onClick={() => handleRemovetags(value)}
                                    />
                                </div>))}
                        </div>
                        <Button variant="success" className='btn-clear' onClick={handleClearAllTags}>Clear All</Button>
                    </>)}
            </div>
        </div>
    )
}