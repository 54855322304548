import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Image } from 'react-bootstrap';
import SwiperCore, { Navigation } from 'swiper';
import ProductObj from './productObj';
import '../../components/checkout-form/UpCellsProducts.scss'

SwiperCore.use([Navigation]);

interface Props {
    product: ProductObj;
}

export default function ProductsImagesSlider({ product }: Props) {
    const defaultImageSrc = product?.data?.images[0]?.src;

    const hasNonEmptyImage = product.data.slider_data.some((slide: any) => {
        return slide.choose_image_video === 'image' && slide.add_image.url !== '';
    });

    if (hasNonEmptyImage) {
        return (
            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                autoHeight={true}
                navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                }}
                scrollbar={{ draggable: true }}
            >
                {Object.values(product.data.slider_data).map((slide: any, index: number) => {
                    const mediaUrl = slide.choose_image_video === 'image' ? slide.add_image.url : slide.add_video;

                    return (
                        <SwiperSlide key={index}>
                            <div className="card-part">
                                <div className="card-col">
                                    {mediaUrl && mediaUrl.endsWith('.mp4') ? (
                                        <video controls className='mb-4 mb-sm-0 video-content'>
                                            <source src={mediaUrl} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : (
                                        <Image
                                            alt="product-img"
                                            src={mediaUrl || defaultImageSrc}
                                            className='mb-4 mb-sm-0'
                                        />
                                    )}
                                </div>
                            </div>
                        </SwiperSlide>
                    );
                })}
                <div className="arrow-wrap mb-4">
                    <div className="arrow-left arrow-both swiper-button-prev"></div>
                    <div className="arrow-right arrow-both swiper-button-next"></div>
                </div>
            </Swiper>
        );
    } else {
        return (
            <Image
                alt="product-img"
                src={defaultImageSrc}
                className='mb-4 mb-sm-0'
            />
        );
    }
}
