import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import MealPlanObj from "./mealPlanObj";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { selectCustomer } from '../customer/customerSlice';

interface Props {
  mealPlan: MealPlanObj;
  count: number;
}

export default function SharedMealButton({ mealPlan, count }: Props) {
  const customer = useSelector(selectCustomer);
  const APP_URL = window.location.origin;
  const mealPlanId = mealPlan?.data?.id;
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  },[copied])
  
  const copyToClipboard = (textToCopy: string) => {
    if (navigator.clipboard && window.isSecureContext) {
        return navigator.clipboard.writeText(textToCopy);
    } else {
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise<void>((res, rej) => {
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
        });
    }
}

  return (
    <>
      <Button
        variant='dark'
        size='sm'
        className="ms-1"
        data-text={`${APP_URL}/sharedmeals/${mealPlanId}/${customer?.email}`}
        onClick={(e: any) => {
          copyToClipboard(e.currentTarget.getAttribute('data-text'));
          setCopied(true);
        }}
        disabled={count === 0 ? true : false}
      >
        {copied ? 
          <>
            <span className='pe-1'>Copied </span>
            <FontAwesomeIcon role="button" icon={faCopy} size={'1x' as SizeProp}/>
          </> : 
          <>
            <span className='pe-1'>Share</span>
            <svg width="14" height="16" viewBox="0 0 18 20" fill="none" >
              <path fillRule="evenodd" clipRule="evenodd" d="M15 6C16.6569 6 18 4.65685 18 3C18 1.34315 16.6569 0 15 0C13.3431 0 12 1.34315 12 3C12 3.19664 12.0189 3.38887 12.055 3.57495C12.0043 3.59429 11.9544 3.61803 11.906 3.64631L5.08598 7.62631C5.03762 7.65453 4.99251 7.68619 4.95077 7.72078C4.42626 7.27144 3.74483 7 3 7C1.34315 7 0 8.34315 0 10C0 11.6569 1.34315 13 3 13C3.74483 13 4.42626 12.7286 4.95077 12.2792C4.99267 12.3139 5.03796 12.3457 5.08653 12.374L11.9165 16.354C11.9618 16.3804 12.0084 16.4029 12.0557 16.4214C12.0192 16.6087 12 16.8021 12 17C12 18.6569 13.3431 20 15 20C16.6569 20 18 18.6569 18 17C18 15.3431 16.6569 14 15 14C14.2575 14 13.578 14.2697 13.0541 14.7166C13.0137 14.6835 12.9701 14.6531 12.9235 14.626L6.09348 10.646C6.0452 10.6179 5.99553 10.5942 5.94496 10.575C5.98108 10.3889 6 10.1966 6 10C6 9.80336 5.98108 9.61113 5.94496 9.42505C5.99572 9.40571 6.04558 9.38196 6.09404 9.35368L12.914 5.37368C12.9624 5.34546 13.0075 5.31381 13.0492 5.27922C13.5737 5.72856 14.2552 6 15 6Z" fill="white"/>
            </svg>
          </>
        }
      </Button>
    </>
  );
}