export default class MightyFitAPI {
    static async getFilters() {
      let response = await(fetch(
        process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/mighty_filter',
        {
          method: 'GET',
          headers: { 'content-type': 'application/json;charset=UTF-8' }
        }
      ));
      if (!response.ok) {
        throw new Error(`HTTP error. status: ${response.status}`);
      }
      return await response.json();
    }
    static getWorkoutBySlug = async (slug: string) => {
      let response = await(fetch(
        process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/' + slug,
        {
          method: 'GET',
          headers: { 'content-type': 'application/json;charset=UTF-8' }
        }
      ));
      if (!response.ok) {
        throw new Error(`HTTP error. status: ${response.status}`);
      }
      return await response.json();
    }
    static async getworkouts(page=1) {
      let response = await(fetch(
        process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/mighty_fits?page=' + page,
        {
          method: 'GET',
          headers: { 'content-type': 'application/json;charset=UTF-8' }
        }
      ));
      if (!response.ok) {
        throw new Error(`HTTP error. status: ${response.status}`);
      }
      
      return await response.json();
    }
    static async getworkoutsDetails(id: number) {
      let response = await(fetch(
        process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/mighty_fits/' + id,
        {
          method: 'GET',
          headers: { 'content-type': 'application/json;charset=UTF-8' }
        }
      ));
      if (!response.ok) {
        throw new Error(`HTTP error. status: ${response.status}`);
      }
      
      return await response.json();
    }
    static async getVideoToken() {
      let response = await(fetch(
        'https://api.fod247.io/flex/v1/auth/token',
        {
          method: 'POST',
          headers: { 'content-type': 'application/json;charset=UTF-8' },
          body: JSON.stringify({ 
            email: "stefano@eatmightymeals.com", 
            clientId: "076891c7-030e-4480-aac7-1d7ff00a9840", 
            clientSecret: "8b082c51-32dd-4d0e-88d9-cf1888eec612"})
        }
      ));
      if (!response.ok) {
        console.error('HTTP error while creating dietary plan. Status:', response.status);
        throw new Error(`HTTP error. status: ${response.status}`);
      }
      return await response.json();
    }
    static async getAllYouTubeVideos(bearerToken: string) {
      let response = await(fetch(
        'https://api.fod247.io/flex/v1/classes/list',
        {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + bearerToken,
            'cache-control': 'no-cache'
          },
        }
      ));
      if (!response.ok) {
        console.error('HTTP error while creating dietary plan. Status:', response.status);
        throw new Error(`HTTP error. status: ${response.status}`);
      }
      return await response.json();
    }
    static async playYouTubeVideo(id: number, bearerToken: string) {
      let response = await(fetch(
        'https://api.fod247.io/flex/v1/classes/play',
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + bearerToken,
            'cache-control': 'no-cache'
          },
          body: JSON.stringify({ 
            videoId: id, 
            startTime: new Date().toISOString()})
        }
      ));
      if (!response.ok) {
        console.error('HTTP error while creating dietary plan. Status:', response.status);
        throw new Error(`HTTP error. status: ${response.status}`);
      }
      return await response.json();
    }
    static async getUsersAuthToken() {
      let response = await(fetch(
        'https://api.fod247.io/management/v1/auth/token',
        {
          method: 'POST',
          headers: { 'content-type': 'application/json;charset=UTF-8' },
          body: JSON.stringify({
            clientId: "076891c7-030e-4480-aac7-1d7ff00a9840", 
            clientSecret: "8b082c51-32dd-4d0e-88d9-cf1888eec612"})
        }
      ));
      if (!response.ok) {
        console.error('HTTP error while creating dietary plan. Status:', response.status);
        throw new Error(`HTTP error. status: ${response.status}`);
      }
      return await response.json();
    }
    static async getUsersFITUsers(bearerToken: string) {
      let response = await(fetch(
        'https://api.fod247.io/management/v1/flex/users/list',
        {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + bearerToken,
            'cache-control': 'no-cache'
          },
        }
      ));
      if (!response.ok) {
        console.error('HTTP error while creating dietary plan. Status:', response.status);
        throw new Error(`HTTP error. status: ${response.status}`);
      }
      return await response.json();
    }
    static async postUserInvitation(email: string, bearerToken: string) {
      let response = await(fetch(
        'https://api.fod247.io/management/v2/flex/users/invite',
        {
          method: 'POST',
          headers: { 
            'content-type': 'application/json;charset=UTF-8', 
            'Authorization': 'Bearer ' + bearerToken,
            'Accept': 'application/json' },
          body: JSON.stringify([{
            email: email,
            sendEmail: false}])
        }
      ));
      if (!response.ok) {
        console.error('HTTP error while creating dietary plan. Status:', response.status);
        throw new Error(`HTTP error. status: ${response.status}`);
      }
      return await response.json();
    }
  }