import { RootState } from '../../app/store';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserAPI from '../../API/userAPI';

export const getSession = createAsyncThunk(
  'user/getSession',
  async () => {
    let token;
    try {
      const localToken = localStorage.getItem('token');
      const setLoginInfo = localStorage.getItem('setAuthInfo');
      const logInfo = setLoginInfo && JSON.parse(setLoginInfo);
      const userToken = localToken ? localToken : logInfo.token;
      token = userToken;
    } catch (e) {
      console.error("Error while getting token from local storage:", e);
      return {};
    }
    if (token) {
      const response = await UserAPI.getUserSession(token);
      if (Object.keys(response).length === 0) {
        localStorage.removeItem('token');
        localStorage.removeItem('setAuthInfo');
      }
      return response;
    }

    return {};
  }
);

export const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async (token: string) => {
    try {
      localStorage.removeItem('token');
      localStorage.removeItem('setAuthInfo');
    } catch (e) {
      console.error("Error while removing token from local storage:", e);
    }
    await UserAPI.logoutUser(token);
  }
);

export interface UserState {
  token: string;
  email: string;
  nicename: string;
  display_name: string;
  sessionLoaded: boolean;
  sessionLoading: boolean;
  sessionLoadError: boolean;
  loggingOut: boolean;
  access_token?: string
}

const initialState: UserState = {
  token: '',
  email: '',
  nicename: '',
  display_name: '',
  sessionLoaded: false,
  sessionLoading: false,
  sessionLoadError: false,
  loggingOut: false,
  access_token: ''
}

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setAuthInfo: (state, action) => {
      return {
        ...state, ...action.payload
      }
    },
    setEmail: (state, action) => {
      return {
        ...state,
        email: action.payload
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(logoutUser.pending, (state, action) => {
      return {
        ...initialState,
        loggingOut: true
      }
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      return {
        ...initialState,
        loggingOut: false
      }
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      return {
        ...initialState,
        loggingOut: false
      }
    });
    builder.addCase(getSession.pending, (state, action) => {
      state.sessionLoading = true;
    });
    builder.addCase(getSession.fulfilled, (state, action) => {
      return {
        ...state, ...action.payload,
        sessionLoaded: true,
        sessionLoading: false
      }
    });
    builder.addCase(getSession.rejected, (state, action) => {
      state.sessionLoading = false;
      state.sessionLoadError = true;
    });
  }
});
export const { setAuthInfo, setEmail } = userSlice.actions;
export const selectLoggingOut = (state: RootState) => state.user.loggingOut;
export const selectUser = (state: RootState) => state.user;
export const selectSessionIsLoaded = (state: RootState) => state.user.sessionLoaded;
export const selectSessionIsLoading = (state: RootState) => state.user.sessionLoading;
export const selectSessionLoadError = (state: RootState) => state.user.sessionLoadError;
export const selectToken = (state: RootState) => state.user.token;
export default userSlice.reducer;