import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row, Spinner, Image } from 'react-bootstrap';
import { loadOrders, selectOrders } from '../../../features/orders/ordersSlice';
import OrderObj from '../../../features/orders/orderObj';
import { setNotifications, setPreviousDate, setSelectedDate, setSelectedZipCode, setTurnOnAutoship } from '../core/autoShipSlice';
import { selectCustomer } from '../../../features/customer/customerSlice';
import { useEffect, useMemo, useState } from 'react';
import { setIsLoadMore } from '../../../features/mobile/mobileSlice';
import { selectToken } from '../../../features/user/userSlice';
import CustomerObj from '../../../features/customer/customerObj';
import AutoShipAPI from '../core/autoshipAPI';
import Window from '../../../utils/Window';

export default function AutoshipOrderListMobile() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = useSelector(selectToken);
    const [turningOn, setTurningON] = useState(false);
    const custData = useSelector(selectCustomer);
    const customer = useMemo(() => {
        return new CustomerObj(custData);
    }, [custData]);
    const orders: Array<Record<string, any>> = useSelector(selectOrders);
    const order = OrderObj.findRecentOrder(orders);
    const lastOrder = orders.at(-1);
    const latestOrder = lastOrder && new OrderObj(lastOrder);
    const lastOrderedDate = latestOrder && latestOrder.getDeliveryDateTime();
    const autoshipOrders = orders.filter((order, index, self) => (order.status === "autoship" ||
        order.status === "mmfailpayment") && index === self.findIndex((o) => o.id === order.id));
    const zip = custData?.shipping?.postcode ? custData?.shipping?.postcode : custData?.billing?.postcode;
    const orderDate = order?.getDeliveryDateTime();
    const OrderPlannerImage = Window.isMobile() ?
        'https://eatmightymeals.com/wp-content/uploads/2024/07/order-planner-banner-mobile.jpg' :
        'https://eatmightymeals.com/wp-content/uploads/2024/07/order-planner-banner-desktop.jpg';
    const zipCode = order?.data.shipping.postcode ? order?.data.shipping.postcode :
        order?.data.billing.postcode ? order?.data.billing.postcode : zip;
    const ClickFromNav = location && location.state && location.state.clickFromNav === true;

    useEffect(() => {
        if (autoshipOrders.length > 0 && ClickFromNav) {
            navigate('/autoship');
        }
    }, [ClickFromNav, autoshipOrders, navigate])

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(setSelectedDate(null));
                if (token && customer.data.id) {
                    await dispatch(loadOrders({ token, isAutoship: true, customerId: customer.data.id }));
                    await dispatch(setIsLoadMore(false));
                }
            } catch (e: any) {
                console.log("Error while listing Orders: ", e);
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, customer.data.id]);

    const handleTurnOnAutoship = async () => {
        setTurningON(true);
        try {
            await AutoShipAPI.postUserNotifications({
                userId: custData.id,
                autoshipAutoAddWeeks: '1',
                autoshipEmailNotifications: '1',
                autoshipTextMsgNotifications: '1',
            }).then((response) => {
                if (response.status === "success") {
                    dispatch(setNotifications(response.data));
                }
            });
        } catch (e: any) {
            console.log(e, "Error while Turning on Autoship");
        } finally {
            dispatch(setTurnOnAutoship(true));
            dispatch(setPreviousDate(orderDate));
            dispatch(setSelectedDate(orderDate));
            dispatch(setSelectedZipCode(zipCode));
            navigate('/autoship');
            setTurningON(false);
        }
    }

    return (
        <div className='my-orders-mobile my-5'>
            {autoshipOrders.length > 0 &&
                <div className='d-flex justify-content-center align-items-center'>
                    <Button
                        onClick={() => {
                            dispatch(setPreviousDate(lastOrderedDate));
                            dispatch(setSelectedDate(lastOrderedDate));
                            dispatch(setSelectedZipCode(zip));
                            navigate('/autoship');
                        }}
                        size="sm"
                        variant="success"
                        className='mx-3 w-100 text-white mb-3'
                    >
                        View Order Planner Calendar
                    </Button>
                </div>}
            {autoshipOrders.length <= 0
                ? <Card body className='my-1'>
                    <div className='autoShip'>
                        {turningOn ?
                            <div className="loader">
                                <Spinner
                                    variant="dark"
                                    animation="border"
                                    as="span"
                                    size='sm'
                                    className='spinner-xl my-25px'
                                />
                            </div> :
                            <Image
                                src={OrderPlannerImage}
                                className='cursor-pointer'
                                alt='orderPlanner'
                                onClick={handleTurnOnAutoship} />}
                    </div>
                </Card>
                : <>
                    {autoshipOrders.map((orderData) => {
                        const order = new OrderObj(orderData);
                        return (
                            <Card body className='my-1' key={order.data.id}>
                                <Row className='my-1'>
                                    <Col xs={6} className='fw-bold'>Order</Col>
                                    <Col xs={6}>
                                        <Button
                                            as={Link as any}
                                            to={'/autoship/orders/' + order.data.id}
                                            variant="link"
                                            order={order}
                                            className='text-black p-0'
                                        >#{order.data.id}</Button>
                                    </Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col xs={6} className='fw-bold'>Date</Col>
                                    <Col xs={6}>
                                        {order.getDate()}
                                    </Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col xs={6} className='fw-bold'>Delivery Date</Col>
                                    <Col xs={6}>{order.getDeliveryDate()}</Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col xs={6} className='fw-bold'>Total</Col>
                                    <Col xs={6}>
                                        <span className='fw-bold'>${order.data.total}</span> for&nbsp;
                                        {order.getTotalItems()} items
                                    </Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col xs={6} className='my-1 fw-bold'>Actions</Col>
                                    <Col xs={6} className='vstack gap-1'>
                                        <Link to={'/autoship/orders/' + order.data.id}>
                                            <Button
                                                size="sm"
                                                variant="success"
                                                className='text-white mt-0 my-2 w-100'
                                            >
                                                View
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </Card>
                        )
                    })}
                </>}
        </div>
    );
}