import { Link } from "react-router-dom"
import { Accordion, Card } from "react-bootstrap"
import './myAccountFAQs.css';

export default function MyAccountFAQs() {
  return (
    <div id='my-account-faqs' className='my-account-faqs my-4'>
      <div className='faq-header text-center mb-4'>Frequently Asked Questions</div>
      <Card>
        <Accordion flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>What time will my order be delivered?</Accordion.Header>
            <Accordion.Body>
              At 7 AM EST on your delivery date, you will receive a text message with the 
              times for your 2 hour delivery window. You will also receive a text message 
              when your driver is 10-15 minutes away. Check out our Delivery Time 
              Frame / Live Update SMS notification system for more details about our 
              home delivery service. 
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              What happens if I won’t be home during delivery time, between 7 AM and 9 PM?
            </Accordion.Header>
            <Accordion.Body>
              Please set out a cooler filled with ice, and your delivery driver will 
              happily place your meals inside to keep them cold and fresh until you arrive 
              home! If you know you won’t be home when you place your order, please add 
              instructions in the notes. 
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Can I change my delivery date?</Accordion.Header>
            <Accordion.Body>
              To adjust your delivery date, go 
              to <Link className='text-black' to="/my-account/orders">My Account &gt; Orders</Link>. If the order 
              deadline for that date has not passed, you'll have access to the “Change 
              Delivery Date” action button where you can change your date. 
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              Can I cancel or get a refund once my order is placed?
            </Accordion.Header>
            <Accordion.Body>
              Due to the nature of our service, cancellations and refunds can not be 
              offered once your meal order has been submitted. 
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Can I add or delete items from my order, after it’s been placed?
            </Accordion.Header>
            <Accordion.Body>
              Unfortunately, we are unable to add or remove items after they have been ordered. 
              However, if you want to add items you can place another order and schedule it 
              on the same delivery date. Then, send us an email at&nbsp;
              <a href="mailto:info@eatmightymeals.com" target="_blank" rel="noreferrer" 
                className='text-black'>info@eatmightymeals.com</a> after your 
              new order has been placed, and we’ll be happy to credit your account for the 
              additional delivery fee! 
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              How do I report an issue with my meals or delivery?
            </Accordion.Header>
            <Accordion.Body>
              Contact our Customer Support Team at info@eatmightymeals.com or 833-40-MEALS 
              (833-406-3257). Mon/Thurs: 9 AM - 11 PM EST and Tues/Wed/Fri/Sat/Sun: 
              9 AM - 9 PM EST.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card>
    </div>
  )
}
