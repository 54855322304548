import { useLocation } from "react-router-dom";
import ElementorPage from "./ElementorPage";
import { useEffect } from "react";

export default function RewardsProgramPage() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/rewards-program' && location.search === "") {
      const cssUrl = `${process.env.REACT_APP_BLOG_URL}/wp-content/uploads/elementor/css/post-631467.css`;
      const cssUrlWithCacheBust = `${cssUrl}?v=${new Date().getTime()}`;

      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = cssUrlWithCacheBust;

      document.head.appendChild(link);
      return () => {
        document.head.removeChild(link);
      };
    }
    else {
      const link = document.querySelector('link[href*="post-631467.css"]');
      if (link) {
        document.head.removeChild(link);
      }
    }
  }, [location.pathname, location.search]);
  
  return (
    <ElementorPage pageNo={631467} />
  )
}