/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Image, Row } from "react-bootstrap";
import PlusMinusInput from "../../components/PlusMinusInput";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import ProductDetailModal from './ProductDetailModal';
import ProductObj from './productObj';
import CustomerObj from '../customer/customerObj';
import DietaryIcons from "./DietaryIcons";
import AddToMealPlan from "../meal_plans/AddToMealPlan";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { selectProducts, setProductTypeFilter } from './productsSlice';
import { selectIsMobileRoute } from '../mobile/mobileSlice';
import Window from '../../utils/Window';
import { selectToken } from '../user/userSlice';
import { selectCartCoupon, selectCartItems, setCartItem } from '../cart/cartSlice';
import CartObj from '../cart/cartObj';
import CouponObj from '../coupons/couponObj';

interface Props {
  product: ProductObj;
  customer: CustomerObj;
  quantity: number;
  onQtyChange: (qty: number) => void;
  onPriceChange: (price: number) => string;
  cartUpdateInProgress: boolean;
  orderInProgress: boolean;
  showModal: boolean;
}
export default function Product({
  product,
  customer,
  quantity,
  onQtyChange,
  onPriceChange,
  cartUpdateInProgress,
  orderInProgress,
  showModal
}: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const products = useSelector(selectProducts);
  const cart = new CartObj(useSelector(selectCartItems));
  const [showDetail, setShowDetail] = useState(showModal);
  const [showCrossSells, setShowCrossSells] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductObj | null>(null);
  const productSlug = useParams().productSlug;
  const AppURL = useSelector(selectIsMobileRoute);
  const coupon = CouponObj.createCoupon(useSelector(selectCartCoupon));

  useEffect(() => {
    if (location.pathname.includes('/product/') && productSlug === product.data.slug && !showDetail) {
      setShowDetail(true);
    }
  }, [location.pathname, product.data.slug, productSlug, showDetail])

  const handleHide = async () => {
    setShowDetail(false);
    setShowCrossSells(false);
    if (location.pathname.includes('/product/')) {
      await navigate(`/order${AppURL}`);
    }
    if (productSlug) {
      await dispatch(setProductTypeFilter('all'));
    }
  }

  const handleAddCrossSellsProducts = (crossSells: any) => {
    const crossProductCart = Object.values(cart.items).filter((productCart: any) => Number(productCart.product_id) === Number(crossSells.data.id));
    const crossQTY = crossProductCart.length > 0 ? (crossProductCart[0].product_qty + 1) : 1;
    dispatch(setCartItem({
      token: token,
      cart_item: {
        product_id: crossSells.data.id,
        product_qty: crossQTY,
        product_price: customer.getProductPrice(crossSells) as number
      }
    }));
  }

  return (
    <div className="product position-relative border border-black p-2 h-100">
      {product.isNew() && (
        <div className="new-badge position-absolute top-0">
          <Badge className="bg-black">New</Badge>
        </div>
      )}
      {showCrossSells && selectedProduct && (
        <ProductDetailModal
          product={selectedProduct}
          show={showCrossSells}
          onHide={() => setShowCrossSells(false)}
          onQtyChange={onQtyChange}
          quantity={quantity}
        />
      )}
      <ProductDetailModal
        product={product}
        show={showDetail}
        onHide={() => handleHide()}
        onQtyChange={onQtyChange}
        quantity={quantity}
      />
      <Card className="border border-0 position-static">
        <div className="card-image">
          <Image
            alt="product-img"
            thumbnail={true}
            role="button"
            src={product?.data?.images[0]?.src}
            onClick={() => setShowDetail(true)}
            className="border-0"
            loading="lazy"
          />
        </div>
        <Card.Title className="product-title text-center text-black">
          {product.data.name}
        </Card.Title>
        <DietaryIcons product={product} className="text-center mb-1" />
        <div className="macro-info text-black my-1 mx-auto">
          {(product.getCalories() ||
            product.getProtein() ||
            product.getFat()) && (
              <Row className="justify-content-md-center text-center">
                <Col xs={6} className="col-md-auto px-md-1">Cal: {product.getCalories()}</Col>
                <Col xs={6} className="col-md-auto px-md-1">P: {product.getProtein()}</Col>
                <Col xs={6} className="col-md-auto px-md-1">C: {product.getCarbs()}</Col>
                <Col xs={6} className="col-md-auto px-md-1">F: {product.getFat()}</Col>
              </Row>
            )}
        </div>
        <Row className="justify-content-center">
          <Col xs={9} md={6}>
            <PlusMinusInput
              onChange={onQtyChange}
              value={quantity}
              disabled={orderInProgress || cartUpdateInProgress}
              className="justify-content-center"
            />
          </Col>
          {product?.data?.cross_sell_ids?.length > 0 &&
            ([product?.data?.cross_sell_ids[0]].map((id: number) => {
              const crossSellsProducts = ProductObj.getById(products, id);
              return (
                <Col xs={12} md={12}>
                  <div className='px-0 py-0 mt-3 border border-black crosssells'>
                    <Row className='align-items-center py-1 px-1'>
                      <Col xs={2} className='plus-minus-input'>
                        <Button
                          size="lg"
                          variant='light'
                          onClick={() => handleAddCrossSellsProducts(crossSellsProducts)}
                          disabled={orderInProgress || cartUpdateInProgress}
                        >+</Button>
                      </Col>
                      <Col xs={6} className='font-glegoo'>
                        <span className='fw-700 fs-13px'>{crossSellsProducts?.data.name}</span><br />
                        <span className='fs-12px'> + ${crossSellsProducts?.data.price}</span>
                      </Col>
                      <Col xs={3} className="d-flex justify-content-end img-wrap">
                        <Image src={crossSellsProducts?.data?.images[0]?.src} alt="" />
                        <div
                          className={` d-flex align-items-center show-product-detail-btn text-black rounded-circle cursor-pointer`}
                          onClick={() => {
                            setSelectedProduct(crossSellsProducts);
                            setShowCrossSells(true)
                          }}
                        >
                          <FontAwesomeIcon icon={faCircleInfo} size="lg" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              )
            }))
          }
        </Row>
        {!Window.isMobile() && <div className='pt-4'>
          {product.hasCategory('bulk') && product.getServingsPerContainer() && (
            <div className='text-start perServing'>
              ${(Number(customer.getProductPrice(product, false)) / Number(product.getServingsPerContainer())).toFixed(2)}
              <span className='fs-12px'>&nbsp;per serving</span>
            </div>
          )
          }
        </div>}
        <div className="d-flex flex-wrap justify-content-between align-items-center p-2 card-bootom">
          <div className="col-12 col-md-4 fw-semibold text-black text-center text-md-start pb-2 pb-md-0">
            {!customer.hasMembership() && (
              <span>{(coupon && coupon.getEarnedPoints()) ?
                (product.getPoints() * (coupon?.getEarnedPoints() / 100))
                : product.getPoints()} Pts</span>
            )}
          </div>
          <div className="col-6 col-md-4 d-flex justify-content-center align-items-center">
            <div
              className={`show-product-detail-btn text-black rounded-circle ${location.pathname !== '/checkout' ? 'me-2' : ''} cursor-pointer`}
              onClick={() => setShowDetail(true)}
            >
              <FontAwesomeIcon icon={faCircleInfo} size="lg" />
            </div>
            {location.pathname !== '/checkout' &&
              <div className="favorite d-flex align-items-center">
                <AddToMealPlan product={product} />
              </div>}
          </div>
          <div className="col-6 col-md-4 text-black text-end d-flex justify-content-end flex-column">
            {customer.hasMembership() && (
              <del>${product.data.price}</del>
            )}
            {!customer.hasMembership() && product.data.sale_price !== "" && (
              <del>${product.data.regular_price}</del>
            )}
            <div>
              {customer.getProductPrice(product, true)}
            </div>
          </div>
        </div>
        {Window.isMobile() && <div className='d-flex justify-content-center align-items-center'>
          {product.hasCategory('bulk') && product.getServingsPerContainer() && (
            <div className='text-start perServing'>
              ${(Number(customer.getProductPrice(product, false)) / Number(product.getServingsPerContainer())).toFixed(2)}
              <span className='fs-12px'>&nbsp;per serving</span>
            </div>
          )
          }
        </div>}
      </Card>
    </div>
  );
}