import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from '../../app/store';
import AffiliateAPI from "../../API/affiliateAPI";
import { IVisitPost } from "./interfaces";
import MediaAPI from "../../API/mediaAPI";
import { setVisitorID } from "../mobile/mobileSlice";

export const loadAffiliate = createAsyncThunk(
  'referrals/loadAffiliate',
  async (slug: string) => {
    let result = await AffiliateAPI.getAffiliate(slug);
    if ('code' in result) {
      console.error("Error retrieving affiliate:", result.code, result.message);
      throw new Error("Unable to retrieve affiliate.");
    }
    return result;
  }
);  

export const loadLogoUrl = createAsyncThunk(
  'referrals/loadLogoUrl',
  async(slug: string) => {
    return await MediaAPI.getAffiliateSRC(slug);
  }
)

// export const loadLogoUrl = createAsyncThunk(
//   'referrals/loadLogoUrl',
//   async(logoId: number) => {
//     return await MediaAPI.getSrc(logoId);
//   }
// )

export const postVisit = createAsyncThunk(
  'referrals/postVisit',
  async (data: IVisitPost, thunkAPI) => {

    try {
      let result = await AffiliateAPI.postVisit(data);

      if ('code' in result) {
        console.error("Error while posting affiliate visit:", result.code, result.message);
        throw new Error(`Unable to post affiliate visit. Code: ${result.code}`);
      }

      thunkAPI.dispatch(setVisitorID(`${result.visit_id}`));
      return result;
    } catch (error) {
      console.error("Error while posting affiliate visit:", error);
      throw error;
    }
  }
);

interface ReferralState {
  affiliate: Record<string, any>;
  affiliateLoading: boolean;
  affiliateLoaded: boolean;
  affiliateLoadError: boolean;
  visit: Record<string, any>;
  postingVisit: boolean;
  visitPosted: boolean;
  visitPostError: boolean;
  embedded: boolean;
  logoUrl: string;
  loadingLogoUrl: boolean;
  logoUrlLoadError: boolean;
}

const initialState: ReferralState = {
  affiliate: {},
  affiliateLoading: false,
  affiliateLoaded: false,
  affiliateLoadError: false,
  visit: {},
  postingVisit: false,
  visitPosted: false,
  visitPostError: false,
  embedded: false,
  logoUrl: '',
  loadingLogoUrl: false,
  logoUrlLoadError: false
}

export const referralsSlice = createSlice({
  name: 'referrals',
  initialState: initialState,
  reducers: {
    setEmbedded: (state, action) => {
      state.embedded = action.payload;
    },
    resetReferrals: (state) => {
      return initialState;
    }
  },
  extraReducers: builder => {
    builder.addCase(loadAffiliate.pending, (state, action) => {
      state.affiliateLoading = true;
    });
    builder.addCase(loadAffiliate.fulfilled, (state, action) => {
      return {
        ...state, 
        affiliateLoading: false,
        affiliateLoaded: true,
        affiliate: { ...action.payload }
      }
    });
    builder.addCase(loadAffiliate.rejected, (state, action) => {
      state.affiliateLoading = false;
      state.affiliateLoadError = true;
    });
    builder.addCase(loadLogoUrl.pending, (state, action) => {
      state.loadingLogoUrl = true;
    });
    builder.addCase(loadLogoUrl.fulfilled, (state, action) => {
      state.loadingLogoUrl = false;
      state.logoUrl = action.payload;
    });
    builder.addCase(loadLogoUrl.rejected, (state, action) => {
      state.logoUrlLoadError = true;
      state.loadingLogoUrl = false;
    })
    builder.addCase(postVisit.pending, (state, action) => {
      state.postingVisit = true;
    });
    builder.addCase(postVisit.fulfilled, (state, action) => {
      return {
        ...state,
        postingVisit: false,
        visitPosted: true,
        visit: { ...action.payload }
      }
    });
    builder.addCase(postVisit.rejected, (state, action) => {
      state.postingVisit = false;
      state.visitPostError = true;
    })
  }
});

export const { resetReferrals, setEmbedded } = referralsSlice.actions;
export const selectAffiliate = (state: RootState) => state.referrals.affiliate;
export const selectAffiliateLoading = (state: RootState) => state.referrals.affiliateLoading;
export const selectAffiliateLoaded = (state: RootState) => state.referrals.affiliateLoaded;
export const selectAffiliateLoadError = (state: RootState) => state.referrals.affiliateLoadError;
export const selectEmbedded = (state: RootState) => state.referrals.embedded;
export const selectLoadingLogoUrl = (state: RootState) => state.referrals.loadingLogoUrl;
export const selectLogoUrl = (state: RootState) => state.referrals.logoUrl;
export const selectLogoUrlLoadError = (state: RootState) => state.referrals.logoUrlLoadError;
export const selectVisit = (state: RootState) => state.referrals.visit;
export const selectPostingVisit = (state: RootState) => state.referrals.postingVisit;
export const selectVisitPosted = (state: RootState) => state.referrals.visitPosted;
export const selectVisitPostError = (state: RootState) => state.referrals.visitPostError;

export default referralsSlice.reducer;