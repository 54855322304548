import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormikErrors, useFormik } from 'formik';
import { Button, Form, Spinner } from "react-bootstrap";
import { getCouponByCode } from '../../API/coupons';
import { useDispatch } from 'react-redux';
import { setCartCoupon, setCartItem, setOrderInProgress } from '../cart/cartSlice';
import { selectToken } from '../user/userSlice';
import { selectCartItems } from '../cart/cartSlice';
import CouponObj from './couponObj';
import CartObj from '../cart/cartObj';
import { selectCustomer, selectCustomerEmail } from '../customer/customerSlice';
import CustomerObj from '../customer/customerObj';
import Window from '../../utils/Window';
import { selectProducts, selectProductsPagesLoaded, selectProductsTotalPages } from '../products/productsSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectIsMobileRoute } from '../mobile/mobileSlice';
import { setIsOrderChanged } from '../../pages/AutoshipPages/core/autoShipSlice';
import { selectOrders, updateOrder } from '../orders/ordersSlice';
import OrdersAPI from '../../API/ordersAPI';
import { loadAffiliate, loadLogoUrl, selectAffiliate, selectAffiliateLoaded, selectLoadingLogoUrl, setEmbedded } from '../referrals/referralsSlice';
import AffiliateObj from '../referrals/AffiliateObj';
import ProductObj from '../products/productObj';

interface FormValues {
  couponCode: string;
}

interface Props {
  orderId?: string;
  isAutoshipOrder?: boolean;
  latestOrder?: any;
  mealCountDiscount?: string;
}

export default function CouponForm({ orderId, mealCountDiscount, isAutoshipOrder, latestOrder }: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const orders = useSelector(selectOrders);
  const email = useSelector(selectCustomerEmail);
  const productPagesLoaded: number = useSelector(selectProductsPagesLoaded);
  const totalProductPages = useSelector(selectProductsTotalPages);
  const affiliateLoaded = useSelector(selectAffiliateLoaded);
  const affiliate = useSelector(selectAffiliate);
  const affiliateLogoUrlLoading = useSelector(selectLoadingLogoUrl);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const customer = new CustomerObj(useSelector(selectCustomer));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cart = new CartObj(useSelector(selectCartItems));
  const products = useSelector(selectProducts);
  const [errorMsg, setErrorMsg] = useState('');
  const [busy, setBusy] = useState(false);
  const AppURL = useSelector(selectIsMobileRoute);
  const placeholder = Window.isMobile() ? 'Enter Promo Code' : 'Enter Gift Card or Discount';
  const queryParams = new URLSearchParams(location.search);
  const couponCodeQuery = queryParams.get("coupon-code");
  const productCodeQuery = queryParams.get("product_id");
  const [couponCode, setCouponCode] = useState<any>('');
  const isMobileDevice = location.search.includes('?utm_source=app');

  useEffect(() => {
    if (couponCodeQuery?.length && (couponCodeQuery !== "" || couponCodeQuery !== null)) {
      formik.setFieldValue("couponCode", couponCodeQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponCodeQuery])

  useEffect(() => {
    const applyCouponCode = (code: any) => {
      setErrorMsg('');
      setBusy(true);
      getCouponCodeFromQuery(code);
    };

    if (!productCodeQuery) {
      if (couponCode.length) {
        let coupon = new CouponObj(couponCode[0]);
        applyCouponCode(coupon?.data?.code);
      } else if (mealCountDiscount) {
        applyCouponCode(mealCountDiscount);
      } else if (couponCodeQuery?.length && (couponCodeQuery !== "" || couponCodeQuery !== null)) {
        applyCouponCode(couponCodeQuery);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.items, mealCountDiscount, couponCodeQuery, totalProductPages, productPagesLoaded, productCodeQuery]);

  useEffect(() => {
    if (affiliateLoaded && !affiliateLogoUrlLoading && couponCodeQuery) {
      const fetchData = async () => {
        const affiliateData = new AffiliateObj(affiliate);

        await dispatch(loadLogoUrl(affiliateData.getCustomSlug()));
        if (affiliateData.getCustomSlug().length > 0) {
          dispatch(setEmbedded(true));
          navigate(`${location.pathname}${AppURL}`);
        }
      }
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliate, affiliateLoaded, affiliateLogoUrlLoading, couponCodeQuery, dispatch]);

  const validate = (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {};
    if (!values.couponCode) {
      errors.couponCode = "Required";
    } else if (!/^\S+$/.test(values.couponCode)) {
      errors.couponCode = "Invalid";
    }
    return errors;
  }

  const getCouponCodeFromQuery = (couponCode: any) => {
    getCouponByCode(
      couponCode
    ).then((coupons: Array<Record<string, any>>) => {
      if (!coupons.length || coupons.length === 0) {
        setErrorMsg('Coupon "' + couponCode + '" is invalid.');
        return;
      }
      setCouponCode(coupons)
      let coupon = new CouponObj(coupons[0]);

      let validationResult = coupon.validate(email, customer, cart, products);
      if (validationResult.error) {
        let errorMessage = validationResult.message;

        const isHtml = /<\/?[a-z][\s\S]*>/i.test(errorMessage);

        if (isHtml) {
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = errorMessage;
          errorMessage = tempDiv.textContent || tempDiv.innerText;
        }

        setErrorMsg(errorMessage);
        return;
      }

      if (coupon.data.code.startsWith('ref') && orders.length > 0) {
        setErrorMsg('Referral coupons are only valid for new customers.');
        return;
      }

      const affiliateDiscount = getAffiliateDiscountMeta(coupon);
      if (affiliateDiscount) {
        fetchAffiliate(affiliateDiscount);
      }

      const freeProducts = getFreeProducts(coupon);
      if (freeProducts && freeProducts.length > 0) {
        addFreeProductsToCart(freeProducts);
      }

      dispatch(setCartCoupon({ token: token, coupon: coupons[0] }));
      dispatch(setIsOrderChanged(false));
      if (location.pathname === '/checkout/') {
        navigate(`/checkout${AppURL}`)
      }
    }).catch((e) => {
      console.error(e);
      setErrorMsg('An unexpected error has occurred. Please try again later.');
    }).finally(() => {
      setBusy(false);
      if (couponCodeQuery === '10offnextorder' && location.pathname === '/order/') {
        navigate(`/order/${AppURL}`)
        setErrorMsg('')
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      couponCode: ''
    },
    validate,
    onSubmit: values => {
      if (values.couponCode.length && (values.couponCode !== "" || values.couponCode !== null)) {
        setErrorMsg('');
        setBusy(true);
        getCouponCodeFromQuery(values.couponCode);
      }
    }
  });

  const applyCouponForAutoship = () => {
    setErrorMsg('');
    const couponCode = formik?.values?.couponCode;
    if (!couponCode) {
      setErrorMsg('Required.');
    } else {
      setBusy(true);
      getCouponByCode(
        couponCode
      ).then((coupons: Array<Record<string, any>>) => {
        if (!coupons.length || coupons.length === 0) {
          setErrorMsg('Coupon "' + couponCode + '" is invalid.');
          return;
        }
        setCouponCode(coupons)
        let coupon = new CouponObj(coupons[0]);
        if (coupon.data.code.startsWith('ref') && orders.length > 0) {
          setErrorMsg('Referral coupons are only valid for new customers.');
          return;
        }
        const cartObj = new CartObj(latestOrder.line_items);
        let validationResult = coupon.validate(email, customer, cartObj, products);
        if (validationResult.error) {
          setErrorMsg("Coupon could not be applied. Please try a different coupon or try again later.");
          return;
        }
        UpdateEditOrder(coupons[0]);
        dispatch(setIsOrderChanged(false));
      }).catch((e) => {
        console.error(e);
        setErrorMsg('An unexpected error has occurred. Please try again later.');
      }).finally(() => {
        setBusy(false);
      });
    }
  }

  const UpdateEditOrder = async (cartCoupon: any) => {
    try {
      dispatch(setOrderInProgress(true));

      const orderData: Record<string, any> = {
        coupon_lines: [{ "code": cartCoupon.code }],
      };

      OrdersAPI.createOrderNote(token, Number(orderId), "Coupon Added.");

      const response = await OrdersAPI.updateOrder(token, Number(orderId), orderData);
      if ('id' in response) {
        dispatch(updateOrder(response));
      }

    } catch (e) {
      console.error("Error While Updating Order: ", e);
    } finally {
      dispatch(setOrderInProgress(false));
    }
  };

  const getAffiliateDiscountMeta = (coupon: any) => {
    const affiliateDiscountMeta = coupon.data.meta_data.find(
      (meta: any) => meta.key === 'affwp_discount_affiliate'
    );

    if (affiliateDiscountMeta) {
      return affiliateDiscountMeta.value;
    } else {
      return null;
    }
  };

  const fetchAffiliate = async (affiliateSlug: any) => {
    try {
      await dispatch(loadAffiliate(affiliateSlug));
    } catch (error) {
      console.error(error);
    }
  }

  const getFreeProducts = (coupon: any) => {
    const { meta_data, product_ids } = coupon.data;
    const freeProductsMeta = meta_data.find((meta: any) => meta.key === '_wjecf_free_product_ids');

    if (!freeProductsMeta) {
      return null;
    }

    const cartIds = Object.keys(cart?.items);
    const cartSubTotal =  cart.getSubtotal(null, null, null) as number
    const allMatch = product_ids?.every((id: number) => cartIds.includes(String(id)));

    if (allMatch || (cartSubTotal > Number(coupon.data.minimum_amount))) {
      return freeProductsMeta.value.split(',').map((id: string) => parseInt(id, 10));
    }

    return null;
  };

  const addFreeProductsToCart = async (productIds: any) => {
    try {
      for (const productId of productIds) {
        const product = ProductObj.getById(products, productId);
        if (product) {
          await dispatch(setCartItem({
            token: token,
            cart_item: {
              product_id: productId,
              product_qty: 1,
              product_price: Number(product.data.price)
            }
          }));
        }
      }
    } catch (e) {
      console.error("Error:", e);
    }
  }

  return (
    <Form className='coupon-form' onSubmit={(e) => {
      e.preventDefault();
      if (isAutoshipOrder) {
        applyCouponForAutoship();
      } else {
        formik.handleSubmit(e)
      }
    }}>
      {errorMsg &&
        <p className='text-danger fs-12px mb-1'>{errorMsg}</p>}
      {!isMobileDevice ?
        <div className={`d-flex align-items-start flex-nowrap`}>
          <Form.Group className="form-group required flex-fill">
            <Form.Control
              id="couponCode"
              type="text"
              size='sm'
              className={`rounded-0`}
              placeholder={placeholder}
              isValid={Boolean(formik.values.couponCode) && !Boolean(formik.errors.couponCode)}
              isInvalid={Boolean(formik.errors.couponCode)}
              value={formik.values.couponCode ||
                ((couponCodeQuery !== null && couponCodeQuery !== undefined && couponCodeQuery !== "") ? couponCodeQuery : "") ||
                ((mealCountDiscount !== null && mealCountDiscount !== undefined && mealCountDiscount !== "") ? mealCountDiscount : "")}
              onChange={formik.handleChange}
            />
            {formik.errors.couponCode &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.couponCode}
              </Form.Control.Feedback>}
          </Form.Group>
          {busy ?
            <Button
              variant='dark'
              className='ms-2 rounded-0'
              disabled
            >
              <Spinner animation="border" as="span" size="sm" />
              Applying ...
            </Button>
            :
            <Button
              variant='dark'
              className={`flex-fill rounded-0 coupon-form-btn`}
              type="submit"
            >
              Apply
            </Button>
          }
        </div> :
        <div className={`d-flex align-items-start flex-nowrap position-relative`}>
          <Form.Group className="form-group required flex-fill">
            <Form.Control
              id="couponCode"
              type="text"
              size='sm'
              className={`rounded-0 isMobileInputForm`}
              placeholder={placeholder}
              isValid={Boolean(formik.values.couponCode) && !Boolean(formik.errors.couponCode)}
              isInvalid={Boolean(formik.errors.couponCode)}
              value={formik.values.couponCode || ((couponCodeQuery !== null && couponCodeQuery !== undefined && couponCodeQuery !== "") ? couponCodeQuery : "")}
              onChange={formik.handleChange}
            />
            {busy ?
              <Button
                variant='dark'
                className='ms-2 rounded-0'
                disabled
              >
                <Spinner animation="border" as="span" size="sm" />
                Applying ...
              </Button>
              :
              <Button
                variant='dark'
                className={`flex-fill rounded-0 ${formik.errors.couponCode ? 'isMobileRequiredFormbtn' : 'ismobileformbtn'}`}
                type="submit"
              >
                Apply
              </Button>
            }
            {formik.errors.couponCode &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.couponCode}
              </Form.Control.Feedback>}
          </Form.Group>
        </div>}
    </Form>
  )
}