export default class DeliveryObj {
  data: Record<string, any>;

  constructor(data: Record<string, any>) {
    this.data = data;
  }

  dateIsAvailable = (
    date: Date,
    city: string | undefined,
    zip: string | undefined,
    shippingMethodByDate: any,
  ) => {
    if (date < this.getStartDate(zip) ||
      date > this.getEndDate()
    ) {
      return false;
    }

    let blockedDates = this.getBlockedDates(city, zip, shippingMethodByDate);
    for (const blockedDate of blockedDates) {
      if (date.getDate() === blockedDate.getDate() &&
        date.getMonth() === blockedDate.getMonth() &&
        date.getFullYear() === blockedDate.getYear()
      ) {
        return false;
      }
    }

    if (!this.getDeliveryDays(city, zip, shippingMethodByDate).includes(date.getDay())) {
      return false;
    }

    return true;
  }

  getBlockedDates = (city: string | undefined, zip: string | undefined, shippingMethodByDate?: any) => {
    const getBlockedDatesInfo = shippingMethodByDate && shippingMethodByDate.data ? this.data?.shipping_method_delivery_days?.filter((deliveryDays: any) => Number(deliveryDays.shipping_method_id) === Number(shippingMethodByDate.data.ID)) : [];
    const blockDates = (getBlockedDatesInfo.length > 0 && getBlockedDatesInfo[0]?.blocked_dates.length > 0) ?
      getBlockedDatesInfo[0]?.blocked_dates : this.data?.blocked_dates;

    let blockedDates = blockDates?.map((date: string) => {
      return new Date(date + 'T00:00:00');
    });

    return blockedDates;
  }

  getDeliveryDays = (city: string | undefined, zip: string | undefined, shippingMethodByDate: any) => {
    const getDeliveryInfo = shippingMethodByDate && shippingMethodByDate.data ? this.data?.shipping_method_delivery_days?.filter((deliveryDays: any) => Number(deliveryDays.shipping_method_id) === Number(shippingMethodByDate.data.ID)) : [];
    let deliveryDays: Array<number> = [];
    let blockedDays: Array<number> = [];

    if (getDeliveryInfo?.length > 0) {
      deliveryDays = getDeliveryInfo[0]?.shipping_method_week_days;
    }


    if (city && this.data.blocked_cities) {
      city = city.toLowerCase();
      if (city in this.data.blocked_cities) {
        blockedDays = this.data.blocked_cities[city].map((day: string) => {
          return parseInt(day);
        });
      }
      deliveryDays = deliveryDays.filter((day: number) => {
        return !blockedDays.includes(day);
      });
    }

    if (zip && this.data.blocked_zips) {
      if (zip in this.data.blocked_zips) {
        blockedDays = this.data.blocked_zips[zip]['weekdays'].map((day: string) => {
          return parseInt(day);
        });
      }
      deliveryDays = deliveryDays.filter((day: number) => {
        return !blockedDays.includes(day);
      });
    }

    return deliveryDays;
  }

  getEndDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + this.data.max_delivery_range);
    return date;
  }

  getStartDate = (zip: string | undefined) => {
    let deliveryBuffer = 1;
    const date = new Date();
    if (this.data.blocked_zips && zip !== undefined && zip in this.data.blocked_zips) {
      if (this.data.blocked_zips[zip]['delivery_buffer']) {
        deliveryBuffer = parseInt(this.data.blocked_zips[zip]['delivery_buffer']);
      }
    }
    date.setDate(date.getDate() + deliveryBuffer);
    return date;
  }
}