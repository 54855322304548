import React from 'react'
import { Col, Container, Row, Table, Image, Modal, Spinner } from 'react-bootstrap';

interface Props {
  isLoading: boolean;
  userItems: any;
}

export default function MealFromURL({ userItems, isLoading }: Props) {

  return (
    <Container fluid="lg" className='my-meal-plans-page w-50 border border-dark'>
      <Modal
        backdrop="static"
        show={isLoading}>
        <Modal.Body className='font-barlow'>
          <Row>
            <Col className='d-flex flex-column p-2'>
              <label className='table-borderless font-barlow d-flex justify-content-center h2 pb-3 fw-bold'>Please Wait ...</label>
              {isLoading ?
                <div className='text-center my-5 h-100'>
                  <Spinner
                    variant="dark"
                    animation="border"
                    as="span"
                    className='spinner-lg my-25px'
                  />
                </div> : (<>
                  <Table
                    striped
                    responsive
                    className='table-borderless font-barlow border border-dark fs-6'>
                    <thead>
                      <tr>
                        <th># Items</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userItems?.map((mealPlanData: any, index: any) => {
                        return (
                          <tr key={index}>
                            <td>
                              {mealPlanData ?
                                <div className='d-flex'>
                                  <Image
                                    src={mealPlanData?.images[0].src}
                                    width="50"
                                    height="50"
                                    className='d-none d-sm-block'
                                  />
                                  <span className='ms-2'>{mealPlanData?.name}
                                  </span>
                                </div>
                                :
                                <div className='mt-2'>
                                  <Spinner animation="border" as="span" size="sm" title='Product Image Loading' />
                                  &nbsp;&nbsp;&nbsp;{mealPlanData?.productName}</div>
                              }
                            </td>
                            <td>
                              {mealPlanData.stock_status !== 'outofstock' ?
                                <>
                                  {mealPlanData && (<>
                                    Adding to cart ...&nbsp;&nbsp;
                                    <Spinner
                                      animation="border"
                                      as="span"
                                      size="sm"
                                    /></>)}
                                </> : <span className='text-danger'>Out Of Stock</span>}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table></>)}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  )
}