import { UpdateUserCIM } from "../features/checkout/interfaces";
import { BillingPayPost } from "../features/customer/interfaces";
import OrderObj from "../features/orders/orderObj";

export default class AuthNetAPI {
  static async capturePayment(
    token: string,
    nonce: string,
    order: OrderObj,
  ) {
    let response = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/authorize_net',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({
          token: token,
          nonce: nonce,
          order: order.data
        })
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while capturing payment:', response);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async getUserCIM(email: string, cimKey: string) {
    let url = new URL(process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/user_cim');
    url.searchParams.append('email', email);
    url.searchParams.append('cim_key', cimKey);

    let response = await fetch(url, {
      method: 'GET',
      headers: { 'content-type': 'application/json;charset=UTF-8' }
    });

    if (!response.ok) {
      console.error('HTTP error while getting user cim:', response);
      throw new Error(`HTTP error. status: ${response.status}`);
    }

    return await response.json();
  }

  static async updateUserCIM(data: UpdateUserCIM) {
    let response = await (fetch(
      process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/update_cim',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({
          email: data.email,
          mm_auth_card_profile_id: data.mmAuthCardProfileId,
          mm_auth_card_type: data.mmAuthCardType,
          mm_auth_card_experiation: data.mmAuthCardExperiation,
          mm_auth_card_last_four: data.mmAuthCardLastFour,
          mm_auth_customer_id: data.mmAuthCustomerId,
          cim_key: data.cimKey
        })
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while updating user cim:', response);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async savedPaymentTransaction(
    total: string,
    customerProfileId: string,
    paymentProfileId: string,
  ) {
    let response = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/auth_saved_payment',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({
          total: total,
          customerProfileId: customerProfileId,
          paymentProfileId: paymentProfileId
        })
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while capture saved payments:', response);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async createProfile(
    token: string,
    expirationDate: string,
    cardNumber: string,
    validationMode: string,
  ) {
    let response = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/auth_create_profile',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({
          token: token,
          expirationDate: expirationDate,
          cardNumber: cardNumber,
          validationMode: validationMode
        })
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while creating user profile:', response);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async getProfile(customerProfileId: string) {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/auth_get_profile?customer_profile_id=' + customerProfileId,
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
      }
    ));

    if (!response.ok) {
      console.error('HTTP error while getting user profile:', response);
      throw new Error(`HTTP error. status: ${response.status}`);
    }

    return await response.json();
  }

  static async updateProfile(
    merchantCustomerId: string,
    description: string,
    email: string,
    customerProfileId: string
  ) {
    let response = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/auth_update_profile',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({
          merchant_customer_id: merchantCustomerId,
          description: description,
          email: email,
          customer_profile_id: customerProfileId
        })
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while updating user profile:', response);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async creatCustPayProfile(
    order: BillingPayPost,
    customerProfileId: string,
    expirationDate: string,
    cardNumber: string,
    validationMode: string
  ) {
    let response = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/auth_create_cust_payment',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({
          order: order,
          customerProfileId: customerProfileId,
          expirationDate: expirationDate,
          cardNumber: cardNumber,
          validationMode: validationMode
        })
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while creating payment profile:', response);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async updateCustPayProfile(
    order: BillingPayPost,
    customerProfileId: string,
    paymentProfileId: string,
    cardNumber: string,
    expirationDate: string,
    validationMode: string
  ) {
    let response = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/auth_update_cust_payment',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({
          order: order,
          customerProfileId: customerProfileId,
          paymentProfileId: paymentProfileId,
          cardNumber: cardNumber,
          expirationDate: expirationDate,
          validationMode: validationMode
        })
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while updating payment profile:', response);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async getCustPayProfile(customerProfileId: string) {
    let url = new URL(process.env.REACT_APP_SERVER_URL + '/auth_get_pay_profile');
    url.searchParams.append('customer_profile_id', customerProfileId);

    let response = await fetch(url, {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset=UTF-8' }
    });

    if (!response.ok) {
        console.error('HTTP error while getting payment profile:', response);
        throw new Error(`HTTP error. status: ${response.status}`);
    }

    return await response.json();
}

  static async deleteCustPayProfile(
    customerProfileId: string,
    customerPaymentProfileId: string
  ) {
    let response = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/auth_delete_cust_payment',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({
          customer_profile_id: customerProfileId,
          customer_payment_profile_id: customerPaymentProfileId
        })
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while deleting payment profile:', response);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }
}