import { ICartItems } from "../cart/interfaces";
import ProductObj from "../products/productObj";
import CustomerObj from "../customer/customerObj";
import { IMealPlan, IMealPlanItem } from "./interfaces";

export default class MealPlanObj {
  data: IMealPlan;

  constructor(data: IMealPlan) {
    this.data = data;
  }

  addProduct = (product: ProductObj) => {
    let item: IMealPlanItem | null = this.findItemByProductId(product.data.id);

    if (item) {
      item.quantity++;
      item.productName = product.data.name;
      return;
    }

    item = {
      productId: product.data.id,
      productName: product.data.name,
      quantity: 1
    }

    this.data.items.push(item);
  }


  findItemByProductId = (productId: number) => {
    for (let item of this.data.items) {
      if (item.productId === productId) {
        return item;
      }
    }
    return null;
  }

  getItemCount = () => {
    let itemCount = 0;

    for (const item of this.data.items) {
      itemCount += item.quantity;
    }
    return itemCount;
  }

  removeItem = (productId: number) => {
    this.data.items = this.data.items.filter((item) => item.productId !== productId);
  }

  setItemQuantity = (productId: number, quantity: number) => {
    let item: IMealPlanItem | null = this.findItemByProductId(productId);

    if (!item) return false;

    item.quantity = quantity;
    return true;
  }

  toCartItems = (
    products: Array<Record<string, any>>, 
    customer: CustomerObj
  ) => {
    const cartItems: ICartItems = {};

    for (const item of this.data.items) {
      let product = ProductObj.getById(products, item.productId);

      if (!product) continue;
      
      cartItems[product.data.id] = {
        product_id: product.data.id,
        product_price: customer.getProductPrice(product) as number,
        product_qty: item.quantity
      }

    }
    return cartItems;
  }

  // -------------------------------------
  // S T A T I C   M E T H O D S
  // -------------------------------------

  static getById = (mealPlans: Array<IMealPlan>, id: number)  => {
    const mealPlan =  mealPlans.find((mealPlan) => {
      return mealPlan.id === id;
    });

    if (mealPlan) return new MealPlanObj(mealPlan);

    return null;
  }
}