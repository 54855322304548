import { useDispatch, useSelector } from 'react-redux';
import { Button, CloseButton, Col, Modal, Row } from 'react-bootstrap';
import { selectShowProductFilters, 
         setShowProductFilters } from '../../mobile/mobileSlice';
import ProductTypeFilter from './ProductTypeFilter';         
import ProteinFilter from './ProteinFilter';
import DietFilter from './DietFilter';
import DietaryRestrictionsFilter from './DietaryRestrictionsFilter';
import MacroFilter from './MacroFilter';
import ProductSearch from './ProductSearch';
import { clearDietaryRestrictions,
         setProductCalorieFilter,
         setProductCarbFilter,
         setProductDietFilter, 
         setProductFatFilter,
         setProductProteinFilter,
         setProductProteinTypeFilter, 
         setProductSearchFilter, 
         setProductSortFilter, 
         setProductTypeFilter } from '../productsSlice';

export default function ProductFiltersModal() {
  const dispatch = useDispatch();
  const show = useSelector(selectShowProductFilters);

  const handleHide = () => {
    dispatch(setShowProductFilters(false));
  }

  const handleReset = () => {
    dispatch(setProductTypeFilter('all'));
    dispatch(setProductProteinTypeFilter('all'));
    dispatch(setProductDietFilter('all'));
    dispatch(clearDietaryRestrictions());
    dispatch(setProductCalorieFilter({ min: 0, max: 1200 }));
    dispatch(setProductCarbFilter({ min: 0, max: 250 }));
    dispatch(setProductFatFilter({ min: 0, max: 100 }));
    dispatch(setProductProteinFilter({ min: 0, max: 150 }));
    dispatch(setProductSearchFilter(''));
    dispatch(setProductSortFilter('none'));
  }

  return (
    <Modal 
      fullscreen={true}
      show={show}
      onHide={handleHide}
    >
      <Modal.Body>
        <div className='d-flex justify-content-between'>
          <span className='fs-5 fw-semibold'>Filters</span>
          <CloseButton onClick={handleHide} />
        </div>
        <div className='d-grid'>
          <Button
            variant="dark"
            className='bg-black'
            onClick={handleHide}
          >
            Apply Filters
          </Button>
        </div>
        <Row className='mt-3'>
          <Col><ProductTypeFilter /></Col>
          <Col><ProteinFilter /></Col>
        </Row>
        <Row className='mt-3'>
          <Col><DietFilter /></Col>
          <Col><DietaryRestrictionsFilter /></Col>
        </Row>
        <div className='mt-3'>
          <MacroFilter />
        </div>
        <Row className='my-1'>
          <Col xs={6}>
            <ProductSearch />
          </Col>
          <Col xs={6}>
            <div className='d-grid'>
              <Button
              variant='dark'
                onClick={handleReset}
              >
                Reset All
              </Button>
            </div>            
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}