import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import { selectCoupons } from "../coupons/couponsSlice";
import { selectCustomer } from '../customer/customerSlice';
import Coupon from "./Coupon";
import CouponObj from "./couponObj";
import CustomerObj from "../customer/customerObj";
import CouponUtils from "./couponUtils";

export default function CouponList() {
  const customerData = useSelector(selectCustomer);
  const customer = useMemo(() => {
    return new CustomerObj(customerData);
  }, [customerData]);
  const couponList: Array<Record<string, any>> = useSelector(selectCoupons);
  const displayableCoupons = useMemo(() => {
    return CouponUtils.getDisplayableCoupons(couponList, customer);
  }, [couponList, customer]);

  return (
    <Row className='coupon-list my-2 position-relative'>
      {displayableCoupons.map((couponData) => {
        let coupon = new CouponObj(couponData);
        return (
          <Coupon key={coupon.data.ID} coupon={coupon} />
        );
      })}
    </Row>
  );
}