import { Card, Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { FormValues } from './interfaces';

export default function PasswordInput() {
  const formik = useFormikContext<FormValues>();

  return (
    <Card className='my-3' body>
      <Form.Group className="mb-3 form-group required">
        <Form.Label>Create an account password</Form.Label>
        <Form.Control 
          id="password"
          type="password" 
          isInvalid={Boolean(formik.errors.password)}
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        {formik.errors.password && 
          <Form.Control.Feedback type="invalid">
            {formik.errors.password}
          </Form.Control.Feedback>
        }
      </Form.Group>
    </Card>
  );
}