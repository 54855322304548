import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { selectProductDietFilter, setProductDietFilter } from '../../productsSlice';

export default function DietFilterForm() {
  const dispatch = useDispatch();
  const filterValue = useSelector(selectProductDietFilter);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProductDietFilter(e.target.id));
  }

  return (
    <Form className='fs-6 mx-2'>
      <Form.Check
        type="radio"
        name="product-diet"
        label="All"
        id="all"
        checked={filterValue === "all"}
        onChange={handleChange}
      />
      <Form.Check
        type="radio"
        name="product-diet"
        label="Low Carb &lt; 35"
        id="low-carb"
        checked={filterValue === "low-carb"}
        onChange={handleChange}
      />
      <Form.Check
        type="radio"
        name="product-diet"
        label="Low Sodium"
        id="low-sodium"
        checked={filterValue === "low-sodium"}
        onChange={handleChange}
      />
      <Form.Check
        type="radio"
        name="product-diet"
        label="Over 500 Calories"
        id="over-500-cal"
        checked={filterValue === "over-500-cal"}
        onChange={handleChange}
      />
      <Form.Check
        type="radio"
        name="product-diet"
        label="Under 500 Calories"
        id="under-500-cal"
        checked={filterValue === "under-500-cal"}
        onChange={handleChange}
      />
      <Form.Check
        type="radio"
        name="product-diet"
        label="Vegan"
        id="vegan"
        checked={filterValue === 'vegan'}
        onChange={handleChange}
      />
      <Form.Check
        type="radio"
        name="product-diet"
        label="Vegetarian"
        id="vegetarian"
        checked={filterValue === "vegetarian"}
        onChange={handleChange}
      />
    </Form>
  );
}