export default class ProfileOptionsAPI {
    static async getProfileOptions() {
      let response = await(fetch(
        process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/user_fields',
        {
          method: 'GET',
          headers: { 'content-type': 'application/json;charset-UTF-8' }  
        }
      ));
      if (!response.ok) {
        throw new Error(`HTTP error. status: ${response.status}`);
      }
      const json = await response.json();
      return json.user_settings;
    }

    static async getGeneralOptions() {
      let response = await(fetch(
        process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/global_settings',
        {
          method: 'GET',
          headers: { 'content-type': 'application/json;charset-UTF-8' }  
        }
      ));
      if (!response.ok) {
        throw new Error(`HTTP error. status: ${response.status}`);
      }
      return await response.json();
    }
  }