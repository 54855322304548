import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from '../../app/store';
import CustomerAPI  from '../../API/customerAPI';

export const loadCustomer = createAsyncThunk(
  'customer/loadCustomer',
  async (token: string) => {
    let result: any[] = [];
    result = await CustomerAPI.getCustomer(token);

    if (result.length) {
      return result[0];
    }
    return {};
  }
);

interface CustomerState {
  customer: Record<string, any>;
  isLoading: boolean;
  isLoaded: boolean;
  loadError: boolean;
}

const initialState: CustomerState = {
  customer: {},
  isLoading: false,
  isLoaded: false,
  loadError: false
}

export const customerSlice = createSlice({
  name: "customer",
  initialState: initialState,
  reducers: {
    resetCustomer: (state) => {
      return initialState;
    },
    setCustomer: (state, action: PayloadAction<Record<string, any>>) => {
      return {
        ...state, 
        customer: { ...action.payload },
        isLoaded: true
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(loadCustomer.pending, (state, action) => {
      state.isLoading = true;
      state.isLoaded = false;
    });
    builder.addCase(loadCustomer.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false, 
        isLoaded: true,
        customer: { ...action.payload },
        loadError: false
      }
    });
    builder.addCase(loadCustomer.rejected,  (state, action) => {
      state.isLoading = false;
      state.loadError = true;
    });
  }
});
export const { resetCustomer, setCustomer } = customerSlice.actions;
export const selectCustomer = (state: RootState) => state.customer.customer;
export const selectCustomerEmail = (state: RootState) => {
  return 'email' in state.customer.customer ? state.customer.customer.email : null;
}
export const selectCustomerId = (state: RootState) => {
  return 'id' in state.customer.customer ? state.customer.customer.id : null;
}
export const selectCustomerIsLoading = (state: RootState) => state.customer.isLoading;
export const selectCustomerIsLoaded = (state: RootState) => state.customer.isLoaded;
export const selectCustomerLoadError = (state: RootState) => state.customer.loadError;
export default customerSlice.reducer;