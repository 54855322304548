import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormikErrors, useFormik } from 'formik';
import { Alert, Button, Container, Form, Spinner } from 'react-bootstrap';
import UserAPI from '../API/userAPI';
import { useSelector } from 'react-redux';
import { selectIsMobileRoute } from '../features/mobile/mobileSlice';

interface FormValues {
  password: string;
  passwordConfirmation: string;
}

export default function SetPasswordPage() {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [busy, setBusy] = useState(false);
  const [params] = useSearchParams();
  const code = params.get('code');
  const email = params.get('email');
  const AppURL = useSelector(selectIsMobileRoute);

  useEffect(() => {
    if (successMsg.length) {
      navigate('/my-account' + AppURL + '?successMsg=' + successMsg);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, navigate]);

  const validate = (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {};
    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 6) {
      errors.password = "Must be at least 6 characters.";
    }
    if (!values.passwordConfirmation) {
      errors.passwordConfirmation = "Required";
    }
    if (values.password && values.passwordConfirmation && 
      (values.password !== values.passwordConfirmation)) {
      errors.password = "Passwords do not match";
      errors.passwordConfirmation = "Passwords do not match";
    }

    return errors;
  }
  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: ''
    },
    validate,
    onSubmit: values => {
      setErrorMsg('');
      setBusy(true);

      if (!code || !email) throw new Error("Password reset email and/or code is invalid");

      UserAPI.setPassword(email, values.password, code).then((response) => {
        setSuccessMsg(response.message);
      }).catch((e) => {
        setErrorMsg(e.message);
      }).finally(() => {
        setBusy(false);
      })
    }
  })

  return (
    <Container className='set-password-page'>
      <h2 className='my-5'>Set New Password</h2>
      {errorMsg &&
        <Alert className='mb-4' variant='error'>{errorMsg}</Alert> 
      }
      <Form className='set-password-form' onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3 form-group required">
          <Form.Label>Password</Form.Label>
          <Form.Control 
            id="password"
            type="password" 
            size="lg"
            isValid={Boolean(formik.values.password) && 
              !Boolean(formik.errors.password)}
            isInvalid={Boolean(formik.errors.password)}
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          {formik.errors.password &&
            <Form.Control.Feedback type="invalid">
              {formik.errors.password}
            </Form.Control.Feedback>
          }
        </Form.Group>
        <Form.Group className="mb-3 form-group required">
          <Form.Label>Password confirmation</Form.Label>
          <Form.Control 
            id="passwordConfirmation"
            type="password" 
            size="lg"
            isValid={Boolean(formik.values.passwordConfirmation) &&
              !Boolean(formik.errors.passwordConfirmation)}
            isInvalid={Boolean(formik.errors.passwordConfirmation)}
            value={formik.values.passwordConfirmation}
            onChange={formik.handleChange}
          />
          {formik.errors.passwordConfirmation && 
            <Form.Control.Feedback type="invalid">
              {formik.errors.passwordConfirmation}
            </Form.Control.Feedback>
          }
        </Form.Group>
        {busy ? 
          <Button className='bg-black my-3' size="lg" disabled>
            <Spinner animation="border" as="span" size="sm" />
            &nbsp;&nbsp;Setting Password ...
          </Button> :
          <Button className='bg-black my-3' size="lg" type="submit">
            Set Password
          </Button>
        }
      </Form>
    </Container>
  );
}