import { ChangeEvent, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import LoginForm from '../features/user/LoginForm';

export default function ReturningCustomer() {
  const [show, setShow] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShow(e.target.checked);
  }

  return (
    <Card className='returning-custmoer' body>
      <div className='d-flex justify-content-between my-2 align-items-center'>
        <span className='fs-3'>Returning customer? Login here.</span>
        <Form.Check 
          type="switch" 
          className='mx-2 d-flex' 
          onChange={handleChange}
        />
      </div>
      {show &&
        <div>
          <p className='fs-5'>
            If you have shopped with us before, please enter your details below. 
            If you are a new customer, please proceed to the Billing section.
          </p>
          <LoginForm />
        </div>
      }
    </Card>
  );
}