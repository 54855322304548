import { IDietaryPlan } from "./interfaces";

export default class DietaryPlanObj {
  data: IDietaryPlan;

  constructor(data: IDietaryPlan) {
    this.data = data;
  }

  // -------------------------------------
  // S T A T I C   M E T H O D S
  // -------------------------------------

  static getById = (dietaryPlans: Array<IDietaryPlan>, id: number)  => {
    const dietaryPlan =  dietaryPlans.find((dietaryPlan) => {
      return dietaryPlan.id === id;
    });

    if (dietaryPlan) return new DietaryPlanObj(dietaryPlan);

    return null;
  }
}