import { useEffect, useRef } from 'react';
import { Button, Tabs, Tab } from 'react-bootstrap';
import DietaryRestrictionsFilter from './DietaryRestrictionsFilter';
import DietFilter from './DietFilter';
import MacroFilter from './MacroFilter';
import ProductTypeFilter from './ProductTypeFilter';
import ProteinFilter from './ProteinFilter';
import SortFilter from './SortFilter';
import ProductSearch from './ProductSearch';
import './productFilters.scss';
import ProductFilterTags from './ProductFilterTags';
import {
  clearDietaryRestrictions,
  selectProductSortFilter,
  setProductCalorieFilter,
  setProductCarbFilter,
  setProductDietFilter,
  setProductFatFilter,
  setProductProteinFilter,
  setProductProteinTypeFilter,
  setProductSearchFilter,
  setProductSortFilter,
  setProductTypeFilter,
  addDietaryRestriction
} from '../productsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export default function ProductFilters() {
  const dispatch = useDispatch();
  const location = useLocation();
  const sortFilter = useSelector(selectProductSortFilter);
  const tabsRef = useRef<HTMLDivElement>(null);

  const handleReset = () => {
    dispatch(setProductTypeFilter('all'));
    dispatch(setProductProteinTypeFilter('all'));
    dispatch(setProductDietFilter('all'));
    dispatch(clearDietaryRestrictions());
    dispatch(setProductCalorieFilter({ min: 0, max: 1200 }));
    dispatch(setProductCarbFilter({ min: 0, max: 250 }));
    dispatch(setProductFatFilter({ min: 0, max: 100 }));
    dispatch(setProductProteinFilter({ min: 0, max: 150 }));
    dispatch(setProductSearchFilter(''));
    dispatch(setProductSortFilter('none'));
  }

  const handleMostPopularClick = () => {
    const newSortFilter = sortFilter === 'most_popular' ? 'none' : 'most_popular';
    dispatch(setProductSortFilter(newSortFilter));
  };

  const handleQuickFilter = (key: any) => {
    let actionsToDispatch: any[] = [];

    switch (key) {
      case 'all':
      case 'bulk':
      case 'breakfast':
      case 'snacks':
      case 'desserts':
        actionsToDispatch = [
          setProductTypeFilter(key),
          setProductProteinTypeFilter('all'),
          setProductDietFilter('all'),
          clearDietaryRestrictions(),
          setProductCalorieFilter({ min: 0, max: 1200 }),
          setProductCarbFilter({ min: 0, max: 250 }),
          setProductFatFilter({ min: 0, max: 100 }),
          setProductProteinFilter({ min: 0, max: 150 }),
          setProductSearchFilter(''),
          setProductSortFilter('none')
        ];
        break;
      case 'snacks-desserts':
      case 'low-carb':
      case 'under-500-cal':
        actionsToDispatch = [
          setProductDietFilter(key),
          setProductTypeFilter('all'),
          setProductProteinTypeFilter('all'),
          clearDietaryRestrictions(),
          setProductCalorieFilter({ min: 0, max: 1200 }),
          setProductCarbFilter({ min: 0, max: 250 }),
          setProductFatFilter({ min: 0, max: 100 }),
          setProductProteinFilter({ min: 0, max: 150 }),
          setProductSearchFilter(''),
          setProductSortFilter('none')
        ];
        break;
      case 'protein_desc':
        actionsToDispatch = [
          setProductSortFilter(key),
          setProductTypeFilter('all'),
          setProductProteinTypeFilter('all'),
          setProductDietFilter('all'),
          clearDietaryRestrictions(),
          setProductCalorieFilter({ min: 0, max: 1200 }),
          setProductCarbFilter({ min: 0, max: 250 }),
          setProductFatFilter({ min: 0, max: 100 }),
          setProductProteinFilter({ min: 0, max: 150 }),
          setProductSearchFilter('')
        ];
        break;
      case 'gluten-free':
      case 'vegan':
        actionsToDispatch = [
          setProductTypeFilter('all'),
          setProductProteinTypeFilter('all'),
          setProductDietFilter('all'),
          addDietaryRestriction(key),
          setProductCalorieFilter({ min: 0, max: 1200 }),
          setProductCarbFilter({ min: 0, max: 250 }),
          setProductFatFilter({ min: 0, max: 100 }),
          setProductProteinFilter({ min: 0, max: 150 }),
          setProductSearchFilter(''),
          setProductSortFilter('none')
        ];
        break;
      case 'vegetarian':
        actionsToDispatch = [
          setProductTypeFilter('all'),
          setProductProteinTypeFilter(key),
          setProductDietFilter('all'),
          clearDietaryRestrictions(),
          setProductCalorieFilter({ min: 0, max: 1200 }),
          setProductCarbFilter({ min: 0, max: 250 }),
          setProductFatFilter({ min: 0, max: 100 }),
          setProductProteinFilter({ min: 0, max: 150 }),
          setProductSearchFilter(''),
          setProductSortFilter('none')
        ];
        break;
      default:


        break;
    }

    actionsToDispatch.forEach(action => dispatch(action));
  };

  const handleFilterButton = (direction: 'left' | 'right') => {

    if (tabsRef.current) {
      const container = tabsRef.current;
      const ulElement = container.querySelector('ul');
      const scrollAmount = container.clientWidth / 2;
      if (ulElement) {
        const newScrollLeft = direction === 'left'
          ? ulElement.scrollLeft - scrollAmount
          : ulElement.scrollLeft + scrollAmount;

        ulElement.scrollTo({
          left: newScrollLeft,
          behavior: 'smooth'
        });

        // Add event listener to update disabled state after scrolling
        ulElement.addEventListener('scroll', updateNavButtons);
      }
    }
  };

  const updateNavButtons = () => {
    if (tabsRef.current) {
      const container = tabsRef.current;
      const ulElement: any = container.querySelector('.nav-tabs');
      const leftNav: any = document.querySelector('.left-nav');
      const rightNav: any = document.querySelector('.right-nav');

      if (ulElement.scrollLeft === 0) {
        leftNav.classList.add('disabled');
      } else {
        leftNav.classList.remove('disabled');
      }

      if (ulElement.scrollLeft + ulElement.clientWidth >= ulElement.scrollWidth) {
        rightNav.classList.add('disabled');
      } else {
        rightNav.classList.remove('disabled');
      }
    }
  };

  // Initial call to set the initial state of the nav buttons
  useEffect(() => {
    updateNavButtons();
  });

  return (
    <div className={`product-filters bg-white pt-3 px-2 pb-3 w-100 ${location.pathname.startsWith('/autoship/menu') && 'isautoshipmenufilter'}`}>
      <div className="product-filters-list" ref={tabsRef}>
        <Tabs
          defaultActiveKey="all"
          id="product-filters-tab"
          className="mb-3"
          onSelect={handleQuickFilter}
        >
          <Tab eventKey="all" title="All Meals"></Tab>
          <Tab eventKey="bulk" title="A la carte"></Tab>
          <Tab eventKey="snacks" title="Snacks"></Tab>
          <Tab eventKey="desserts" title="Dessert"></Tab>
          <Tab eventKey="low-carb" title="Low Carb"></Tab>
          <Tab eventKey="under-500-cal" title="Under 500 Calories"></Tab>
          <Tab eventKey="protein_desc" title="High Protein"></Tab>
          <Tab eventKey="vegan" title="Vegan"></Tab>
          <Tab eventKey="vegetarian" title="Vegetarian"></Tab>
          <Tab eventKey="gluten-free" title="Gluten Free"></Tab>
          <Tab eventKey="breakfast" title="Breakfast"></Tab>
        </Tabs>
        <div className="product-filters-navs">
          <div
            className="left-nav nav arrow-left arrow-both swiper-button-prev"
            onClick={() => handleFilterButton('left')}
          >
          </div>
          <div
            className="right-nav nav arrow-right arrow-both swiper-button-next"
            onClick={() => handleFilterButton('right')}>
          </div>
        </div>
      </div>
      <div className='d-flex'>
        <div className='fs-2 fw-normal me-2 d-none d-lg-block'>Filter:</div>
        <div className='d-flex flex-wrap wrapfilter'>
          <div className='d-flex flex-wrap col-md-8 col-xxl-auto top-wrapFilter'>
            <div className='p-1 col-md-6 col-xxl-auto'><ProductTypeFilter /></div>
            <div className='p-1 col-md-6 col-xxl-auto'><ProteinFilter /></div>
            <div className='p-1 col-md-6 col-xxl-auto'><DietFilter /></div>
            <div className='p-1 col-md-6 col-xxl-auto'><DietaryRestrictionsFilter /></div>
            <div className='p-1 col-md-6 col-xxl-auto'><MacroFilter /></div>
            <div className='p-1 col-md-6 col-xxl-auto'><SortFilter /></div>
          </div>
          <div className='d-flex flex-wrap col-md-4 col-xxl-auto bottom-wrapFilter'>
            <div className='p-1 col-12 col-xxl-auto'><ProductSearch /></div>
            <div className='p-1 col-12 col-xxl-auto'>
              <Button
                variant="dark"
                className='reset-filters-btn w-100 border border-black text-black'
                onClick={handleReset}
              >
                Reset All
              </Button>
            </div>
            <div className='p-1 col-12 col-xxl-auto'>
              <Button
                variant={sortFilter === 'most_popular' ? 'success' : 'dark'}
                className={`${sortFilter === 'most_popular' ? 'popular-meal-success-btn' : 'popular-meal-unsuccess-btn'} w-100 border border-black`}
                onClick={handleMostPopularClick}
              >
                Most Popular
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ProductFilterTags />
    </div>
  );
}
