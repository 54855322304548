/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import { Image } from 'react-bootstrap';
import logo from '../assets/images/mightymeals_logo.png';

function MaintenanceMode() {
    return (
        <div className='container'>
            <div className='mt-3 text-center'>
                <Image
                    src={logo}
                    className='d-block mb-4 mx-auto'
                />
                <span className='fw-bolder display-6 d-block pb-2'>WE'RE COOKING UP SOMETHING HOT!</span>
                <span className='fw-bolder fs-2 d-block pb-2'>THE WEBSITE IS UNDERGOING PLANNED MAINTENANCE</span>
                <span className='fw-bolder fs-2 d-block text-success'>PLEASE CHECK BACK IN A FEW HOURS</span>
                <iframe src="https://forms.monday.com/forms/embed/c23b4029742681f09a5a95858b06a9fd?r=use1" width="100%" height="650" style={{border: '0', margin:'0 auto'}}></iframe>
            </div>
        </div>
    )
}

export default MaintenanceMode