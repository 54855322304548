import { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { Button, FormControl } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { setNewCartItem, setNotifyItem } from '../features/mobile/mobileSlice';
import './plusMinusInput.scss';

interface Props {
  onChange: (qty: number) => void;
  value: number;
  disabled?: boolean;
  className?: string
}

export default function PlusMinusInput({
  onChange, 
  value, 
  disabled = false,
  className = ''
}: Props) {
  const dispatch = useDispatch();
  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    if (value !== fieldValue) {
      setFieldValue(value);
    }
  // eslint-disable-next-line
  }, [value]);

  const handleMinusClick = () => {
    if (value > 0) {
      onChange(value - 1);
      dispatch(setNewCartItem(false));
      dispatch(setNotifyItem([]));
    }
  }

  const handlePlusClick = async () => {
    if (value < 99) {
      await onChange(value + 1);
      await dispatch(setNewCartItem(true));
    }
  } 

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const qty = parseInt(e.target.value);
    if (isNaN(qty)) return;

    setFieldValue(qty);
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.value === "") onChange(0);

    const qty = parseInt(e.target.value);
    if (isNaN(qty)) return;

    onChange(qty);
  }

  return (
    <div className={`plus-minus-input d-flex ${className}`}>
      <Button 
        size="sm"
        variant='light'
        onClick={handleMinusClick}
        disabled={disabled}
      >-</Button>
      <FormControl
        type="text"
        className="text-center mx-0"
        maxLength={2}
        value={fieldValue}
        size="sm"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
      />
      <Button 
        size="sm"
        variant='light'
        onClick={handlePlusClick}
        disabled={disabled}
      >+</Button>
    </div>
  );
}