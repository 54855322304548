import React, { useEffect, useMemo, useState } from 'react'
import { Button, Container, Spinner } from 'react-bootstrap'
import { Image } from 'react-bootstrap';
import cardStars from '../../../assets/images/Stars.svg';
import cardlogo from '../../../assets/images/white-logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../../../features/user/userSlice';
import { selectProducts } from '../../../features/products/productsSlice';
import { selectCartItems, setCartItem } from '../../../features/cart/cartSlice';
import { useNavigate } from 'react-router-dom';
import { filterProductsByCategory } from '../../../features/products/productUtils';
import '../core/mightyBucks.scss';
import CartObj from '../../../features/cart/cartObj';
import ToastError from '../../../components/ToastError';
import { selectIsMobileRoute } from '../../../features/mobile/mobileSlice';

interface Props {
    cartUpdateInProgress?: boolean;
    orderInProgress?: boolean;
}

function BucksCard({ cartUpdateInProgress, orderInProgress }: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector(selectToken);
    const products = useSelector(selectProducts);
    const cartItems = useSelector(selectCartItems);
    const GiftCard = filterProductsByCategory(products, 'mighty-bucks-gift-card');
    const [isFalse, setIsFalse] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [selectedProductId, setSelectedProductId] = useState([]);
    const AppURL = useSelector(selectIsMobileRoute);
    const cart = useMemo(() => {
        return new CartObj(cartItems)
      }, [cartItems]);

    useEffect(() => {
        if (GiftCard.length > 0) {
            const giftCardInCart = GiftCard.some(giftCard => Object.keys(cartItems).some(product_id => Number(product_id) === Number(giftCard.id)));
            setIsFalse(giftCardInCart)
        }
    }, [GiftCard, cartItems])

    const handleBucksGirftCards = async (price: any, CardId: number) => {
        if (!cart.isEmpty() && GiftCard.length === 0) {
            setErrorMsg('MightyBucks must be purchased separate from other items. Clear your cart to purchase this item.');
            setTimeout(() => {
                setErrorMsg('');
            }, 5000);
        } else if(!cart.isEmpty() && isFalse){
            setErrorMsg('MightyBucks gift card is already added, Please clear your cart to purchase this item.')
            setTimeout(() => {
                setErrorMsg('')
            }, 5000)
        } else {
            setIsAdding(true);
            if (GiftCard) {
                await dispatch(setCartItem({
                    token: token,
                    cart_item: {
                        product_id: CardId,
                        product_qty: 1,
                        product_price: price
                    }
                }));
                navigate(`/checkout${AppURL}`);
            }
            setIsAdding(false);
        }
    }

    return (
        <Container className="bucks-page mx-2">
            <div className='mb-cards'>
                <div className='mb-card-wrap'>
                    {GiftCard.sort((a, b) => a.price - b.price).map((giftCard: any, index: number) => (
                        <div className='mb-top' key={index}>
                            <div className='mb-bg-wrap'>
                                <div className='mb-bg-image'>
                                    <Image
                                        src={cardStars}
                                    />
                                </div>
                                <div className='mb-top-wrap'>
                                    <div className='mb-left'>
                                        <div className='mb-bg-gradient'></div>
                                        <div className='mb-img-content'>
                                            <div className='mb-img-top'>
                                                <div className='mb-logo'>
                                                    <Image
                                                        src={cardlogo}
                                                        alt="MightyMeals Logo"
                                                    />
                                                </div>
                                                <h6 className='mb-gift'>Gift Card</h6>
                                            </div>
                                            <div className='mb-img-bottom'>
                                                <div className='mb-price'>${giftCard?.regular_price}</div>
                                                <div className='mb-subtitle'>Mightybucks</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mb-right'>
                                        <div className='mb-img-wrap'>
                                            <img src='https://eatmightymeals.com/wp-content/uploads/2023/07/mightybuck.jpg' alt='meal' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-line'></div>
                            <div className='mb-content-block'>
                            {selectedProductId === giftCard.id && errorMsg &&
                                    <ToastError
                                        msg={errorMsg}
                                        onClose={() => setErrorMsg('')}
                                        position="middle-center"
                                    />
                                }
                                <h5>MightyBucks Gift Cards</h5>
                                <p>Buy <span className='font-barlow'>$</span>{giftCard?.regular_price} worth for 
                                <span className='font-barlow'> $</span>{giftCard?.price} 
                                 {giftCard.meta_data.map((metaItems: any, idx: number) => (
                                    metaItems.key === 'gift_card_discount_note' && <span key={idx}> ({metaItems.value} Discount)</span>
                                 ))}</p>
                                <Button
                                    className='w-100'
                                    variant="success"
                                    size="sm"
                                    disabled={orderInProgress || cartUpdateInProgress || isAdding}
                                    onClick={() => {
                                        setSelectedProductId(giftCard.id);
                                        handleBucksGirftCards(giftCard?.price, giftCard.id);
                                      }}
                                >
                                    {(selectedProductId === giftCard.id && isAdding) ? 
                                    <>ADDING TO CART ...  <Spinner animation="border" as="span" size="sm" /></> : 'ADD TO CART'}
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    )
}

export default BucksCard