import { useSelector } from 'react-redux';
import { Image } from 'react-bootstrap';
import logo from '../assets/images/big-mightymeals_logo.png';
import circleLogo from '../assets/images/mm_circle_logo.png';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { selectEmbedded, selectLogoUrl } from '../features/referrals/referralsSlice';

export default function NavLogoDesktop() {
  //const embedded = useSelector(selectEmbedded);
  const affiliateLogoUrl = useSelector(selectLogoUrl);

  return (
    <>
      {affiliateLogoUrl ? 
        <div className='d-flex gap-1 align-items-center'>
          <Image 
            className="mm-affiliate border-0" 
            width="75"
            src={circleLogo} 
            thumbnail={true}
            alt="MightyMeals Logo" 
          />
          <Image 
            className="mm-affiliate mm-thumbnail border-0" 
            width="100"
            src={affiliateLogoUrl} 
            thumbnail={true}
            alt="Affiliate Logo" 
          />
        </div>
      :
        <img className="mm-logo" src={logo} alt="MightyMeals Logo" /> 
      }
    </>
  )
}