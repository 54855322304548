import React, { useState } from 'react';
import '../core/filters.scss';
import { loadWorkouts } from '../core/workoutSlice';
import { useDispatch } from 'react-redux';

export default function SearchFilter() {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleInputBlur = () => {
        handleSearch();
    };

    const handleSearch = () => {
        console.log(searchText, "searchText")
        let url = `mighty_fits?page=1&search_text=${searchText}`;
        dispatch(loadWorkouts(url));
    };

    return (
        <div className="form-wrap">
            <input
                type="text"
                name="search_text"
                placeholder="Search..."
                value={searchText}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleInputKeyPress(e)}
                onBlur={handleInputBlur}
            />
            <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={handleSearch}
                style={{ cursor: 'pointer' }}
            >
                <path
                    d="M8.625 16.25C12.56 16.25 15.75 13.06 15.75 9.125C15.75 5.18997 12.56 2 8.625 2C4.68997 2 1.5 5.18997 1.5 9.125C1.5 13.06 4.68997 16.25 8.625 16.25Z"
                    stroke="#707070"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M16.5 17L15 15.5" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    );
}

// import React from 'react'
// import '../core/filters.scss';
// import { loadWorkouts } from '../core/workoutSlice';
// import { useDispatch } from 'react-redux';

// export default function SearchFilter() {
//     const dispatch = useDispatch();

//     const handleSearch = (e: any) => {
//         let url = `mighty_fits?page=1&serach_text=${e.target.value}`;

//         // eslint-disable-next-line @typescript-eslint/no-unused-vars
//         url = url.slice(0, -1);
//         dispatch(loadWorkouts(url))
//     }
//     return (
//         <div className="form-wrap">
//             <input type="text" name="search_text" placeholder="Search..." onBlur={(e: any) => handleSearch(e)} />
//             <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
//                 <path d="M8.625 16.25C12.56 16.25 15.75 13.06 15.75 9.125C15.75 5.18997 12.56 2 8.625 2C4.68997 2 1.5 5.18997 1.5 9.125C1.5 13.06 4.68997 16.25 8.625 16.25Z" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" />
//                 <path d="M16.5 17L15 15.5" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" />
//             </svg>
//         </div>
//     )
// }