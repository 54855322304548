import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Image } from 'react-bootstrap';
import PlusMinusInput from "../../components/PlusMinusInput";
import {
  selectCartItems,
  selectCartUpdateInProgress,
  selectOrderInProgress,
  setCartItem
} from "./cartSlice";
import { ICartItem } from './interfaces';
import { selectProducts } from '../../features/products/productsSlice';
import { selectUser } from '../user/userSlice';
import ProductObj from '../products/productObj';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { setNotifyItem } from '../mobile/mobileSlice';
import TiktokPixel from 'tiktok-pixel';
import CustomerObj from '../customer/customerObj';
import { selectCustomer } from '../customer/customerSlice';
import Helper from '../../utils/Helper';
import { useMemo } from 'react';
import CartObj from './cartObj';
import { setIsOrderChanged } from '../../pages/AutoshipPages/core/autoShipSlice';

declare const _learnq: any;
interface Props {
  product_id: number;
  cart_item: ICartItem;
}

export default function CartItem({ product_id, cart_item }: Props) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const customer = new CustomerObj(useSelector(selectCustomer));
  const cartUpdateInProgress = useSelector(selectCartUpdateInProgress);
  const orderInProgress = useSelector(selectOrderInProgress);
  const products = useSelector(selectProducts);
  const product = ProductObj.getById(products, product_id);
  const cartItems = useSelector(selectCartItems);
  const cart = useMemo(() => {
    return new CartObj(cartItems);
  }, [cartItems]);

  const trackAddToCartEvent = (num_items: number) => {
    ReactPixel.track(
      'AddToCart',
      {
        content_ids: [product_id],
        content_name: product?.data.name,
        content_type: 'product',
        currency: 'USD',
        num_items: num_items,
        value: cart_item.product_price * num_items
      }
    );
    ReactGA.event(
      'add_to_cart',
      {
        currency: 'USD',
        items: [{
          item_id: product_id,
          item_name: product?.data.name,
          quantity: num_items
        }],
        value: cart_item.product_price * num_items
      }
    );
    
    TiktokPixel.track(
      'AddToCart',
      {
        contents: [
          {
            content_id: product_id,
            content_name: product?.data.name,
            quantity: num_items,
            price: product?.data?.price,
          }
        ],
        content_type: 'product',
        value: cart_item.product_price * num_items,
        currency: 'USD'
      }
    );

    // Reddit Pixel tracking
    const email = customer.data.email ? customer.data.email : "";
    (window as any).rdt('track', 'AddToCart', {
      email: email,
      currency: 'USD',
      itemCount: num_items,
      value: cart_item.product_price * num_items,
      products: [
        {
          id: product_id,
          name: product?.data.name,
          category: product?.data.categories,
        }
      ]
    });

    const item = {
      CheckoutURL: `${process.env.REACT_APP_WEB_URL}checkout`,
      items: [{
        ProductName: product?.data.name,
        ProductID: product?.data.id,
        SKU: product?.data.sku,
        Categories: product?.data.categories,
        ImageURL: product?.data.images[0].src,
        URL: `${process.env.REACT_APP_WEB_URL}product/${product?.data.slug}`,
        Price: product?.data.price,
        product_id: product?.data.id,
        product_name: product?.data.name,
      }],
      $value: cart_item.product_price * num_items
    };

    if (typeof _learnq !== 'undefined') {
      _learnq.push(["track", "Added to Cart", item]);
    }
  }

  const handleQtyChange = (qty: number) => {
    if (qty - cart_item.product_qty > 0) {
      trackAddToCartEvent(qty - cart_item.product_qty);
    }
    dispatch(setCartItem({
      token: user.token,
      cart_item: {
        product_id: product_id,
        product_qty: qty,
        product_price: cart_item.product_price
      }
    }));
    const newCartItem = {
      productName: product?.data?.name,
      productPrice: cart_item.product_price,
      productIMG: product?.data?.images[0]?.src,
    };
    dispatch(setNotifyItem(newCartItem));
    dispatch(setIsOrderChanged(false));
  }

  return (
    <div className="cart-item">
      <hr />
      <Row className='py-1 cart-item-row mx-0'>
        <Col xs={4} className='ps-0'>
          {product &&
            <Image
              src={product.data.images[0].src}
              thumbnail={true}
          />}
          <div className="text-center fw-bold d-none d-md-block">
            {(cart.hasProductWithCategory('gift-card', products) || 
                cart.hasProductWithCategory('mighty-bucks-gift-card', products) ||
                cart.hasProductWithCategory('giftcard-donation', products)) ?
                Helper.formattedCurrency(cart_item.product_price) :
                product && customer.getProductPrice(product, true)}
          </div>
        </Col>
        <Col xs={8} className='px-0'>
          <div className="pb-1 text-md-center">
            <div className='pb-2'>
              {product && product.data.name}
            <div className='d-md-none fw-bold'> {product && customer.getProductPrice(product, true)}</div>
            </div>
            <div>
              <PlusMinusInput
                onChange={handleQtyChange}
                value={cart_item.product_qty}
                disabled={orderInProgress || cartUpdateInProgress || product?.isNameYourPrice()}
                className='justify-content-center'
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}