import { ICartItems } from "./interfaces";
import Helper from '../../utils/Helper';
import CouponObj from '../coupons/couponObj';
import ProductObj from "../products/productObj";
import { mightyPointsToCurrency } from '../customer/mightyPointsUtils';
import CustomerObj from "../customer/customerObj";

export default class CartObj {
  items: ICartItems;

  constructor(items: ICartItems) {
    this.items = items;
  }

  // Remove any produs not contained in passed-in product array
  cleanse = (
    products: Array<Record<string, any>>
  ) => {
    for (let productId in this.items) {
      const product = ProductObj.getById(products, parseInt(productId));
      if (!product) {
        delete this.items[productId]
      }
    }
  }

  decrementProductQtyByID = (productID: number) => {
    if (this.items[productID].product_qty > 1) {
      this.items[productID].product_qty -= 1;
    } else {
      delete this.items[productID];
    }
  }

  getAmtForFreeShipping = (formatted = false, shippingMethod?: string, vanThreshold?: number, upsThreshold?: number) => {
    const limitedPrice: number = (shippingMethod === "UPS Free Shipping" || shippingMethod === "UPS Shipping") ? upsThreshold || 130 : vanThreshold || 130;

    const subtotal = this.getSubtotal(null, null, null) as number;
    let amount = 0;

    if (limitedPrice !== undefined && subtotal < limitedPrice) {
      amount = limitedPrice - subtotal;
    }

    if (!formatted) return amount;

    return Helper.formattedCurrency(amount);
  }

  getContents = () => {
    let contents = [];

    for (let productID in this.items) {
      contents.push({
        'id': productID,
        'quantity': this.items[productID].product_qty
      });
    }

    return contents;
  }

  getItemsForGA = (products: Array<Record<string, any>>) => {
    let items = [];
    for (let productId in this.items) {
      let cartItem = this.items[productId];
      let product = ProductObj.getById(products, parseInt(productId));
      if (product) {
        items.push({
          'item_id': parseInt(productId),
          'item_name': product.data.name,
          'quantity': cartItem.product_qty
        });
      }
    }
    return items;
  }

  getItemCount = () => {
    let count = 0;
    for (let product_id in this.items) {
      count += this.items[parseInt(product_id)].product_qty;
    }
    return count;

  }

  getLineItems = (products: Array<Record<string, any>>, cartCoupon?: CouponObj | null) => {
    let lineItems = [];
    let remainingDiscountableQty = 6;

    type LineItem = {
      product_id: number;
      quantity: any;
      custom_price?: string;
      total?: number;
    };

    // Sort cart items by price in descending order
    const sortedItems = Object.entries(this.items).sort(([idA, itemA], [idB, itemB]) => {
      const productA = ProductObj.getById(products, parseInt(idA));
      const productB = ProductObj.getById(products, parseInt(idB));
      const priceA = productA ? productA.data.price : 0;
      const priceB = productB ? productB.data.price : 0;
      return priceA - priceB;
    });

    const freeProducts = cartCoupon?.data.meta_data?.find((meta: any) => meta.key === '_wjecf_free_product_ids')
      ?.value.split(',').map(Number) || [];

    const cartIds = Object.keys(this.items);

    const allMatch = freeProducts.length && freeProducts.every((id: any) => cartIds.includes(String(id)));

    for (const [productId, cartItem] of sortedItems) {
      let product = ProductObj.getById(products, parseInt(productId));
      let customPrice = '';

      const meta_data = cartCoupon?.data?.meta_data;
      const freeProductsMeta = meta_data?.find((meta: any) => meta.key === '_wjecf_free_product_ids');
      const matchedFreeProducts = freeProductsMeta?.value.split(',').map((id: string) => parseInt(id, 10));
      const includedProductIds = matchedFreeProducts?.includes(parseInt(productId));
      const freeMetaProducts = 1;

      const isCouponApplicable = cartCoupon && cartCoupon.data.code.startsWith('ref') &&
        cartCoupon.data.product_categories && product?.data.categories &&
        product.data.categories.some((category: any) => cartCoupon.data.product_categories.includes(category.id));

      let maxDiscountableQty = 0;

      if (isCouponApplicable) {
        maxDiscountableQty = Math.min(remainingDiscountableQty, cartItem.product_qty);
        remainingDiscountableQty -= maxDiscountableQty;
        customPrice = (cartItem.product_price * maxDiscountableQty).toFixed(2);
      }

      let lineItem: LineItem = {
        product_id: parseInt(productId),
        quantity: cartItem.product_qty,
      };

      if (isCouponApplicable) {
        lineItem.custom_price = String(cartItem.product_price * cartItem.product_qty - Number(customPrice));
      }

      if (includedProductIds && cartItem.product_qty === freeMetaProducts) {
        lineItem.custom_price = '0.00';
      } else if (includedProductIds && cartItem.product_qty && cartItem.product_qty > freeMetaProducts && product) {
        const productPrice = cartItem.product_price * cartItem.product_qty;
        const freeProductPrice = cartItem.product_price * freeMetaProducts;
        lineItem.custom_price = String(productPrice - Number(freeProductPrice));
      } else if (allMatch && freeProducts.includes(parseInt(productId))) {
        lineItem.custom_price = '0.00';
      }

      // Additional logic for Name Your Price products
      if (product && product.isNameYourPrice()) {
        lineItem.total = cartItem.product_qty * cartItem.product_price;
      }

      lineItems.push(lineItem);

      if (remainingDiscountableQty <= 0) {
        break;
      }
    }

    return lineItems;
  };

  getPoints = (products: Array<Record<string, any>>) => {
    let points = 0;

    for (const productId in this.items) {
      const cartItem = this.items[parseInt(productId)];
      let product = ProductObj.getById(products, parseInt(productId));
      if (product) {
        points += product.getPoints() * cartItem.product_qty;
      }
    }
    return points;

  }

  getEarnedPoints = (products: Array<Record<string, any>>, coupon: CouponObj | null,) => {
    let points = 0;

    for (const productId in this.items) {
      const cartItem = this.items[parseInt(productId)];
      let product = ProductObj.getById(products, parseInt(productId));
      if (product) {
        if (coupon) {
          points += (product.getPoints() * (coupon?.getEarnedPoints() / 100)) * cartItem.product_qty;
        } else {
          points += product.getPoints() * cartItem.product_qty;
        }
      }
    }
    return points;

  }

  getPriciestProductID = () => {
    let maxProductID = null;
    let maxPrice = 0;

    for (let productID in this.items) {
      if (this.items[productID].product_price > maxPrice) {
        maxProductID = parseInt(productID);
        maxPrice = this.items[productID].product_price;
      }
    }

    return [maxProductID, maxPrice];
  }

  getProductCatSubtotal = (
    productCats: number | Array<number>,
    products: Array<Record<string, any>>,
    formatted = false
  ) => {
    productCats = Array.isArray(productCats) ? productCats : [productCats];
    let subtotal = 0;
    for (let productId in this.items) {
      let product = ProductObj.getById(products, parseInt(productId));

      if (product && product.hasCategoryID(productCats)) {
        let cartItem = this.items[productId];
        subtotal += cartItem.product_qty * cartItem.product_price;
      }
    }

    if (!formatted) return subtotal;

    return Helper.formattedCurrency(subtotal);
  }

  getProductIDs = () => {
    let productIDs = [];

    for (let productID in this.items) {
      productIDs.push(productID)
    }
    return productIDs;
  }

  getProductNames = (products: Array<Record<string, any>>) => {
    let productNames = [];

    for (let productID in this.items) {
      const product = ProductObj.getById(products, parseInt(productID));
      if (product) productNames.push(product.data.name);
    }

    return productNames;
  }

  getProductSubtotal = (
    productIds: number | Array<number>,
    formatted = false,
    productPrice?: any
  ) => {
    productIds = Array.isArray(productIds) ? productIds : [productIds];
    let subtotal = 0;

    for (const productId of productIds) {
      if (productId in this.items) {
        let cartItem = this.items[productId];
        const price = productPrice ? productPrice : cartItem.product_price;
        subtotal += cartItem.product_qty * price;
      }
    }

    if (!formatted) return subtotal;

    return Helper.formattedCurrency(subtotal);
  }

  getSubtotal = (
    coupon: CouponObj | null,
    products: Array<Record<string, any>> | null,
    customer: CustomerObj | null,
    formatted = false,
    MPConversionRate?: any
  ) => {
    let subtotal = 0;
    for (const product_id in this.items) {
      let cart_item = this.items[product_id];
      if (products) {
        const product = ProductObj.getById(products, Number(product_id));
        if (product && customer) {
          subtotal += cart_item.product_qty * Number(customer.getProductPrice(product, false));
        } else {
          subtotal += cart_item.product_qty * cart_item.product_price;
        }
      } else {
        subtotal += cart_item.product_qty * cart_item.product_price;
      }
    }

    let discount = 0;

    if (coupon) {
      if (coupon.data.code.startsWith('ref')) {
        if (products) {
          if (coupon.data.meal_limit) {
            discount = customer ?
              coupon.getCartPercentDiscount(products, this.items, coupon, false, customer) as number :
              coupon.getCartPercentDiscount(products, this.items, coupon) as number;
          }
        }
      } else if (customer && coupon.isPointsCoupon(customer)) {
        let maxPoints = customer.getMaxUsablePoints(subtotal);
        discount = Number(MPConversionRate) ?
          mightyPointsToCurrency(maxPoints, false, MPConversionRate) as number :
          mightyPointsToCurrency(maxPoints) as number;
      } else if (
        ['fixed_cart', 'fixed_product', 'percent_product', 'percent'].includes(coupon.data.discount_type) &&
        !coupon.data.code.startsWith('ref')
      ) {
        const { meta_data } = coupon.data;
        const freeProductsMeta = meta_data?.find((meta: any) => meta.key === '_wjecf_free_product_ids');
        const freeProducts = freeProductsMeta?.value.split(',').map((id: string) => parseInt(id, 10));
        const cartIds = Object.keys(this.items);
        const allMatch = freeProducts?.every((id: number) => cartIds.includes(String(id)));

        if (allMatch && products) {
          discount = freeProducts?.reduce((acc: number, productId: number) => {
            const product = ProductObj.getById(products, productId);
            return product ? acc + Number(product.data.price) : acc;
          }, 0);
        } else if (products) {
          discount = coupon.getCartSubtotalDiscount(this, products) as number;
        }
      } else if (coupon.data.discount_type === 'smart_coupon') {
        discount = coupon.getSmartCouponAmount(subtotal) as number;
      }
    }

    subtotal -= discount;

    if (!formatted) return subtotal;

    return Helper.formattedCurrency(subtotal);
  }

  getTax = (
    products: Array<Record<string, any>>,
    coupon: CouponObj | null,
    formatted = false,
    stateTax?: any,
    customer?: CustomerObj | null,
  ) => {
    const taxPercentage = stateTax !== undefined && stateTax !== null && stateTax !== '';
    let tax = 0;
    const epsilon = 0.0001;

    const freeProducts = coupon?.data?.meta_data?.find((meta: any) => meta.key === '_wjecf_free_product_ids')
      ?.value.split(',').map(Number) || [];

    const cartIds = Object.keys(this.items);

    const allMatch = freeProducts.length && freeProducts.every((id: any) => cartIds.includes(String(id)));

    for (let productId in this.items) {
      let cartItem = this.items[productId];
      const product = ProductObj.getById(products, Number(productId));
      const meta_data = coupon?.data?.meta_data;
      const freeProductsMeta = meta_data?.find((meta: any) => meta.key === '_wjecf_free_product_ids');
      const matchedFreeProducts = freeProductsMeta?.value.split(',').map((id: string) => parseInt(id, 10));
      const includedProductIds = matchedFreeProducts?.includes(parseInt(productId));
      const freeMetaProducts = 1;
      // const isCouponApplicable = product && coupon && product.data.categories &&
      //   Array.isArray(coupon.data.product_categories) && product.data.categories.some((category: any) =>
      //     coupon.data.product_categories.includes(category.id)
      //   );//

      if (product?.data.tax_class === 'zero-rate') continue;

      if (includedProductIds && cartItem.product_qty === freeMetaProducts) continue;
      
      if (includedProductIds && cartItem.product_qty && cartItem.product_qty > freeMetaProducts) {
        if (product && customer) {
          const productPrice = (Number(customer.getProductPrice(product, false)) * cartItem.product_qty);
          const freeProductPrice = Number(customer.getProductPrice(product, false)) * freeMetaProducts;
          const finalProductPrice = productPrice - freeProductPrice;
          tax += finalProductPrice * (taxPercentage ? stateTax : 6) / 100;
        } else {
          const productPrice = cartItem.product_price * cartItem.product_qty;
          const freeProductPrice = cartItem.product_price * freeMetaProducts;
          const finalProductPrice = productPrice - freeProductPrice;
          tax += finalProductPrice * (taxPercentage ? stateTax : 6) / 100;
        }
      }

      if (allMatch && freeProducts.includes(Number(productId))) continue;

      // if (isCouponApplicable === true) continue;

      if (product && customer) {
        tax += (cartItem.product_qty * Number(customer.getProductPrice(product, false))) * (taxPercentage ? stateTax : 6) / 100;
      } else {
        tax += (cartItem.product_qty * cartItem.product_price) * (taxPercentage ? stateTax : 6) / 100;
      }
    }

    if (coupon) {
      const totalDiscount = coupon.getCartSubtotalDiscount(this, products) as number;
      //const subTotal = this.getSubtotal(null, products, null) as number;
      const taxRate = taxPercentage ? stateTax : 6;
      const couponType = coupon.data.discount_type === "percent";

      if (coupon.data.amount === "100" && couponType) {
        tax = 0;
      } else if (tax > 0) {
        const subTotalWithRate = totalDiscount * taxRate;
        const cartSubTotal = subTotalWithRate / 100;
        tax -= cartSubTotal;
      }
    }

    tax = Math.abs(tax) < epsilon ? 0 : Math.round(tax * 100) / 100;

    if (!formatted) return tax;

    return Helper.formattedCurrency(tax);
  }

  getTotal = (
    products: Array<Record<string, any>>,
    shippingCost: number,
    coupon: CouponObj | null,
    customer: CustomerObj | null,
    formatted = false,
    stateTax?: any,
  ) => {
    let total;
    if (coupon && coupon.isPreTax()) {
      total = this.getSubtotal(coupon, products, customer, false) as number;
    } else {
      if (coupon && coupon.data.discount_type === "percent") {
        total = this.getSubtotal(coupon, products, customer, false) as number;
      } else {
        total = this.getSubtotal(null, products, customer, false) as number;
      }
    }

    total += shippingCost;
    total += this.getTax(products, coupon, false, stateTax, customer) as number;

    total = Math.round(total * 100) / 100;

    let discount = 0;

    if (coupon) {
      if (coupon.data.discount_type === 'smart_coupon') {
        discount = coupon.getSmartCouponAmount(total) as number;
      } else if (coupon.data.code.startsWith('ref')) {
        discount = customer ?
          coupon.getCartSubtotalDiscount(this, products, false, customer) as number :
          coupon.getCartSubtotalDiscount(this, products) as number;
      }
    }

    total -= discount;

    if (!formatted) return total;

    return Helper.formattedCurrency(total);
  }

  hasProductWithCategory = (
    category: string,
    products: Array<Record<string, any>>
  ) => {
    let product;
    for (let productId in this.items) {
      product = ProductObj.getById(products, parseInt(productId));
      if (product && product.hasCategory(category)) return true;
    }
    return false;
  }

  hasProductWithCategoryID = (
    categoryID: number,
    products: Array<Record<string, any>>
  ) => {
    let product;
    for (let productId in this.items) {
      product = ProductObj.getById(products, parseInt(productId));
      if (product && product.hasCategoryID(categoryID)) return true;
    }
    return false;
  }

  hasProductWithoutCategory = (
    category: string,
    products: Array<Record<string, any>>
  ) => {
    let product;
    for (let productId in this.items) {
      product = ProductObj.getById(products, parseInt(productId));
      if (product && !product.hasCategory(category)) return true;
    }
    return false;
  }

  hasProductWithID = (id: string) => {
    for (let productId in this.items) {
      if (productId === id) return true;
    }
    return false;
  }

  isEmpty = () => {
    return Object.keys(this.items).length === 0;
  }

  removeProductsWithoutCategoryID = (
    categoryIDs: Array<number>,
    products: Array<Record<string, any>>
  ) => {
    let product;
    for (let productId in this.items) {
      product = ProductObj.getById(products, parseInt(productId));
      let hasCategory = false;
      for (let categoryID of categoryIDs) {
        if (product?.hasCategoryID(categoryID)) {
          hasCategory = true;
          break;
        }
      }
      if (!hasCategory) {
        delete this.items[productId]
      }
    }
  }
}