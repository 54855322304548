import { useSelector } from 'react-redux';
import { selectCustomerDetails, selectCustomerDetailsIsLoaded, selectCustomerDetailsIsLoading } from '../../features/customer/customerDetailSlice';
import './CircularProgressBar.scss';

interface Props {
    progress: number
}
function CircularProgressBar({ progress }: Props) {
    const strokeWidth = 10;
    const radius = 50 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - progress / 100 * circumference;
    const customerData = useSelector(selectCustomerDetails);
    const customerLoading = useSelector(selectCustomerDetailsIsLoading);
    const customerLoaded = useSelector(selectCustomerDetailsIsLoaded);

    return (
        <div className="circular-progress-bar position-relative">
            <svg
                className="progress-ring"
                width="100%"
                height="100%"
                viewBox="0 0 100 100"
            >
                <circle
                    className="progress-ring-circle"
                    stroke="#eee"
                    strokeWidth={strokeWidth}
                    fill="transparent"
                    r={radius}
                    cx="50"
                    cy="50"
                />
                <circle
                    className="progress-ring-indicator"
                    stroke="#000"
                    strokeWidth={strokeWidth}
                    fill="transparent"
                    r={radius}
                    cx="50"
                    cy="50"
                    style={{
                        strokeDasharray: `${circumference} ${circumference}`,
                        strokeDashoffset: offset
                    }}
                />
            </svg>
            <span className='progress-ring-text position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center'>
                {(!customerLoading && customerLoaded && customerData) ? `${progress}%` : `0%`}
                </span>
        </div>
    );
}

export default CircularProgressBar;