import { Col, Row } from 'react-bootstrap';
import OrderObj from './orderObj';

interface Props {
  order: OrderObj;
}

export default function DeliveryDetails({ order }: Props) {
  return (
    <div className='delivery-details'>
      <Row className='my-2'>
        <Col xs={12} className='font-glegoo fs-23px fw-700'>
          Delivery Details:
        </Col>
      </Row>
      <Row className='my-2'>
        <Col xs={12} className='fs-5'>
          Your order will arrive 
          on <span className='fw-bold'>{order.getDeliveryDate('long')}</span>
        </Col>
      </Row>
    </div>
  )
}