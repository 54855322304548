export default class pagesAPI {
  static async getPage(pageId: number) {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/pages/' + pageId,
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset=UTF-8' }
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }
}