import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FormikErrors, useFormik } from "formik";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { loadCustomer, selectCustomer, selectCustomerIsLoaded } from "./customerSlice";
import { selectToken } from '../user/userSlice';
import CustomerObj from "./customerObj";
import CustomerAPI from "../../API/customerAPI";
import Window from '../../utils/Window';
import {
  selectRefferalBtnText,
  selectRefferalSMSBodyText,
  setRefferalBtnText,
  setRefferalCode,
  setRefferalSMSBodyText
} from '../mobile/mobileSlice';

interface FormValues {
  email1: string;
  email2: string;
  email3: string;
  email4: string;
  email5: string;
}
export default function ReferAFriendForm() {
  const dispatch = useDispatch();
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsgs, setErrorMsgs] = useState<string[]>([]);
  const [busy, setBusy] = useState(false);
  const customerIsLoaded = useSelector(selectCustomerIsLoaded);
  const customer = new CustomerObj(useSelector(selectCustomer));
  const token = useSelector(selectToken);
  const referralSMSBodyText = useSelector(selectRefferalSMSBodyText);
  const referralButtonText = useSelector(selectRefferalBtnText);

  function isIOS() {
    const userAgent = navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod|macintosh/i.test(userAgent);
  }

  useEffect(() => {
    if(!customerIsLoaded){
      handleCustomerLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(customerIsLoaded){
      if (Window.isMobile()) {
        handleReferFriends(customer?.data?.email);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerIsLoaded])

  const handleCustomerLoad = async () => {
    setBusy(true);
    await dispatch(loadCustomer(token));
    setBusy(false);
  }

  const handleReferFriends = async (email: string) => {
    setBusy(true);
    await CustomerAPI.referFriendSMS(
      email,
    ).then((response) => {
      if (response?.status === 200) {
        dispatch(setRefferalCode(response?.code))
        dispatch(setRefferalSMSBodyText(response?.sms_body_text))
        dispatch(setRefferalBtnText(response?.sms_button_text))
      }
      if ('errors' in response) console.log(response.errors);
    }).catch((e) => {
      console.log([e.message]);
    }).finally(() => {
      setBusy(false);
    })
  }

  const validate = (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {};

    if (!values.email1 && !values.email2 && !values.email3 &&
      !values.email4 && !values.email5
    ) {
      errors.email1 = "Required";
    }

    if (values.email1 &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email1)
    ) {
      errors.email1 = "Invalid email address";
    }

    if (values.email2 &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email2)
    ) {
      errors.email2 = "Invalid email address";
    }
    if (values.email3 &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email3)
    ) {
      errors.email3 = "Invalid email address";
    }
    if (values.email4 &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email4)
    ) {
      errors.email4 = "Invalid email address";
    }
    if (values.email5 &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email5)
    ) {
      errors.email5 = "Invalid email address";
    }

    return errors;
  }

  const formik = useFormik({
    initialValues: {
      email1: '',
      email2: '',
      email3: '',
      email4: '',
      email5: ''
    },
    validate,
    onSubmit: (values, actions) => {
      setSuccessMsg('');
      setErrorMsgs([]);
      setBusy(true);
      CustomerAPI.referFriends(
        token,
        [values.email1, values.email2, values.email3, values.email4,
        values.email5].filter(Boolean)
      ).then((response) => {
        if ('success' in response) {
          setSuccessMsg(response.success);
          actions.resetForm();
        }
        if ('errors' in response) setErrorMsgs(response.errors);
      }).catch((e) => {
        setErrorMsgs([e.message]);
      }).finally(() => {
        setBusy(false);
      })
    }
  });

  if (!customerIsLoaded) {
    return (
      <></>
    )
  }

  if (customer?.data?.refer_a_friend?.can_share !== true) {
    return (
      <p className='fs-4 text-center'>
        {customer?.data?.refer_a_friend?.can_share?.message}
      </p>
    );
  }

  return (
    <Form className={`refer-a-friend-form mx-4 ${Window.isMobile() ? 'text-center' : ''}`} onSubmit={formik.handleSubmit}>
      {successMsg &&
        <Alert variant='success'>{successMsg}</Alert>
      }
      {errorMsgs.length > 0 && (
        errorMsgs.map((msg, index) => (
          <Alert key={index} variant='danger'>{msg}</Alert>
        ))
      )}
      {!Window.isMobile() ? (<>
        <Form.Group className="mb-3 form-group required">
          <Form.Control
            id="email1"
            type="email"
            isValid={Boolean(formik.values.email1) && !Boolean(formik.errors.email1)}
            isInvalid={Boolean(formik.errors.email1)}
            value={formik.values.email1}
            onChange={formik.handleChange}
            placeholder="Enter email address"
            autoComplete="foo"
          />
          {formik.errors.email1 &&
            <Form.Control.Feedback type="invalid">
              {formik.errors.email1}
            </Form.Control.Feedback>
          }
        </Form.Group>
        <Form.Group className="mb-3 form-group required">
          <Form.Control
            id="email2"
            type="email"
            isValid={Boolean(formik.values.email2) && !Boolean(formik.errors.email2)}
            isInvalid={Boolean(formik.errors.email2)}
            value={formik.values.email2}
            onChange={formik.handleChange}
            placeholder="Enter email address"
            autoComplete="foo"
          />
          {formik.errors.email2 &&
            <Form.Control.Feedback type="invalid">
              {formik.errors.email2}
            </Form.Control.Feedback>
          }
        </Form.Group>
        <Form.Group className="mb-3 form-group required">
          <Form.Control
            id="email3"
            type="email"
            isValid={Boolean(formik.values.email3) && !Boolean(formik.errors.email3)}
            isInvalid={Boolean(formik.errors.email3)}
            value={formik.values.email3}
            onChange={formik.handleChange}
            placeholder="Enter email address"
            autoComplete="foo"
          />
          {formik.errors.email3 &&
            <Form.Control.Feedback type="invalid">
              {formik.errors.email3}
            </Form.Control.Feedback>
          }
        </Form.Group>
        <Form.Group className="mb-3 form-group required">
          <Form.Control
            id="email4"
            type="email"
            isValid={Boolean(formik.values.email4) && !Boolean(formik.errors.email4)}
            isInvalid={Boolean(formik.errors.email4)}
            value={formik.values.email4}
            onChange={formik.handleChange}
            placeholder="Enter email address"
            autoComplete="foo"
          />
          {formik.errors.email4 &&
            <Form.Control.Feedback type="invalid">
              {formik.errors.email4}
            </Form.Control.Feedback>
          }
        </Form.Group>
        <Form.Group className="mb-3 form-group required">
          <Form.Control
            id="email5"
            type="email"
            isValid={Boolean(formik.values.email5) && !Boolean(formik.errors.email5)}
            isInvalid={Boolean(formik.errors.email5)}
            value={formik.values.email5}
            onChange={formik.handleChange}
            placeholder="Enter email address"
            autoComplete="foo"
          />
          {formik.errors.email5 &&
            <Form.Control.Feedback type="invalid">
              {formik.errors.email5}
            </Form.Control.Feedback>
          }
        </Form.Group>
        {busy ?
          <Button variant="dark" disabled>
            <Spinner animation="border" as="span" size="sm" />
            &nbsp;&nbsp;Processing ...
          </Button> :
          <Button variant="dark" type="submit">
            Send
          </Button>
        }
      </>) :
        (<>
          {busy ? (
            <Spinner className="text-center text-dark" variant="primary" animation="grow" />
          ) : (
            <>
              {referralSMSBodyText && referralButtonText && (
                <>
                  <p>Send your referral code to friends using a pre-filled text message to your friends with your referral code already applied.</p>
                  <a
                    href={`sms:${isIOS() ? '&' : '?'}body=${encodeURIComponent(referralSMSBodyText)}`}
                    className="d-flex justify-content-center text-white btn btn-success"
                  >
                    {referralButtonText}
                  </a>
                </>
              )}
            </>
          )}
        </>)
      }
    </Form>
  );
}