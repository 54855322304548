import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card } from 'react-bootstrap';
import { selectCustomer } from './customerSlice';
import BillingAddressForm from './BillingAddressForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

export default function BillingAddress() {
  const customer = useSelector(selectCustomer);
  const [editing, setEditing] = useState(false);
  const handleClick = () => setEditing(true);
  const handleClose = () => setEditing(false);
  const billing_info = customer && customer.billing ? customer.billing : {};
  const billing = {
    first_name: !!billing_info.first_name && billing_info.first_name.trim(),
    last_name: !!billing_info.last_name && billing_info.last_name.trim(),
    address_1: !!billing_info.address_1 && billing_info.address_1.trim(),
    city: !!billing_info.city && billing_info.city.trim(),
    state: !!billing_info.state && billing_info.state.trim(),
    postcode: !!billing_info.postcode && billing_info.postcode.trim(),
    country: 'US',
    phone: !!billing_info.phone && billing_info.phone.trim(),
    email: !!billing_info.email && billing_info.email.trim()
  };
  const anyNullOrEmpty = Object.values(billing).some(value => value === false);

  if (editing) {
    return (
      <BillingAddressForm onClose={handleClose} />
    );
  }
  return (
    <Card className="billing-address">
      <Card.Header>
        <div className="d-flex justify-content-between">
          <span className="fs-4">Billing Address</span>
          <Button variant="light" onClick={handleClick}>
            <FontAwesomeIcon icon={faPenToSquare} size={"xl" as SizeProp} />
          </Button>
        </div>
      </Card.Header>
      <Card.Body>
        {anyNullOrEmpty && <span>No records found.</span>}
        {customer?.billing?.first_name} {customer?.billing?.last_name}<br />
        {customer?.billing?.address_1}<br />
        {customer?.billing?.address_2.length > 0 &&
          <div>{customer?.billing?.address_2}<br /></div>}
        {customer?.billing?.city.length > 0 &&
          <span>{customer?.billing?.city}, </span>}
        {customer?.billing?.state} {customer?.billing?.postcode}<br /><br />
        {customer?.billing?.email}<br />
        {customer?.billing?.phone}
      </Card.Body>
    </Card>
  );
}
