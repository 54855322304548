import { FocusEvent } from 'react';
import { FormControl, InputGroup } from "react-bootstrap";

interface Props {
  className?: string;
  onPriceChange: (price: number) => string;
  price: number;
  disabled?: boolean;
}
export default function NYPInput({ 
    onPriceChange, 
    price, 
    disabled,
    className=''
}: Props) {
  const handlePriceChange = (e: FocusEvent<HTMLInputElement>) => {
    if (onPriceChange(parseFloat(e.target.value))) {
      e.target.value = "0";
    }
  }
  return (
    <div className={`nyp-input d-flex ${className}`}>
      <InputGroup>
        <InputGroup.Text className='text-black border border-black'>$</InputGroup.Text>        
        <FormControl
          type="text"
          maxLength={4}
          size="sm"
          className="text-center"
          defaultValue={price}
          onBlur={handlePriceChange}
          disabled={disabled}
        />
        <InputGroup.Text className='text-black border border-black'>.00</InputGroup.Text>        
      </InputGroup>
    </div>
  );
}