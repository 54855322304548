import { useFormikContext } from "formik";
import { Card, Form } from 'react-bootstrap';
import { FormValues } from './interfaces';
import ShippingMethodObj from "../../features/shipping/shippingMethodObj";
import { selectShippingMethods } from "../../features/shipping/shippingSlice";
import { useSelector } from "react-redux";

interface Props{
  shippingMethod?: number;
}

export default function DeliveryNotesInput({ shippingMethod }: Props) {
  const formik = useFormikContext<FormValues>();
  const shippingMethods = useSelector(selectShippingMethods);
  const shippingMethodByID = ShippingMethodObj.getById(
    shippingMethods,
    shippingMethod || 0
  );
  let placeholder = "Unfortunately we do not provide custom times for home delivery. If you are not home, " +
    "please leave a cooler filled with ice out. Home delivery window is 7am to 9pm.";

  if((shippingMethodByID?.data?.title === 'UPS Shipping' || shippingMethodByID?.data?.title === 'UPS Free Shipping')){
    return (
      <></>
    )
  }
  
  return (
    <Card  className='my-3' body>
      <Form.Group className="mb-3 form-group">
        <Form.Label>Delivery notes (optional)</Form.Label>
        <Form.Control 
          as="textarea"
          id="delivery_notes"
          rows={4}
          placeholder={placeholder}
          isInvalid={Boolean(formik.errors.delivery_notes)}
          value={formik.values.delivery_notes}
          onChange={formik.handleChange}
        />
        {formik.errors.delivery_notes &&
          <Form.Control.Feedback type="invalid">
            {formik.errors.delivery_notes}
          </Form.Control.Feedback>}
      </Form.Group>
    </Card>
  );
}
