import { useState } from 'react';
import { FormikErrors, useFormik } from 'formik';
import { Alert, Button, Container, Form, Spinner } from 'react-bootstrap';
import UserAPI from '../API/userAPI';

interface FormValues {
  pwEmail: string;
}
export default function LostPasswordPage() {
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [busy, setBusy] = useState(false);

  const validate = (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {};
    if (!values.pwEmail) {
      errors.pwEmail = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.pwEmail)) {
      errors.pwEmail = "Invalid email address";
    }

    return errors;
  }

  const formik = useFormik({
    initialValues: {
      pwEmail: ''
    },
    validate,
    onSubmit: values => {
      setErrorMsg('');
      setBusy(true);
      UserAPI.resetPassword(values.pwEmail).then((response) => {
        setSuccessMsg(response.message);
      }).catch((e) => {
        setErrorMsg(e.message);
      }).finally(() => {
        setBusy(false);
      })
    }
  })
  
  return (
    <Container className='lost-password-page'>
      <h2 className='my-5'>Lost Password?</h2>
      {successMsg &&
        <Alert className='mb-4' variant='success'>{successMsg}</Alert> 
      }
      {errorMsg &&
        <Alert className='mb-4' variant='error'>{errorMsg}</Alert> 
      }
      <Form className='reset-password-form' onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3 form-group required">
          <Form.Label>Email address</Form.Label>
          <Form.Control 
            id="pwEmail"
            type="email"
            isValid={Boolean(formik.values.pwEmail) &&
              !Boolean(formik.errors.pwEmail)}
            isInvalid={Boolean(formik.errors.pwEmail)}
            value={formik.values.pwEmail}
            onChange={formik.handleChange}
          />
          {formik.errors.pwEmail &&
            <Form.Control.Feedback type="invalid">
              {formik.errors.pwEmail}
            </Form.Control.Feedback>}
        </Form.Group>
        {busy ? 
          <Button className='bg-black my-3' size="lg" disabled>
            <Spinner animation="border" as="span" size="sm" />
            &nbsp;&nbsp;Resetting Password ...
          </Button> :
          <Button className='bg-black my-3' size="lg" type="submit">
            Reset Password
          </Button>
        }
      </Form>
    </Container>
  )
}