import { useSelector } from 'react-redux';
import { selectCustomer } from './customerSlice';
import { Card } from 'react-bootstrap';
import CustomerObj from './customerObj';
import Membership from './Membership';
import { IMembership } from './interfaces';

export default function MyMemberships() {
  const customer = new CustomerObj(useSelector(selectCustomer));
  const memberships = customer.getMemberships();

  return (
    <Card className='my-memberships my-5 flex-fill' body>
      <h2>Membership Details</h2>
      {memberships?.length > 0 ?
        memberships?.map((membership: IMembership) => {
        return (
          <Membership key={membership.plan_id} membership={membership}/>
        );
      }):
        <div className='fs-4 my-5'>Looks like you don't have any memberships</div>
      }
    </Card>
  );
}