import React from 'react'
import { useSelector } from 'react-redux';
import { selectDetails } from '../../core/detailsSlice';

export default function DescriptionPage() {
    const workoutDetails = useSelector(selectDetails)[0];
    
    return (
        <div className="dw-desall">
            <h1 className='h1'>Details</h1>
            <div className="peraall" dangerouslySetInnerHTML={{__html: workoutDetails?.description}}></div>
            {/* <div className="peraall">
                <p>{workoutDetails?.description?.replace(/(<([^>]+)>)/gi, '')}</p>
            </div> */}
        </div>
    )
}
