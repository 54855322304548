import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, 
         CloseButton, 
         Col, 
         Modal, 
         Row } from 'react-bootstrap';
import { selectCartItems, 
        selectCartUpdateInProgress,
        selectOrderInProgress } from '../cart/cartSlice';
import NYPInput from '../../components/NYPInput';
import CartObj from '../cart/cartObj';
import ProductObj from './productObj';
import ToastError from '../../components/ToastError';

interface Props {
  product: ProductObj;
  show: boolean;
  onHide: () => void;
  onPriceChange: (price: number) => string;
  price: number;
}
export default function NYPProductDetailModal({
  product, 
  show, 
  onHide,
  onPriceChange,
  price
}: Props) {
  const [errorMsg, setErrorMsg] = useState('');
  const cart = new CartObj(useSelector(selectCartItems));
  const cartUpdateInProgress = useSelector(selectCartUpdateInProgress);
  const orderInProgress = useSelector(selectOrderInProgress);
  const description = new DOMParser().parseFromString(product.data.description, "text/html")
    .documentElement.textContent;

  const handlePriceChange = (price: number) => {
    const error = onPriceChange(price);

    if (error) setErrorMsg(error);

    return error;
  }
  
  return (
    <Modal size="xl" show={show} onHide={onHide} className='nyp-product-detail-modal'>
      <Modal.Body className='p-5'>
        <Row>
          <Col><CloseButton className="float-end" onClick={onHide} /></Col>
        </Row>
        <Row>
          <Col className="product-title fs-2">{product.data.name}</Col>
        </Row>
        <Row>
          <Col md={12} lg={6}>
          </Col>
          <Col md={12} lg={6}>
            <div className='d-flex justify-content-between mb-3'>
              <div className='py-2'>
                {errorMsg &&
                  <ToastError 
                    msg={errorMsg} 
                    onClose={() => setErrorMsg('')}
                    position="top-end" 
                    className='m-3'
                  />
                }
                <NYPInput 
                  onPriceChange={handlePriceChange} 
                  price={price} 
                  disabled={orderInProgress || cartUpdateInProgress}
                />
              </div>
              <div className="fs-5">
                {cart.getProductSubtotal(product.data.id, true)}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col  md={12} lg={6}>
            <Card className='border-0'>
              <img
                alt="product-img"
                src={product.data.images[0].src} 
              />
            </Card>
          </Col>
          <Col md={12} lg={6}>
            {description &&
              <Card className='product-description fs-5 h-100' body>
                <p className='mb-5'>{description}</p>
              </Card>
            }
          </Col>
        </Row>
      </Modal.Body>
    </Modal>      
  );
}