import CustomerObj from "../customer/customerObj"
import CouponObj from "./couponObj";

export default class CouponUtils {
  static getDisplayableCoupons = (couponList: Array<Record<string, any>>, customer: CustomerObj) => {
    return couponList.filter((couponData) => {
      let coupon = new CouponObj(couponData);
      if (customer.hasMembership()) {
        if (coupon.data.discount_type === 'smart_coupon') return true;

        return false;
      }

      const validationResult = coupon.validateAgainstCustomer(customer);
      if (validationResult.error) return false;
      return true;
    });
  }
}