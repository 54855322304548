import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from '../../app/store';
import CustomerDetailsAPI  from '../../API/customerDetailsAPI';

export const loadCustomerDetails = createAsyncThunk(
  'customerDetails/loadCustomerDetails',
  async (email: string) => {
    let result: any[] = [];
    result = await CustomerDetailsAPI.getCustomerDetails(email);

    if (result) {
      return result;
    }
    return {};
  }
);

interface CustomerDetailsState {
  customerDetails: Record<string, any>;
  isLoading: boolean;
  isLoaded: boolean;
  loadError: boolean;
}

const initialState: CustomerDetailsState = {
  customerDetails: {},
  isLoading: false,
  isLoaded: false,
  loadError: false
}

export const customerDetailsSlice = createSlice({
  name: "customerDetails",
  initialState: initialState,
  reducers: {
    resetCustomerDetails: (state) => {
      return initialState;
    },
    setCustomerDetails: (state, action: PayloadAction<Record<string, any>>) => {
      return {
        ...state, 
        customerDetails: { ...action.payload },
        isLoaded: true
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(loadCustomerDetails.pending, (state, action) => {
      state.isLoading = true;
      state.isLoaded = false;
    });
    builder.addCase(loadCustomerDetails.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false, 
        isLoaded: true,
        customerDetails: { ...action.payload }
      }
    });
    builder.addCase(loadCustomerDetails.rejected,  (state, action) => {
      state.isLoading = false;
      state.loadError = true;
    });
  }
});
export const { resetCustomerDetails, setCustomerDetails } = customerDetailsSlice.actions;
export const selectCustomerDetails = (state: RootState) => state.customerDetails.customerDetails;
export const selectCustomerDetailsIsLoading = (state: RootState) => state.customerDetails.isLoading;
export const selectCustomerDetailsIsLoaded = (state: RootState) => state.customerDetails.isLoaded;
export const selectCustomerDetailsLoadError = (state: RootState) => state.customerDetails.loadError;
export default customerDetailsSlice.reducer;