import { useParams } from "react-router-dom";
import { Alert, Container } from 'react-bootstrap';
import ElementorPage from "./ElementorPage";

export default function Page() {
  const params = useParams();

  if (!params.pageId) {
    return (
      <Container fluid>
        <Alert className='my-3' variant="danger">
          <Alert.Heading className='fs-1'>
            We're sorry, an error has occurred:
          </Alert.Heading>
          <p className='fs-3 my-4'>Invalid page number</p>
        </Alert>
      </Container>
    )
  }
  
  return (
    <ElementorPage pageNo={parseInt(params.pageId)} />
  );
}