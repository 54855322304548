import { Card } from 'react-bootstrap';
import ProductObj from './productObj';
import { useState } from 'react';

interface Props {
  product: ProductObj;
}
export default function ProductIngredients({ product }: Props) {
  const [viewAllIngredients, setViewAllIngredients] = useState(false);

  return (
    <Card body className='product-ingredients my-3 border border-black border-2'>
        <Card.Title className='fw-600'>Ingredients</Card.Title>
        <div>{product.getIngredients()}</div>
        {product.getSubIngredients().length > 0 && !viewAllIngredients && 
          <span className='fw-600 cursor-pointer' onClick={() => setViewAllIngredients(true)}>
            View All Ingredients
          </span>}
        {viewAllIngredients && <div>{product.getSubIngredients()}</div>}
    </Card>
  )
}