import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from '../../app/store';
import { IMealPlan } from './interfaces';
import MealPlansAPI from '../../API/mealPlansAPI';

interface MealPlansState {
  mealPlans: Array<IMealPlan>;
  loaded: boolean;
  loading: boolean;
  loadError: Boolean;
}

const initialState: MealPlansState = {
  mealPlans: [],
  loaded: false,
  loading: false,
  loadError: false
}

export const loadMealPlans = createAsyncThunk(
  'mealPlans/loadMealPlans',
  async (token: string) => {
    return await MealPlansAPI.getMealPlans(token);
  }
);

export const mealPlansSlice = createSlice({
  name: "mealPlans",
  initialState: initialState,
  reducers: {
    createMealPlan: (state, action: PayloadAction<IMealPlan>) => {
      return {
        ...state,
        mealPlans: [...state.mealPlans, action.payload]
      }
    },
    deleteMealPlan: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        mealPlans: state.mealPlans.filter((mp) => mp.id !== action.payload)
      }
    },
    updateMealPlan: (state, action: PayloadAction<IMealPlan>) => {
      return {
        ...state,
        mealPlans: state.mealPlans.map((mp) => {
          if (mp.id === action.payload.id) return action.payload;
          return mp;
        })
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(loadMealPlans.pending, (state) => {
      return {
        ...state,
        loading: true
      }
    });
    builder.addCase(loadMealPlans.fulfilled, (state, action) => {
      return {
        ...state,
        mealPlans: [...action.payload],
        loading: false,
        loaded: true
      }
    });
    builder.addCase(loadMealPlans.rejected, (state) => {
      return {
        ...state,
        loading: false,
        loadError: true
      }
    });
  }
});

export const { createMealPlan, 
               deleteMealPlan, 
               updateMealPlan } = mealPlansSlice.actions;
export const selectMealPlans = (state: RootState) => state.mealPlans.mealPlans;
export const selectMealPlansLoaded = (state: RootState) => state.mealPlans.loaded;
export const selectMealPlansLoadError = (state: RootState) => state.mealPlans.loadError;
export const selectMealPlansLoading = (state: RootState) => state.mealPlans.loading;
export default mealPlansSlice.reducer;