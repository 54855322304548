import React from 'react'
import { useSelector } from 'react-redux';
import SwiperProductsPage from '../../../../components/SwiperProductsPage';
import { selectDetails } from '../../core/detailsSlice';

export default function RelatedMeals() {
    const workoutDetails = useSelector(selectDetails)[0];

    return (
        <div className="releted-work">
            {workoutDetails?.related_meals && workoutDetails?.related_meals.length > 0 && 
                <div className='pb-5'>
                    <div className="rw-heading">
                        <h2 className='h2'>Related Meals</h2>
                    </div> 
                    <SwiperProductsPage swiperProducts={workoutDetails.related_meals} />
                </div>
            }
        </div>
    )
}
