import React from 'react'
import ProductObj from './productObj';
import { Card, Col, Row } from 'react-bootstrap';

interface Props {
    product: ProductObj;
}

export default function NutritionPerServing({ product }: Props) {
    return (
        <Card body className='product-ingredients my-3 border border-black border-2'>
            <Card.Title className='fw-600'>Nutrition Per Serving</Card.Title>
            <Row className='py-1'>
                <Col sm={12}>
                    {Number(product.getServingsPerContainer()) >= 2 && product.getServingsPerSize() &&
                    <span>Servings Size: {product.getServingsPerSize()}</span>}
                </Col>
                <Col sm={12}>
                    {Number(product.getServingsPerContainer()) >= 2 && 
                    <span>Servings Per Container: {product.getServingsPerContainer()}</span>}
                </Col>
            </Row>
            <div className='pt-2'>
                {product.getCalories().length > 0 && product.getCalories() !== '0' &&
                <Row className='border-top border-bottom py-1'>
                    <Col className='text-start'>
                        <span>Calories</span>
                    </Col>
                    <Col className='text-end'>
                        <span>{product.getCalories()}</span>
                    </Col>
                </Row>}
                {product.getNutritionFat().length > 0 && product.getNutritionFat() !== '0' && 
                <Row className='border-bottom py-1'>
                    <Col className='text-start'>
                        <span>Fat</span>
                    </Col>
                    <Col className='text-end'>
                        <span>{product.getNutritionFat()}</span>
                    </Col>
                </Row>}
                {product.getNutritionSaturatedFat().length > 0 && 
                <Row className='border-bottom py-1'>
                    <Col className='text-start'>
                        <span>Saturated Fat</span>
                    </Col>
                    <Col className='text-end'>
                        <span>{product.getNutritionSaturatedFat()}</span>
                    </Col>
                </Row>}
                {product.getNutritionCarbohydrate().length > 0 && 
                <Row className='border-bottom py-1'>
                    <Col className='text-start'>
                        <span>Carbohydrate</span>
                    </Col>
                    <Col className='text-end'>
                        <span>{product.getNutritionCarbohydrate()}</span>
                    </Col>
                </Row>}
                {product.getNutritionSugar().length > 0 && 
                <Row className='border-bottom py-1'>
                    <Col className='text-start'>
                        <span>Sugar</span>
                    </Col>
                    <Col className='text-end'>
                        <span>{product.getNutritionSugar()}</span>
                    </Col>
                </Row>}
                {product.getNutritionDietaryFiber().length > 0 && 
                <Row className='border-bottom py-1'>
                    <Col className='text-start'>
                        <span>Dietary Fiber</span>
                    </Col>
                    <Col className='text-end'>
                        <span>{product.getNutritionDietaryFiber()}</span>
                    </Col>
                </Row>}
                {product.getNutritionProtein().length > 0 && product.getNutritionProtein() !== '0' && 
                <Row className='border-bottom py-1'>
                    <Col className='text-start'>
                        <span>Protein</span>
                    </Col>
                    <Col className='text-end'>
                        <span>{product.getNutritionProtein()}</span>
                    </Col>
                </Row>}
                {product.getNutritionCholesterol().length > 0 && 
                <Row className='border-bottom py-1'>
                    <Col className='text-start'>
                        <span>Cholesterol</span>
                    </Col>
                    <Col className='text-end'>
                        <span>{product.getNutritionCholesterol()}</span>
                    </Col>
                </Row>}
                {product.getNutritionSodium().length > 0 && 
                <Row className='border-bottom py-1'>
                    <Col className='text-start'>
                        <span>Sodium</span>
                    </Col>
                    <Col className='text-end'>
                        <span>{product.getNutritionSodium()}</span>
                    </Col>
                </Row>}
            </div>
        </Card>
    )
}
