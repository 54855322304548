import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { selectSavedCardData } from '../../features/mobile/mobileSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { selectOrderInProgress } from '../../features/cart/cartSlice';
import AuthrizedCardForm from './AuthrizedCardForm';
import Window from "../../utils/Window";
// import SavePaymentData from './SavePaymentData';
import { useLocation } from 'react-router-dom';

interface Props {
    useNewPayment: boolean;
    setUseNewPayment: (useNewPayment: boolean) => void;
}
const cardImages = {
    'AmericanExpress': 'http://emmstaging.com/wp-content/uploads/2024/04/american-express.png',
    'Discover': 'http://emmstaging.com/wp-content/uploads/2024/04/discover.png',
    'JCB': 'http://emmstaging.com/wp-content/uploads/2024/04/jcb.png',
    'MasterCard': 'http://emmstaging.com/wp-content/uploads/2024/04/mastercard.png',
    'Visa': 'http://emmstaging.com/wp-content/uploads/2024/04/visa.png',
    'Unknown': 'http://emmstaging.com/wp-content/uploads/2024/04/jcb.png',
};

export default function AuthSavedCards({ useNewPayment, setUseNewPayment }: Props) {
    const location = useLocation();
    const orderInProgress = useSelector(selectOrderInProgress);
    const getSavedCardData = useSelector(selectSavedCardData);
    const cardType: keyof typeof cardImages = getSavedCardData?.user_cim?.authorize_card_type as keyof typeof cardImages;
    const lastFourDigit = getSavedCardData?.user_cim?.authorize_card_lastfour;
    const CVVDigits = getSavedCardData?.user_cim?.authorize_card_expiration;
    const isCSSAble = useNewPayment ? 'ms-4' : '';
    // const isAutoShipOrder = location.state !== null && location.state.pickedForMyself === true;
    const isAutoshipPayment = location.pathname === "/autoship/settings" && Window.isMobile();

    const formatExpirationDate = (date: string) => {
        if (!date) return '';

        if (date.match(/^\d{2}\/\d{2}$/)) return date;

        const [year, month] = date.split('-');
        return `${month}/${year.slice(2)}`;
    };

    const handleClick = () => {
        setUseNewPayment(false);
    };

    return (
        <Card body={!isAutoshipPayment}>
            <div className='fs-3 my-3 text-center'>Pay with Saved Credit Card</div>
            <div className={`border ${useNewPayment ? 'border-black' : 'border-green'} border-2 w-100 authsavedcards btn btn-white rounded-0 py-0 `}
                onClick={!orderInProgress ? handleClick : undefined}>
                <Row className='card-mainrow'>
                    <Col sm={2} xs='auto'
                        className={`${useNewPayment ? 'bg-black' : 'bg-success'} d-flex justify-content-center align-items-center w-auto`}>
                        {!useNewPayment &&
                            <FontAwesomeIcon
                                icon={faCircleCheck}
                                size={'xl' as SizeProp}
                                color='white' />
                        }
                    </Col>
                    <Col
                        sm={3}
                        xs='auto'
                        className={`card-name d-flex justify-content-center align-items-center ${isCSSAble}`}>
                        <img src={cardImages[cardType]} alt={cardType} style={{ maxHeight: '50px' }} />
                    </Col>
                    <Col sm={7} xs={6} className='d-flex justify-content-end align-items-center pe-0 detail-card'>
                        <div className={`d-flex flex-column fw-500 ${!Window.isMobile() ? 'fontmobile' : ''}`}>
                            <span className='d-flex justify-content-end'>XXXX XXXX XXXX {lastFourDigit}</span>
                            <span className='d-flex justify-content-end'>Exp: {formatExpirationDate(CVVDigits)}</span>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row className='d-flex justify-content-center align-items-center pt-2 savecardor'>
                <Col sm={5} lg={5} md={2} xs={5} className='d-flex justify-content-end'>
                    <div className='divider-line my-3'></div>
                </Col>
                <Col sm={2} lg={1} xs='auto' className='d-flex justify-content-center'>
                    OR
                </Col>
                <Col sm={5} lg={5} md={2} xs={5}>
                    <div className='divider-line my-3'></div>
                </Col>
            </Row>
            <div className='d-flex justify-content-center align-items-center'>
                <Button
                    variant={`${useNewPayment ? 'success' : 'dark'}`}
                    className={`rounded-1 my-3 w-100 ${useNewPayment ? 'usenewcard' : 'useoldcard'}`}
                    size="lg"
                    onClick={() => setUseNewPayment(!useNewPayment)}
                    disabled={orderInProgress}
                >
                    Use a New Credit Card
                </Button>
            </div>
            {useNewPayment &&
                <>
                    <AuthrizedCardForm />
                    {/* {!isAutoShipOrder && !isAutoshipPayment && <SavePaymentData />} */}
                </>
            }
        </Card>
    )
}