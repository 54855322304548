import { Alert, Image, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loadCustomerDetails, selectCustomerDetails, selectCustomerDetailsIsLoaded, selectCustomerDetailsIsLoading } from './customerDetailSlice';
import { selectCustomer, selectCustomerIsLoading, setCustomer } from './customerSlice';
import DefaultImg from '../../assets/images/userdefault.png';
import FileUploadeIMG from '../../assets/images/Icon-awesome-camera.png';
import { useEffect, useState } from 'react';
import ProfileUpload from './ProfileUpload';
import CustomerDetailsAPI from '../../API/customerDetailsAPI';

export default function WelcomeCustomer() {
  const dispatch = useDispatch();
  const custData = useSelector(selectCustomer);
  const custLoading = useSelector(selectCustomerIsLoading);
  const customerData = useSelector(selectCustomerDetails);
  const customerLoading = useSelector(selectCustomerDetailsIsLoading);
  const customerLoaded = useSelector(selectCustomerDetailsIsLoaded);
  const customerDetails = customerData?.user_data;
  const customerImage = customerDetails?.imageUrl;
  const [image, setImage] = useState(DefaultImg);
  const [showModal, setShowModal] = useState(false);
  const [busy, setBusy] = useState(false);
  const [imageVersion, setImageVersion] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [updatingImage, setUpdatingImage] = useState(false);
  const imageExt = ['JPEG', 'jpeg', 'JPG', 'jpg', 'PNG', 'png'];

  useEffect(() => {
    if(errorMsg !== ''){
      setTimeout(() => {
        setErrorMsg('');
      }, 3000);
    }
  }, [errorMsg])

  const handleRemoveImage = async () => {
    setUpdatingImage(true);
    setBusy(true);

    try {
      const response = await CustomerDetailsAPI.updateUserProfileInfo({
        email: custData.email,
        user_image: DefaultImg,
      });
      dispatch(setCustomer({ email: custData.email, avatar_url: response.imageUrl }));
      await dispatch(loadCustomerDetails(custData.email));
    } catch (e) {
      setErrorMsg(errorMsg);
    } finally {
      await dispatch(loadCustomerDetails(custData.email));
      setBusy(false);
      setUpdatingImage(false);
    }
  };

  const onChange = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    const fileExt = file.name.split('.').pop();

    if (!imageExt.includes(fileExt)) {
      setErrorMsg('Invalid file type. Please select an image with a JPEG, JPG, or PNG extension.');
      e.target.value = "";
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(file);
    setShowModal(true);
    setImageVersion(Math.random());
    e.target.value = "";
  };

  return (
    <>
      {errorMsg &&
        <Alert variant='danger'>{errorMsg}</Alert>
      }
      <div className="welcome-customer my-3 d-flex align-items-center">
        <ProfileUpload image={image} showModal={showModal} setShowModal={setShowModal} />
        <div className='profilePicMain'>
          <div className='profilePic'>
            {(!customerLoading && customerLoaded && customerData)
              ? <Image src={`${customerImage}?v=${imageVersion}`} alt={updatingImage ? "avatar updating" : "avatar"} className="profileAvtar" />
              : <Image src={DefaultImg} alt="default avatar" className="profileAvtar" />}
            <div className='profileupload'>
              <Image src={FileUploadeIMG} className='cam' />
              <div className='ddlShowOptions cursor-pointer'>
                <div className='ddlShowOptionsRow'>
                  <span className='ddlShowOptionsIcon'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.79395 15.3703L14.1598 3.98956L19.9774 9.81487L8.6116 21.1957L2.79395 15.3703Z" fill="#707070" />
                      <path d="M0.117437 21.7874C-0.339563 23.3893 0.574437 24.3428 2.21246 23.8852L7.43074 22.3978L1.60295 16.524L0.117437 21.7874Z" fill="#707070" />
                      <path d="M23.3143 3.25127L20.7243 0.657868C19.8483 -0.219289 18.4008 -0.219289 17.4865 0.657868L15.3152 2.83173L21.143 8.66719L23.3143 6.49305C24.2285 5.57783 24.2285 4.12843 23.3143 3.25127Z" fill="#707070" />
                    </svg>
                  </span>
                  <span className='ddlShowOptionsText'>Change Profile Picture</span>
                  <input id="file-upload" type="file" onChange={onChange} />
                </div>
                <div className='ddlShowOptionsRow' onClick={handleRemoveImage}>
                  <span className='ddlShowOptionsIcon'>
                    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M17.7354 6.51163V19.4418C17.7354 21.9534 15.69 24 13.1795 24H6.20637C3.69603 24 1.65045 21.9536 1.65045 19.4418V6.51163H17.7349H17.7354ZM15.5039 10.4186V19.0696C15.5039 20.8371 13.1796 20.8371 13.1796 19.0696V10.4186C13.1796 8.55819 15.5039 8.55819 15.5039 10.4186ZM10.8551 10.4186V19.0696C10.8551 20.8371 8.53076 20.8371 8.53076 19.0696V10.4186C8.53076 8.55819 10.8551 8.55819 10.8551 10.4186ZM6.2062 10.4186V19.0696C6.2062 20.8371 3.88189 20.8371 3.88189 19.0696V10.4186C3.88189 8.55819 6.2062 8.55819 6.2062 10.4186Z" fill="#707070" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M1.18573 3.06977H5.46254C5.3695 2.23257 5.64843 1.48845 6.20628 0.93029V0.83721C7.41503 -0.27907 12.1565 -0.27907 13.2723 0.83721C13.8302 1.48841 14.2021 2.23257 14.1091 3.06977H17.828C19.4085 3.06977 19.4085 5.48833 17.828 5.48833H1.18539C-0.39513 5.48833 -0.39513 3.06977 1.18539 3.06977L1.18573 3.06977ZM7.32183 3.06977H12.2495C12.3426 2.7907 12.2495 2.41856 11.9706 2.13948C11.5987 1.76733 7.97276 1.76733 7.50766 2.13948C7.22873 2.41855 7.22873 2.79068 7.32176 3.06977H7.32183Z" fill="#707070" />
                    </svg>
                  </span>
                  <span className='ddlShowOptionsText'>{busy ? 'Removing ...' : 'Remove Profile Picture'}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='profileName'>
          <div className='d-flex flex-column text-uppercase ps-3 fs-28px lh-50px'>
            {(!customerLoading && customerLoaded && customerData && !custLoading)
              ? <>
                {customerData?.user_data?.f_name
                  ? <>
                    <h4 className='font-league-gothic text-primary-300'>Hey,</h4>
                    <h3 className='overflow-hidden text-truncate font-league-gothic text-primary-300'>{customerData?.user_data?.f_name}!</h3></>
                  : <h3 className='text-primary-300 font-league-gothic '>Welcome!</h3>
                }
              </>
              : <Spinner className="text-center text-dark" variant="primary" animation="grow" />}
          </div>
        </div>
      </div>
    </>
  )
}