import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { selectDetails } from '../../core/detailsSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faRegularHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faSolidHeart } from "@fortawesome/free-solid-svg-icons";
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { selectToken } from '../../../../features/user/userSlice';
import { createWorkPlan, deleteWorkPlan, selectWorkPlans } from '../../core/workoutPlansSlice';
import WorkoutPlansAPI from '../../../../API/myWorkoutsPlanAPI';
import DefaultImg from '../../../../assets/images/userdefault.png';

export default function VideoPage() {
    const workoutDetails = useSelector(selectDetails)[0];
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const favoriteWorkouts = useSelector(selectWorkPlans);
    const [isAdding, setIsAdding] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (errorMessage) {
            setTimeout(() => {
                setErrorMessage('')
            }, 3000)
        }
    }, [errorMessage])

    useEffect(() => {
        const matchedWorkouts = favoriteWorkouts.some((myworkouts: any) =>
            myworkouts.items.some((myWorkoutFavorite: any) =>
                Number(myWorkoutFavorite.workoutId) === Number(workoutDetails.id)
            )
        );

        setIsFavorite(matchedWorkouts ? true : false);
    }, [favoriteWorkouts, workoutDetails])

    const handleFavoriteSet = () => {
        if (!token) {
            return setErrorMessage('Please Log-in/Register To Add To Your Favorite.')
        }
        else {
            if (isFavorite) {
                setIsAdding(true);
                const matchedWorkouts = favoriteWorkouts.filter((myworkouts: any) =>
                    myworkouts.items.some((myWorkoutFavorite: any) =>
                        Number(myWorkoutFavorite.workoutId) === Number(workoutDetails.id)
                    )
                );

                WorkoutPlansAPI.deleteWorkoutsPlan(token, matchedWorkouts[0].id).then(async () => {
                    await dispatch(deleteWorkPlan(matchedWorkouts[0].id));
                }).catch((e) => {
                    console.error(e);
                }).finally(() => {
                    setIsAdding(false);
                });
            }
            else {
                setIsAdding(true);
                WorkoutPlansAPI.createWorkoutsPlan(
                    token, {
                    name: workoutDetails.trainer_name,
                    items: [{
                        workoutId: Number(workoutDetails.id),
                        workoutName: workoutDetails.title
                    }]
                }).then(async (workPlan) => {
                    await dispatch(createWorkPlan(workPlan));
                }).catch((e) => {
                    console.error(e);
                }).finally(() => {
                    setIsAdding(false);
                });
            }
        }
    }

    return (
        <div className="dw-banner">
            <Row className="dw-row">
                <Col lg={8} className="dw-col">
                    <div className='iframdiv'>
                        <iframe
                            title="Embedded Content"
                            src={`${process.env.REACT_APP_API_URL}video-page/?vid=${workoutDetails?.video_id}`}
                            height="600"
                            width="900"
                            allowFullScreen 
                            style={{backgroundColor: 'black'}}
                        ></iframe>
                    </div>
                </Col>
                <Col lg={4} className="dw-col">
                    <div className="dw-des">
                        <div className="imgwrap">
                            {workoutDetails.image !== ""
                                ? <Image src={`${workoutDetails.image}`} alt={workoutDetails.image} className="trainersImg" />
                                : <Image src={DefaultImg} alt="default Image" className="defaultImg" />}
                            <span>{workoutDetails?.trainers_name[0]}</span>
                        </div>
                        <h4 className='h4'>{workoutDetails?.title}</h4>
                        <p className='pera' dangerouslySetInnerHTML={{__html: workoutDetails?.small_description}}></p>
                        <div className="iconpart">
                            <div className='first-part'>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="#00B156" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 4.5V9L12 10.5" stroke="#00B156" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span>{Math.floor((workoutDetails?.duration_in_minutes / 1000) / 60)}</span>
                            </div>
                            <div className='first-part'>
                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_213_937)">
                                        <path d="M3 0L3.927 1.878L6 2.181L4.5 3.642L4.854 5.706L3 4.731L1.146 5.706L1.5 3.642L0 2.181L2.073 1.878L3 0Z" fill="#00B156" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_213_937">
                                            <rect width="6" height="6" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <span>Upper Body</span>
                            </div>
                        </div>
                        <div className="event-part">
                            <div className='ep-part'>
                                {isAdding ? <Spinner animation="border" as="span" size="sm" /> :
                                    <FontAwesomeIcon
                                        role="button"
                                        icon={isFavorite ? faSolidHeart : faRegularHeart}
                                        color={`${isFavorite ? 'red' : 'black'}`}
                                        size={'xl' as SizeProp}
                                        onClick={() => {
                                            handleFavoriteSet();
                                        }}
                                    />}
                                <span>Add to Favorite</span>
                            </div>
                            {/* <div className='ep-part'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z" stroke="#707070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16 2V6" stroke="#707070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 2V6" stroke="#707070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M3 10H21" stroke="#707070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span>Calendar</span>
                            </div> */}
                        </div>

                        <div className="cp-hover">
                            <Row className="hoverrow">
                                <Col className="hover-col">
                                    <p>Difficulty</p>
                                </Col>
                                <Col className="hover-col right">
                                    <p>{workoutDetails.difficulty_level}</p>
                                </Col>
                            </Row>
                            <Row className="hoverrow">
                                <Col className="hover-col">
                                    <p>Training Type</p>
                                </Col>
                                <Col className="hover-col right">
                                    <p>{workoutDetails?.training_type.join(", ")}</p>
                                </Col>
                            </Row>
                            <Row className="hoverrow">
                                <Col className="hover-col">
                                    <p>Equipment</p>
                                </Col>
                                <Col className="hover-col right">
                                    <p>{workoutDetails?.equipments.join(", ")}</p>
                                </Col>
                            </Row>
                        </div>
                        {/* <div className="dw-btn">
                            <Link to={'#'}>Mark Complete</Link>
                            <span className='circle-arrow'></span>
                        </div> */}
                    </div>

                </Col>
            </Row>
        </div>
    )
}
