import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, {useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomerDetailsAPI from '../../API/customerDetailsAPI';
import { setEmail } from '../guest/guestSlice';
import { loadCustomerDetails, selectCustomerDetails, setCustomerDetails } from './customerDetailSlice';
import { selectCustomer } from './customerSlice';
import { selectEditAccountOptions } from './editaccountOptionsSlice';
import { selectIsMobileRoute } from '../mobile/mobileSlice';

interface Props {
    handleThirdAccordian: () => void;
    setErrorMsg: (errorMsg: string) => void;
}
function FitnessGoalsForm({ handleThirdAccordian, setErrorMsg }: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const custData = useSelector(selectCustomer);
    const customerData = useSelector(selectCustomerDetails);
    const userSettings = useSelector(selectEditAccountOptions);
    const customerDetails = customerData?.user_data;
    const [busy, setBusy] = useState(false);
    const AppURL = useSelector(selectIsMobileRoute);

    const formik = useFormik({
        initialValues: {
            email: custData?.email,
            fitness_goal: customerDetails?.fitness_goal,
            focus_area : customerDetails?.focus_area,
            activity_level: customerDetails?.activity_level,
        },
        onSubmit: values => {
            setErrorMsg('');
            setBusy(true);
            CustomerDetailsAPI.updateFitNessGoalsCustomerDetails({
                email: custData.email,
                fitness_goal: values?.fitness_goal,
                focus_area : values?.focus_area,
                activity_level: values?.activity_level,
            }).then((response) => {
                if ('code' in response) {
                    console.error(response);
                    if ('message' in response) {
                        setErrorMsg(response.message);
                    } else {
                        setErrorMsg("An error has occurred. Please try again later.");
                    }
                } else if ('id' in response || 'email' in response) {
                    dispatch(setCustomerDetails(response));
                    dispatch(setEmail(response.email));
                }
            }).catch((e) => {
                setErrorMsg(e.message);
            }).finally(() => {
                dispatch(loadCustomerDetails(custData.email));
                setBusy(false);
                handleThirdAccordian();
            })
        }
    });

    const handleCancel = () => {
        navigate(`/my-account${AppURL}`);
    }
    
    return (
        <Form>
            <Row>
                <Col sm={6} lg={4} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Fitness Goal</Form.Label>
                        <div className='d-flex'>
                            <Form.Select className="form-textbox" name="fitness_goal" value={formik?.values?.fitness_goal} onChange={formik.handleChange}>
                                <option value="">Select Fitness Goal</option>
                                {userSettings?.fitness_goal_option?.map((items: any) => (
                                    <option key={items} value={items}>{items}</option>
                                ))}
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                <Col sm={6} lg={4} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Focus Area</Form.Label>
                        <div className='d-flex'>
                            <Form.Select className="form-textbox" name="focus_area" value={formik?.values?.focus_area} onChange={formik.handleChange}>
                                <option value="">Select Focus Area</option>
                                {userSettings?.focus_area_option?.map((items: any) => (
                                    <option key={items} value={items}>{items}</option>
                                ))}
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                <Col sm={6} lg={4} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Activity Level</Form.Label>
                        <div className='d-flex'>
                            <Form.Select className="form-textbox" name="activity_level" value={formik?.values?.activity_level} onChange={formik.handleChange}>
                                <option value="">Select Activity Level</option>
                                {userSettings?.activity_level_option?.map((items: any) => (
                                    <option key={items} value={items}>{items}</option>
                                ))}
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <div className='mt-2 d-flex justify-content-end flex-wrap'>
                <Button
                    className="col-12 col-md-auto me-md-2 mb-2"
                    variant="outline-dark"
                    disabled={busy}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                {busy ?
                    <Button variant='success'
                        className='mb-2 col-12 col-md-auto'
                        disabled>
                        <Spinner animation="border" as="span" size="sm" />
                        &nbsp;&nbsp;Update & Continue Editing ...
                    </Button>
                    :
                    <Button 
                    variant='success'
                    className='mb-2 col-12 col-md-auto'
                        type="submit" onClick={(e: any) => { e.preventDefault(); formik.handleSubmit(); }}>
                        Update & Continue Editing &nbsp;&nbsp;
                        <FontAwesomeIcon
                            role="button"
                            icon={faArrowUpRightFromSquare}
                            size={'1x' as SizeProp}
                        />
                    </Button>
                }
            </div>
        </Form>
    )
}

export default FitnessGoalsForm