import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from '../../../app/store';
import { IWorkoutPlan } from './interfaces';
import WorkoutPlansAPI from '../../../API/myWorkoutsPlanAPI';

interface MealPlansState {
  workoutPlans: Array<IWorkoutPlan>;
  loaded: boolean;
  loading: boolean;
  loadError: Boolean;
}

const initialState: MealPlansState = {
  workoutPlans: [],
  loaded: false,
  loading: false,
  loadError: false
}

export const loadWorkoutPlans = createAsyncThunk(
  'workoutPlans/loadWorkoutPlans',
  async (token: string) => {
    return await WorkoutPlansAPI.getWorkoutsPlans(token);
  }
);

export const workPlansSlice = createSlice({
  name: "workoutPlans",
  initialState: initialState,
  reducers: {
    createWorkPlan: (state, action: PayloadAction<IWorkoutPlan>) => {
      return {
        ...state,
        workoutPlans: [...state.workoutPlans, action.payload]
      }
    },
    deleteWorkPlan: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        workoutPlans: state.workoutPlans.filter((mp) => mp.id !== action.payload)
      }
    },
    updateWorkPlan: (state, action: PayloadAction<IWorkoutPlan>) => {
      return {
        ...state,
        workoutPlans: state.workoutPlans.map((mp) => {
          if (mp.id === action.payload.id) return action.payload;
          return mp;
        })
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(loadWorkoutPlans.pending, (state) => {
      return {
        ...state,
        loading: true
      }
    });
    builder.addCase(loadWorkoutPlans.fulfilled, (state, action) => {
      return {
        ...state,
        workoutPlans: [...action.payload],
        loading: false,
        loaded: true
      }
    });
    builder.addCase(loadWorkoutPlans.rejected, (state) => {
      return {
        ...state,
        loading: false,
        loadError: true
      }
    });
  }
});

export const { createWorkPlan, 
               deleteWorkPlan, 
               updateWorkPlan } = workPlansSlice.actions;
export const selectWorkPlans = (state: RootState) => state.workoutPlans.workoutPlans;
export const selectWorkPlansLoaded = (state: RootState) => state.workoutPlans.loaded;
export const selectWorkPlansLoadError = (state: RootState) => state.workoutPlans.loadError;
export const selectWorkPlansLoading = (state: RootState) => state.workoutPlans.loading;
export default workPlansSlice.reducer;