import React, { useMemo, useState } from 'react';
import { Col, Form, Alert, Container, Row, Button, Card, Spinner } from 'react-bootstrap';
import MyAccountNav from '../../components/MyAccountNav';
import { FormikErrors, useFormik } from 'formik';
import CustomerObj from '../../features/customer/customerObj';
import { useSelector } from 'react-redux';
import { selectCustomer } from '../../features/customer/customerSlice';
import { selectToken } from '../../features/user/userSlice';
import { selectGuestEmail } from '../../features/guest/guestSlice';
import CustomerDetailsAPI from '../../API/customerDetailsAPI';

export interface UpdatePassword {
    email: string,
    old_password: string,
    new_password: string,
    password_confirmation: string
}

const ChangePassword = () => {
    const custData = useSelector(selectCustomer);
    const token = useSelector(selectToken);
    const guestEmail = useSelector(selectGuestEmail);

    const customer = useMemo(() => {
        return new CustomerObj(custData);
    }, [custData]);

    const user_info = Object.keys(customer.data).length > 0 ? customer.data.billing : {};
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);

    const initialEmailValue = () => {
        if (token && user_info && user_info.email) {
            return 'email' in user_info ? user_info.email : '';
        } else if (token && customer && customer?.data?.email) {
            return customer?.data ? customer?.data?.email : '';
        }
        return guestEmail;
    }

    const validate = (values: UpdatePassword) => {
        let errors: FormikErrors<UpdatePassword> = {};

        if (!values.old_password) {
            errors.old_password = "Required";
        }

        if (!values.new_password) {
            errors.new_password = "Required";
        } else if (values.new_password.length < 6) {
            errors.new_password = "Must be at least 6 characters.";
        }

        if (!values.password_confirmation) {
            errors.password_confirmation = "Required";
        }

        if (values.new_password && values.password_confirmation &&
            (values.new_password !== values.password_confirmation)) {
            errors.new_password = "Passwords do not match";
            errors.password_confirmation = "Passwords do not match";
        }
        return errors;
    }

    const formik = useFormik<UpdatePassword>({
        initialValues: {
            email: initialEmailValue(),
            old_password: '',
            new_password: '',
            password_confirmation: '',
        },
        validate,
        onSubmit: async (values) => {
            setIsUpdating(true);
            setErrorMsg('');
            setSuccessMsg('');

            const { old_password, new_password } = values;

            try {
                await CustomerDetailsAPI.updateUserPassword({
                    email: initialEmailValue(),
                    old_password: old_password,
                    new_password: new_password,
                }).then((response: any) => {
                    setSuccessMsg("Password updated successfully.");
                    setIsUpdating(false);

                })
            } catch (error: any) {
                let errorMessage = 'An unknown error occurred.';
                try {
                    const parsedError = JSON.parse(error.message);
                    errorMessage = parsedError.message || errorMessage;
                } catch (e) {
                    console.error('Failed to parse error message:', e);
                }
                setIsUpdating(false);
                setErrorMsg(errorMessage);
            }
        }
    });

    return (
        <Container fluid="lg" className='address-page'>
            <Row>
                <Col sm={12} md={3}>
                    <MyAccountNav />
                </Col>
                <Col sm={12} md={9} className="my-5">
                    <Card className="billing-address">
                        <Card.Header>
                            <div className="d-flex justify-content-between">
                                <span className="fs-4">Change Password</span>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {errorMsg && <Alert variant="danger" className='my-3'>{errorMsg}</Alert>}
                            {successMsg && <Alert variant="success" className='my-3'>{successMsg}</Alert>}
                            <Form className='login-form' onSubmit={formik.handleSubmit}>
                                <Form.Group className="mb-3 form-group required">
                                    <Form.Label>Old Password</Form.Label>
                                    <Form.Control
                                        placeholder='Old Password'
                                        id="old_password"
                                        type="password"
                                        size="lg"
                                        isValid={Boolean(formik.values.old_password) && !Boolean(formik.errors.old_password)}
                                        isInvalid={Boolean(formik.errors.old_password)}
                                        value={formik.values.old_password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.old_password && formik.touched.old_password &&
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.old_password}
                                        </Form.Control.Feedback>}
                                </Form.Group>
                                <Form.Group className="mb-3 form-group required">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        placeholder='New Password'
                                        id="new_password"
                                        type="password"
                                        size="lg"
                                        isValid={Boolean(formik.values.new_password) && !Boolean(formik.errors.new_password)}
                                        isInvalid={Boolean(formik.errors.new_password)}
                                        value={formik.values.new_password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.new_password && formik.touched.new_password &&
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.new_password}
                                        </Form.Control.Feedback>}
                                </Form.Group>
                                <Form.Group className="mb-3 form-group required">
                                    <Form.Label>Confirm New Password</Form.Label>
                                    <Form.Control
                                        placeholder='Confirm New Password'
                                        id="password_confirmation"
                                        type="password"
                                        size="lg"
                                        isValid={Boolean(formik.values.password_confirmation) && !Boolean(formik.errors.password_confirmation)}
                                        isInvalid={Boolean(formik.errors.password_confirmation)}
                                        value={formik.values.password_confirmation}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.password_confirmation && formik.touched.password_confirmation &&
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.password_confirmation}
                                        </Form.Control.Feedback>}
                                </Form.Group>
                                <Button variant="primary" type="submit" disabled={isUpdating}>
                                    {isUpdating ?
                                        <>Updating Password ... &nbsp;&nbsp;
                                            <Spinner
                                                animation="border"
                                                as="span"
                                                size="sm"
                                            />
                                        </>
                                        : 'Change Password'}
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default ChangePassword;
