import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikErrors, useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomerDetailsAPI from '../../API/customerDetailsAPI';
import Helper from '../../utils/Helper';
import { setEmail } from '../user/userSlice';
import { loadCustomerDetails, selectCustomerDetails, setCustomerDetails } from './customerDetailSlice';
import { selectCustomer } from './customerSlice';
import { selectEditAccountOptions } from './editaccountOptionsSlice';
import config from '../../config.json';
import { selectIsMobileRoute } from '../mobile/mobileSlice';

interface FormValues {
    email: string;
    f_name: string;
    l_name: string;
    address_one: string;
    address_two: string;
    gender: string;
    mobile_number: string;
    zip_code: string;
    user_city: string;
    user_state: string;
    birthdate: string;
}

interface Props {
    handleFirstAccordian: () => void;
    setErrorMsg: (errorMsg: string) => void;
}

function BasicInformationForm({ handleFirstAccordian, setErrorMsg }: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const custData = useSelector(selectCustomer);
    const customerData = useSelector(selectCustomerDetails);
    const userSettings = useSelector(selectEditAccountOptions);
    const customerDetails = customerData?.user_data;
    const [busy, setBusy] = useState(false);
    const AppURL = useSelector(selectIsMobileRoute);
    const [scriptLoaded, setScriptLoaded] = useState(false);

    const validate = (values: FormValues) => {
        const errors: FormikErrors<FormValues> = {};
        if (values.mobile_number) {
            if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(values.mobile_number)) {
                errors.mobile_number = "Invalid mobile number";
            }
        }
        if (values.zip_code) {
            if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(values.zip_code)) {
                errors.zip_code = "Invalid zip code";
            }
        }
        return errors;
    }

    const formik = useFormik({
        initialValues: {
            email: custData?.email,
            f_name: customerDetails?.f_name,
            l_name: customerDetails?.l_name,
            address_one: customerDetails?.address_one,
            address_two: customerDetails?.address_two,
            gender: customerDetails?.gender,
            mobile_number: Helper.formatPhoneNo(customerDetails?.mobile_number),
            zip_code: customerDetails?.zip_code,
            user_city: customerDetails?.user_city,
            user_state: customerDetails?.user_state,
            birthdate: customerDetails?.birthdate,
        },
        validate,
        onSubmit: values => {
            setErrorMsg('');
            setBusy(true);
            CustomerDetailsAPI.updateBasicInformationCustomerDetails({
                email: custData.email,
                f_name: values?.f_name,
                l_name: values?.l_name,
                address_one: values?.address_one,
                address_two: values?.address_two,
                gender: values?.gender,
                mobile_number: Helper.formatPhoneNo(values?.mobile_number),
                zip_code: values?.zip_code,
                user_city: values?.user_city,
                user_state: values?.user_state,
                birthdate: values?.birthdate
            }).then((response) => {
                if ('code' in response) {
                    if ('message' in response) {
                        setErrorMsg(response.message);
                    } else {
                        setErrorMsg("An error has occurred. Please try again later.");
                    }
                } else if ('id' in response || 'email' in response) {
                    dispatch(setCustomerDetails(response));
                    dispatch(setEmail(response.email));
                }
            }).catch((e) => {
                setErrorMsg(e.message);
            }).finally(() => {
                dispatch(loadCustomerDetails(custData.email));
                setBusy(false);
                handleFirstAccordian();
            })
        }
    });

    const streetRef = useRef<any>();
    const townRef = useRef<any>();
    
    useEffect(() => {
        if (!scriptLoaded) {
            const script = document.createElement('script');
            const GoogleApiKey = process.env.REACT_APP_WEB_URL === 'https://shop.emmstaging.com/' ? 'AIzaSyB9UC5V2PvY2kJnzilocA3GD3Fc5hUo9Oo' : 'AIzaSyAyl3jI8_4oiGf_t4mk3rRiOnFt0-T-SJc';
            script.src = `https://maps.googleapis.com/maps/api/js?key=${GoogleApiKey}&libraries=places`;
            script.async = true;
            script.onload = () => setScriptLoaded(true);
            document.body.appendChild(script);
        }

        return () => {
            if (scriptLoaded) {
                document.body.removeChild(WScript as any);
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scriptLoaded]);

    useEffect(() => {
        if (scriptLoaded) {
            initAutocomplete();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scriptLoaded]);

    const initAutocomplete = () => {
        const streetOptions = {
            types: ['geocode'],
            componentRestrictions: { country: 'us' },
        };

        const townOptions = {
            types: ['(cities)'],
            componentRestrictions: { country: 'us' },
        };

        if (!google.maps?.places) {
            return console.error("Google maps places API must be loaded.");
        }

        const autocompleteStreet = new (window as any).google.maps.places.Autocomplete(
            streetRef.current!,
            streetOptions
        );

        const autocompleteTown = new (window as any).google.maps.places.Autocomplete(
            townRef.current!,
            townOptions
        );

        autocompleteStreet.addListener('place_changed', handlePlaceChanged.bind(autocompleteStreet));
        autocompleteTown.addListener('place_changed', handleTownPlaceChanged.bind(autocompleteTown));
    };

    const handlePlaceChanged = function (this: google.maps.places.Autocomplete) {
        const place = this.getPlace();
        if (!place.geometry) {
            console.error("No details available for input: '" + place.name + "'");
            return;
        }

        const streetAddress = place.name;
        // setStreet(streetAddress);
        formik.setFieldValue('address_one', streetAddress)

        let extractedTown = '';
        let extractedPostalCode = '';
        let extractedState = '';

        const addressComponents = place.address_components as any;
        for (let i = 0; i < addressComponents?.length; i++) {
            const component = addressComponents[i];
            if (component.types.includes('locality')) {
                extractedTown = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
                extractedState = component.short_name;
            } else if (component.types.includes('postal_code')) {
                extractedPostalCode = component.long_name;
            }
        }

        const cityStatePostal = [extractedTown].filter(Boolean).join(', ');

        // setTown(extractedTown);
        formik.setFieldValue('user_city', cityStatePostal)
        formik.setFieldValue('user_state', extractedState);
        formik.setFieldValue('zip_code', extractedPostalCode);
    };

    const handleTownPlaceChanged = function (this: google.maps.places.Autocomplete) {
        const place = this.getPlace();
        if (!place.geometry) {
            console.error("No details available for input: '" + place.name + "'");
            return;
        }

        let extractedTown = '';
        let extractedPostalCode = '';
        let extractedState = '';

        const addressComponents = place.address_components as any;
        for (let i = 0; i < addressComponents?.length; i++) {
            const component = addressComponents[i];
            if (component.types.includes('locality')) {
                extractedTown = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
                extractedState = component.short_name;
            } else if (component.types.includes('postal_code')) {
                extractedPostalCode = component.long_name;
            }
        }

        const cityStatePostal = [extractedTown].filter(Boolean).join(', ');

        formik.setFieldValue('user_city', cityStatePostal);
        formik.setFieldValue('user_state', extractedState);
        formik.setFieldValue('zip_code', extractedPostalCode);
    }

    const handleCancel = () => {
        navigate(`/my-account${AppURL}`);
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    return (
        <Form>
            <Row>
                <Col className='mb-4' md={6}>
                    <Form.Group className="form-group">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                            id="f_name"
                            type="text"
                            value={formik?.values?.f_name}
                            onChange={formik.handleChange}
                            className="form-textbox"
                            placeholder='Enter your first name'
                        />
                    </Form.Group>
                </Col>
                <Col className='mb-4' md={6}>
                    <Form.Group className="form-group">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                            id="l_name"
                            type="text"
                            value={formik?.values?.l_name}
                            onChange={formik.handleChange}
                            className="form-textbox"
                            placeholder='Enter your last name'
                        />
                    </Form.Group>
                </Col>
                <Col className='mb-4' md={6}>
                    <Form.Group className="form-group">
                        <Form.Label>Address 1</Form.Label>
                        <Form.Control
                            ref={streetRef}
                            type="text"
                            id="address_one"
                            name="address_one"
                            placeholder="Enter Street Address"
                            value={formik.values.address_one}
                            onChange={formik.handleChange}
                            onKeyDown={handleKeyPress}
                        />
                    </Form.Group>
                </Col>
                <Col className='mb-4' md={6}>
                    <Form.Group className="form-group">
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control
                            id="address_two"
                            type="text"
                            value={formik?.values?.address_two}
                            onChange={formik.handleChange}
                            className="form-textbox"
                            placeholder="Apartment, suite, unit etc. (optional)"
                        />
                    </Form.Group>
                </Col>
                <Col className='mb-4' md={6}>
                    <Form.Group className="form-group">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            id="user_city"
                            ref={townRef}
                            type="text"
                            name="user_city"
                            placeholder="Enter Town/Area"
                            value={formik.values.user_city}
                            onChange={formik.handleChange}
                            onKeyDown={handleKeyPress}
                        />
                    </Form.Group>
                </Col>
                <Col className='mb-4' md={6}>
                    <Form.Group className="mb-3 form-group required">
                        <Form.Label>State</Form.Label>
                        <div className='d-flex'>
                            <Form.Select className="form-textbox" name="user_state" value={formik?.values?.user_state} onChange={formik.handleChange}>
                                <option value="">Select State</option>
                                {config?.STATE_CODES?.map((entry) => (
                                    <option key={entry?.code} value={entry?.code}>{entry?.state}</option>
                                ))}
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                <Col className='mb-4' md={6} lg={6}>
                    <Form.Group className="form-group">
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control
                            id="zip_code"
                            type="text"
                            maxLength={10}
                            placeholder="Enter Postcode / ZIP"
                            value={formik.values.zip_code}
                            onChange={formik.handleChange}
                        />
                        {formik?.errors?.zip_code &&
                            <Form.Control.Feedback type="invalid">
                                {formik?.errors?.zip_code}
                            </Form.Control.Feedback>}
                    </Form.Group>
                </Col>
                <Col className='mb-4' md={6}>
                    <Form.Group className="form-group">
                        <Form.Label>Gender</Form.Label>
                        <div className='d-flex'>
                            <Form.Select className="form-textbox" name="gender" value={formik?.values?.gender} onChange={formik.handleChange}>
                                <option value="">Select Gender</option>
                                {userSettings?.gender_option?.map((items: any) => (
                                    <option key={items} value={items}>{items}</option>
                                ))}
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                <Col className='mb-4' md={6}>
                    <Form.Group className="form-group">
                        <Form.Label>Mobile Phone</Form.Label>
                        <Form.Control
                            id="mobile_number"
                            type="text"
                            maxLength={14}
                            isValid={Boolean(formik?.values?.mobile_number) &&
                                !Boolean(formik?.errors?.mobile_number)}
                            isInvalid={Boolean(formik?.errors?.mobile_number)}
                            value={formik?.values?.mobile_number}
                            onChange={(e) => formik.setFieldValue("mobile_number", Helper.formatPhoneNo(e.target.value))}
                            placeholder='Enter your mobile number'
                        />
                        {formik?.errors?.mobile_number &&
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.mobile_number}
                            </Form.Control.Feedback>}
                    </Form.Group>
                </Col>
                <Col className='mb-4' md={6} lg={6}>
                    <Form.Group className="form-group">
                        <Form.Label>Birthdate</Form.Label>
                        <Form.Control
                            id="birthdate"
                            type="date"
                            className="form-textbox text-uppercase"
                            value={formik?.values?.birthdate}
                            onChange={formik.handleChange}
                            max={new Date().toISOString().split('T')[0]}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <div className='d-flex flex-wrap justify-content-end mt-2'>
                <Button
                    className="col-12 col-md-auto me-md-2 mb-2"
                    variant="outline-dark"
                    disabled={busy}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                {busy ?
                    <Button
                        variant='success'
                        className='mb-2 col-12 col-md-auto'
                        disabled>
                        <Spinner animation="border" as="span" size="sm" />
                        &nbsp;&nbsp;Update & Continue Editing ...
                    </Button>
                    :
                    <Button
                        variant='success'
                        className='mb-2 col-12 col-md-auto'
                        type="submit" onClick={(e: any) => { e.preventDefault(); formik.handleSubmit(); }}>
                        Update & Continue Editing &nbsp;&nbsp;
                        <FontAwesomeIcon
                            role="button"
                            icon={faArrowUpRightFromSquare}
                            size={'1x' as SizeProp}
                        />
                    </Button>
                }
            </div>
        </Form>
    )
}

export default BasicInformationForm