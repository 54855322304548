import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from '../../app/store';
import { getCoupons } from "../../API/coupons";

export const loadCoupons = createAsyncThunk(
  'coupons/loadCoupons',
  async ({token, email}: { token: string, email: string}) => {
    return await getCoupons(token, email);
  }
);

interface CouponsState {
  coupons: Array<Record<string, any>>;
  pointsCoupon: Record<string, any> | null;
  loaded: boolean;
  loading: boolean;
  loadError: boolean;
}

const initialState: CouponsState = {
  coupons: [],
  pointsCoupon: null,
  loaded: false,
  loading: false,
  loadError: false
}

export const couponsSlice = createSlice({
  name: "coupons",
  initialState: initialState,
  reducers: {
    setPointsCoupon: (state, action: PayloadAction<Record<string, any> | null>) => {
      state.pointsCoupon = action.payload;
    },
    resetCoupons: (state, action: PayloadAction<undefined>) => {
      return initialState;
    }
  },
  extraReducers: builder => {
    builder.addCase(loadCoupons.pending, (state, action) => {
      return {
        ...state,
        loadError: false,
        loading: true
        }
    });
    builder.addCase(loadCoupons.fulfilled, (state, action) => {
      return {
        ...state,
        coupons: [...action.payload],
        loading: false,
        loaded: true
      }  
    });
    builder.addCase(loadCoupons.rejected, (state) => {
      return {
        ...state,
        loadError: true,
        loading: false
      }
    });
  }
});
export const { resetCoupons, setPointsCoupon } = couponsSlice.actions;
export const selectCoupons = (state: RootState) => state.coupons.coupons;
export const selectPointsCoupon = (state: RootState) => state.coupons.pointsCoupon;
export const selectCouponsLoaded = (state: RootState) => state.coupons.loaded;
export const selectCouponsLoading = (state: RootState) => state.coupons.loading;
export const selectCouponLoadError = (state: RootState) => state.coupons.loadError;
export default couponsSlice.reducer;