import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { selectProductSearchFilter, setProductSearchFilter } from '../productsSlice';
import './productSearch.scss';

interface Props {
  className?: string;
}
export default function ProductSearch({ className = '' }: Props) {
  const dispatch = useDispatch();
  const searchVal = useSelector(selectProductSearchFilter);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProductSearchFilter(e.target.value));
  }
  return (
    <div className={`product-search w-100 ${className}`}>
      <InputGroup>
        <FormControl 
          className='rounded-2'
          value={searchVal} 
          onChange={handleChange}
          placeholder='Search Products'
        />
        <span>
          <FontAwesomeIcon icon={faMagnifyingGlass} className="text-muted" /> 
        </span>
      </InputGroup>
    </div>
  );
}