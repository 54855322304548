import {  Dropdown } from 'react-bootstrap';
import Window from '../../../utils/Window';
import DietaryRestrictionsFilterForm from './forms/DietaryRestrictionsFilterForm';

export default function DietaryRestrictionsFilter() {

  if (Window.isMobile()) {
    return (
      <div className='dietary-restrictions-filter'>
        <div className='fs-6 mb-1'>RESTRICTIONS</div>
        <DietaryRestrictionsFilterForm />
      </div>
    );
  }
  return (
    <Dropdown className="dietary-restrictions-filter">
      <Dropdown.Toggle 
      className='rounded-2 w-100'
      variant='outline-dark'
      >Restrictions</Dropdown.Toggle>
      <Dropdown.Menu className='px-2'>
        <DietaryRestrictionsFilterForm />
      </Dropdown.Menu>
    </Dropdown>
  );
}