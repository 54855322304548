import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectMealPlans } from './mealPlansSlice';
import { IMealPlan } from './interfaces';
import MealPlanObj from './mealPlanObj';
import DeleteMealPlan from './DeleteMealPlan';
import { Link } from 'react-router-dom';
import SharedMealButton from './SharedMealButton';

export default function MyMealPlans() {
  const mealPlans = useSelector(selectMealPlans);

  return (
    <Table 
      responsive
      hover
      borderless
      className='fs-6'
    >
      <thead className="d-none d-md-block">
        <tr className="table-secondary fs-5 d-flex">
          <th className="px-3 col-12 col-md-5 col-lg-6">Meal Lists</th>
          <th className="px-3 px-md-0 col-2 text-center"># of Items</th>
          <th className="px-3 col-10 col-md-5 col-lg-4 text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        {mealPlans.map((mealPlanData: IMealPlan) => {
          const mealPlan = new MealPlanObj(mealPlanData);
          return (
            <tr key={mealPlan.data.id} className="d-flex flex-wrap">
              <td className="px-3 d-block col-12 col-md-5 col-lg-6">
                {mealPlan?.getItemCount() === 0 ? <label>{mealPlan.data.name}</label> :
                  <Link
                    to={'/my-account/my-meal-plans/' + mealPlan.data.id}
                    className='text-black'
                  >
                    {mealPlan.data.name}
                  </Link>
                }
              </td>
              <td className="px-3 px-md-0 d-block col-2 text-center">{mealPlan.getItemCount()}</td>
              <td className="px-3 d-block col-10 col-md-5 col-lg-4 d-flex justify-content-end">
                <DeleteMealPlan mealPlan={mealPlan} /> <SharedMealButton mealPlan={mealPlan} count={mealPlan.getItemCount()} />
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>

  )
}