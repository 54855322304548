import { Dropdown } from 'react-bootstrap';
import Window from '../../../utils/Window';
import ProductTypeFilterForm from './forms/ProductTypeFilterForm';

export default function ProductTypeFilter() {
  if (Window.isMobile()) {
    return (
      <div className='product-type-filter'>
        <div className='fs-6 mb-1'>PRODUCT TYPE</div>
        <ProductTypeFilterForm />
      </div>
    )
  }

  return (
    <Dropdown className="product-type-filter">
      <Dropdown.Toggle 
        className='rounded-2 w-100'
        variant='outline-dark'
      >
        Product Type
      </Dropdown.Toggle>
      <Dropdown.Menu className='px-2'>
        <ProductTypeFilterForm />
      </Dropdown.Menu>
    </Dropdown>
  );
}