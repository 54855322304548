import { Alert, Col, Row } from 'react-bootstrap';
import OrderObj from './orderObj';

interface Props {
  order: OrderObj;
}
export default function BillingAndShipping({ order }: Props) {
  return (
    <Row className='my-2'>
      <Col xs={12}>
        <Alert className='shadow-box bg-white text-black font-barlow fs-20px'>
          <Row className='my-2'>
            <Col xs={6} className='fs-3 fw-700'>
              Billing Address
            </Col>
            <Col xs={6} className='fs-3 fw-700'>
              Shipping Address
            </Col>
          </Row>
          <Row className='my-2'>
            <Col xs={6} className='fs-5'>
              {order.data.billing.first_name} {order.data.billing.last_name}<br />
              {order.data.billing.address_1}<br />
              {order.data.billing.address_2 &&
                <div>{order.data.billing.address_2}<br /></div>}
              {order.data.billing.city}, {order.data.billing.state} {order.data.billing.postcode}<br /><br />
              {order.data.billing.phone}<br />
              {order.data.billing.email}
            </Col>
            <Col xs={6} className='fs-5'>
              {order.data.shipping.first_name} {order.data.shipping.last_name}<br />
              {order.data.shipping.address_1}<br />
              {order.data.shipping.address_2 &&
                <div>{order.data.shipping.address_2}<br /></div>}
              {order.data.shipping.city}, {order.data.shipping.state} {order.data.shipping.postcode}
            </Col>
          </Row>
        </Alert>
      </Col>
    </Row>
  );
}