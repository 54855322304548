import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { loadDetails, selectDetailsLoading } from '../core/detailsSlice'
import { Container, Spinner } from 'react-bootstrap'
import DetailsWrapper from './DetailsWrapper'
import { selectToken } from '../../../features/user/userSlice'
import { selectIsMobileRoute } from '../../../features/mobile/mobileSlice'

export default function DetailsPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector(selectToken);
    const paramId = useParams().workoutId;
    const detailsLoading = useSelector(selectDetailsLoading);
    const [isLoaded, setIsLoaded] = useState(false);
    const AppURL = useSelector(selectIsMobileRoute);

    useEffect(() => {
        if(!token){
            navigate(`/${AppURL}`)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, token])

    useEffect(() => {
        if(detailsLoading){
            setIsLoaded(true); 
        }
    }, [detailsLoading])

    useEffect(() => {
        fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramId])

    const fetchDetails = async () => {
        await dispatch(loadDetails(Number(paramId)))
    }

    return (
        <Container>
            <section className="detail-page mb-5">
                {detailsLoading &&
                    <div className='text-center my-5 h-100'>
                        <Spinner
                            variant="dark"
                            animation="border"
                            as="span"
                            className='spinner-xl my-25px'
                        />
                    </div>}
                {isLoaded && !detailsLoading &&
                    <DetailsWrapper />}
            </section>
        </Container>
    )
}
