import { Dropdown } from 'react-bootstrap';
import ProteinFilterForm from './forms/ProteinFilterForm';
import Window from '../../../utils/Window';

export default function ProteinFilter() {
  if (Window.isMobile()) {
    return (
      <div className='protein-filter'>
        <div className='fs-6 mb-1'>PROTEIN</div>
        <ProteinFilterForm />
      </div>
    );
  }

  return (
    <Dropdown className="protein-filter">
      <Dropdown.Toggle 
      className='rounded-2 w-100'
      variant='outline-dark'
      >
        Protein
      </Dropdown.Toggle>
      <Dropdown.Menu className='px-2'>
        <ProteinFilterForm />
      </Dropdown.Menu>
    </Dropdown>
  );
}