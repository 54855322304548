export default class ProductsAPI {
  static async getProducts(page = 1) {
    let response = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/products?page=' + page,
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset=UTF-8' }
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    let total_pages = response.headers.get('x-wp-totalpages');
    let result: { total_pages: number | null; json: Array<Record<string, any>> } = {
      total_pages: total_pages ? parseInt(total_pages) : null,
      json: await response.json()
    };

    return result;
  }

  static getProductBySlug = async (slug: string) => {
    let response = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/products?slug=' + slug,
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset=UTF-8' }
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    let result = await response.json();
    return result;
  }
  static loadProductsByCategory = async (category: string, page = 1) => {
    let response = await (fetch(
      process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/product_cat_list?slug=' + category + '&page=' + page,
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset=UTF-8' }
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    let result = await response.json();
    return result;
  }

  static loadProductsByURL = async (products: any) => {
    let response = await (fetch(
      process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/filter_products?ids=' + products,
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset=UTF-8' }
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    let result = await response.json();
    return result;
  }
}
