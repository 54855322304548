import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Spinner } from 'react-bootstrap'
import { FormikErrors, useFormik } from 'formik';
import UserAPI, { AuthResponse, RegResponse } from '../../API/userAPI';
import { setAuthInfo } from './userSlice';
import { selectAffiliate } from '../referrals/referralsSlice';
import TiktokPixel from 'tiktok-pixel';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

interface Props {
  loggingIn: boolean;
}

export default function RegisterForm({ loggingIn }: Props) {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const [registering, setRegistering] = useState(false);
  const affiliate = useSelector(selectAffiliate);
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
  const [recaptchaError, setRecaptchaError] = useState('');

  const handleRememberMeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      formik.setFieldValue('remember_me', '1');
    } else {
      formik.setFieldValue('remember_me', '0');
    }
  }

  interface FormValues {
    email: string;
    password: string;
    password_confirmation: string;
    remember_me: string;
    honeypot: string;
  }

  const validate = (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 6) {
      errors.password = "Must be at least 6 characters."
    }

    if (!values.password_confirmation) {
      errors.password_confirmation = "Required";
    }

    if (values.password && values.password_confirmation &&
      (values.password !== values.password_confirmation)) {
      errors.password = "Passwords do not match";
      errors.password_confirmation = "Passwords do not match";
    }
    return errors;
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      password_confirmation: '',
      remember_me: '0',
      honeypot: '',
      recaptcha: ''
    },
    validate,
    onSubmit: values => {
      if (!recaptchaValue) {
        setRecaptchaError("Please complete the reCAPTCHA.");
        return;
      }
      setErrorMsg('');
      setRegistering(true);
      let metaData = [];
      if ('affiliate_id' in affiliate) {
        metaData.push({
          key: "_mm_referring_affiliate_id",
          value: affiliate.affiliate_id
        })
      }

      // Check the honeypot field
      if (values.honeypot) {
        setErrorMsg("Something went wrong, Please try again later!");
        setRegistering(false);
        return;
      }

      UserAPI.registerUser(values.email, values.password, metaData).then((response: RegResponse) => {
        if (response.data && response.data.status === 400) {
          setErrorMsg("That email address is already registered. Please log in.");
          setRegistering(false);
        } else {
          UserAPI.authenticateUser(
            values.email,
            values.password,
            values.remember_me === '1'
          ).then((response: AuthResponse) => {
            if (values.remember_me === '1') {
              try {
                localStorage.setItem('token', response.token);
              } catch (e) {
                console.error("Error while setting token in local storage:", e);
              }
            }
            if (response.data && response.data.status === 403) {
              setErrorMsg("Invalid email/password combination.");
              setRegistering(false);
            } else {

              TiktokPixel.track(
                'CompleteRegistration',
                {
                  email: response.user_email,
                  nicename: response.user_nicename,
                  display_name: response.user_display_name
                }
              );
              const setLoginInfo = {
                token: response.token,
                email: response.user_email,
                nicename: response.user_nicename,
                display_name: response.user_display_name,
                isRememberMe: values.remember_me === '1',
                dateStored: new Date()
              }
              localStorage.setItem('setAuthInfo', JSON.stringify(setLoginInfo));
              dispatch(setAuthInfo({
                token: response.token,
                email: response.user_email,
                nicename: response.user_nicename,
                display_name: response.user_display_name
              }));
            }
          }).catch((e) => {
            setErrorMsg(e.message);
          });
        }
      }).catch((e) => {
        setErrorMsg(e.message);
      });
    }
  });

  const onReCAPTCHAChange = (value: string | null) => {
    setRecaptchaValue(value);
    if (value) {
      setRecaptchaError('');
    }
  };

  return (
    <Form className='login-form' onSubmit={formik.handleSubmit}>
      {errorMsg && (
        <p className='text-danger'>{errorMsg}</p>
      )}
      <Form.Group className="mb-3 form-group required">
        <Form.Control
          placeholder='Email address'
          id="email"
          type="email"
          size="lg"
          isValid={Boolean(formik.values.email) &&
            !Boolean(formik.errors.email)}
          isInvalid={Boolean(formik.errors.email)}
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        {formik.errors.email &&
          <Form.Control.Feedback type="invalid">
            {formik.errors.email}
          </Form.Control.Feedback>
        }
      </Form.Group>
      <Form.Group className="mb-3 form-group required">
        <Form.Control
          placeholder='Password'
          id="password"
          type="password"
          size="lg"
          isValid={Boolean(formik.values.password) &&
            !Boolean(formik.errors.password)}
          isInvalid={Boolean(formik.errors.password)}
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        {formik.errors.password &&
          <Form.Control.Feedback type="invalid">
            {formik.errors.password}
          </Form.Control.Feedback>
        }
      </Form.Group>
      <Form.Group className="mb-3 form-group required">
        <Form.Control
          placeholder='Password confirmation'
          id="password_confirmation"
          type="password"
          size="lg"
          isValid={Boolean(formik.values.password_confirmation) &&
            !Boolean(formik.errors.password_confirmation)}
          isInvalid={Boolean(formik.errors.password_confirmation)}
          value={formik.values.password_confirmation}
          onChange={formik.handleChange}
        />
        {formik.errors.password_confirmation &&
          <Form.Control.Feedback type="invalid">
            {formik.errors.password_confirmation}
          </Form.Control.Feedback>
        }
      </Form.Group>
      <div className='d-none mb-3'>
        <label className="form-label">Leave this field blank</label>
        <input
          type="text"
          name="honeypot"
          onChange={formik.handleChange}
          value={formik.values.honeypot}
        />
      </div>
      <Form.Group className='mb-3'>
        <Form.Label
          className='d-inline-flex align-items-center text-white'
        ><Form.Check
            className='d-inline me-2 '
            id="remember_me"
            value={formik.values.remember_me}
            onChange={handleRememberMeChange}
          />
          Remember me</Form.Label>
      </Form.Group>
      <span className='text-white fs-12px'>
        By submitting your email, you agree to our &nbsp;
        <Link to={'/terms-and-conditions'} className='text-white fw-bold'>Terms</Link> and <Link to={'/terms-and-conditions#privacy'} className='text-white fw-bold'>Privacy Policy</Link> to receive email correspondence from us.
      </span>

      <div className="mb-3 pt-3">
        <ReCAPTCHA
          sitekey="6LcqKu8pAAAAAMvOF2uKTXCUqNAAdzm-AMroLVv8"
          onChange={onReCAPTCHAChange}
        />
        {recaptchaError && <div className="text-danger">{recaptchaError}</div>}
      </div>

      <Button className='bg-white login-btn text-black w-100 mt-3' type="submit" size="lg" disabled={registering}>
        {(registering || loggingIn) ?
          <>
            Please wait ...&nbsp;&nbsp;
            <Spinner animation="border" as="span" size="sm" />
          </>
          : 'Register'
        }
      </Button>
    </Form>
  );
}