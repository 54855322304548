import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from '../../app/store';
import ProductsAPI from '../../API/productsAPI';
import ProductObj from "./productObj";
import {
  filterNewProducts,
  filterPopularProducts,
  filterProductsByCalories,
  filterProductsByCarbs,
  filterProductsByDiet,
  filterProductsByDietRestrictions,
  filterProductsByFat,
  filterProductsByProtein,
  filterProductsByProteinType,
  filterProductsBySearch,
  filterProductsByType,
  sortProducts
} from "./productUtils";
import { MinMaxFilter } from "./interfaces";

export const loadProducts = createAsyncThunk(
  'products/loadProducts',
  async (PageNumber: number, api) => {
    const state = (api.getState() as RootState).products;
    let result: { total_pages: number | null, json: Array<Record<string, any>> | null } = {
      total_pages: null,
      json: null
    };
    try {
      result = await ProductsAPI.getProducts(PageNumber);
    } catch (e) {
      if (e instanceof Error) {
        return { error: "Error retrieving products: " + e.message };
      }
      return { error: "Unknown error while retrieving products." };
    }

    if (result.json) {
      result.json = result.json.filter((product: Record<string, any>) => {
        return product.images.length > 0 && product.catalog_visibility === "visible";
      });

    }
    const products = result.json ? [...result.json] : null;

    if (state.pages_loaded === 0 && products) {
      let new_products = 0;
      for (const p_data of products) {
        const product = new ProductObj(p_data);
        if (!product.isNameYourPrice()) {
          product.data.new_product = true;
          new_products++;
          if (new_products >= 8) break;
        }
      }
    }
    return {
      products: products,
      total_pages: result.total_pages
    }
  }
);

export const loadProductBySlug = createAsyncThunk(
  'products/loadProductBySlug',
  async (payload: { slug: string; isCategory: boolean }) => {
    const { slug, isCategory } = payload;
    let result;
    if (isCategory === true) {
      result = await ProductsAPI.loadProductsByCategory(slug);
    } else {
      result = await ProductsAPI.getProductBySlug(slug);
    }
    return result;
  }
);

interface ProductsState {
  products: Array<Record<string, any>>;
  pages_loaded: number;
  total_pages: number | undefined;
  load_error: boolean;
  preLoadedProducts: Array<Record<string, any>>;
  loadBySlugComplete: boolean;
  loadBySlugPending: boolean;
  loadBySlugError: boolean;
  calorie_filter: MinMaxFilter;
  carb_filter: MinMaxFilter;
  diet_filter: string;
  dietary_restrictions: Array<string>;
  fat_filter: MinMaxFilter;
  product_type_filter: string;
  protein_filter: MinMaxFilter;
  protein_type_filter: string;
  search_filter: string;
  sort_filter: string;
}

const initialState: ProductsState = {
  products: [],
  preLoadedProducts: [],
  loadBySlugComplete: false,
  loadBySlugPending: false,
  loadBySlugError: false,
  pages_loaded: 0,
  total_pages: undefined,
  load_error: false,
  calorie_filter: { min: 0, max: 1200 },
  carb_filter: { min: 0, max: 150 },
  diet_filter: "all",
  dietary_restrictions: [],
  fat_filter: { min: 0, max: 100 },
  product_type_filter: "all",
  protein_filter: { min: 0, max: 150 },
  protein_type_filter: "all",
  search_filter: "",
  sort_filter: "none"
}

export const productsSlice = createSlice({
  name: "products",
  initialState: initialState,
  reducers: {
    addDietaryRestriction: (state, action) => {
      state.dietary_restrictions.push(action.payload);
    },
    clearDietaryRestrictions: (state) => {
      state.dietary_restrictions = [];
    },
    removeDietaryRestriction: (state, action) => {
      state.dietary_restrictions = state.dietary_restrictions.filter((item) => {
        return item !== action.payload;
      });
    },
    setProductCalorieFilter: (state, action) => {
      state.calorie_filter = action.payload;
    },
    setProductCarbFilter: (state, action) => {
      state.carb_filter = action.payload;
    },
    setProductDietFilter: (state, action) => {
      state.diet_filter = action.payload;
    },
    setProductFatFilter: (state, action) => {
      state.fat_filter = action.payload;
    },
    setProductProteinFilter: (state, action) => {
      state.protein_filter = action.payload;
    },
    setProductProteinTypeFilter: (state, action) => {
      state.protein_type_filter = action.payload;
    },
    setProductSearchFilter: (state, action) => {
      state.search_filter = action.payload;
    },
    setProductSortFilter: (state, action) => {
      state.sort_filter = action.payload;
    },
    setProductTypeFilter: (state, action) => {
      state.product_type_filter = action.payload;
    },
    clearProducts: (state) => {
      state.products = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(loadProducts.pending, (state, action) => {
      state.load_error = false;
    });
    builder.addCase(loadProducts.fulfilled, (state, action) => {
      if (action.payload.products && action.payload.products.length &&
        action.payload.total_pages) {
        return {
          ...state,
          total_pages: action.payload.total_pages,
          pages_loaded: state.pages_loaded + 1,
          products: [...state.products, ...action.payload.products]
        }
      }
    });
    builder.addCase(loadProducts.rejected, (state) => {
      state.load_error = true;
    });
    builder.addCase(loadProductBySlug.pending, (state, action) => {
      state.loadBySlugPending = true;
    });
    builder.addCase(loadProductBySlug.rejected, (state, action) => {
      state.loadBySlugPending = false;
      state.loadBySlugError = true;
    });
    builder.addCase(loadProductBySlug.fulfilled, (state, action) => {
      if (action.payload.length &&
        state.products.find((product) => product.id === action.payload[0].id)
      ) {
        return {
          ...state,
          loadBySlugPending: false,
          loadBySlugComplete: true
        }
      }
      return {
        ...state,
        preLoadedProducts: [...action.payload],
        loadBySlugPending: false,
        loadBySlugComplete: true
      }
    });
  }
});

export const { addDietaryRestriction,
  clearDietaryRestrictions,
  removeDietaryRestriction,
  setProductCalorieFilter,
  setProductCarbFilter,
  setProductDietFilter,
  setProductFatFilter,
  setProductTypeFilter,
  setProductProteinFilter,
  setProductProteinTypeFilter,
  setProductSearchFilter,
  setProductSortFilter,
  clearProducts } = productsSlice.actions;
export const selectFilteredProducts = (state: RootState) => {
  let products = selectProducts(state);
  products = filterProductsByCalories(products, state.products.calorie_filter);
  products = filterProductsByCarbs(products, state.products.carb_filter);
  products = filterProductsByDiet(products, state.products.diet_filter);
  products = filterProductsByDietRestrictions(products, state.products.dietary_restrictions);
  products = filterProductsByFat(products, state.products.fat_filter);
  products = filterProductsByType(products, state.products.product_type_filter);
  products = filterProductsByProtein(products, state.products.protein_filter);
  products = filterProductsByProteinType(products, state.products.protein_type_filter);
  products = sortProducts(products, state.products.sort_filter);
  products = filterProductsBySearch(products, state.products.search_filter);
  return products;
}
export const selectNewProducts = (state: RootState) => {
  return filterNewProducts(state.products.products);
}
export const selectPopularProducts = (state: RootState) => {
  return filterPopularProducts(state.products.products);
}
export const selectProductCalorieFilter = (state: RootState) => state.products.calorie_filter;
export const selectProductCarbFilter = (state: RootState) => state.products.carb_filter;
export const selectProductDietFilter = (state: RootState) => state.products.diet_filter;
export const selectProductDietRestrictionsFilter = (state: RootState) => state.products.dietary_restrictions;
export const selectProductFatFilter = (state: RootState) => state.products.fat_filter;
export const selectProductProteinFilter = (state: RootState) => state.products.protein_filter;
export const selectProductProteinTypeFilter = (state: RootState) => state.products.protein_type_filter;
export const selectProductSearchFilter = (state: RootState) => state.products.search_filter;
export const selectProductSortFilter = (state: RootState) => state.products.sort_filter;
export const selectProductTypeFilter = (state: RootState) => state.products.product_type_filter;
export const selectProducts = (state: RootState) => {
  if (state.products.preLoadedProducts.length &&
    state.products.products.find((product: Record<string, any>) => {
      return product.id === state.products.preLoadedProducts[0].id;
    })
  ) {
    return state.products.products;
  }

  return state.products.products.concat(state.products.preLoadedProducts);
}
export const selectProductsTotalPages = (state: RootState) => state.products.total_pages;
export const selectProductsPagesLoaded = (state: RootState) => state.products.pages_loaded;
export const selectProductLoadError = (state: RootState) => state.products.load_error;
export default productsSlice.reducer;

