import { MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeDietaryRestriction, selectProductDietRestrictionsFilter } from "../productsSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function DietaryRestrictionsFilterTags() {
  const dispatch = useDispatch();
  const restrictionsFilters = useSelector(selectProductDietRestrictionsFilter);

  const getFilterText = (filter: string) => {
    switch (filter) {
      case 'dairy-free':
        return 'Dairy Free';
      case 'gluten-free':
        return 'Gluten Free';
      case 'not-spicy':
        return 'Not Spicy';
      case 'nut-free':
        return 'Nut Free';
      case 'shellfish-free':
        return 'Shellfish Free';
      case 'soy-free':
        return 'Soy Free';
      case 'wheat-free':
        return 'Wheat Free';
      case 'sesame-free':
        return 'Sesame Free';
      case 'vegan':
        return 'Vegan';
    }
  }

  const handleClick = (e: MouseEvent) => {
    dispatch(removeDietaryRestriction(e.currentTarget.id));
  }

  if (!restrictionsFilters.length) {
    return (<></>);
  }

  return (
    <>
      {restrictionsFilters.map((filter: string, index: number) => {
        return (
          <div 
            className='diet-restriction-filter-tag bg-secondary bg-opacity-25 p-2 fw-bold text-nowrap'
            key={index}
          >
            {getFilterText(filter)}
            <FontAwesomeIcon 
              id={filter}
              role="button"
              className='ps-3 pe-auto' 
              icon={faXmark} 
              size={"xl" as SizeProp}
              onClick={handleClick} 
            />
          </div>          
        )
      })}
    </>
  )

}