import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from '../../app/store';
import ProfileOptionsAPI from "../../API/profileOptionsAPI";

export const loadEditAccountOptions = createAsyncThunk(
  'editAccountOptions/loadEditAccountOptions',
  async () => {
    let result: any[] = [];
    result = await ProfileOptionsAPI.getProfileOptions();

    if (result) {
      return result;
    }
    return {};
  }
);

interface CustomerDetailsState {
  editAccountOptions: Record<string, any>;
  isLoading: boolean;
  isLoaded: boolean;
  loadError: boolean;
}

const initialState: CustomerDetailsState = {
  editAccountOptions: {},
  isLoading: false,
  isLoaded: false,
  loadError: false
}

export const editAccountOptionsSlice = createSlice({
  name: "editAccountOptions",
  initialState: initialState,
  reducers: {
    resetEditAccountOptions: (state) => {
      return initialState;
    },
    setEditAccountOptions: (state, action: PayloadAction<Record<string, any>>) => {
      return {
        ...state, 
        editAccountOptions: { ...action.payload },
        isLoaded: true
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(loadEditAccountOptions.pending, (state, action) => {
      state.isLoading = true;
      state.isLoaded = false;
    });
    builder.addCase(loadEditAccountOptions.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false, 
        isLoaded: true,
        editAccountOptions: { ...action.payload }
      }
    });
    builder.addCase(loadEditAccountOptions.rejected,  (state, action) => {
      state.isLoading = false;
      state.loadError = true;
    });
  }
});
export const { resetEditAccountOptions, setEditAccountOptions } = editAccountOptionsSlice.actions;
export const selectEditAccountOptions = (state: RootState) => state.editAccountOptions.editAccountOptions;
export const selectEditAccountOptionsIsLoading = (state: RootState) => state.editAccountOptions.isLoading;
export const selectEditAccountOptionsIsLoaded = (state: RootState) => state.editAccountOptions.isLoaded;
export const selectEditAccountOptionsLoadError = (state: RootState) => state.editAccountOptions.loadError;
export default editAccountOptionsSlice.reducer;