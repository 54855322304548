import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { Form, Spinner } from 'react-bootstrap';
import { selectShippingLoaded, selectShippingLoading, selectShippingMethods } from "../../features/shipping/shippingSlice";
import { FormValues } from "./interfaces";
import { ChangeEvent } from "react";
import CartObj from "../../features/cart/cartObj";
import ShippingMethodObj from "../../features/shipping/shippingMethodObj";
import { selectCartCoupon, selectCartItems } from "../../features/cart/cartSlice";
import { selectProducts } from '../../features/products/productsSlice';
import { selectCustomer } from "../../features/customer/customerSlice";
import { selectToken } from "../../features/user/userSlice";
import validateForm from "./validateForm";
import DeliveryObj from "../../features/delivery/deliveryObj";
import { selectDeliveryData } from "../../features/delivery/deliverySlice";
import { selectGeneralOptions } from "../../features/mobile/mobileSlice";


export default function ShippingMethodInput() {
  const token = useSelector(selectToken);
  const formik = useFormikContext<FormValues>();
  const customer = useSelector(selectCustomer);
  const cartItems = useSelector(selectCartItems);
  const products = useSelector(selectProducts);
  const coupon = useSelector(selectCartCoupon);
  const shippingLoaded = useSelector(selectShippingLoaded);
  const shippingLoading = useSelector(selectShippingLoading);
  const shippingMethods = useSelector(selectShippingMethods);
  const deliveryInfo = new DeliveryObj(useSelector(selectDeliveryData));
  const getGeneralOptions = useSelector(selectGeneralOptions);
  const paymentOption = getGeneralOptions?.payment_option;
  const creditCardProcessor = paymentOption ? paymentOption : process.env.REACT_APP_CREDIT_CARD_PROCESSOR;
  const [isShippingID, setIsShippingID] = useState<number | null>(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let isShipping: ShippingMethodObj[] = [];
  const cart = useMemo(() => {
    return new CartObj(cartItems)
  }, [cartItems]);

  const zipCodes = formik.values.use_shipping ? formik.values.shipping_postcode :
    formik.values.billing_postcode;

  const getMatchedMethods = useCallback(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isShipping = [];
    const zip = formik.values.use_shipping ? formik.values.shipping_postcode :
      formik.values.billing_postcode;

    let matchedMethods = [];
    //const totalCartAmount = parseFloat(cart.getSubtotal(null, null, null, true).toString().replace(/[^0-9.-]+/g, ""));

    let hasFreeDelivery = false;

    for (const method of shippingMethods) {
      const sm = new ShippingMethodObj(method);
      if (sm.isMatch(zip, cart, products, coupon, customer)) {
        if (cart.hasProductWithCategory('mighty-bucks-gift-card', products) && sm.data.title === "Free Email Delivery") {
          hasFreeDelivery = false;
          matchedMethods.push(sm);
        } else if (!cart.hasProductWithCategory('mighty-bucks-gift-card', products)) {
          if ((sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping") || sm.data.cost === 0) {
            hasFreeDelivery = true;

            matchedMethods.push(sm);
          } else if (!hasFreeDelivery && (sm.data.title !== "Free Home Delivery" || sm.data.title !== "Free Shipping")) {

            matchedMethods.push(sm);
            if (sm.data.ups_delivery_method === true && isShipping.length === 0) {

              isShipping.push(sm);
            }
          }
        }

      }
    }
    if (hasFreeDelivery) {
      matchedMethods = matchedMethods.filter(sm => (sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping"));
    }

    return matchedMethods;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.use_shipping, formik.values.shipping_postcode, formik.values.billing_postcode, shippingMethods, cart, products, coupon, customer]);

  useEffect(() => {
    const matchedMethods = getMatchedMethods();
    if (matchedMethods.length === 1) {
      setIsShippingID(Number(matchedMethods[0].data.ID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCodes, cart, coupon]);

  useEffect(() => {
    if (isShippingID) {
      formik.setFieldValue('shipping_method', isShippingID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShippingID]);

  useEffect(() => {
    const matchedMethods = getMatchedMethods();
    if (shippingLoaded && matchedMethods.length === 1 && !formik.values.shipping_method) {
      formik.setFieldValue('shipping_method', matchedMethods[0].data.ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingLoaded, formik.values.shipping_method]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const shippingMethodByDate = ShippingMethodObj.getById(shippingMethods, Number(e.target.value));
    const isUPSOrUPSFreeShipping = shippingMethodByDate?.data?.ups_delivery_method === true;
    formik.setFieldValue('shipping_method', parseInt(e.target.value));
    validateForm(creditCardProcessor, formik.values, token, deliveryInfo, cart, products, isUPSOrUPSFreeShipping);
  };

  const isChecked = (sm: ShippingMethodObj) => {
    const matchedMethods = getMatchedMethods();
    if (matchedMethods.length === 1) {
      return matchedMethods.length === 1 && matchedMethods[0].data.ID === sm.data.ID
    } else {
      return matchedMethods.length >= 1 && formik.values.shipping_method === sm.data.ID;
    }
  };

  if (shippingLoading) {
    return (
      <Spinner
        animation="border"
        as="span"
        className='text-dark'
      />
    );
  }

  const matchedMethods = getMatchedMethods();
  if (matchedMethods.length === 0 && zipCodes) {
    return (
      <div className='fw-normal'>
        Unfortunately, we do not deliver to the zipcode you have provided. Please check your address to ensure we deliver to your home.
      </div>
    );
  }

  if (matchedMethods.length === 0) {
    return (
      <div className='fw-normal'>
        Enter Zip Code to See If We Deliver To You.
      </div>
    );
  }

  return (
    <Form.Group className="shipping-method-input my-3 form-group required">
      {matchedMethods.map((sm: ShippingMethodObj) => (
        <Form.Check
          type="radio"
          name="shipping-method"
          label={sm.getLabel()}
          key={sm.data.ID}
          id={sm.data.ID}
          value={sm.data.ID}
          checked={isChecked(sm)}
          onChange={handleChange}
        />
      ))}
      {formik.errors.shipping_method &&
        <div className='text-danger mt-3 fw-normal'>
          {formik.errors.shipping_method}
        </div>}
    </Form.Group>
  );
}