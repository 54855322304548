import { useEffect, useState } from 'react';
import { Alert, Container, Spinner } from 'react-bootstrap';
import pagesAPI from "../API/pagesAPI";
import parseHTML from 'html-react-parser';
import EmailZipPortal from '../features/guest/EmailZipPortal';
import './elementorPage.scss'
import { useLocation } from 'react-router-dom';

interface Props {
  pageNo: number;
}

export default function ElementorPage({ pageNo }: Props) {
  const location = useLocation();
  const [errorMsg, setErrorMsg] = useState('');
  const [content, setContent] = useState<any>('');
  const [container, setContainer] = useState<any>('');
  const coreJSUrls = [
    `${process.env.REACT_APP_BLOG_URL}/wp-content/plugins/woocommerce/assets/js/jquery-blockui/jquery.blockUI.min.js`,
    `${process.env.REACT_APP_BLOG_URL}/wp-content/themes/hello-elementor/assets/js/hello-frontend.min.js`,
    `${process.env.REACT_APP_BLOG_URL}/wp-content/plugins/elementor-pro/assets/lib/smartmenus/jquery.smartmenus.min.js`,
    `${process.env.REACT_APP_BLOG_URL}/wp-content/plugins/elementor-pro/assets/js/webpack-pro.runtime.min.js`,
    `${process.env.REACT_APP_BLOG_URL}/wp-content/plugins/elementor/assets/js/webpack.runtime.min.js`,
    `${process.env.REACT_APP_BLOG_URL}/wp-content/plugins/elementor/assets/js/frontend-modules.min.js`,
    `${process.env.REACT_APP_BLOG_URL}/wp-includes/js/dist/vendor/regenerator-runtime.min.js`,
    `${process.env.REACT_APP_BLOG_URL}/wp-includes/js/dist/vendor/wp-polyfill.min.js`,
    `${process.env.REACT_APP_BLOG_URL}/wp-includes/js/dist/hooks.min.js`,
    `${process.env.REACT_APP_BLOG_URL}/wp-content/plugins/elementor-pro/assets/js/frontend.min.js`,
    `${process.env.REACT_APP_BLOG_URL}/wp-content/plugins/elementor/assets/lib/waypoints/waypoints.min.js`,
    `${process.env.REACT_APP_BLOG_URL}/wp-includes/js/jquery/ui/core.min.js`,
    `${process.env.REACT_APP_BLOG_URL}/wp-content/plugins/elementor/assets/js/frontend.min.js`,
    `${process.env.REACT_APP_BLOG_URL}/wp-content/plugins/elementor-pro/assets/js/elements-handlers.min.js`,
    `${process.env.REACT_APP_BLOG_URL}/wp-content/plugins/elementor-pro/assets/lib/sticky/jquery.sticky.min.js`,
  ];
  const loadScript = (
    url: string, 
    where: 'head' | 'body' = 'body',
    onLoad: (() => void) | null  = null) => {
    const  script = document.createElement('script');
    script.src = url;
    script.async = true;

    if (onLoad) script.onload = onLoad;
    if (where === 'head') {
      document.head.appendChild(script);
    } else {
      document.body.appendChild(script);
    }
  }

  useEffect(() => {
    const cssUrls = [
      `${process.env.REACT_APP_BLOG_URL}/wp-content/uploads/elementor/css/post-6.css?ver=1662752224`,
    ];
    
    cssUrls.forEach((url) => {
      const cssUrlWithCacheBust = `${url}?v=${new Date().getTime()}`;

      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = cssUrlWithCacheBust;

      document.head.appendChild(link);
      
      return () => {
        document.head.removeChild(link);
      };
    });
  }, [content, pageNo]);

  useEffect(() => {
    pagesAPI.getPage(pageNo).then((result) => {
      setContent(parseHTML(result.rendered));
    }).catch((e) => {
      setErrorMsg(e.message);
    });
  }, [pageNo]);

  useEffect(() => {
    if (content) {
      setContainer(document.getElementById('emailzipinput'));
      for (let url of coreJSUrls) {
        loadScript(url);
      }
    }
  // eslint-disable-next-line    
  }, [content])

  if (errorMsg) {
    return (
      <Container fluid>
        <Alert className='my-3' variant="danger">
          <Alert.Heading className='fs-1'>
            We're sorry, an error has occurred:
          </Alert.Heading>
          <p className='fs-3 my-4'>{errorMsg}</p>
        </Alert>
      </Container>
    );
  }

  if (!content && !errorMsg) {
    return (
      <>
      {(location.pathname !== '/checkout' && location.pathname !== '/checkout/') && 
        <Container fluid className='elementor-page-loading flex-fill'>
          <div className='text-center my-5 h-100'>
            <Spinner 
              variant="dark"
              animation="border"
              as="span"
              className='spinner-xl my-25px'
            />
          </div>
        </Container>}
      </>
    )
  }

  return (
    <Container fluid className='home-page px-0'>
      {container &&
        <EmailZipPortal container={container} />
      }
      {content}
    </Container>
  );
}