import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function Loader() {
    return (
        <div className='text-center my-5 h-100'>
            <Spinner
                variant="dark"
                animation="border"
                as="span"
                className='spinner-xl my-25px'
            />
        </div>
    )
}
