import { Table } from 'react-bootstrap';
import CustomerObj from './customerObj';

interface Props {
  customer: CustomerObj;
}

export default function MightyPointsLog({ customer }: Props) {
  if (customer.hasMembership()) {
    return (
      <p className='my-5 font-barlow fs-23px'>
        Customers with Discount Memberships cannot participate in the Mighty 
        Points program.
      </p>
    )
  }
  return (
    <Table striped hover responsive className='mightypoints-log my-4'>
      <thead>
        <tr>
          <th>Event</th>
          <th>Date</th>
          <th>Mighty Points</th>
        </tr>
      </thead>
      <tbody>
        {
          Object.keys(customer.data.mightypoints_log).map((key) => {
            return (
              <tr key={customer.data.mightypoints_log[key].id}>
                <td>{customer.data.mightypoints_log[key].description}</td>
                <td>{customer.data.mightypoints_log[key].date_display_human}</td>
                <td>{customer.data.mightypoints_log[key].points}</td>
              </tr>
            )
          })
        }
      </tbody>
    </Table>
  );
}