import config from '../../config.json';
import Helper from '../../utils/Helper';

const conversionRate = parseFloat(config.MIGHTY_POINTS_CONVERSION_RATE);

export const currencyToMightyPoints = (currency: number, MPConversionRate?: any) => {
  const mpconversionrate = Number(MPConversionRate) > 0 ?
    (conversionRate * Number(MPConversionRate)) : conversionRate;
  return Math.round((currency / Number(mpconversionrate)));
}


export const mightyPointsToCurrency = (points: number, formatted = false, MPConversionRate?: any) => {
  const mpconversionrate = Number(MPConversionRate) > 0 ?
    (conversionRate * Number(MPConversionRate)) : conversionRate;
  let amount = Math.round(points * mpconversionrate * 100) / 100;

  if (!formatted) return amount;

  return Helper.formattedCurrency(amount);
}