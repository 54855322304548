import React, { useEffect } from 'react';
import ElementorPage from '../pages/ElementorPage';
import { useLocation } from 'react-router-dom';

export default function CheckoutReviews() {
  const location = useLocation();

  useEffect(() => {
    const addCssLink = (cssUrl: string) => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = cssUrl;
      document.head.appendChild(link);
      return link;
    };

    const removeCssLink = (link: Element | null) => {
      if (link && link.parentNode) {
        link.parentNode.removeChild(link);
      }
    };

    if (location.pathname === '/tennis' || location.pathname === '/tennis/') {
      let cssUrl;
      if (process.env.REACT_APP_BLOG_URL === 'https://blog.mightymeals.com') {
        cssUrl = `${process.env.REACT_APP_BLOG_URL}/wp-content/uploads/elementor/css/post-647047.css`;
      } else {
        cssUrl = `${process.env.REACT_APP_BLOG_URL}/wp-content/uploads/elementor/css/post-646593.css`;
      }

      const cssUrlWithCacheBust = `${cssUrl}?v=${new Date().getTime()}`;

      const link = addCssLink(cssUrlWithCacheBust);

      return () => {
        removeCssLink(link);
      };
    } else {
      let link;
      if (process.env.REACT_APP_BLOG_URL === 'https://blog.mightymeals.com') {
        link = document.querySelector('link[href*="post-647047.css"]');
      } else {
        link = document.querySelector('link[href*="post-646593.css"]');
      }
      removeCssLink(link);
    }
  }, [location.pathname]);

  return (
    <>
      {process.env.REACT_APP_BLOG_URL === 'https://blog.mightymeals.com' ? (
        <ElementorPage pageNo={647047} />
      ) : (
        <ElementorPage pageNo={646593} />
      )}
    </>
  );
}
