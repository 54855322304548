import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Container, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { selectWorkout, selectWorkoutLoaded, selectWorkoutLoading } from '../core/workoutSlice';
import MightyFitAPI from '../../../API/mightyFitAPI';
import WorkoutPlansAPI from '../../../API/myWorkoutsPlanAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faRegularHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faSolidHeart } from "@fortawesome/free-solid-svg-icons";
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { selectToken } from '../../../features/user/userSlice';
import { createWorkPlan, deleteWorkPlan, selectWorkPlans } from '../core/workoutPlansSlice';

export default function WorkOutPage() {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const mightyFit: Array<Record<string, any>> = useSelector(selectWorkout);
  const mightyLoading = useSelector(selectWorkoutLoading);
  const mightyLoaded = useSelector(selectWorkoutLoaded);
  const favoriteWorkouts = useSelector(selectWorkPlans);
  const [workouts, setWorkouts] = useState<Record<string, any>[]>(mightyFit);
  const [pages, setPages] = useState(1);
  const [loadMore, setLoadMore] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModalId, setShowModalId] = useState<number[] | null>(null);
  const [selectedProductId, setSelectedProductId] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage('')
        setSelectedProductId([]);
      }, 3000)
    }
  }, [errorMessage])

  useEffect(() => {
    setWorkouts(mightyFit);
    if (mightyFit.length < 9) {
      setLoadMore(false);
      setPages(1);
    }
    else {
      setLoadMore(true);
    }
  }, [mightyFit])

  useEffect(() => {
    const matchedWorkouts = workouts.filter((workout: any) =>
      favoriteWorkouts.some((myworkouts: any) =>
        myworkouts.items.some((myWorkoutFavorite: any) =>
          myWorkoutFavorite.workoutId === workout.id
        )
      )
    );

    const matchingIds = matchedWorkouts.map((workout: any) => workout.id);
    setShowModalId(matchingIds);
  }, [favoriteWorkouts, workouts, isAdding]);

  const handleLoadMore = async () => {
    setIsLoading(true)
    setPages(pages + 1);
    let response = await MightyFitAPI.getworkouts(pages + 1);

    if (response.data && Array.isArray(response.data)) {
      response.data.map((items: any) => (
        setWorkouts((prevWorkouts) => [...prevWorkouts, items])
      ));

      if (response.data.length < 9) {
        setLoadMore(false);
        setPages(1);
      }
    }
    setIsLoading(false)
  }

  const handleFavoriteSet = (items: any) => {
    if (!token) {
      return setErrorMessage('Please Log-in/Register To Add To Your Favorite.')
    } else {
      if (showModalId?.includes(items.id)) {
        setIsAdding(true);
        const matchedWorkouts = favoriteWorkouts.filter((myworkouts: any) =>
          myworkouts.items.some((myWorkoutFavorite: any) =>
            myWorkoutFavorite.workoutId === items.id
          )
        );
        WorkoutPlansAPI.deleteWorkoutsPlan(token, matchedWorkouts[0].id).then(async () => {
          await dispatch(deleteWorkPlan(matchedWorkouts[0].id));
        }).catch((e) => {
          console.error(e);
        }).finally(() => {
          setIsAdding(false);
          setSelectedProductId([]);
        });
      } else {
        setIsAdding(true);
        WorkoutPlansAPI.createWorkoutsPlan(
          token, {
          name: items.trainer_name,
          items: [{
            workoutId: items.id,
            workoutName: items.title
          }]
        }).then(async (workPlan) => {
          await dispatch(createWorkPlan(workPlan));
        }).catch((e) => {
          console.error(e);
        }).finally(() => {
          setIsAdding(false);
          setSelectedProductId([]);
        });
      }
    }
  }

  return (
    <Container fluid="lg" className='address-page'>
      {mightyLoading && !mightyLoaded &&
        <div className='text-center my-5 h-100'>
          <Spinner
            variant="dark"
            animation="border"
            as="span"
            className='spinner-xl my-25px'
          />
        </div>}
      {!mightyLoading && mightyLoaded &&
        <Row className="card-row">
          {workouts.map((items: any, index: number) => (
            <Col lg={4} md={6} className="card-col" key={index}>
              <div className="cp-wrapper">
                <div className='icon-wrap'>
                  {selectedProductId === items.id && errorMessage && (
                    <Alert variant="danger" className='my-3 text-center error-message'>{errorMessage}</Alert>
                    )}
                  {(selectedProductId === items.id && !errorMessage) && (
                    <Spinner animation="border" as="span" size="sm" />
                    )} {(selectedProductId !== items.id && 
                    <FontAwesomeIcon
                    role="button"
                    icon={showModalId?.includes(items.id) ? faSolidHeart : faRegularHeart}
                    color={`${showModalId?.includes(items.id) ? 'red' : 'black'}`}
                    size={'xl' as SizeProp}
                    onClick={() => {
                      setSelectedProductId(items.id);
                      handleFavoriteSet(items);
                    }}
                    />
                    )}
                </div>
                <Link to={`/mightyfit/${items.id}`} className="card-link">
                  <div className="cp-imagewrap">
                      <div className='imagewrap'>
                        <img src={items.image} alt="" />
                      </div>
                  </div>
                </Link>
                <div className="cp-deswrap">
                  <div className="cp-show">
                    <h5 className='h5' dangerouslySetInnerHTML={{__html: items.title}}></h5>
                    <p className="pera" dangerouslySetInnerHTML={{__html: items.small_description}}></p>
                    <div className="iconpart">
                      <div className='first-part'>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="#00B156" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9 4.5V9L12 10.5" stroke="#00B156" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <span>{Math.floor((items.duration_in_minutes / 1000) / 60)}</span>
                      </div>
                      <div className='first-part'>
                        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_213_937)">
                            <path d="M3 0L3.927 1.878L6 2.181L4.5 3.642L4.854 5.706L3 4.731L1.146 5.706L1.5 3.642L0 2.181L2.073 1.878L3 0Z" fill="#00B156" />
                          </g>
                          <defs>
                            <clipPath id="clip0_213_937">
                              <rect width="6" height="6" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <span className='text-capitalize'>{items.body_focus}</span>
                      </div>
                    </div>
                  </div>
                  <div className="bg-hover">
                    <div className="cp-hover">
                      <Row className="hoverrow">
                        <Col className="hover-col">
                          <p>Difficulty</p>
                        </Col>
                        <Col className="hover-col right col">
                          <span className='text-white'>{items.difficulty_level}</span>
                        </Col>
                      </Row>
                      <Row className="hoverrow">
                        <Col className="hover-col">
                          <p>Training Type</p>
                        </Col>
                        <Col className="hover-col right">
                          <p>{items.training_type.join(", ")}</p>
                        </Col>
                      </Row>
                      <Row className="hoverrow">
                        <Col className="hover-col">
                          <p>Equipment</p>
                        </Col>
                        <Col className="hover-col right">
                          <p>{items.equipments.join(", ")}</p>
                        </Col>
                      </Row>
                      {/* <Row className="hoverrow">
                        <Col className="hover-col">
                          <p>Burn Estimate</p>
                        </Col>
                        <Col className="hover-col right">
                          <p>{items.minimum_duration_of_calorie_burn} - {items.maximum_duration_of_calorie_burn} Calories</p>
                        </Col>
                      </Row> */}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
          {loadMore &&
            <div className="btn-wrap">
              <Button variant='success' className='btn-load' disabled={isLoading} onClick={handleLoadMore}>{isLoading ? 'LOADING...' : 'LOAD MORE'}</Button>
            </div>
          }
        </Row>}
    </Container>
  )
}
