import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import ElementorPage from '../ElementorPage';

export default function EssentialsPage() {
    const location = useLocation();

    useEffect(() => {
      const addCssLink = (cssUrl: string) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = cssUrl;
        document.head.appendChild(link);
        return link;
      };
  
      const removeCssLink = (link: Element | null) => {
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      };
  
      if ((location.pathname === '/supplements' || location.pathname === '/essentials') 
            || (location.pathname === '/supplements/' || location.pathname === '/essentials/')) {
        let cssUrl;
        if (process.env.REACT_APP_BLOG_URL === 'https://blog.mightymeals.com') {
          cssUrl = `${process.env.REACT_APP_BLOG_URL}/wp-content/uploads/elementor/css/post-647166.css`;
        } else {
          cssUrl = `${process.env.REACT_APP_BLOG_URL}/wp-content/uploads/elementor/css/post-646602.css`;
        }
  
        const cssUrlWithCacheBust = `${cssUrl}?v=${new Date().getTime()}`;
  
        const link = addCssLink(cssUrlWithCacheBust);
  
        return () => {
          removeCssLink(link);
        };
      } else {
        let link;
        if (process.env.REACT_APP_BLOG_URL === 'https://blog.mightymeals.com') {
          link = document.querySelector('link[href*="post-647166.css"]');
        } else {
          link = document.querySelector('link[href*="post-646602.css"]');
        }
        removeCssLink(link);
      }
    }, [location.pathname]);
  
  return (
    <>
      {process.env.REACT_APP_BLOG_URL === 'https://blog.mightymeals.com' ? (
        <ElementorPage pageNo={647166} />
      ) : (
        <ElementorPage pageNo={646602} />
      )}
    </>
  )
}
