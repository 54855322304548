import { useState } from 'react';
import { Card, Image} from "react-bootstrap";
import NYPInput from '../../components/NYPInput';
import ProductObj from './productObj';
import NYPProductDetailModal from './NYPProductDetailModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import ToastError from '../../components/ToastError';

interface Props {
  product: ProductObj;
  price: number;
  onPriceChange: (price: number) => string;
  cartUpdateInProgress: boolean;
  orderInProgress: boolean;
  showModal: boolean;
}

export default function NYPProduct({
  product, 
  price,
  onPriceChange,
  cartUpdateInProgress,
  orderInProgress,
  showModal
}: Props) {
  const [errorMsg, setErrorMsg] = useState('');
  const [showDetail, setShowDetail] = useState(showModal);

  const handlePriceChange = (price: number) => {
    const error = onPriceChange(price);

    if (error) setErrorMsg(error);

    return error;
  }

  return (
    <div
      className='nyp-product border border-dark p-2 h-100'
    >
      <NYPProductDetailModal 
        product={product} 
        show={showDetail} 
        onHide={() => setShowDetail(false)}
        onPriceChange={onPriceChange}
        price={price}
      />
      <Card className='border-0'>
        {errorMsg &&
          <ToastError 
            msg={errorMsg} 
            onClose={() => setErrorMsg('')} 
            position="middle-center"
          />
        }
        <div className='card-image'>
          <Image
            alt="product-img"
            thumbnail={true}
            role="button"
            src={product.data.images[0].src} 
            onClick={() => setShowDetail(true)}
            className='border-0'
          />
        </div>
        <Card.Title className='product-title mx-auto my-2 text-center text-black'>
          {product.data.name}
        </Card.Title>
        <NYPInput 
          className='my-2 mx-xs-0  mx-lg-2 mx-xl-1 mx-xxl-5'
          onPriceChange={handlePriceChange} 
          price={price} 
          disabled={orderInProgress || cartUpdateInProgress}
        />
        <div className='d-flex justify-content-center show-product-detail-btn'>
          <FontAwesomeIcon 
            icon={faCircleInfo} 
            size="lg"  
            role="button"
            className='mt-3'
            onClick= {() => setShowDetail(true)}/>
        </div>
      </Card>
    </div>
  );
}