import { MinMaxFilter } from "./interfaces";
import ProductObj from "./productObj";


export const filterNewProducts = (products: Array<Record<string, any>>) => {
  return products.filter((p) => {
    const product = new ProductObj(p);
    return product.isNew();
  });
}

export const filterPopularProducts = (products: Array<Record<string, any>>) => {
  return products.filter((p) => {
    const product = new ProductObj(p);
    return product.hasTag('most-popular');
  });
}

export const filterProductsBySLug = (products: Array<Record<string, any>>, slug: string) => {
  const product = products.find((product) => {
    return product.slug === slug;
  });

  return product || null;
}

export const filterProductsByCalories = (products: Array<Record<string, any>>, filter: MinMaxFilter) => {
  products = products.filter((p) => {
    const product = new ProductObj(p);
    const caloriesString = product.getCalories();
    const match = caloriesString.match(/\d+/);
    const calories = match ? parseInt(match[0], 10) : null;
    return calories === null || (calories >= filter?.min && calories <= filter?.max);
  });
  return products;
}

export const filterProductsByCarbs = (products: Array<Record<string, any>>, filter: MinMaxFilter) => {
  products = products.filter((p) => {
    const product = new ProductObj(p);
    const carbs = product.getCarbs();
    return carbs >= filter.min && carbs <= filter.max;
  });
  return products;
}

export const filterProductsByCategory = (products: Array<Record<string, any>>, category: string) => {
  products = products.filter((p) => {
    const product = new ProductObj(p);
    return product.hasCategory(category);
  });
  return products;
}

export const filterProductsByDiet = (products: Array<Record<string, any>>, diet: string) => {
  switch (diet) {
    case 'all':
      break;
    case 'breakfast':
      products = products.filter((p) => {
        const product = new ProductObj(p);
        return product.isBreakfastItem();
      });
      break;
    case 'emp180-approved':
      products = products.filter((p) => {
        const product = new ProductObj(p);
        return product.isEmp180Approved();
      });
      break;
    case 'low-carb':
      products = products.filter((p) => {
        const product = new ProductObj(p);
        return product.isLowCarb();
      });
      break;
    case 'low-sodium':
      products = products.filter((p) => {
        const product = new ProductObj(p);
        return product.isLowSodium();
      });
      break;
    case 'over-500-cal':
      products = products.filter((p) => {
        const product = new ProductObj(p);
        return product.isOver500Cal();
      });
      break;
    case 'under-500-cal':
      products = products.filter((p) => {
        const product = new ProductObj(p);
        return product.isUnder500Cal();
      });
      break;
    case 'vegan':
      products = products.filter((p) => {
        const product = new ProductObj(p);
        return product.hasTag(diet);
      });
      break;
    case 'vegetarian':
      products = products.filter((p) => {
        const product = new ProductObj(p);
        return product.hasCategory(diet);
      });
      break;
    default:
      console.error("Unknown diet: ", diet);
  }
  return products;
}

export const filterProductsByDietRestrictions = (products: Array<Record<string, any>>, restrictions: Array<string>) => {
  restrictions.forEach((restriction) => {
    products = products.filter((p) => {
      const product = new ProductObj(p);
      return product.hasTag(restriction);
    });
  });
  return products;
}

export const filterProductsByFat = (products: Array<Record<string, any>>, filter: MinMaxFilter) => {
  products = products.filter((p) => {
    const product = new ProductObj(p);
    const fat = product.getFat();
    return fat >= filter.min && fat <= filter.max;
  });
  return products;
}

export const filterProductsByProtein = (products: Array<Record<string, any>>, filter: MinMaxFilter) => {
  products = products.filter((p) => {
    const product = new ProductObj(p);
    const protein = product.getProtein();
    return protein >= filter.min && protein <= filter.max;
  });
  return products;
}

export const filterProductsByProteinType = (products: Array<Record<string, any>>, protein: string) => {
  if (protein === "all") return products;

  return products.filter((p) => {
    const product = new ProductObj(p);
    return product.hasCategory(protein);
  });
}

export const filterProductsBySearch = (products: Array<Record<string, any>>, search: string) => {
  search = search.trim();
  if (search.length <= 0) return products;

  const re = new RegExp(search, "i");
  return products.filter((product) => {
    if (product.name.match(re) != null) return true;
    if (product.description.match(re) != null) return true;

    for (let category of product.categories) {
      if (category.name.match(re) !== null) return true;
    }
    for (let tag of product.tags) {
      if (tag.name.match(re) !== null) return true;
    }
    for (let attribute of product.attributes) {
      for (let option of attribute.options) {
        if (option.match(re) !== null) return true;
      }
    }
    return false;
  });
}

export const filterProductsByType = (products: Array<Record<string, any>>, type: string) => {
  if (type === "all") return products;

  return products.filter((p) => {
    const product = new ProductObj(p);
    return product.hasCategory(type);
  });
}

export const sortProducts = (products: Array<Record<string, any>>, sort_by: string) => {
  switch (sort_by) {
    case 'none':
      break;
    case 'calories_asc':
      products = products.sort((a, b) => {
        const pA = new ProductObj(a);
        const pB = new ProductObj(b);
        if (pA.getCalories() < pB.getCalories()) return -1;
        if (pA.getCalories() > pB.getCalories()) return 1;
        return 0;
      });
      break;
    case 'most_popular':
      products = products.sort((a, b) => {
        const pA = new ProductObj(a);
        const pB = new ProductObj(b);
        return pB.getMostPopular() - pA.getMostPopular();
      });
      break;
    case 'calories_desc':
      products = products.sort((a, b) => {
        const pA = new ProductObj(a);
        const pB = new ProductObj(b);
        if (pA.getCalories() > pB.getCalories()) return -1;
        if (pA.getCalories() < pB.getCalories()) return 1;
        return 0;
      });
      break;
    case 'carbs_asc':
      products = products.sort((a, b) => {
        const pA = new ProductObj(a);
        const pB = new ProductObj(b);
        if (pA.getCarbs() < pB.getCarbs()) return -1;
        if (pA.getCarbs() > pB.getCarbs()) return 1;
        return 0;
      });
      break;
    case 'carbs_desc':
      products = products.sort((a, b) => {
        const pA = new ProductObj(a);
        const pB = new ProductObj(b);
        if (pA.getCarbs() > pB.getCarbs()) return -1;
        if (pA.getCarbs() < pB.getCarbs()) return 1;
        return 0;
      });
      break;
    case 'fat_asc':
      products = products.sort((a, b) => {
        const pA = new ProductObj(a);
        const pB = new ProductObj(b);
        if (pA.getFat() < pB.getFat()) return -1;
        if (pA.getFat() > pB.getFat()) return 1;
        return 0;
      });
      break;
    case 'fat_desc':
      products = products.sort((a, b) => {
        const pA = new ProductObj(a);
        const pB = new ProductObj(b);
        if (pA.getFat() > pB.getFat()) return -1;
        if (pA.getFat() < pB.getFat()) return 1;
        return 0;
      });
      break;
    case 'name_asc':
      products = products.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      break;
    case 'name_desc':
      products = products.sort((a, b) => {
        if (a.name > b.name) return -1;
        if (a.name < b.name) return 1;
        return 0;
      });
      break;
    case 'protein_asc':
      products = products.sort((a, b) => {
        const pA = new ProductObj(a);
        const pB = new ProductObj(b);
        if (pA.getProtein() < pB.getProtein()) return -1;
        if (pA.getProtein() > pB.getProtein()) return 1;
        return 0;
      });
      break;
    case 'protein_desc':
      products = products.sort((a, b) => {
        const pA = new ProductObj(a);
        const pB = new ProductObj(b);
        if (pA.getProtein() > pB.getProtein()) return -1;
        if (pA.getProtein() < pB.getProtein()) return 1;
        return 0;
      });
      break;
    default:
      console.error("Unknown sort filter: ", sort_by);
  }
  return products;
}