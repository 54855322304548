import { IDietaryPlan, IDietaryPlanPost } from "../features/dietary_plans/interfaces";

export default class DietaryPlansAPI {
  static getDietaryPlans = async (token: string) => {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/dietary_plans?token=' + token,
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset=UTF-8' }
      }
    ));

    if (!response.ok) {
      console.error('HTTP error while retrieving dietary plans. Status:', response.status);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static createDietaryPlan = async(token: string, dietaryPlan: IDietaryPlanPost) => {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/dietary_plans',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({ token: token, dietary_plan: JSON.stringify(dietaryPlan)})
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while creating dietary plan. Status:', response.status);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static updateDietaryPlan = async(token: string, dietaryPlan: IDietaryPlan) => {
    let response = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/dietary_plans/' + dietaryPlan.id,
      {
        method: 'PUT',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({ token: token, dietary_plan: JSON.stringify(dietaryPlan)})
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while updating dietary plan. Status:', response.status);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static deleteDietaryPlan = async(token: string, dietaryPlanId: number) => {
    let response: any = await(fetch(
      process.env.REACT_APP_SERVER_URL + '/dietary_plans/' + dietaryPlanId + 
        '?token=' + token,
      {
        method: 'DELETE',
        headers: { 'content-type': 'application/json;charset=UTF-8' }
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while deleting dietary plan. Status:', response.status);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
   
  }
}