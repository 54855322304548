import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomerDetailsAPI from '../../API/customerDetailsAPI';
import { setEmail } from '../guest/guestSlice';
import { loadCustomerDetails, selectCustomerDetails, setCustomerDetails } from './customerDetailSlice';
import { selectCustomer } from './customerSlice';
import { selectEditAccountOptions } from './editaccountOptionsSlice';
import { selectIsMobileRoute } from '../mobile/mobileSlice';

interface Props {
    handleSecondAccordian: () => void;
    setErrorMsg: (errorMsg: string) => void;
}
function DietaryNeedsGolsForm({ handleSecondAccordian, setErrorMsg }: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const custData = useSelector(selectCustomer);
    const customerData = useSelector(selectCustomerDetails);
    const userSettings = useSelector(selectEditAccountOptions);
    const customerDetails = customerData?.user_data;
    const [busy, setBusy] = useState(false);
    const AppURL = useSelector(selectIsMobileRoute);

    const formik = useFormik({
        initialValues: {
            email: custData?.email,
            my_diet: customerDetails?.my_diet,
            current_weight: customerDetails?.current_weight,
            target_weight: customerDetails?.target_weight,
            height_in_feet: customerDetails?.height_in_feet,
            height_in_inches: customerDetails?.height_in_inches,
        },
        onSubmit: values => {
            setErrorMsg('');
            setBusy(true);
            CustomerDetailsAPI.updateDietaryNeedsGoalsCustomerDetails({
                email: custData.email,
                my_diet: values?.my_diet,
                current_weight: values?.current_weight,
                target_weight: values?.target_weight,
                height_in_feet: values?.height_in_feet,
                height_in_inches: values?.height_in_inches,
            }).then((response) => {
                if ('code' in response) {
                    console.error(response);
                    if ('message' in response) {
                        setErrorMsg(response.message);
                    } else {
                        setErrorMsg("An error has occurred. Please try again later.");
                    }
                } else if ('id' in response || 'email' in response) {
                    dispatch(setCustomerDetails(response));
                    dispatch(setEmail(response.email));
                }
            }).catch((e) => {
                setErrorMsg(e.message);
            }).finally(() => {
                dispatch(loadCustomerDetails(custData.email));
                setBusy(false);
                handleSecondAccordian();
            })
        }
    });

    const handleCancel = () => {
        navigate(`/my-account${AppURL}`);
    }

    return (
        <Form>
            <Row>
                <Col sm={6} md={4} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Diet</Form.Label>
                        <div className='d-flex'>
                            <Form.Select className="form-textbox" name="my_diet" value={formik?.values?.my_diet} onChange={formik.handleChange}>
                                <option value="">Select Diet</option>
                                {userSettings?.diet_option?.map((items: any) => (
                                    <option key={items} value={items}>{items}</option>
                                ))}
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                <Col sm={6} md={4} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Current Weight</Form.Label>
                        <Form.Control
                            id="current_weight"
                            type="text"
                            className="form-textbox"
                            value={formik?.values?.current_weight}
                            onChange={formik.handleChange}
                            placeholder='Enter your current weight'
                            pattern="\d*"
                            onInput={(event: React.FormEvent<HTMLInputElement>) => {
                                const input = event.currentTarget;
                                const value = input.value.trim();
                                const newValue = value.replace(/[^\d]/g, '');
                                if (newValue !== value) {
                                  input.value = newValue;
                                  formik.setFieldValue('current_weight', newValue);;
                                }
                            }}
                        />
                    </Form.Group>
                </Col>
                <Col sm={6} md={4} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Target Weight</Form.Label>
                        <Form.Control
                            id="target_weight"
                            type="text"
                            className="form-textbox"
                            value={formik?.values?.target_weight}
                            onChange={formik.handleChange}
                            placeholder='Enter your taget weight'
                            pattern="\d*"
                            onInput={(event: React.FormEvent<HTMLInputElement>) => {
                                const input = event.currentTarget;
                                const value = input.value.trim();
                                const newValue = value.replace(/[^\d]/g, '');
                                if (newValue !== value) {
                                    input.value = newValue;
                                    formik.setFieldValue('target_weight', newValue);;
                                }
                            }}
                        />
                    </Form.Group>
                </Col>
                <Col sm={12} lg={6}>
                    <Form.Label className='fw-bold'>Height</Form.Label>
                    <Row>
                        <Col className='mb-4' xs={6}>
                            <Form.Group className="form-group">
                                <Form.Label>Feet</Form.Label>
                                <div className='d-flex'>
                                    <Form.Select className="form-textbox" name="height_in_feet" value={formik?.values?.height_in_feet} onChange={formik.handleChange}>
                                        <option value="">Select Feet</option>
                                        {userSettings?.height_option_in_feet?.map((items: any) => (
                                            <option key={items} value={items}>{items}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col className='mb-4' xs={6}>
                            <Form.Group className="form-group">
                                <Form.Label>Inches</Form.Label>
                                <div className='d-flex'>
                                    <Form.Select className="form-textbox" name="height_in_inches" value={formik?.values?.height_in_inches} onChange={formik.handleChange}>
                                        <option value="">Select Inches</option>
                                        {userSettings?.height_option_in_inches?.map((items: any) => (
                                            <option key={items} value={items}>{items}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className='mt-2 d-flex justify-content-end flex-wrap'>
                <Button
                    className="col-12 col-md-auto me-md-2 mb-2"
                    variant="outline-dark"
                    disabled={busy}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                {busy ?
                    <Button 
                    className='mb-2 col-12 col-md-auto'
                    variant='success' disabled>
                        <Spinner animation="border" as="span" size="sm" />
                        &nbsp;&nbsp;Update & Continue Editing ...
                    </Button>
                    :
                    <Button 
                    variant='success'
                    className='mb-2 col-12 col-md-auto'
                        type="submit" onClick={(e: any) => { e.preventDefault(); formik.handleSubmit(); }}>
                        Update & Continue Editing &nbsp;&nbsp;
                        <FontAwesomeIcon
                            role="button"
                            icon={faArrowUpRightFromSquare}
                            size={'1x' as SizeProp}
                        />
                    </Button>
                }
            </div>
        </Form>
    )
}

export default DietaryNeedsGolsForm