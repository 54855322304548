import { ChangeEvent, useEffect, useState } from 'react';
import { Card, Form } from "react-bootstrap";
import CouponForm from "./CouponForm";
import { useLocation } from 'react-router-dom';

export default function CouponEntry() {
  const [showForm, setShowForm] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const couponCodeQuery = queryParams.get("coupon-code");
  const [isChecked, setIsChecked] = useState(couponCodeQuery !== null && couponCodeQuery !== undefined);

  useEffect(() => {
    if(couponCodeQuery !== null || couponCodeQuery !== "" || couponCodeQuery !== "null" || couponCodeQuery !== undefined){
      if(isChecked){
        setShowForm(true);
      }
    }
  },[couponCodeQuery, isChecked])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(prevState => !prevState);
    setShowForm(e.target.checked);
  }

  return (
    <Card className='coupon-entry fs-5 mb-0'>
      <Card.Header 
        className='d-flex justify-content-between p-3 bg-white text-black font-barlow fs-20px fw-700'
      >
        <div>
          Have a Gift Card or Coupon Code?
        </div>
        <Form.Check 
          type="switch" 
          className='mx-2' 
          onChange={handleChange}
          checked={isChecked}
        />
      </Card.Header>
      {showForm &&
        <Card.Body>
          <CouponForm />
        </Card.Body>
      }
    </Card>
  )
}