export default class Window {
  static isMobile() {
    return window.innerWidth <= 768;
  }

  static isTablet() {
    return window.innerWidth > 768 && window.innerWidth < 992;
  }

  static isXS = ()  => {
    return window.innerWidth < 576;
  }

  static isSM = () => {
    return window.innerWidth >= 576 && window.innerWidth < 768;
  }

  static isMD = () => {
    return window.innerWidth >= 768 && window.innerWidth < 992;
  }

  static isLG = () => {
    return window.innerWidth >= 992 && window.innerWidth < 1200;
  }

  static isXL = () => {
    return window.innerWidth >= 1200 && window.innerWidth < 1400;
  }

  static isXLorGreater = () => {
    return window.innerWidth >= 1200;
  }

  static isXXL = () => {
    return window.innerWidth >= 1400;
  }
}