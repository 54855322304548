import { Toast, ToastContainer } from 'react-bootstrap';
import { ToastPosition } from 'react-bootstrap/esm/ToastContainer';

interface Props {
  Error?: string;
  msg: string;
  onClose: () => void;
  position?: ToastPosition;
  className?: string;
}

export default function ToastError({ 
  Error,
  msg, 
  onClose, 
  position='middle-center',
  className=''
}: Props) {
  return (
    <ToastContainer className={` ${className}`} position={position}>
      <Toast
        className="bg-danger"
        onClose={onClose} 
        delay={7000} 
      >
        <Toast.Header>
          <strong className='me-auto text-danger'>{Error ? Error : "Error"}</strong>
        </Toast.Header>
        <Toast.Body className='text-white fw-semibold'>{msg}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}