import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomerDetailsAPI from '../../API/customerDetailsAPI';
import { setEmail } from '../guest/guestSlice';
import { loadCustomerDetails, selectCustomerDetails, setCustomerDetails } from './customerDetailSlice';
import { selectCustomer } from './customerSlice';
import { selectIsMobileRoute } from '../mobile/mobileSlice';

interface Props {
    setErrorMsg: (errorMsg: string) => void;
    handleFourthAccordian: () => void;
}
function AdditionInformationForm({ setErrorMsg, handleFourthAccordian }: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const custData = useSelector(selectCustomer);
    const customerData = useSelector(selectCustomerDetails);
    const customerDetails = customerData?.user_data;
    const [busy, setBusy] = useState(false);
    const [saved, setSaved] = useState(false);
    const AppURL = useSelector(selectIsMobileRoute);

    useEffect(() => {
        setTimeout(() => {
            setSaved(false);
        }, 2500);
    }, [saved])

    const formik = useFormik({
        initialValues: {
            email: custData?.email,
            ordering_mm_for_multi_people: customerDetails?.ordering_mm_for_multi_people,
            have_a_pet: customerDetails?.have_a_pet,
            mobile_phone: customerDetails?.mobile_phone,
            housing: customerDetails?.housing,
            how_many_people_in_house_use_mm: customerDetails?.how_many_people_in_house_use_mm,
            number_of_children_in_household: customerDetails?.number_of_children_in_household,
            have_you_ever_ordered_prepared_meal: customerDetails?.have_you_ever_ordered_prepared_meal,
            occupation: customerDetails?.occupation,
            mode_of_transportation: customerDetails?.mode_of_transportation,
            do_member_of_our_mighty_concierge: customerDetails?.do_member_of_our_mighty_concierge,
        },
        onSubmit: values => {
            setErrorMsg('');
            setBusy(true);
            CustomerDetailsAPI.updateAdditionalInformationCustomerDetails({
                email: custData.email,
                ordering_mm_for_multi_people: values?.ordering_mm_for_multi_people,
                have_a_pet: values?.have_a_pet,
                mobile_phone: values?.mobile_phone,
                housing: values?.housing,
                how_many_people_in_house_use_mm: values?.how_many_people_in_house_use_mm,
                number_of_children_in_household: values?.number_of_children_in_household,
                have_you_ever_ordered_prepared_meal: values?.have_you_ever_ordered_prepared_meal,
                occupation: values?.occupation,
                mode_of_transportation: values?.mode_of_transportation,
                do_member_of_our_mighty_concierge: values?.do_member_of_our_mighty_concierge,
            }).then((response) => {
                if ('code' in response) {
                    console.error(response);
                    if ('message' in response) {
                        setErrorMsg(response.message);
                    } else {
                        setErrorMsg("An error has occurred. Please try again later.");
                    }
                } else if ('id' in response || 'email' in response) {
                    dispatch(setCustomerDetails(response));
                    dispatch(setEmail(response.email));
                }
            }).catch((e) => {
                setErrorMsg(e.message);
            }).finally(() => {
                dispatch(loadCustomerDetails(custData.email));
                setBusy(false);
                setSaved(true);
                setTimeout(async () => {
                    handleFourthAccordian();
                }, 3000);
            })
        }
    });

    const handleCancel = () => {
        navigate(`/my-account${AppURL}`);
    }

    return (
        <Form>
            <Row className='align-items-end'>
                <Col sm={6} lg={4} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Ordering MightyMeals for multiple people?</Form.Label>
                        <div className='d-flex'>
                            <Form.Select className="form-textbox" name="ordering_mm_for_multi_people" value={formik?.values?.ordering_mm_for_multi_people} onChange={formik.handleChange}>
                                <option value="">Select Yes or No</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                <Col sm={6} lg={4} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Pets?</Form.Label>
                        <div className='d-flex'>
                            <Form.Select className="form-textbox" name="have_a_pet" value={formik?.values?.have_a_pet} onChange={formik.handleChange}>
                                <option value="">Select Yes or No</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                <Col sm={6} lg={4} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Mobile Phone</Form.Label>
                        <div className='d-flex'>
                            <Form.Select className="form-textbox" name="mobile_phone" value={formik?.values?.mobile_phone} onChange={formik.handleChange}>
                                <option value="">Select Mobile</option>
                                <option value="iPhone">iOS</option>
                                <option value="Android">Android</option>
                                <option value="Other">Other</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                <Col sm={6} lg={4} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Housing</Form.Label>
                        <div className='d-flex'>
                            <Form.Select className="form-textbox" name="housing" value={formik?.values?.housing} onChange={formik.handleChange}>
                                <option value="">Select Housing</option>
                                <option value="Own">Own</option>
                                <option value="Rent">Rent</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                <Col sm={6} lg={4} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Occupation</Form.Label>
                        <Form.Control
                            id="occupation"
                            type="text"
                            className="form-textbox"
                            placeholder='Enter Job Title'
                            value={formik?.values?.occupation}
                            onChange={formik.handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col sm={6} lg={4} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Mode Of Transportation</Form.Label>
                        <div className='d-flex'>
                            <Form.Select className="form-textbox" name="mode_of_transportation" value={formik?.values?.mode_of_transportation} onChange={formik.handleChange}>
                                <option value="">Select Mode Of Transportation</option>
                                <option value="Car">Car</option>
                                <option value="Public Transportation">Public Transportation</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                <Col sm={6} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Number of People in Household Using MightyMeals</Form.Label>
                        <Form.Control
                            id="how_many_people_in_house_use_mm"
                            type="text"
                            className="form-textbox"
                            placeholder='Number of people in Household using MightyMeals'
                            value={formik?.values?.how_many_people_in_house_use_mm}
                            onChange={formik.handleChange}
                            pattern="\d*"
                            onInput={(event: React.FormEvent<HTMLInputElement>) => {
                                const input = event.currentTarget;
                                const value = input.value.trim();
                                const newValue = value.replace(/[^\d]/g, '');
                                if (newValue !== value) {
                                    input.value = newValue;
                                    formik.setFieldValue('how_many_people_in_house_use_mm', newValue);;
                                }
                            }}
                        />
                    </Form.Group>
                </Col>
                <Col sm={6} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Number of Children in Household</Form.Label>
                        <Form.Control
                            id="number_of_children_in_household"
                            type="text"
                            className="form-textbox"
                            placeholder='Number of children in Household'
                            value={formik?.values?.number_of_children_in_household}
                            onChange={formik.handleChange}
                            pattern="\d*"
                            onInput={(event: React.FormEvent<HTMLInputElement>) => {
                                const input = event.currentTarget;
                                const value = input.value.trim();
                                const newValue = value.replace(/[^\d]/g, '');
                                if (newValue !== value) {
                                    input.value = newValue;
                                    formik.setFieldValue('number_of_children_in_household', newValue);;
                                }
                            }}
                        />
                    </Form.Group>
                </Col>
                <Col sm={6} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Do you want a member of our Mighty Concierge team to contact you?</Form.Label>
                        <div className='d-flex'>
                            <Form.Select className="form-textbox" name="do_member_of_our_mighty_concierge" value={formik?.values?.do_member_of_our_mighty_concierge} onChange={formik.handleChange}>
                                <option value="">Select Yes or No</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
                <Col sm={6} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Have You Tried Other Meal Prep Services?</Form.Label>
                        <div className='d-flex'>
                            <Form.Select className="form-textbox" name="have_you_ever_ordered_prepared_meal" value={formik?.values?.have_you_ever_ordered_prepared_meal} onChange={formik.handleChange}>
                                <option value="">Select Yes or No</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <div className='mt-2 d-flex justify-content-end flex-wrap'>
                <Button
                    className="col-12 col-md-auto me-md-2 mb-2"
                    variant="outline-dark"
                    disabled={busy}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant='success'
                    className='mb-2 col-12 col-md-auto'
                    type="submit"
                    disabled={busy || saved}
                    onClick={(e: any) => { e.preventDefault(); formik.handleSubmit(); }}>
                    {busy ? <><Spinner animation="border" as="span" size="sm" />
                        &nbsp;&nbsp;Saving ...</> : saved ? 'Saved!' : 'Save'}
                </Button>
            </div>
        </Form>
    )
}

export default AdditionInformationForm