export async function getCouponByCode(code: string) {
  let response = await (fetch(
    process.env.REACT_APP_SERVER_URL +
    '/coupons?code=' + code,
    {
      method: 'GET',
      headers: { 'content-type': 'application/json;charset=UTF-8' }
    }
  ));
  if (!response.ok) {
    throw new Error(`HTTP error. status: ${response.status}`);
  }

  return await response.json();
}

export async function getCoupons(token: string, email: string) {
  const isStaging = process.env.REACT_APP_API_URL === 'https://emmstaging.com/';
  const fetchURL = isStaging ? `${process.env.REACT_APP_API_URL}wp-json/mmr/v1/coupons?email=${email}`
    : `${process.env.REACT_APP_SERVER_URL}/coupons?token=${token}&email=${email}`;

  let response = await (fetch(
    fetchURL,
    {
      method: 'GET',
      headers: { 'content-type': 'application/json;charset=UTF-8' }
    }
  ));
  if (!response.ok) {
    throw new Error(`HTTP error. status: ${response.status}`);
  }
  return await response.json();
}

export interface CouponCreate {
  code: string;
  amount: string;
  usage_limit: number;
  usage_limit_per_user: number;
  enable_free_shipping: boolean;
  email_restrictions: Array<string>;
}

export async function createCoupon(
  token: string,
  coupon: CouponCreate
) {
  let response = await (fetch(
    process.env.REACT_APP_SERVER_URL + '/coupons',
    {
      method: 'POST',
      headers: { 'content-type': 'application/json;charset=UTF-8' },
      credentials: 'include',
      body: JSON.stringify({ token: token, coupon: coupon })
    }
  ));
  if (!response.ok) {
    throw new Error(`HTTP error. status: ${response.status}`);
  }
  return await response.json();
}